import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import ReactDOMServer from 'react-dom/server';
import { useCookies } from 'react-cookie';
import { TabContent, TabPane, Nav, NavItem, Alert, Card, CardHeader, CardBody, CardFooter, Row, Col, Modal, ModalBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { Input, Container } from 'reactstrap';
import PageSpinner from 'components/PageSpinner';
import { FaArrowLeft, FaCheck } from 'react-icons/fa';
import { toJpeg } from 'html-to-image';
import Carousel from 'react-grid-carousel';
import axios from 'axios';
import SeatPlanQR from '../assets/img/seat-plan-qr.png';
import softworks from '../assets/img/logo-cms/softworks.png';
import icon from '../assets/img/icon.png';
import irishTimes from '../assets/img/logo-cms/irish-times-logo.png';
import elis from '../assets/img/company-logos/elis.png';
import crannog from '../assets/img/company-logos/crannog.png';
import winesDirect from '../assets/img/company-logos/wines-direct.JPG';
import esb from '../assets/img/company-logos/esb.jpg';
import larnrod from '../assets/img/company-logos/larnrod.JPG';
import kirby from '../assets/img/company-logos/kirby.jpg';
import lmc from '../assets/img/company-logos/lmc.png';
import mitie from '../assets/img/company-logos/mitie.png';
import bam from '../assets/img/company-logos/bam.jpg';
import lotusWorks from '../assets/img/company-logos/lotus-works.jpg';
import faithfulGould from '../assets/img/company-logos/faithful-gould.JPG';
import fastway from '../assets/img/company-logos/fastway.png';
import leckey from '../assets/img/company-logos/leckey.png';
import mannok from '../assets/img/company-logos/mannok.png';
import tirlan from '../assets/img/company-logos/tirlan.jpg';
import gasNetworks from '../assets/img/company-logos/gas-networks.png';
import sensori from '../assets/img/company-logos/sensori.jpg';
import abbvie from '../assets/img/company-logos/abbvie.png';
import allview from '../assets/img/company-logos/allview.jpg';
import keltech from '../assets/img/company-logos/keltech.jpg';
import bridgewater from '../assets/img/company-logos/bridgewater.jpg';
import guildhall from '../assets/img/company-logos/guildhall.jpg';
import ruralWifi from '../assets/img/company-logos/rural-wifi.png';
import workhuman from '../assets/img/company-logos/workhuman.png';
import donoghue from '../assets/img/company-logos/donoghue.jpg';
import dpd from '../assets/img/company-logos/dpd.png';
import george from '../assets/img/company-logos/george.jpg';
import mias from '../assets/img/company-logos/mias.png';
import centric from '../assets/img/company-logos/centric.jpg';
import comfort from '../assets/img/company-logos/comfort.png';
import uisce from '../assets/img/company-logos/uisce.png';
import viking from '../assets/img/company-logos/viking.jpg';
import abbott from '../assets/img/company-logos/abbott.jpeg';
import griffithResidence from '../assets/img/company-logos/griffith-residence.png';
import griffithFaculty from '../assets/img/company-logos/griffith-faculty.jpg';
import west from '../assets/img/company-logos/west.jpg';
import anpost from '../assets/img/company-logos/anpost.png';
import eurofins from '../assets/img/company-logos/eurofins.PNG';
import ventac from '../assets/img/company-logos/ventac.png';
import breedon from '../assets/img/company-logos/breedon.png';
import primark from '../assets/img/company-logos/primark.jpg';
import kerrygold from '../assets/img/company-logos/kerrygold.png';
import southCoast from '../assets/img/company-logos/south-coast.jpg';
import skibbereen from '../assets/img/company-logos/skibbereen.jpg';
import salesforce from '../assets/img/company-logos/salesforce.png';
import atu from '../assets/img/company-logos/atu.jpg';
import cheshire from '../assets/img/company-logos/cheshire.jpg';
import iss from '../assets/img/company-logos/iss.png';
import teagasc from '../assets/img/company-logos/teagasc.jpg';
import cxv from '../assets/img/company-logos/cxv.png';
import kora from '../assets/img/company-logos/kora.png';
import metabolomic from '../assets/img/company-logos/metabolomic.jpeg';
import dentaqua from '../assets/img/company-logos/dentaqua.jpg';
import boston from '../assets/img/company-logos/boston-scientific.svg';
import almac from '../assets/img/company-logos/almac.png';
import medtronic from '../assets/img/company-logos/medtronic.jpg';
import mcardle from '../assets/img/company-logos/mcardle.png';
import cuh from '../assets/img/company-logos/cuh.jpg';
import iconPlc from '../assets/img/company-logos/icon-plc.jpg';
import janssen from '../assets/img/company-logos/janssen.png';
import syncrophi from '../assets/img/company-logos/syncrophi.jpg';
import cbre from '../assets/img/company-logos/cbre.jpg';
import pfizer from '../assets/img/company-logos/pfizer.png';
import deciphex from '../assets/img/company-logos/deciphex.png';
import ptc from '../assets/img/company-logos/ptc.jpg';
import abbotLsa from '../assets/img/company-logos/abbott-lsa.png';
import dmf from '../assets/img/company-logos/dmf.png';
import wuxi from '../assets/img/company-logos/wuxi.png';
import cec from '../assets/img/company-logos/cec.JPG';
import arrotek from '../assets/img/company-logos/arrotek.png';
import fsi from '../assets/img/company-logos/fsi.jpg';
import mcr from '../assets/img/company-logos/mcr.jpg';
import hgf from '../assets/img/company-logos/hgf.png';
import kuehne from '../assets/img/company-logos/kuehne.png';
import synergy from '../assets/img/company-logos/synergy.jpg';
import dhl from '../assets/img/company-logos/dhl.jpg';
import atuLsa from '../assets/img/company-logos/atu-lsa.jpg';
import lilly from '../assets/img/company-logos/lilly.jpg';
import tuDublin from '../assets/img/company-logos/tu-dublin.png';
import symphysis from '../assets/img/company-logos/symphysis.png';
import asgard from '../assets/img/company-logos/asgard.png';
import byrne from '../assets/img/company-logos/byrne.png';
import businessriver from '../assets/img/company-logos/businessriver.jpg';
import unispace from '../assets/img/company-logos/unispace-life-sciences.png';
import viatris from '../assets/img/company-logos/viatris.png';
import actylis from '../assets/img/company-logos/actylis.png';
import aldi from '../assets/img/company-logos/aldi.png';
import alexion from '../assets/img/company-logos/alexion.png';
import arthurCox from '../assets/img/company-logos/arthur-cox.jpg';
import clayton from '../assets/img/company-logos/clayton.jpg';
import dairygold from '../assets/img/company-logos/dairygold.png';
import dublinBus from '../assets/img/company-logos/dublin-bus.jpg';
import ebay from '../assets/img/company-logos/ebay.jpg';
import garrett from '../assets/img/company-logos/garrett.jpg';
import harmac from '../assets/img/company-logos/harmac.jpg';
import graham from '../assets/img/company-logos/graham.jpg';
import lidl from '../assets/img/company-logos/lidl.jpg';
import mahon from '../assets/img/company-logos/mahon.png';
import matheson from '../assets/img/company-logos/matheson.png';
import meritmedical from '../assets/img/company-logos/meritmedical.jpeg';
import queenUniversity from '../assets/img/company-logos/queen-university.png';
import refillz from '../assets/img/company-logos/refillz.jpg';
import sig from '../assets/img/company-logos/sig.png';
import swords from '../assets/img/company-logos/swords.png';
import samuel from '../assets/img/company-logos/samuel.jpg';
import thermo from '../assets/img/company-logos/thermo.jpg';
import wren from '../assets/img/company-logos/wren.png';
import jacobs from '../assets/img/company-logos/jacobs.png';
import isea from '../assets/img/company-logos/isea.jpg';
import climate from '../assets/img/company-logos/climate.png';
import aviva from '../assets/img/company-logos/aviva.jpg';
import abp from '../assets/img/company-logos/abp.jpg';
import gilead from '../assets/img/company-logos/gilead.png';
import analog from '../assets/img/company-logos/analog.jpeg';
import rcsi from '../assets/img/company-logos/rcsi.jpg';
import isa123 from '../assets/img/company-logos/123.ie.png';
import allianz from '../assets/img/company-logos/allianz.jpeg';
import avivaIsa from '../assets/img/company-logos/aviva-isa.jpg';
import electricIreland from '../assets/img/company-logos/electric-ireland.png';
import footballAssociationOfIreland from '../assets/img/company-logos/football-association-of-ireland.png';
import justEat from '../assets/img/company-logos/just-eat.png';
import kpmg from '../assets/img/company-logos/kpmg.jpg';
import legacyCommunications from '../assets/img/company-logos/legacy-communications.png';
import liveNation from '../assets/img/company-logos/live-nation.png';
import punchestownRacecourseEventVenue from '../assets/img/company-logos/punchestown-tacecourse-event-venue.png';
import pwc from '../assets/img/company-logos/pwc.jpg';
import rte from '../assets/img/company-logos/rte.png';
import sage from '../assets/img/company-logos/sage.png';
import supermacs from '../assets/img/company-logos/supermacs.jpg';
import theBigIdea from '../assets/img/company-logos/the-big-idea.png';
import urbanMedia from '../assets/img/company-logos/urban-media.png';
import urbanmedia from '../assets/img/company-logos/urban-media.png';
import badmintonIreland from '../assets/img/company-logos/badminton-ireland.png';
import bordGaisEnergy from '../assets/img/company-logos/bord-gais-energy.jpg';
import irishLand from '../assets/img/company-logos/irish-life.png';
import permanentTsb from '../assets/img/company-logos/permanent-tsb.png';
import vhi from '../assets/img/company-logos/vhi.jpg';
import bwgFoodsSparIteland from '../assets/img/company-logos/bwg-foods-spar-ireland.jpg';
import dublinInternationalFilmFestival from '../assets/img/company-logos/dublin-international-film-festival.jpg';
import esbIsa from '../assets/img/company-logos/esb.jpg';
import forefrontSports from '../assets/img/company-logos/forefront-sports.webp';
import greyhoundRacingIreland from '../assets/img/company-logos/greyhound-racing-ireland.png';
import horseRacingIreland from '../assets/img/company-logos/horse-racing-ireland.png';
import kick from '../assets/img/company-logos/kick.png';
import pentlandBrandsCanterbury from '../assets/img/company-logos/pentland-brands-canterbury.jpg';
import specialOlympicsIreland from '../assets/img/company-logos/special-olympics-ireland.png';
import specialOlympicsIrelandEir from '../assets/img/company-logos/special-olympics-ireland.png';
import teneo from '../assets/img/company-logos/teneo.png';
import theCamogieAssocation from '../assets/img/company-logos/the-camogie-association.jpg';
import titanExperience from '../assets/img/company-logos/titan-experience.png';
import pinergy from '../assets/img/company-logos/pinergy.png';
import aerLingus from '../assets/img/company-logos/aer-lingus.png';
import futureProofOfMedia from '../assets/img/company-logos/future-proof-of-media.png';
import imageMedia from '../assets/img/company-logos/image-media.png';
import leaderKicking from '../assets/img/company-logos/leader-kicking.png';
import newsIreland from '../assets/img/company-logos/news-ireland.jpg';
import zenith from '../assets/img/company-logos/zenith.jpg';
import mindshareMediaIreland from '../assets/img/company-logos/mindshare-media-ireland.jpg';
import sweartaker from '../assets/img/company-logos/sweartaker.jpg';
import aig from '../assets/img/company-logos/aig.png';
import cleanCoast from '../assets/img/company-logos/clean-coasts.png';
import glenveagh from '../assets/img/company-logos/glenveagh.png';
import silverCloud from '../assets/img/company-logos/silver-cloud.png';
import hotPress from '../assets/img/company-logos/hot-press.png';
import businessRiverIsa from '../assets/img/company-logos/businessriver.jpg';
import mediahuisIreland from '../assets/img/company-logos/mediahuis-ireland.jpg';
import nationalConcertHall from '../assets/img/company-logos/national-concert-hall.jpg';
import theSundayTimes from '../assets/img/company-logos/the-sunday-times.jpg';
import accordHealthcare from '../assets/img/company-logos/accord-healthcare.jpg';
import almacGroup from '../assets/img/company-logos/almac.png';
import biopharmaDynamics from '../assets/img/company-logos/biopharma-dynamics.png';
import bristolLaboratoriesLtd from '../assets/img/company-logos/bristol-laboratories-ltd.jpg';
import businessRiver from '../assets/img/company-logos/businessriver.jpg';
import cellomaticsBiosciencesLimited from '../assets/img/company-logos/cellomatics-biosciences-limited.png';
import chiesi from '../assets/img/company-logos/chiesi.png';
import diaceuticsPlc from '../assets/img/company-logos/diaceutics-plc.png';
import hertilityHealth from '../assets/img/company-logos/hertility-health.png';
import insife from '../assets/img/company-logos/insife.jpg';
import inspiredPharmaTrainingLtd from '../assets/img/company-logos/inspired-pharma-training-ltd.jpg';
import ipsenBioharmLtd from '../assets/img/company-logos/ipsen-biopharma-ltd.png';
import kiwiBiosciences from '../assets/img/company-logos/kiwi-biosciences.png';
import kuehneNagelLtd from '../assets/img/company-logos/kuehne.png';
import medisonal from '../assets/img/company-logos/medisonal.jpg';
import micregen from '../assets/img/company-logos/micregen.jpg';
import pantheraBiopartners from '../assets/img/company-logos/panthera-biopartners.png';
import paradigmShiftConsultingLimited from '../assets/img/company-logos/paradigm-shift-consuling-limited.jpg';
import pharsafer from '../assets/img/company-logos/pharsafer.jpg';
import rosemontPharmaceuticals from '../assets/img/company-logos/rosemont-pharmaceuticals.jpg';
import sedaPharmaceuticalDevelopmentServices from '../assets/img/company-logos/seda-pharmaceutical-development-services.jpeg';
import semarion from '../assets/img/company-logos/semarion.png';
import taurxPharmaceuticalsLtd from '../assets/img/company-logos/taurx-pharmaceuticals-ltd.png';
import tiroTrainingLtd from '../assets/img/company-logos/tiro-training-ltd.jpg';
import uptake from '../assets/img/company-logos/uptake.png';
import dawsongroup from '../assets/img/company-logos/dawsongroup.png';
import skycell from '../assets/img/company-logos/skycell.png';
import agility from '../assets/img/company-logos/agility.png';
import aquaclinical from '../assets/img/company-logos/aquaclinical.jpg';
import arecor from '../assets/img/company-logos/arecor.jpg';
import astrazeneca from '../assets/img/company-logos/astrazeneca.png';
import besinsHealthcareUkLimited from '../assets/img/company-logos/besins-healthcare-uk-limited.jpg';
import biocare from '../assets/img/company-logos/biocair.png';
import boydsConsultans from '../assets/img/company-logos/boyds-consultants.jpg';
import deepxHealth from '../assets/img/company-logos/deepx-health.jpeg';
import dexcelPharma from '../assets/img/company-logos/dexcel-pharma.png';
import internationalHealthPartners from '../assets/img/company-logos/international-health-partners.png';
import kingstonUniversityLondon from '../assets/img/company-logos/kingston-university-london.png';
import kyowaKirin from '../assets/img/company-logos/kyowa-kirin.png';
import microBioIrelandLtd from '../assets/img/company-logos/micro-bio-ireland-ltd.jpg';
import pharmanovia from '../assets/img/company-logos/pharmanovia.png';
import universityCollegeLondonUcl from '../assets/img/company-logos/university-college-london-ucl.jpg';
import universityOfEastAnglia from '../assets/img/company-logos/university-of-east-anglia.png';
import bionow from '../assets/img/company-logos/bionow.jpg';
import cognizant from '../assets/img/company-logos/cognizant.jpg';
import unispaceLifeSciences from '../assets/img/company-logos/unispace-life-sciences.png';
import approachPeopleRecruitment from '../assets/img/company-logos/approach-people-recruitment.png';
import busEirann from '../assets/img/company-logos/bus-eireann.png';
import businessriverHr from '../assets/img/company-logos/businessriver.jpg';
import cartonHouseResort from '../assets/img/company-logos/carton-house-resort.jpg';
import chadwicksGroup from '../assets/img/company-logos/chadwicks-group.png';
import circet from '../assets/img/company-logos/circet.png';
import clunetech from '../assets/img/company-logos/clunetech.jpg';
import complianceAndRisks from '../assets/img/company-logos/compliance-and-risks.png';
import copaceticBusinessSolutionLtd from '../assets/img/company-logos/copacetic-business-solution-ltd.jpg';
import cpl from '../assets/img/company-logos/cpl.png';
import cpmIreland from '../assets/img/company-logos/cpm-ireland.jpg';
import cyaraIrelandLimited from '../assets/img/company-logos/cyara-ireland-limited.png';
import decare from '../assets/img/company-logos/decare.png';
import deparmentOfHealth from '../assets/img/company-logos/department-of-health.png';
import deparmentOfTransport from '../assets/img/company-logos/department-of-transport.png';
import digitalMarketingInstitute from '../assets/img/company-logos/digital-marketing-institute.jpg';
import dpdIreland from '../assets/img/company-logos/dpd-ireland.jpg';
import firstDerivative from '../assets/img/company-logos/first-derivative.jpg';
import fragomenIrelandLtd from '../assets/img/company-logos/fragomen-ireland-ltd.png';
import gleneagleHotelGroup from '../assets/img/company-logos/gleneagle-hotel-group.png';
import iconPlcHr from '../assets/img/company-logos/icon-plc.jpg';
import irishRail from '../assets/img/company-logos/irish-rail.jpg';
import kerryDiocesanYouthService from '../assets/img/company-logos/kerry-diocesan-youth-service.jpg';
import materMisericordiaeUniversityHospital from '../assets/img/company-logos/mater-misericordiae-university-hospital.jpg';
import materPrivateNetwork from '../assets/img/company-logos/mater-private-network.jpg';
import mckesson from '../assets/img/company-logos/mckesson.jpg';
import nibrt from '../assets/img/company-logos/nibrt.png';
import oriflameRndLtd from '../assets/img/company-logos/oriflame-rnd-ltd.jpg';
import permanentTsbHr from '../assets/img/company-logos/hrpermanent-tsb.png';
import pobal from '../assets/img/company-logos/pobal.jpg';
import ptcTherapeutics from '../assets/img/company-logos/ptc-therapeutics.jpg';
import rcsiHr from '../assets/img/company-logos/rcsi.jpg';
import sdworx from '../assets/img/company-logos/sd-worx.jpg';
import telusInternational from '../assets/img/company-logos/telus-international.png';
import trigonHotels from '../assets/img/company-logos/trigon-hotels.jpg';
import tusla from '../assets/img/company-logos/tusla.png';
import valeoFoodsIreland from '../assets/img/company-logos/valeo-foods-ireland.png';
import version1 from '../assets/img/company-logos/version-1.jpg';
import vmware from '../assets/img/company-logos/vmware.png';
import voltedgeManagement from '../assets/img/company-logos/voltedge-management.png';
import woodies from '../assets/img/company-logos/woodies.png';
import h2Compliance from '../assets/img/company-logos/h2-compliance.jpg';
import inspiros from '../assets/img/company-logos/inspiros.png';
import avolon from '../assets/img/company-logos/avolon.png';
import hse from '../assets/img/company-logos/health-service-executive.png';
import baxterStorey from '../assets/img/company-logos/baxter-storey.jpg';
import excelRecruitment from '../assets/img/company-logos/excel-recruitment.png';
import healthAndSafetyAuthority from '../assets/img/company-logos/health-and-safety-authority.png';
import healthServiceExecutive from '../assets/img/company-logos/health-service-executive.png';
import idaireland from '../assets/img/company-logos/ida-ireland.png';
import masonHayesCurran from '../assets/img/company-logos/mason-hayes-curran.png';
import ncbi from '../assets/img/company-logos/ncbi.jpg';
import orpheaResidencesIreland from '../assets/img/company-logos/orpea-residences-reland.jpg';
import primeline from '../assets/img/company-logos/primeline.jpg';
import primelineExpress from '../assets/img/company-logos/primeline.jpg';
import steeringPoint from '../assets/img/company-logos/steering-point.png';
import dublinCityUniversity from '../assets/img/company-logos/dublin-city-university.png';
import ryanHrsLtd from '../assets/img/company-logos/ryan-hrs-ltd.png';
import SponsorsMarquee from '../components/SponsorsMarquee';
import alexionAthlone from '../assets/img/company-logos/alexion-pharma.jpg';
import alexionPharma from '../assets/img/company-logos/alexion-pharma.jpg';
import amgen from '../assets/img/company-logos/amgen.jpg';
import apcVleTherapeutics from '../assets/img/company-logos/apc-vle-therapeutics.jpg';
import asgardCleanroomSolutions from '../assets/img/company-logos/asgard.png';
import bauschHealth from '../assets/img/company-logos/bausch-health.jpg';
import biocair from '../assets/img/company-logos/biocair.png';
import biopharmachemIreland from '../assets/img/company-logos/biopharmachem-ireland.jpg';
import biostorIreland from '../assets/img/company-logos/biostor-ireland.png';
import blynksolve from '../assets/img/company-logos/blynksolve.jpg';
import bostonScientific from '../assets/img/company-logos/boston-scientific.png';
import bristolMyersSquibb from '../assets/img/company-logos/bristol-myers-squibb.png';
import piaIrelandCbre from '../assets/img/company-logos/cbre.jpg';
import piaIrelandCognizant from '../assets/img/company-logos/cognizant.jpg';
import piaIrelandcpl from '../assets/img/company-logos/cpl.png';
import eliLilly from '../assets/img/company-logos/eli-lilly.jpg';
import eurofinsBpt from '../assets/img/company-logos/eurofins-bpt.jpg';
import getreskilled from '../assets/img/company-logos/getreskilled.jpg';
import piaIrelandh2Compliance from '../assets/img/company-logos/h2-compliance.jpg';
import hanleyCalibration from '../assets/img/company-logos/hanley-calibration.jpg';
import horizonTherapeiticsNowAPartAmgen from '../assets/img/company-logos/horizon-therapeutics-now-a-part-of-amgen.jpg';
import janssenSciencesIreland from '../assets/img/company-logos/janssen.png';
import kiernanSteel from '../assets/img/company-logos/kiernan-steel.jpg';
import koerberPharmaSoftware from '../assets/img/company-logos/koerber-pharma-software.jpg';
import piaIrelandLotusworks from '../assets/img/company-logos/lotusworks.png';
import piaIrelandMckesson from '../assets/img/company-logos/mckesson.jpg';
import merckKgaa from '../assets/img/company-logos/merck-kgaa.jpg';
import metabolomicDiagnostics from '../assets/img/company-logos/metabolomic-diagnostics.jpg';
import microBioIreland from '../assets/img/company-logos/micro-bio-ireland.jpg';
import msd from '../assets/img/company-logos/msd.png';
import msdIreland from '../assets/img/company-logos/msd.png';
import piaIrelandNibrt from '../assets/img/company-logos/nibrt.png';
import nutriband from '../assets/img/company-logos/nutriband.jpg';
import obwTechnologies from '../assets/img/company-logos/obw-teachnologies.png';
import pfizerIrelandPharmaceuticalsRingaskiddy from '../assets/img/company-logos/pfizer-ireland-pharmaceuticals-ringaskiddy.jpg';
import pharmaIntegrity from '../assets/img/company-logos/pharma-integrity.png';
import q1Scientific from '../assets/img/company-logos/q1-scientific.png';
import piaIrelandSkycell from '../assets/img/company-logos/skycell.png';
import sspc from '../assets/img/company-logos/sspc.png';
import piaIrelandSynergy from '../assets/img/company-logos/synergy.jpg';
import takedaIreland from '../assets/img/company-logos/takeda-ireland.jpg';
import piaIrelandTuDublin from '../assets/img/company-logos/tu-dublin.png';
import piaIrelandUnispaceLifeSciences from '../assets/img/company-logos/unispace-life-sciences.png';
import viatrisLittleIsland from '../assets/img/company-logos/viatris-little-island.jpg';
import wdExcellence from '../assets/img/company-logos/wd-excellence.jpg';
import westPharceuticalServices from '../assets/img/company-logos/west.jpg';
import wolfMedical from '../assets/img/company-logos/wolf-medical.png';
import wuxiBiologicsIreland from '../assets/img/company-logos/wuxi.png';
import zoetis from '../assets/img/company-logos/zoetis.jpg';
import bakerFinn from '../assets/img/company-logos/baker-finn.jpg';
import bocGasses from '../assets/img/company-logos/boc-gasses.jpg';
import camida from '../assets/img/company-logos/camida.png';
import ecolab from '../assets/img/company-logos/ecolab.jpg';
import ellabIreland from '../assets/img/company-logos/ellab-ireland.png';
import indaver from '../assets/img/company-logos/indaver.jpg';
import nextGeneration from '../assets/img/company-logos/next-generation.jpg';
import kpc from '../assets/img/company-logos/kpc-international.png';
import quickstat from  '../assets/img/company-logos/quickstat.jpeg';
import catalyx from '../assets/img/company-logos/catalyx.jpg';
import titanExperiencePia from '../assets/img/company-logos/titan-experience-pia.png';
import pmbrc from '../assets/img/company-logos/pmbrc.jpg';
import mtu from '../assets/img/company-logos/mtu.png';
import careerVision from '../assets/img/company-logos/career-vision.png';
import ucc from '../assets/img/company-logos/ucc.png';
import actavo from '../assets/img/company-logos/actavo.png';
import alternativeHeat from '../assets/img/company-logos/alternative-heat.png';
import alucraft from '../assets/img/company-logos/alucraft.png';
import arcadisIrelandLimited from '../assets/img/company-logos/arcadis-ireland-limited.png';
import ardmac from '../assets/img/company-logos/ardmac.jpg';
import asee from '../assets/img/company-logos/asee.png';
import ashviewConsultantsLtd from '../assets/img/company-logos/ashview-consultants-ltd.png';
import axisengMELtd from '../assets/img/company-logos/axiseng-m-e-ltd.png';
import ballymore from '../assets/img/company-logos/ballymore.png';
import bamIreland from '../assets/img/company-logos/bam-ireland.png';
import bennetConstructionLtd from '../assets/img/company-logos/bennett-construction-ltd.png';
import boyleConstruction from '../assets/img/company-logos/boyle-construction.jpg';
import breedonIcia from '../assets/img/company-logos/bredon.jpg';
import cairnHomes from '../assets/img/company-logos/cairn-homes.png';
import cfieldConstruction from '../assets/img/company-logos/cfield-construction.png';
import clancyEngineering from '../assets/img/company-logos/clancy-engineering.jpg';
import collenConstruction from '../assets/img/company-logos/collen-construction.png';
import cunningmanCivilMarineLtd from '../assets/img/company-logos/cunningman-civil-marine-ltd.png';
import cunninghamContracts from '../assets/img/company-logos/cunningham-contracts-group.png';
import dugganBrothers from '../assets/img/company-logos/duggan-brothers.png';
import dukeMccaffrey from '../assets/img/company-logos/duke-mccaffrey.jpg';
import edcEngineeringDesignConsultantsLimited from '../assets/img/company-logos/edc-engineering-design-consultants-limited.png';
import ethosEngineering from '../assets/img/company-logos/ethos-engineering.jpg';
import extremeBalconies from '../assets/img/company-logos/extreme-balconies.png';
import flynn from '../assets/img/company-logos/flynn.jpg';
import hollis from '../assets/img/company-logos/hollis.png';
import instrotecLtd from '../assets/img/company-logos/instrotec-ltd.png';
import jcpsMechanicalLtd from '../assets/img/company-logos/jcps-mechanical-ltd.png';
import johnPaulConstruction from '../assets/img/company-logos/john-paul-constuction.png';
import johnSiskSon from '../assets/img/company-logos/john-sisk-son.png';
import jonesEngineering from '../assets/img/company-logos/jones-engineering.jpg';
import jpmContractsLtd from '../assets/img/company-logos/jpm-contracts-ltd.jpg';
import kelbuildLtd from '../assets/img/company-logos/kelbuild-ltd.jpg';
import kiernanSteelLtd from '../assets/img/company-logos/kiernan-steel-ltd.jpg';
import kingMoffattBuildingServices from '../assets/img/company-logos/king-moffatt-building-services.png';
import kirbyGroup from '../assets/img/company-logos/kirby-group.png';
import lmdBuild from '../assets/img/company-logos/lmd-build.jpg';
import mccabeGroup from '../assets/img/company-logos/mccabe-group.jpg';
import mccauleyDateOConnellArchitects from '../assets/img/company-logos/mccauley-date-o-connell-architects.png';
import miethealArchitects from '../assets/img/company-logos/meitheal-architects.png';
import mitchellMcdermott from '../assets/img/company-logos/mitchell-mcdermott.png';
import molaArchitecture from '../assets/img/company-logos/mola-architecture.png';
import murphyIreland from '../assets/img/company-logos/murphy-ireland.png';
import nationalBroadbandIreland from '../assets/img/company-logos/national-broadband-ireland.png';
import ors from '../assets/img/company-logos/ors.png';
import pMcveyBuildingSystems from '../assets/img/company-logos/p-mcvey-building-systems.jpg';
import qmacConstructionLtd from '../assets/img/company-logos/qmac-construction-ltd.jpg';
import reddyArchitectureUrbanism from '../assets/img/company-logos/reddy-architecture-urbanism.png';
import rocculLtd from '../assets/img/company-logos/roccul-ltd.jpg';
import scottTallonWalkerArchitects from '../assets/img/company-logos/scott-tallon-walker-architects.png';
import sesWaterManagement from '../assets/img/company-logos/ses-water-management.jpg';
import shareridge from '../assets/img/company-logos/shareridge.jpg';
import shayMurtaghPrecast from '../assets/img/company-logos/shay-murtagh-precast.png';
import stoneconLimited from '../assets/img/company-logos/stonecon-limited.png';
import suirEngineeringLtd from '../assets/img/company-logos/suir-engineering-ltd.png';
import timelessSashWindows from '../assets/img/company-logos/timeless-sash-windows.png';
import titanRoofingLtd from '../assets/img/company-logos/titan-roofing-ltd.png';
import truwood from '../assets/img/company-logos/truwood.png';
import visionContracting from '../assets/img/company-logos/vision-contracting.png';
import wallsConstruction from '../assets/img/company-logos/walls-construction.png';
import writech from '../assets/img/company-logos/writech.png';
import boomEdam from '../assets/img/company-logos/boon-edam.png';
import globoserv from '../assets/img/company-logos/globoserv.png';
import kccGroup from '../assets/img/company-logos/kcc-group.png';
import williamCox from '../assets/img/company-logos/william.png';
import meinhardtIreland from '../assets/img/company-logos/meinhardt-ireland.jpg';
import techRefrigeration from '../assets/img/company-logos/tech-refrigeration.png';
import lumenSearch from '../assets/img/company-logos/lumen.png';
import mmcIreland from '../assets/img/company-logos/mmc-ireland.png';
import considerateConstructorsScheme from '../assets/img/company-logos/mmc-ireland.png';
import korec from '../assets/img/company-logos/korec.png';
import its from '../assets/img/company-logos/its.png';
import robinmandal from '../assets/img/company-logos/robin-mandal.png';
import propertyDistrict from '../assets/img/company-logos/property-disctrict.png';
import technologicalUniversity from '../assets/img/company-logos/technological-university.png';
import southEastTechno from '../assets/img/company-logos/south-east-technological.png';
import alliedIrish from '../assets/img/company-logos/allied-irish-bank.png';
import layherIreland from '../assets/img/company-logos/layher-ireland.png';
import uValue from '../assets/img/company-logos/u-value-insulation.png';
import ksn from '../assets/img/company-logos/ksn-horizon.png';
import anPost from '../assets/img/company-logos/anpost.jpg';
import bordNaMona from '../assets/img/company-logos/bord-na-mona.jpg';
import busEireann from '../assets/img/company-logos/bus-eireann.jpg';
import compassGroupIreland from '../assets/img/company-logos/compass-group-ireland.jpg';
import danskoFoods from '../assets/img/company-logos/dansko-foods.jpg';
import dublinBusNpa from '../assets/img/company-logos/dublin-bus-npa2023.jpg';
import esbNpa from '../assets/img/company-logos/esb-npa2023.jpg';
import greenvilleProcurementPartners from '../assets/img/company-logos/greenville-procurement-partners.jpg';
import griffithCollege from '../assets/img/company-logos/griffith-college.png';
import healthServiceExecutiveNpa from '../assets/img/company-logos/hse.jpg';
import heinekenIreland from '../assets/img/company-logos/heineken-ireland.jpg';
import hseNpa from '../assets/img/company-logos/hse.jpg';
import hseServicesForOldPeople from '../assets/img/company-logos/hse.jpg';
import hseServicesForOldPeopleCopy from '../assets/img/company-logos/hse.jpg';
import iarnodEirann from '../assets/img/company-logos/larnrod-eireann-irish-rail.jpg';
import meadeFarmGroup from '../assets/img/company-logos/meade-farm-group.jpg';
import msdNpa from '../assets/img/company-logos/msd-npa2023.png';
import musgrave from '../assets/img/company-logos/musgrave.jpg';
import obwTechnologiesNpa from '../assets/img/company-logos/obw-teachnologies.png';
import officeOfGovermentProcurement from '../assets/img/company-logos/office-of-government-procurement.jpg';
import penneysPrimark from '../assets/img/company-logos/penneys-primark.jpg';
import permanentTsbAndEfficio from '../assets/img/company-logos/permanent-tsb-efficio.png';
import pfizerIrelandPharmaceuticals from '../assets/img/company-logos/pfizer-ireland-pharmaceuticals.jpg';
import uisceEireann from '../assets/img/company-logos/uisce-eireann.jpg';
import universityCollegeCork from '../assets/img/company-logos/university-college-cork.jpg';
import verizon from '../assets/img/company-logos/verizon.jpg';
import westPharmaceutical from '../assets/img/company-logos/west.jpg';
import transportInfrastructureIreland from '../assets/img/company-logos/transport-infrastructure.png';
import bristolMyersSquibbNpa from '../assets/img/company-logos/bristol-myers-squibb.png';
import kpmgNpa from '../assets/img/company-logos/kpmg.jpg';
import sigma from '../assets/img/company-logos/sigma.png';
import accountOnline from '../assets/img/company-logos/accountant-online.png';
import aerLingusIcx from '../assets/img/company-logos/aer-lingus.png';
import aibIcx from '../assets/img/company-logos/allied-irish-bank.png';
import allviewHealthcare from '../assets/img/company-logos/allview.jpg';
import bankOfIreland from '../assets/img/company-logos/bank-of-ireland.png';
import busEirannIrishBus from '../assets/img/company-logos/bus-eireann.jpg';
import businessRiverIcx from '../assets/img/company-logos/businessriver.jpg';
import calor from '../assets/img/company-logos/calor.png';
import circit from '../assets/img/company-logos/circit.png';
import coll8Logisctics from '../assets/img/company-logos/coll-8-logistics.png';
import diageoIreland from '../assets/img/company-logos/diageo.png';
import dubarryOfIreland from '../assets/img/company-logos/dubarry-ireland.png';
import eason from '../assets/img/company-logos/eason.png';
import electricIrelandIcx from '../assets/img/company-logos/electric-ireland.png';
import esbNetworks from '../assets/img/company-logos/esb-networks.png';
import fastwayIcx from '../assets/img/company-logos/fastway.png';
import fragomenIrealandIcx from '../assets/img/company-logos/fragomen.png';
import gasNetworksIreland from '../assets/img/company-logos/gas-networks-ireland.png';
import irishLifeIcx from '../assets/img/company-logos/irish-life.png';
import karaConnect from '../assets/img/company-logos/kara-connect.png';
import kylemoreCars from '../assets/img/company-logos/kylemore-cars.png';
import kylemoreCarsIcx from '../assets/img/company-logos/kylemore-cars.png';
import libertyInsurance from '../assets/img/company-logos/liberty-insurance.png';
import locoScootersLtd from '../assets/img/company-logos/loco-scooters.png';
import magnetPlus from '../assets/img/company-logos/magnet-plus.png';
import musgraveIcx from '../assets/img/company-logos/musgrave.jpg';
import nationalRehabilitationHospital from '../assets/img/company-logos/national-rehabilitation-hospital.png';
import oakviewEarlyYearsServices from '../assets/img/company-logos/oakview-early-years-services.png';
import passportServiceDepartmentOfForeignAffairs from '../assets/img/company-logos/passport-service-department-of-foreign-affairs.png';
import pennypopIcx from '../assets/img/company-logos/pennypop.png';
import queensUniversity from '../assets/img/company-logos/queens-university.png';
import shawsDepartmentStores from '../assets/img/company-logos/shaws-dept-store.png';
import sseAirtricity from '../assets/img/company-logos/sse-airtricity.png';
import theConventionCentreDublin from '../assets/img/company-logos/the-convention-centre-dublin.png';
import trinityBrandCommercialServicesLtd from '../assets/img/company-logos/trinity-brand-commercial-services-ltd.png';
import universityOfLimerick from '../assets/img/company-logos/university-limerick.png';
import cxCompany from '../assets/img/company-logos/cx-company.png';
import cxpa from '../assets/img/company-logos/cxpa.png';
import epam from '../assets/img/company-logos/epam.png';
import gs from '../assets/img/company-logos/gs.png';
import imi from '../assets/img/company-logos/imi.png';
import ucd from '../assets/img/company-logos/ucd.png';
import w5 from '../assets/img/company-logos/w5.png';
import rentalMatic from '../assets/img/company-logos/rental-matic.png';
import cxia123 from '../assets/img/company-logos/cxia-123.png';
import locoScooterLtd from '../assets/img/company-logos/loco-scooter.png';
import theExperienceEdge from '../assets/img/company-logos/the-experience-edge.png';
import alphaMechanicalServices from '../assets/img/company-logos/alpha-mechanical-services.png';
import anPostFoa from '../assets/img/company-logos/anpost.jpg';
import ardmacFoa from '../assets/img/company-logos/ardmac.jpg';
import arup from '../assets/img/company-logos/arup.png';
import ashWilsonDesign from '../assets/img/company-logos/ash-wilson-design.png';
import ashenCLoud from '../assets/img/company-logos/ashen-cloud.png';
import altanticTechnologicalUniversity from '../assets/img/company-logos/atu-lsa.jpg';
import axisengME from '../assets/img/company-logos/axiseng-m-e-ltd.png';
import azureContractingLtd from '../assets/img/company-logos/azure-contracting.png';
import bsgDesign from '../assets/img/company-logos/bsg-design.png';
import builtInteriorLtd from '../assets/img/company-logos/built-interior-ltd.png';
import busEirannFoa from '../assets/img/company-logos/bus-eireann.jpg';
import byrneCustomJoinery from '../assets/img/company-logos/bryne-custom-joinery.png';
import calibroWorkspace from '../assets/img/company-logos/calibro-workspace.png';
import castleBrook from '../assets/img/company-logos/castlebrook.png';
import cbreFoa from '../assets/img/company-logos/cbre.jpg';
import cogentAssociates from '../assets/img/company-logos/cogent-associates.png';
import commaArchitects from '../assets/img/company-logos/comma-architects.png';
import corgan from '../assets/img/company-logos/corgan.png';
import crmFitOut from '../assets/img/company-logos/crm-fit-out.png';
import davidJamesRetailSolutions from '../assets/img/company-logos/david-james-retail-solutions.png';
import diarmuidReilArchitects from '../assets/img/company-logos/diarmuid-reil-architects.png';
import ddmodArchitects from '../assets/img/company-logos/dmod-architects.png';
import dmvfArchitects from '../assets/img/company-logos/dmvf-architects.png';
import dukeMccaffreyConsulting from '../assets/img/company-logos/duke-mccaffrey.jpg';
import edc from '../assets/img/company-logos/edc-engineering-design-consultants-limited.png';
import editDesignStudio from '../assets/img/company-logos/edit-design-studio.png';
import elliottGroup from '../assets/img/company-logos/elliott-group.png';
import elmGray from '../assets/img/company-logos/elm-gray.png';
import ethosEngineeringFoa from '../assets/img/company-logos/ethos-engineering.jpg';
import gdcInteriors from '../assets/img/company-logos/gdc-interiors.png';
import globoservLtd from '../assets/img/company-logos/globoserv.png';
import griffithCollegeFoa from '../assets/img/company-logos/griffith-college.png';
import hamiltonRobson from '../assets/img/company-logos/hamilton-robson.png';
import heleneBroderickInteriors from '../assets/img/company-logos/helene-broderick-interiors.png';
import henryJLyons from '../assets/img/company-logos/henry-j-lyons.png';
import iconicOffices from '../assets/img/company-logos/iconic-offices.png';
import interiorsBySisk from '../assets/img/company-logos/interiors-by-sisk.png';
import jcaArchitects from '../assets/img/company-logos/jca-architects.png';
import jenningsDesignStudioArchitects from '../assets/img/company-logos/jennings-design-studio-architects.png';
import kingstonLaffertyDesign from '../assets/img/company-logos/kingston-lafferty-design.png';
import knightFrank from '../assets/img/company-logos/knight-frank.png';
import kota from '../assets/img/company-logos/kota.png';
import kpmgFoa from '../assets/img/company-logos/kpmg.jpg';
import llSolutions from '../assets/img/company-logos/ll-solutions.png';
import lucaArchitecture from '../assets/img/company-logos/luca-architecture.png';
import mcaArchitects from '../assets/img/company-logos/mca-architects.png';
import mcmahonAssociates from '../assets/img/company-logos/mcmahon-associates.png';
import millimetreDesign from '../assets/img/company-logos/millimetre-design.png';
import molaArchitectureFoa from '../assets/img/company-logos/mola-architecture.png';
import oDonnelONiellDesign from '../assets/img/company-logos/o-donnell-o-neill-design.png';
import oakPm from '../assets/img/company-logos/oak-pm.png';
import oppermannAssociates from '../assets/img/company-logos/oppermann-associates.png';
import perkinsWill from '../assets/img/company-logos/perkins-will.png';
import foaFlynn from '../assets/img/company-logos/flynn.png';
import pureFitoutAssociatedLtd from '../assets/img/company-logos/pure-fitout-associated.png';
import reddyArchitectureUrbanismFoa from '../assets/img/company-logos/reddy-architecture-urbanism.png';
import roccul from '../assets/img/company-logos/roccul.png';
import saulDesign from '../assets/img/company-logos/saul-design.png';
import sineadCassidyDesign from '../assets/img/company-logos/sinead-cassidy-design.png';
import stephenCarrArchitects from '../assets/img/company-logos/stephen-carr-architects.png';
import structureTone from '../assets/img/company-logos/structure-tone.png';
import tIFitouts from '../assets/img/company-logos/t-i-fitouts.png';
import tapCreative from '../assets/img/company-logos/tap-creative.png';
import theInteriorGroup from '../assets/img/company-logos/the-interiors-group.png';
import toddArchitects from '../assets/img/company-logos/todd-architects.png';
import totalFitout from '../assets/img/company-logos/total-fitout.png';
import tuDublinFoa from '../assets/img/company-logos/tu-dublin.png';
import turnerTownsend from '../assets/img/company-logos/turnet-townsend.png';
import vardisGroup from '../assets/img/company-logos/vardis-group.png';
import wilsonArchitecture from '../assets/img/company-logos/wilson-architecture.png';
import ygInteriors from '../assets/img/company-logos/yg-interiors.png';
import dcon from '../assets/img/company-logos/dcon.png';
import procore from '../assets/img/company-logos/procore.png';
import w2w from '../assets/img/company-logos/w2w.png';
import bene from '../assets/img/company-logos/bene.jpg';
import optima from '../assets/img/company-logos/optima.jpg';
import johnstonFitout from '../assets/img/company-logos/johnson-fitout.png';
import mjfInteriors from '../assets/img/company-logos/mjf.jpg';
import etag from '../assets/img/company-logos/etag.png';
import radii from '../assets/img/company-logos/radii.png';
import solutions from '../assets/img/company-logos/solutions.png';
import janJanssens from '../assets/img/company-logos/jan-janssens.png';
import sigWorkplace from '../assets/img/company-logos/sig.png';
import sas from '../assets/img/company-logos/sas.jpg';
import lumenFoa from '../assets/img/company-logos/lumen-search.jpg';
import vescom from '../assets/img/company-logos/vescom.jpg';
import greenLam from '../assets/img/company-logos/green-lam.jpeg';
import finsaIreland from '../assets/img/company-logos/finsa.png';
import mckeonGroup from '../assets/img/company-logos/mckeon-group.png';
import zipWater from '../assets/img/company-logos/zip-water.png';
import hamiltonRobsonFoa from '../assets/img/company-logos/hamilton-robson.jpg';
import uValueFoa from '../assets/img/company-logos/u-value.png';
import textSpace from '../assets/img/company-logos/texspace.png';
import autexAcoustics from '../assets/img/company-logos/autex-acoustic.png';
import visionCreativeStudios from '../assets/img/company-logos/vision-creative.png';
import jabesInteriors from '../assets/img/company-logos/jabes-interiors.png';
import winroy from '../assets/img/company-logos/winroy.png';
import dmgEngineeringLimited from '../assets/img/company-logos/dmg.png';
import conbuInteriorDesign from '../assets/img/company-logos/conbu.png';
import hillmacFitouts from '../assets/img/company-logos/hillmac.png';
import harcourt from '../assets/img/company-logos/harcourt-architects.png';
import slmdLtd from '../assets/img/company-logos/slmd.png';
import gcFitout from '../assets/img/company-logos/gc-fitout.png';
import blackrockFurther from '../assets/img/company-logos/blackrock-further.png';
import lioncor from '../assets/img/company-logos/lioncore.png';
import creighanContracts from '../assets/img/company-logos/creighan-contracts-ltd.png';
import gannonOfficeSolutions from '../assets/img/company-logos/gannon-office-solutions.png';
import havwoodsIreland from '../assets/img/company-logos/havwoods-ireland.png';
import willieDuggan from '../assets/img/company-logos/willie-duggan.png';
import tarkett from '../assets/img/company-logos/tarkett-svg.png';
import creativeTechnology from '../assets/img/company-logos/creative-technology-svg.png';
import radiusOffice from '../assets/img/company-logos/radius-office.png';
import gemJoinery from '../assets/img/company-logos/gem-joinery.png';
import omanBeverlySmyth from '../assets/img/company-logos/oman-beverly-smyth.png';
import allianceExploreFlooring from '../assets/img/company-logos/alliance-explore-flooring.png';
import noyeks from '../assets/img/company-logos/noyeks.png';
import reputation from '../assets/img/company-logos/reputation.png';
import interfaceIreland from '../assets/img/company-logos/interface-ireland.png';
import cwBrien from '../assets/img/company-logos/cw-brien.png';
import meinhardtFoa from '../assets/img/company-logos/meinhardt-foa.png';
import handleCraft from '../assets/img/company-logos/handle-craft.png';
import ablaviation from '../assets/img/company-logos/ablaviation.png';
import aerarann from '../assets/img/company-logos/aerarann.png';
import aerlingus from '../assets/img/company-logos/aerlingus.png';
import aeradvise from '../assets/img/company-logos/aeradvise.png';
import aeroinspection from '../assets/img/company-logos/aeroinspection.png';
import aeropods from '../assets/img/company-logos/aeropods.png';
import airlineflight from '../assets/img/company-logos/airlineflight.png';
import airtel from '../assets/img/company-logos/airtel.png';
import aterim from '../assets/img/company-logos/aterim.png';
import avolonLogo from '../assets/img/company-logos/avolon-logo.png';
import businessriveraia from '../assets/img/company-logos/businessriver.jpg';
import cae from '../assets/img/company-logos/cae.png';
import cloudcards from '../assets/img/company-logos/cloudcards.png';
import dhlExpress from '../assets/img/company-logos/dhl.png';
import dublinAirport from '../assets/img/company-logos/dublinairport.png';
import dublincityuniverity from '../assets/img/company-logos/dcu.png';
import eirtech from '../assets/img/company-logos/eirtech.png';
import emeraldairlines from '../assets/img/company-logos/emeraldairlines.png';
import fingalaviation from '../assets/img/company-logos/fingalaviation.png';
import funflyaero from '../assets/img/company-logos/funflyaero.png';
import galway from '../assets/img/company-logos/galway.png';
import inflightdublin from '../assets/img/company-logos/inflightdublin.png';
import iac from '../assets/img/company-logos/iac.png';
import leinster from '../assets/img/company-logos/leinster.png';
import manna from '../assets/img/company-logos/manna.png';
import pilotpath from '../assets/img/company-logos/pilotpath.png';
import retailinmotion from '../assets/img/company-logos/retailinmotion.png';
import sellue from '../assets/img/company-logos/sellue.png';
import tcalliance from '../assets/img/company-logos/tcalliance.png';
import universitylimerick from '../assets/img/company-logos/universitylimerick.png';
import regen from '../assets/img/company-logos/regen.png';
import socomore from '../assets/img/company-logos/socomore.png';
import altairadvisory from '../assets/img/company-logos/altair.png';
import falko from '../assets/img/company-logos/falco.png';

import gaAbbvie from '../assets/img/company-logos/ga-2024/abbvie.png';
import gaAnpost from '../assets/img/company-logos/ga-2024/anpost.png';
import gaAnalog from '../assets/img/company-logos/ga-2024/analog.png';
import gaCiti from '../assets/img/company-logos/ga-2024/citi.png';
import gaCocacola from '../assets/img/company-logos/ga-2024/coca-logo.png';
import gaDaa from '../assets/img/company-logos/ga-2024/daa.png';
import gaDeloitte from '../assets/img/company-logos/ga-2024/deloitte.png';
import gaEthos from '../assets/img/company-logos/ga-2024/ethos.png';
import gaEvershed from '../assets/img/company-logos/ga-2024/evershed.png';
import gaGasNetworks from '../assets/img/company-logos/ga-2024/gas-networks.png';
import gaGrantThorthon from '../assets/img/company-logos/ga-2024/grant-thornton.png';
import gaKildare from '../assets/img/company-logos/ga-2024/kildare.png';
import gaKilkenky from '../assets/img/company-logos/ga-2024/kilkenky.png';
import gaMsd from '../assets/img/company-logos/ga-2024/msd.png';
import gaComhaircle from '../assets/img/company-logos/ga-2024/comhaircle.png'; // offaly
import gaShareclub from '../assets/img/company-logos/ga-2024/shareclub.png';
import gaSse from '../assets/img/company-logos/ga-2024/sse.png';
import gaTakeda from '../assets/img/company-logos/ga-2024/takeda.png';
import gaTirlan from '../assets/img/company-logos/ga-2024/tirlan.png';
import gaWaterways from '../assets/img/company-logos/ga-2024/waterways.png';
import gaArup from '../assets/img/company-logos/ga-2024/arup.png';
import gaBritvic from '../assets/img/company-logos/ga-2024/britvic.png';
import gaMomenta from '../assets/img/company-logos/ga-2024/momenta.png';
import gaJacobs from '../assets/img/company-logos/ga-2024/jacobs.png';
import gaEcomerit from '../assets/img/company-logos/ga-2024/ecomerit.png';
import gaClimateChange from '../assets/img/company-logos/ga-2024/climate-change.png';
import gaChangeDegrees from '../assets/img/company-logos/ga-2024/change-degrees.png';
import gaERI from '../assets/img/company-logos/ga-2024/environmental-research-institute.png';
import gaEmma from '../assets/img/company-logos/ga-2024/emma-plant.png';
import gaAmbie from '../assets/img/company-logos/ga-2024/ambie.png';
import gaPanel from '../assets/img/company-logos/ga-2024/the-panel.png';
import gaSouthWest from '../assets/img/company-logos/ga-2024/south-west-college.png';
import gaBroadlake from '../assets/img/company-logos/ga-2024/broadlake.png';
import gaLongford from '../assets/img/company-logos/ga-2024/longford.png';
import gaEducation from '../assets/img/company-logos/ga-2024/education.png';
import gaDRes from '../assets/img/company-logos/ga-2024/d-res.png';
import gaZeroWaste from '../assets/img/company-logos/ga-2024/zero-waste.png';

import gaUisce from '../assets/img/company-logos/ga-2024/uisce.png';
import ga20fifty from '../assets/img/company-logos/ga-2024/20fifty.png';
import gaDavy from '../assets/img/company-logos/ga-2024/davy.png';
import gaDiageo from '../assets/img/company-logos/ga-2024/diageo.png';
import gaCalor from '../assets/img/company-logos/ga-2024/calor.png';
import gaEsb from '../assets/img/company-logos/ga-2024/esb.png';
import gaVeolia from '../assets/img/company-logos/ga-2024/veolia.png';
import gaLumen from '../assets/img/company-logos/ga-2024/lumen.png';
import gaCpl from '../assets/img/company-logos/ga-2024/cpl.png';
import gaPanda from '../assets/img/company-logos/ga-2024/panda.png';
import gaEnergia from '../assets/img/company-logos/ga-2024/energia.png';
import gaVerde from '../assets/img/company-logos/ga-2024/verde.png';
import gaGreenOcean from '../assets/img/company-logos/ga-2024/ocean.png';
import gaSchneider from '../assets/img/company-logos/ga-2024/schneider.png';
import gaBord from '../assets/img/company-logos/ga-2024/bord.png';
import gaCodex from '../assets/img/company-logos/ga-2024/codex.png';
import gaEnterprise from '../assets/img/company-logos/ga-2024/enterprise.png';

import gaAbp from '../assets/img/company-logos/ga-2024/abp.png';
import gaAer from '../assets/img/company-logos/ga-2024/aer.png';
import gaAib from '../assets/img/company-logos/ga-2024/aib.png';
import gaAldi from '../assets/img/company-logos/ga-2024/aldi.png';
import gaAlkermes from '../assets/img/company-logos/ga-2024/alkermes.png';
import gaAllfresh from '../assets/img/company-logos/ga-2024/allfresh.png';
import gaApc from '../assets/img/company-logos/ga-2024/apc.png';
import gaAramark from '../assets/img/company-logos/ga-2024/aramark.png';
import gaArdmore from '../assets/img/company-logos/ga-2024/ardmore.png';
import gaAurivo from '../assets/img/company-logos/ga-2024/aurivo.png';
import gaBam from '../assets/img/company-logos/ga-2024/bam.png';
import gaBon from '../assets/img/company-logos/ga-2024/bon.png';
import gaBostonScientific from '../assets/img/company-logos/ga-2024/boston-scientific.png';
import gaBreedon from '../assets/img/company-logos/ga-2024/breedon.png';
import gaBrownThomas from '../assets/img/company-logos/ga-2024/brown-thomas.png';
import gaBurren from '../assets/img/company-logos/ga-2024/burren.png';
import gaCairn from '../assets/img/company-logos/ga-2024/cairn.png';
import gaCastle from '../assets/img/company-logos/ga-2024/castle.png';
import gaCie from '../assets/img/company-logos/ga-2024/cie.png';
import gaClarins from '../assets/img/company-logos/ga-2024/clarins.png';
import gaClayton from '../assets/img/company-logos/ga-2024/clayton.png';
import gaCompass from '../assets/img/company-logos/ga-2024/compass.png';
import gaCoolplanet from '../assets/img/company-logos/ga-2024/coolplanet.png';
import gaCrowleys from '../assets/img/company-logos/ga-2024/crowleys.png';
import gaCully from '../assets/img/company-logos/ga-2024/cully.png';
import gaDgd from '../assets/img/company-logos/ga-2024/dgd.png';
import gaDhl from '../assets/img/company-logos/ga-2024/dhl.png';
import gaDpd from '../assets/img/company-logos/ga-2024/dpd.png';
import gaDromoland from '../assets/img/company-logos/ga-2024/dromoland.png';
import gaDublin from '../assets/img/company-logos/ga-2024/dublin.png';
import gaEliLilly from '../assets/img/company-logos/ga-2024/eli-lilly.png';
import gaFexco from '../assets/img/company-logos/ga-2024/fexco.png';
import gaFineFoods from '../assets/img/company-logos/ga-2024/fine-foods.png';
import gaFreightSpeed from '../assets/img/company-logos/ga-2024/freightspeed.png';
import gaFyffes from '../assets/img/company-logos/ga-2024/fyffes.png';
import gaGmc from '../assets/img/company-logos/ga-2024/gmc.png';
import gaGrafton from '../assets/img/company-logos/ga-2024/grafton.png';
import gaHanley from '../assets/img/company-logos/ga-2024/hanley.png';
import gaHannigan from '../assets/img/company-logos/ga-2024/hannigan.png';
import gaHarmac from '../assets/img/company-logos/ga-2024/harmac.png';
import gaHealthcare from '../assets/img/company-logos/ga-2024/healthcare.png';
import gaHorizon from '../assets/img/company-logos/ga-2024/horizon.png';
import gaHotelDoolin from '../assets/img/company-logos/ga-2024/hotel-doolin.png';
import gaIes from '../assets/img/company-logos/ga-2024/ies.png';
import gaIres from '../assets/img/company-logos/ga-2024/ires.png';
import gaIrishManu from '../assets/img/company-logos/ga-2024/irish-manu.png';
import gaJcdecaux from '../assets/img/company-logos/ga-2024/jcdecaux.png';
import gaJohnson from '../assets/img/company-logos/ga-2024/johnson.png';
import gaKilsaran from '../assets/img/company-logos/ga-2024/kilsaran.png';
import gaKillarney from '../assets/img/company-logos/ga-2024/killarney.png';
import gaKingMoffat from '../assets/img/company-logos/ga-2024/king-moffatt.png';
import gaKingspan from '../assets/img/company-logos/ga-2024/kingspan.png';
import gaKpmg from '../assets/img/company-logos/ga-2024/kpmg.png';
import gaKwetb from '../assets/img/company-logos/ga-2024/kwetb.png';
import gaLawler from '../assets/img/company-logos/ga-2024/lawler.png';
import gaLidi from '../assets/img/company-logos/ga-2024/lidi.png';
import gaMason from '../assets/img/company-logos/ga-2024/mason.png';
import gaMcculla from '../assets/img/company-logos/ga-2024/mcculla.png';
import gaMeade from '../assets/img/company-logos/ga-2024/meade.png';
import gaMercury from '../assets/img/company-logos/ga-2024/mercury.png';
import gaMurphy from '../assets/img/company-logos/ga-2024/murphy.png';
import gaMusgrave from '../assets/img/company-logos/ga-2024/musgrave.png';
import gaPfizer from '../assets/img/company-logos/ga-2024/pfizer.png';
import gaPowerCapital from '../assets/img/company-logos/ga-2024/power-capital.png';
import gaPrimark from '../assets/img/company-logos/ga-2024/primark.png';
import gaPv from '../assets/img/company-logos/ga-2024/pv.png';
import gaSmarttech from '../assets/img/company-logos/ga-2024/smarttech.png';
import gaSpeedFibre from '../assets/img/company-logos/ga-2024/speed-fibre.png';
import gaSupernode from '../assets/img/company-logos/ga-2024/supernode.png';
import gaSwordsPavilions from '../assets/img/company-logos/ga-2024/swords-pavilions.png';
import gaSymphony from '../assets/img/company-logos/ga-2024/symphony.png';
import gaThorn from '../assets/img/company-logos/ga-2024/thorn.png';
import gaThorntons from '../assets/img/company-logos/ga-2024/thorntons.png';
import gaVersion from '../assets/img/company-logos/ga-2024/version.png';
import gaVesi from '../assets/img/company-logos/ga-2024/vesi.png';
import gaVodafone from '../assets/img/company-logos/ga-2024/vodafone.png';
import gaWalls from '../assets/img/company-logos/ga-2024/walls.png';
import gaWalkers from '../assets/img/company-logos/ga-2024/walkers.png';
import gaWinthrop from '../assets/img/company-logos/ga-2024/winthrop.png';
import gaWipeout from '../assets/img/company-logos/ga-2024/wipeout.png';
import gaWisetek from '../assets/img/company-logos/ga-2024/wisetek.png';
import gaWren from '../assets/img/company-logos/ga-2024/wren.png';
import gaWyeth from '../assets/img/company-logos/ga-2024/wyeth.png';
import gaXlvets from '../assets/img/company-logos/ga-2024/xlvets.png';

// ILTA 2024
import iltaAce from '../assets/img/company-logos/ilta-2024/ace-express-freight.jpg';
import iltaMusgrave from '../assets/img/company-logos/ilta-2024/musgrave.jpg';
import iltaBwgFoods from '../assets/img/company-logos/ilta-2024/bwg-foods.png';
import iltaRsa from '../assets/img/company-logos/ilta-2024/rsa.png';
import iltaBunzl from '../assets/img/company-logos/ilta-2024/bunzl.png';
import iltaIsm from '../assets/img/company-logos/ilta-2024/ism.png';
import iltaCrane from '../assets/img/company-logos/ilta-2024/crane-worldwide.png';
import iltaTub from '../assets/img/company-logos/ilta-2024/technical-university-dublin.png';
import iltaQub from '../assets/img/company-logos/ilta-2024/queens-university-belfast.png';
import iltaFacilitrain from '../assets/img/company-logos/ilta-2024/facilitrain.jpeg';
import iltaMtu from '../assets/img/company-logos/ilta-2024/munster-technological-university.jpeg';
import iltaIpics from '../assets/img/company-logos/ilta-2024/ipics.png';
import iltaUcd from '../assets/img/company-logos/ilta-2024/university-college-dublin.jpg';
import iltaIdf from '../assets/img/company-logos/ilta-2024/irish-defence-forces.png';
import iltaIifa from '../assets/img/company-logos/ilta-2024/irish-international-freight-association.png';
import iltaAib from '../assets/img/company-logos/ilta-2024/aib.png';
import iltaGreenTiger from '../assets/img/company-logos/ilta-2024/green-tiger.jpg';
import iltaMilandTyre from '../assets/img/company-logos/ilta-2024/midland-tyre-services.png';
import iltaDixon from '../assets/img/company-logos/ilta-2024/dixon.png';
import iltaDempsey from '../assets/img/company-logos/ilta-2024/dempsey-associates.png';
import iltaDrivingForce from '../assets/img/company-logos/ilta-2024/driving-force.png';
import iltaClare from '../assets/img/company-logos/ilta-2024/claire-distribution-services.jpg';
import iltaIanrod from '../assets/img/company-logos/ilta-2024/ianrod-eireann.png';
import iltaExcel from '../assets/img/company-logos/ilta-2024/excel-recruitment.png';
import iltaDublinBus from '../assets/img/company-logos/ilta-2024/dublin-bus.jpg';
import iltaRoadTruckServices from '../assets/img/company-logos/ilta-2024/road-truck-services.jpg';
import iltaTranspoco from '../assets/img/company-logos/ilta-2024/transpoco.png';
import iltaCubo from '../assets/img/company-logos/ilta-2024/cubo.png';
import iltaCargoPartner from '../assets/img/company-logos/ilta-2024/cargo-partner.png';
import iltaFleetData from '../assets/img/company-logos/ilta-2024/fleet-data.jpg';
import iltaSysco from '../assets/img/company-logos/ilta-2024/sysco.jpg';
import iltaDot from '../assets/img/company-logos/ilta-2024/department-of-transport.png';
import iltaCorduff from '../assets/img/company-logos/ilta-2024/corduff-travel.webp';
import iltaMcculla from '../assets/img/company-logos/ilta-2024/mcculla.jpg';
import iltaWaterwipes from '../assets/img/company-logos/ilta-2024/water-wipes.jpg';
import iltaDerryBros from '../assets/img/company-logos/ilta-2024/derry-bros.png';
import iltaSpectrum from '../assets/img/company-logos/ilta-2024/spectrum.png';
import iltaFreightspeed from '../assets/img/company-logos/ilta-2024/freightspeed.png';
import iltaWestPharma from '../assets/img/company-logos/ilta-2024/west-pharmaceuticals.jpg';
import iltaJacobus from '../assets/img/company-logos/ilta-2024/jacobus-coaches.jpg';
import iltaBusEirann from '../assets/img/company-logos/ilta-2024/bus-eirann.png';

//FM
import fmAramark from '../assets/img/company-logos/fm-2024/aramark-logo.png';
import fmBailey from '../assets/img/company-logos/fm-2024/bailey.png';
import fmBam from '../assets/img/company-logos/fm-2024/bam.png';
import fmBerka from '../assets/img/company-logos/fm-2024/berka-logo.png';
import fmCbre from '../assets/img/company-logos/fm-2024/cbre-logo.png';
import fmContico from '../assets/img/company-logos/fm-2024/contico.png';
import fmEwl from '../assets/img/company-logos/fm-2024/ewl.png';
import fmGloboserv from '../assets/img/company-logos/fm-2024/globoserv-logo.png';
import fmIss from '../assets/img/company-logos/fm-2024/iss-logo.png';
import fmKarcher from '../assets/img/company-logos/fm-2024/karcher.png';
import fmLmc from '../assets/img/company-logos/fm-2024/lmc.png';
import fmMartin from '../assets/img/company-logos/fm-2024/martin-logo.png';
import fmMitie from '../assets/img/company-logos/fm-2024/mitie-logo.png';
import fmMkf from '../assets/img/company-logos/fm-2024/mkf-logo.png';
import fmMk from '../assets/img/company-logos/fm-2024/mk-logo.png';
import fmSensori from '../assets/img/company-logos/fm-2024/sensori-logo.png';
import fmSodexo from '../assets/img/company-logos/fm-2024/sodexo-logo.png';
import fmSos from '../assets/img/company-logos/fm-2024/sos-logo.png';
import fmSynergy from '../assets/img/company-logos/fm-2024/synergy-logo.png';
import fmTech from '../assets/img/company-logos/fm-2024/tech.png';
import fmThorntons from '../assets/img/company-logos/fm-2024/thorntons-logo.png';
import fmTork from '../assets/img/company-logos/fm-2024/tork-logo.png';
import fmWestern from '../assets/img/company-logos/fm-2024/western-logo.png';
import fmWilliamcox from '../assets/img/company-logos/fm-2024/williaamcox-logo.png';
import fmKpmg from '../assets/img/company-logos/fm-2024/kpmg.png';
import fmCircle from '../assets/img/company-logos/fm-2024/circle.png';
import fmRyans from '../assets/img/company-logos/fm-2024/ryans.png';
import fmOffice from '../assets/img/company-logos/fm-2024/office.png';
import fmSalesForce from '../assets/img/company-logos/fm-2024/salesforce.png';
import fmOfficeGov from '../assets/img/company-logos/fm-2024/office-gov.png';
import fmBiomarin from '../assets/img/company-logos/fm-2024/biomarin.png';
import fmMasterTech from '../assets/img/company-logos/fm-2024/master.png';
import fmUisce from '../assets/img/company-logos/fm-2024/uisce.png';
import fmMagna from '../assets/img/company-logos/fm-2024/magna.png';
import fmAnalog from '../assets/img/company-logos/fm-2024/analog.png';
import fmKing from '../assets/img/company-logos/fm-2024/king.png';
import fmDanone from '../assets/img/company-logos/fm-2024/danone.png';
import fmPowerscourt from '../assets/img/company-logos/fm-2024/powerscourt.png';
import fmBrien from '../assets/img/company-logos/fm-2024/brien.png';
import fmElis from '../assets/img/company-logos/fm-2024/elis.png';
import fmAnPost from '../assets/img/company-logos/fm-2024/an-post.png';
import fmAtu from '../assets/img/company-logos/fm-2024/atu.png';
import fmBunzl from '../assets/img/company-logos/fm-2024/bunzl.png';
import fmEir from '../assets/img/company-logos/fm-2024/eir.png';
import fmG4s from '../assets/img/company-logos/fm-2024/g4s.png';
import fmSecuriGroup from '../assets/img/company-logos/fm-2024/securi-group.png';
import fmCroninGroup from '../assets/img/company-logos/fm-2024/cronin-group.png';
import fmGilead from '../assets/img/company-logos/fm-2024/gilead.png';
import fmHibernia from '../assets/img/company-logos/fm-2024/hibernia.png';
import fmOasis from '../assets/img/company-logos/fm-2024/oasis.png';
import fmMacro from '../assets/img/company-logos/fm-2024/macro.png';
import fmApleona from '../assets/img/company-logos/fm-2024/apleona.png';
import fmWyse from '../assets/img/company-logos/fm-2024/wyse.png';
import fmRiverside from '../assets/img/company-logos/fm-2024/riverside.png';
import fmAppleGreen from '../assets/img/company-logos/fm-2024/apple-green.png';
import fmHps from '../assets/img/company-logos/fm-2024/hps.png';

import meaDenroy from '../assets/img/company-logos/mea-2024/denroy.png';
import meaMcavoy from '../assets/img/company-logos/mea-2024/mcavoy.png';
import meaBallyMaguireFoods from '../assets/img/company-logos/mea-2024/bally-maguire-foods.png';
import meaBiopaxLtd from '../assets/img/company-logos/mea-2024/biopax-ltd.png';
import meaOliverConroyPallets from '../assets/img/company-logos/mea-2024/oliver-conroy-pallets.png';
import meaDanoneNutricia from '../assets/img/company-logos/mea-2024/danone-nutricia.png';
import meaDellTechnologies from '../assets/img/company-logos/mea-2024/dell-technologies.png';
import meaHanleyEnergy from '../assets/img/company-logos/mea-2024/hanley-energy.png';
import meaHarmacMedical from '../assets/img/company-logos/mea-2024/harmac-medical.png';
import meaJanssenSciences from '../assets/img/company-logos/mea-2024/janssen-sciences.png';
import meaJonesEngineering from '../assets/img/company-logos/mea-2024/jones-engineering.png';
import meaMerckGroup from '../assets/img/company-logos/mea-2024/merck.png';
import meaMeranda from '../assets/img/company-logos/mea-2024/merenda.png';
import meaSuretank from '../assets/img/company-logos/mea-2024/suretank.png';
import meaTakeda from '../assets/img/company-logos/mea-2024/takeda.png';
import meaViatris from '../assets/img/company-logos/mea-2024/viatris.png';
import meaVle from '../assets/img/company-logos/mea-2024/vle.png';
import meaVentac from '../assets/img/company-logos/mea-2024/ventac-engineered.png';
import meaDosco from '../assets/img/company-logos/mea-2024/dosco.png';
import meaArrotek from '../assets/img/company-logos/mea-2024/arrotek.png';
import meaUniversityCollegeDublin from '../assets/img/company-logos/mea-2024/ucd.png';
import meaUcc from '../assets/img/company-logos/mea-2024/ucc.png';
import meaMannok from '../assets/img/company-logos/mea-2024/mannok.png';
import meaBusinessRiver from '../assets/img/company-logos/mea-2024/business-river.png';
import meaAnalog from '../assets/img/company-logos/mea-2024/analog.png';

import bayaDavid from '../assets/img/company-logos/baya-2024/david.png';
import bayaRhatigan from '../assets/img/company-logos/baya-2024/rhatigan.png';
import bayaPascall from '../assets/img/company-logos/baya-2024/pascall.png';
import bayaCwpa from '../assets/img/company-logos/baya-2024/cwpa.png';
import bayaDenis from '../assets/img/company-logos/baya-2024/denis.png';
import bayaDhb from '../assets/img/company-logos/baya-2024/dhb.png';
import bayaFarren from '../assets/img/company-logos/baya-2024/farren.png';
import bayaHenry from '../assets/img/company-logos/baya-2024/henry.png';
import bayaMcadam from '../assets/img/company-logos/baya-2024/mcadam.png';
import bayaMosart from '../assets/img/company-logos/baya-2024/mosart.png';
import bayaNoyeks from '../assets/img/company-logos/baya-2024/noyeks.png';
import bayaOmahony from '../assets/img/company-logos/baya-2024/omahony.png';
import bayaPerkins from '../assets/img/company-logos/baya-2024/perkins.png';
import bayaWain from '../assets/img/company-logos/baya-2024/wain.png';
import bayaWalls from '../assets/img/company-logos/baya-2024/walls-logo.png';
import bayaVescom from '../assets/img/company-logos/baya-2024/vescom-logo.png';
import bayaReddy from '../assets/img/company-logos/baya-2024/reddy.png';
import bayaCraftstudio from '../assets/img/company-logos/baya-2024/craftstudio.png';
import bayaMccabe from '../assets/img/company-logos/baya-2024/mccabe.png';
import bayaDmvf from '../assets/img/company-logos/baya-2024/dmvf.png';
import bayaCrawford from '../assets/img/company-logos/baya-2024/crawford.png';
import bayaClancy from '../assets/img/company-logos/baya-2024/clancy.png';
import bayaStw from '../assets/img/company-logos/baya-2024/stw.png';
import bayaTexfel from '../assets/img/company-logos/baya-2024/texfel.png';
import bayaBoonedam from '../assets/img/company-logos/baya-2024/boonedam.png';
import bayaCwobrienarchi from '../assets/img/company-logos/baya-2024/cwobrien-archi.png';
import bayaDarmody from '../assets/img/company-logos/baya-2024/darmody.png';
import bayaRyanlamb from '../assets/img/company-logos/baya-2024/ryan-lamb-archi.png';
import bayaTudublin from '../assets/img/company-logos/baya-2024/tu-dublin.png';
import bayaCundall from '../assets/img/company-logos/baya-2024/cundall.png';
import bayaMcorm from '../assets/img/company-logos/baya-2024/mcorm-archi.png';
import bayaMccauley from '../assets/img/company-logos/baya-2024/mccauley.png';
import bayaGroWorks from '../assets/img/company-logos/baya-2024/gro-works.png';
import bayaTexSpace from '../assets/img/company-logos/baya-2024/texspace.png';
import bayaTodd from '../assets/img/company-logos/baya-2024/todd.png';
import bayaSbi from '../assets/img/company-logos/baya-2024/sbi.png';
import bayaRkd from '../assets/img/company-logos/baya-2024/rkd.png';
import bayaBwdi from '../assets/img/company-logos/baya-2024/bwdi.png';

import hsIss from '../assets/img/company-logos/hs-2024/iss-logo.png';
import hsBretland from '../assets/img/company-logos/hs-2024/bretland.png';
import hsOreilly from '../assets/img/company-logos/hs-2024/oreilly.png';
import hsSkillko from '../assets/img/company-logos/hs-2024/skillko-logo.png';
import hsApc from '../assets/img/company-logos/hs-2024/apc.png';
import hsArdmac from '../assets/img/company-logos/hs-2024/ardmac.png';
import hsAsgard from '../assets/img/company-logos/hs-2024/asgard.png';
import hsBiomarin from '../assets/img/company-logos/hs-2024/biomarin.png';
import hsClancy from '../assets/img/company-logos/hs-2024/clancy.png';
import hsDanone from '../assets/img/company-logos/hs-2024/danone.png';
import hsKellihers from '../assets/img/company-logos/hs-2024/kellihers.png';
import hsLmc from '../assets/img/company-logos/hs-2024/lmc.png';
import hsMater from '../assets/img/company-logos/hs-2024/mater.png';
import hsPobal from '../assets/img/company-logos/hs-2024/pobal.png';
import hsShareridge from '../assets/img/company-logos/hs-2024/shareridge.png';
import hsStewarts from '../assets/img/company-logos/hs-2024/stewarts.png';
import hsTirlan from '../assets/img/company-logos/hs-2024/tirlan.png';
import hsWoodies from '../assets/img/company-logos/hs-2024/woodies.png';
import hsUisce from '../assets/img/company-logos/hs-2024/uisce.png';
import hsAryzta from '../assets/img/company-logos/hs-2024/aryzta.png';
import hsBam from '../assets/img/company-logos/hs-2024/bam.png';
import hsBoston from '../assets/img/company-logos/hs-2024/boston.png';
import hsCentric from '../assets/img/company-logos/hs-2024/centric.png';
import hsCef from '../assets/img/company-logos/hs-2024/cef.png';
import hsDublinBus from '../assets/img/company-logos/hs-2024/dublin-bus.png';
import hsIarnrod from '../assets/img/company-logos/hs-2024/iarnrod.png';
import hsIbec from '../assets/img/company-logos/hs-2024/ibec.png';
import hsPfizer from '../assets/img/company-logos/hs-2024/pfizer.png';
import hsUcd from '../assets/img/company-logos/hs-2024/ucd.png';
import hsCooley from '../assets/img/company-logos/hs-2024/cooley.png';
import hsBreedon from '../assets/img/company-logos/hs-2024/breedon.png';
import hsSiemens from '../assets/img/company-logos/hs-2024/siemens.png';
import hsTUDublin from '../assets/img/company-logos/hs-2024/tu-dublin.png';
import hsTritech from '../assets/img/company-logos/hs-2024/tritech.png';
import hsDHL from '../assets/img/company-logos/hs-2024/dhl.png';
import hsSodexo from '../assets/img/company-logos/hs-2024/sodexo.png';
import hsUH from '../assets/img/company-logos/hs-2024/uh.png';
import hsSurecom from '../assets/img/company-logos/hs-2024/surecom.png';

import eduAbbvie from '../assets/img/company-logos/edu-2024/abbvie.png';
import eduCmit from '../assets/img/company-logos/edu-2024/cmit.png';
import eduDundalk from '../assets/img/company-logos/edu-2024/dundalk.png';
import eduFluirse from '../assets/img/company-logos/edu-2024/fluirse.png';
import eduLove from '../assets/img/company-logos/edu-2024/love.png';
import eduInsurance from '../assets/img/company-logos/edu-2024/insurance.png';
import eduMic125 from '../assets/img/company-logos/edu-2024/mic-125.png';
import eduLincMic from '../assets/img/company-logos/edu-2024/linc-mic.png';
import eduMayo from '../assets/img/company-logos/edu-2024/mayo.png';
import eduMunster from '../assets/img/company-logos/edu-2024/munster.png';
import eduNibrt from '../assets/img/company-logos/edu-2024/nibrt.png';
import eduOfiaich from '../assets/img/company-logos/edu-2024/ofiaich.png';
import eduRcsi from '../assets/img/company-logos/edu-2024/rcsi.png';
import eduTechnological from '../assets/img/company-logos/edu-2024/technological.png';
import eduTusst from '../assets/img/company-logos/edu-2024/tusst.png';
import eduUcc from '../assets/img/company-logos/edu-2024/ucc.png';
import eduSystems from '../assets/img/company-logos/edu-2024/systems.png';
import eduCalpd from '../assets/img/company-logos/edu-2024/calpd.png';
import eduDrogheda from '../assets/img/company-logos/edu-2024/drogheda.png';
import eduUniversityul from '../assets/img/company-logos/edu-2024/universityul.png';
import eduApplegreen from '../assets/img/company-logos/edu-2024/applegreen.png';
import eduUcccork from '../assets/img/company-logos/edu-2024/ucc-cork.png';
import eduFinnesse from '../assets/img/company-logos/edu-2024/finnese-core.png';
import eduIbat from '../assets/img/company-logos/edu-2024/ibat.png';
import eduSspc from '../assets/img/company-logos/edu-2024/sspc-two.png';
import eduIswapatu from '../assets/img/company-logos/edu-2024/uu-iswap.png';
import eduSetu from '../assets/img/company-logos/edu-2024/setu.png';
import eduLimerickacademic from '../assets/img/company-logos/edu-2024/setu.png';
import eduMedtronic from '../assets/img/company-logos/edu-2024/meditronic.png';
import eduUcd from '../assets/img/company-logos/edu-2024/systems.png';
import eduMiddletown from '../assets/img/company-logos/edu-2024/centre-aut.png';
import eduVirtuallabs from '../assets/img/company-logos/edu-2024/virtuallab.png';
import eduIadt from '../assets/img/company-logos/edu-2024/iadt.png';
import eduAtu from '../assets/img/company-logos/edu-2024/atlantic.png';
import eduMic from '../assets/img/company-logos/edu-2024/mic.png';
import eduNationalcollegeireland from '../assets/img/company-logos/edu-2024/nationalcollegeireland.png';
import eduUnilimerick from '../assets/img/company-logos/edu-2024/university.png';
import eduForwardemphasis from '../assets/img/company-logos/edu-2024/forward-emphasis.png';
import eduGomapped from '../assets/img/company-logos/edu-2024/gomapped.png';
import eduBrickfield from '../assets/img/company-logos/edu-2024/brickfield.png';
import eduEtbi from '../assets/img/company-logos/edu-2024/etbi.png';
import eduMulgravelimerick from '../assets/img/company-logos/edu-2024/mulgrave-limerick.png';
import eduThea from '../assets/img/company-logos/edu-2024/thea.png';
import eduDbs from '../assets/img/company-logos/edu-2024/dbs.png';
import eduGriffithcol from '../assets/img/company-logos/edu-2024/griffith-college.png';
import eduEdhoc from '../assets/img/company-logos/edu-2024/ed-hoc.png';
import eduLia from '../assets/img/company-logos/edu-2024/lia.png';
import eduIce from '../assets/img/company-logos/edu-2024/ice.png';
import eduGenireland from '../assets/img/company-logos/edu-2024/genireland.png';
import eduGaelchultur from '../assets/img/company-logos/edu-2024/gaelchultur.png';

import iaaAxon from '../assets/img/company-logos/iaa-2024/axon-logo.png';
import iaaSage from '../assets/img/company-logos/iaa-2024/sage.png';
import iaaCpa from '../assets/img/company-logos/iaa-2024/cpa-logo.png';
import iaaChartered from '../assets/img/company-logos/iaa-2024/institute-logo.png';
import iaaIntax from '../assets/img/company-logos/iaa-2024/intax.png';
import iaaPtsb from '../assets/img/company-logos/iaa-2024/permanent-logo.png';
import iaaBigred from '../assets/img/company-logos/iaa-2024/bigred.png';
import iaaIplicit from '../assets/img/company-logos/iaa-2024/iplicit-logo.png';
import iaaFinance from '../assets/img/company-logos/iaa-2024/finance.png';
import iaaCapisso from '../assets/img/company-logos/iaa-2024/capisso.png';
import iaaPinnacle from '../assets/img/company-logos/iaa-2024/pinnacles.png';
import iaaEtl from '../assets/img/company-logos/iaa-2024/noone.png';
import iaaFenero from '../assets/img/company-logos/iaa-2024/fenero.png';
import iaaFexco from '../assets/img/company-logos/iaa-2024/fexco.png';
import iaaFitz from '../assets/img/company-logos/iaa-2024/fitz.png';
import iaaFpm from '../assets/img/company-logos/iaa-2024/fpm.png';
import iaaGriffith from '../assets/img/company-logos/iaa-2024/griffith.png';
import iaaJpa from '../assets/img/company-logos/iaa-2024/jpa.png';
import iaaLidl from '../assets/img/company-logos/iaa-2024/lidl.png';
import iaaMaynooth from '../assets/img/company-logos/iaa-2024/maynooth.png';
import iaaSatori from '../assets/img/company-logos/iaa-2024/satori.png';
import iaaUisce from '../assets/img/company-logos/iaa-2024/uisce.png';
import iaaUniversitygalway from '../assets/img/company-logos/iaa-2024/university.png';
import iaaWest from '../assets/img/company-logos/iaa-2024/west.png';
import iaaXeinadin from '../assets/img/company-logos/iaa-2024/xeinadin.png';
import iaaAtu from '../assets/img/company-logos/iaa-2024/atlantic.png';
import iaaMoore from '../assets/img/company-logos/iaa-2024/moore.png';
import iaaDeloitte from '../assets/img/company-logos/iaa-2024/deloitte.png';
import iaaTuDublin from '../assets/img/company-logos/iaa-2024/tu-dublin.png';
import iaaGeneralElectric from '../assets/img/company-logos/iaa-2024/GE.png';
import iaaScaleIreland from '../assets/img/company-logos/iaa-2024/ScaleIreland.png';
import iaaBdo from '../assets/img/company-logos/iaa-2024/BDO.png';
import iaaCtrlS from '../assets/img/company-logos/iaa-2024/Ctrl-S.png';
import iaaDublinCityUniversity from '../assets/img/company-logos/iaa-2024/Dublin-City-University.png';
import iaaAgAssociates from '../assets/img/company-logos/iaa-2024/AG-Associates.png';
import iaaTdSecurities from '../assets/img/company-logos/iaa-2024/TD-Securities.png';
import iaaTaxlink from '../assets/img/company-logos/iaa-2024/Tax+Link.png';
import iaaFmAccountants from '../assets/img/company-logos/iaa-2024/FM-Accountants.png';
import iaaFloirin from '../assets/img/company-logos/iaa-2024/Floirin.png';
import iaaGarveyMoran from '../assets/img/company-logos/iaa-2024/Garvey-Moran.png';
import iaaGlanbia from '../assets/img/company-logos/iaa-2024/glanbia-logo.png';
import iaaKpmg from '../assets/img/company-logos/iaa-2024/KPMG.png';
import iaaMoneyCorp from '../assets/img/company-logos/iaa-2024/Moneycorp.png';
import iaaAccountingTechniciansIreland from '../assets/img/company-logos/iaa-2024/Accounting-Technicians-Ireland.png';
import iaaEsb from '../assets/img/company-logos/iaa-2024/ESB.png';
import iaaShamrock from '../assets/img/company-logos/iaa-2024/Shamrock.png';
import iaaMannings from '../assets/img/company-logos/iaa-2024/Mannings.png';
import iaaMedicalAccountants from '../assets/img/company-logos/iaa-2024/medical-accountants.png';
import iaaDavy from '../assets/img/company-logos/iaa-2024/Davy.png';
import iaaNonExecutive from '../assets/img/company-logos/iaa-2024/sponsor-logo.png';
import iaaOlivia from '../assets/img/company-logos/iaa-2024/olivia.png';
import iaaBordNaMona from '../assets/img/company-logos/iaa-2024/bordnamona.png';
import iaaUlster from '../assets/img/company-logos/iaa-2024/ulster-university.png';
import iaaRoryWilliams from '../assets/img/company-logos/iaa-2024/rory-williams.png';
import iaaHostelWorld from '../assets/img/company-logos/iaa-2024/hostelworld.png';
import iaaStorm from '../assets/img/company-logos/iaa-2024/storm-tech.png';
import iaaStrata from '../assets/img/company-logos/iaa-2024/strata-financial.png';
import iaaFahChart from '../assets/img/company-logos/iaa-2024/fah-chartered-acc.png';
import iaaWoodsPartners from '../assets/img/company-logos/iaa-2024/woods-partners.png';
import iaaBt from '../assets/img/company-logos/iaa-2024/bt.png';
import iaaRbk from '../assets/img/company-logos/iaa-2024/rbk.png';
import iaaWentWorth from '../assets/img/company-logos/iaa-2024/wentworth.png';
import iaaIncorpro from '../assets/img/company-logos/iaa-2024/incorpro.png';
import iaaFintechPro from '../assets/img/company-logos/iaa-2024/fintech-pro.png';
import iaaFdcGroup from '../assets/img/company-logos/iaa-2024/fdc-group.png';
import iaaMcManus from '../assets/img/company-logos/iaa-2024/mcmanus.png';
import iaaHlbIreland from '../assets/img/company-logos/iaa-2024/hlb-ireland.png';
import iaaMaraYoung from '../assets/img/company-logos/iaa-2024/mara-young.png';
import iaaBusinessRiver from '../assets/img/company-logos/iaa-2024/business-river-logo.png';

import taiaChartered from '../assets/img/company-logos/taia-2024/chartered.png';
import taiaHealthtech from '../assets/img/company-logos/taia-2024/healthtech.png';
import taiaInsurance from '../assets/img/company-logos/taia-2024/insurance-institute.png';
import taiaHealth from '../assets/img/company-logos/taia-2024/health.png';
import taiaLearnovate from '../assets/img/company-logos/taia-2024/learnovate.png';
import taiaWomen from '../assets/img/company-logos/taia-2024/women.png';
import taiaInnovation from '../assets/img/company-logos/taia-2024/innovation.png';
import taiaCipfa from '../assets/img/company-logos/taia-2024/cipfa.png';
import taiaThea from '../assets/img/company-logos/taia-2024/thea.png';
import taiaWoven from '../assets/img/company-logos/taia-2024/woven.png';
import taiaBusinessriver from '../assets/img/company-logos/taia-2024/business-river-logo.png';
import taiaIapi from '../assets/img/company-logos/taia-2024/iapi.png';
import taiaCpa from '../assets/img/company-logos/taia-2024/cpa-ireland.png';

import Opex24Abbott from '../assets/img/company-logos/opex-2024/abbott.png';
import Opex24Aldi from '../assets/img/company-logos/opex-2024/aldi.png';
import Opex24AnpostCommerce from '../assets/img/company-logos/opex-2024/anpost-commerce.png';
import Opex24ApcVle from '../assets/img/company-logos/opex-2024/apcvle.png';
import Opex24Bridgewater from '../assets/img/company-logos/opex-2024/bridgewater.png';
import Opex24BusinessRiver from '../assets/img/company-logos/opex-2024/business-river.png';
import Opex24BwgFoods from '../assets/img/company-logos/opex-2024/bwgfoods.png';
import Opex24Esb from '../assets/img/company-logos/opex-2024/esb.png';
import Opex24Gleneagle from '../assets/img/company-logos/opex-2024/gleneagle.png';
import Opex24GriffithCollege from '../assets/img/company-logos/opex-2024/griffithcollege.png';
import Opex24Hpra from '../assets/img/company-logos/opex-2024/hpra.png';
import Opex24Iss from '../assets/img/company-logos/opex-2024/iss.png';
import Opex24LotusWorks from '../assets/img/company-logos/opex-2024/lotusworks.png';
import Opex24Sensori from '../assets/img/company-logos/opex-2024/sensori.png';
import Opex24Sodexo from '../assets/img/company-logos/opex-2024/sodexo.png';
import Opex24Ucc from '../assets/img/company-logos/opex-2024/ucc.png';
import Opex24IrishPensionsFinance from '../assets/img/company-logos/opex-2024/irishpension.png';
import Opex24DerryBros from '../assets/img/company-logos/opex-2024/derrybros.png';
import Opex24CentricHealth from '../assets/img/company-logos/opex-2024/centrichealth.png';
import Opex24Writech from '../assets/img/company-logos/opex-2024/writech.png';
import Opex24PassportService from '../assets/img/company-logos/opex-2024/passport-service.png';
import Opex24DublinBus from '../assets/img/company-logos/opex-2024/dublin-bus.png';
import Opex24Lmc from '../assets/img/company-logos/opex-2024/lmc.png';
import Opex24ConnectedHealth from '../assets/img/company-logos/opex-2024/connected-health.png';
import Opex24CjSheeran from '../assets/img/company-logos/opex-2024/cj-sheeran.png';
import Opex24SouthCoast from '../assets/img/company-logos/opex-2024/south-coast.png';
import Opex24Viatris from '../assets/img/company-logos/opex-2024/viatris.png';
import Opex24ComfortKeepers from '../assets/img/company-logos/opex-2024/comfort-keepers.png';
import Opex24CargoPartner from '../assets/img/company-logos/opex-2024/cargo-partner.png';
import Opex24EnterpriseIreland from '../assets/img/company-logos/opex-2024/enterprise-ireland.png';
import Opex24Atu from '../assets/img/company-logos/opex-2024/atu-sligo.png';
import Opex24SalesForce from '../assets/img/company-logos/opex-2024/salesforce.png';
import Opex24Aurivo from '../assets/img/company-logos/opex-2024/aurivo.png';
import Opex24Dcu from '../assets/img/company-logos/opex-2024/dcu.png';
import Opex24Imil from '../assets/img/company-logos/opex-2024/imil.png';

import WicaArdmac from '../assets/img/company-logos/wica-2024/ardmac.png';
import WicaBretland from '../assets/img/company-logos/wica-2024/bretland.png';
import WicaDubh from '../assets/img/company-logos/wica-2024/dubh.png';
import WicaElliott from '../assets/img/company-logos/wica-2024/elliott.png';
import WicaEthos from '../assets/img/company-logos/wica-2024/ethos.png';
import WicaFkm from '../assets/img/company-logos/wica-2024/fkm.png';
import WicaHelena from '../assets/img/company-logos/wica-2024/helena.png';
import WicaKirby from '../assets/img/company-logos/wica-2024/kirby.png';
import WicaPennypop from '../assets/img/company-logos/wica-2024/pennypop.png';
import WicaRkd from '../assets/img/company-logos/wica-2024/rkd.png';
import WicaSigWorkplace from '../assets/img/company-logos/wica-2024/sigworkplace.png';
import WicaTuDublin from '../assets/img/company-logos/wica-2024/tudublin.png';
import WicaWalls from '../assets/img/company-logos/wica-2024/walls.png';
import WicaLighthouse from '../assets/img/company-logos/wica-2024/lighthouse-logo.png';
import WicaFalconGreen from '../assets/img/company-logos/wica-2024/falcon-logo.png';
import WicaBwdi from '../assets/img/company-logos/wica-2024/bwdi-logo.png';
import WicaEir from '../assets/img/company-logos/wica-2024/eireng.png';
import WicaCargon from '../assets/img/company-logos/wica-2024/corgan-logo.png';
import WicaRockfon from '../assets/img/company-logos/wica-2024/rockfon.png';
import WicaShay from '../assets/img/company-logos/wica-2024/shay-murtagh.png';
import WicaProperty from '../assets/img/company-logos/wica-2024/propery-district.png';
import WicaUB from '../assets/img/company-logos/wica-2024/university-of-bath.png';
import WicaKC from '../assets/img/company-logos/wica-2024/kc-service.png';
import WicaWorkday from '../assets/img/company-logos/wica-2024/workday.png';
import WicaHumanScale from '../assets/img/company-logos/wica-2024/human-scale.png';
import WicaMurphy from '../assets/img/company-logos/wica-2024/murphy.png';
import WicaWyse from '../assets/img/company-logos/wica-2024/wyse.png';
import WicaClaraglhea from '../assets/img/company-logos/wica-2024/claraglhea.png';
import WicaCcs from '../assets/img/company-logos/wica-2024/ccs.png';
import WicaJoyces from '../assets/img/company-logos/wica-2024/joyces.png';
import WicaCw from '../assets/img/company-logos/wica-2024/cw-brien.png';
import WicaBusinessRiver from '../assets/img/company-logos/wica-2024/business-river-logo.png';
import WicaMCAArchitects from '../assets/img/company-logos/wica-2024/mca-architects.png';


import LsiaApcVle from '../assets/img/company-logos/lsia-2024/apc-vle-therapeutics-ltd.png';
import LsiaAssayGenie from '../assets/img/company-logos/lsia-2024/assay-genie.png';
import LsiaAstellas from '../assets/img/company-logos/lsia-2024/astellas.png';
import LsiaBiostor from '../assets/img/company-logos/lsia-2024/biostor.png';
import LsiaBusinessRiver from '../assets/img/company-logos/lsia-2024/business-river-logo.png';
import LsiaDeciphex from '../assets/img/company-logos/lsia-2024/deciphex.png';
import LsiaGlHealthAdvisors from '../assets/img/company-logos/lsia-2024/gl-health-advisors.png';
import LsiaMcArdle from '../assets/img/company-logos/lsia-2024/mcardle.png';
import LsiaPfizer from '../assets/img/company-logos/lsia-2024/pfizer.png';
import LsiaPtcTherapeutics from '../assets/img/company-logos/lsia-2024/ptc-therapeutics.png';
import LsiaRoche from '../assets/img/company-logos/lsia-2024/roche.png';
import LsiaSelfCare from '../assets/img/company-logos/lsia-2024/safe-care.png';
import LsiaVertigenius from '../assets/img/company-logos/lsia-2024/vertigenius.png';
import LsiaWestbourne from '../assets/img/company-logos/lsia-2024/westbourne.png';
import LsiaJanssenScience from '../assets/img/company-logos/lsia-2024/janssen-sciences-ireland.png';
import LsiaNeurovalens from '../assets/img/company-logos/lsia-2024/neurovalens.png';
import LsiaWuxi from '../assets/img/company-logos/lsia-2024/wuxi.png';
import LsiaNelipak from '../assets/img/company-logos/lsia-2024/nelipak.png';
import lsiaIPU from '../assets/img/company-logos/lsia-2024/ipu.jpg';
import lsiaHealthyIre from '../assets/img/company-logos/lsia-2024/healthy-ireland.png';
import lsiaCundall from '../assets/img/company-logos/lsia-2024/cundall.png';
import lsiaVha from '../assets/img/company-logos/lsia-2024/vha.png';
import lsiaCpc from '../assets/img/company-logos/lsia-2024/cpc.png';
import lsiaSkBiotek from '../assets/img/company-logos/lsia-2024/sk-biotek.png';
import lsiaMko from '../assets/img/company-logos/lsia-2024/mko.png';
import lsiaRkd from '../assets/img/company-logos/lsia-2024/rkd.png';
import lsiaCec from '../assets/img/company-logos/lsia-2024/cec.JPG';
import lsiaEllab from '../assets/img/company-logos/lsia-2024/ellab.png';
import lsiaEnterprise from '../assets/img/company-logos/lsia-2024/enterprise.png';
import lsiaXBolt from '../assets/img/company-logos/lsia-2024/x-bolt.png';
import lsiaRcsi from '../assets/img/company-logos/lsia-2024/rcsi.jpg';
import lsiaUnispace from '../assets/img/company-logos/lsia-2024/unispace.png';
import lsiaIspe from '../assets/img/company-logos/lsia-2024/ispe.jpg';
import lsiaMsd from '../assets/img/company-logos/lsia-2024/msd.jpg';
import lsiaTuDublin from '../assets/img/company-logos/lsia-2024/tu-dublin.png';
import lsiaEngineersIre from '../assets/img/company-logos/lsia-2024/engineers-ire.png';
import lsiaEnhanced from '../assets/img/company-logos/lsia-2024/enhanced.png';
import lsiaRcsiUmhs from '../assets/img/company-logos/lsia-2024/rcsi-umhs.png';
import lsiaEviView from '../assets/img/company-logos/lsia-2024/evi-view.png';
import lsiaExyte from '../assets/img/company-logos/lsia-2024/exyte.png';
import lsiaHse from '../assets/img/company-logos/lsia-2024/hse.png';

import dtaBridgewater from '../assets/img/company-logos/dta-2024/bridgewater.png';
import dtaDanskeBank from '../assets/img/company-logos/dta-2024/danske.png';
import dtaEsbNetworks from '../assets/img/company-logos/dta-2024/esb-networks.png';
import dtaBusinessRiver from '../assets/img/company-logos/dta-2024/business-river-logo.png';
import dtaCls from '../assets/img/company-logos/dta-2024/cls.png';
import dtaGoodbodys from '../assets/img/company-logos/dta-2024/goodbody.png';
import dtaGs1 from '../assets/img/company-logos/dta-2024/gs1.png';
import dtaDcuBusinessSchool from '../assets/img/company-logos/dta-2024/dcubusinessschool.png';
import dtaAleph from '../assets/img/company-logos/dta-2024/aleph.png';
import dtaEviden from '../assets/img/company-logos/dta-2024/eviden.png';
import dtaEpam from '../assets/img/company-logos/dta-2024/epam.png';
import dtaClanwilliam from '../assets/img/company-logos/dta-2024/clanwilliam.png';
import dtaMaynoothUniversity from '../assets/img/company-logos/dta-2024/maynoothuni.png';
import dtaSyncrophi from '../assets/img/company-logos/dta-2024/syncrophi.png';
import dtaMsd from '../assets/img/company-logos/dta-2024/msd.png';
import dtaAccuscience from '../assets/img/company-logos/dta-2024/accuscience.png';
import dtaHse from '../assets/img/company-logos/dta-2024/hse.png';
import dtaPat from '../assets/img/company-logos/dta-2024/pat.png';
import dtaKaraConnect from '../assets/img/company-logos/dta-2024/kara-connect.png';
import dtaJonesEngineering from '../assets/img/company-logos/dta-2024/jones-engineering.png';
import dtaBt from '../assets/img/company-logos/dta-2024/bt-logo.png';
import dtaKeltech from '../assets/img/company-logos/dta-2024/keltech.png';
import dtaMaynoothHse from '../assets/img/company-logos/dta-2024/maynooth-hse.png';
import dtaMerck from '../assets/img/company-logos/dta-2024/merck.png';
import dtaCore from '../assets/img/company-logos/dta-2024/core.png';
import dtaRedZinc from '../assets/img/company-logos/dta-2024/redzinc.png';
import dtaVpParkinsons from '../assets/img/company-logos/dta-2024/vp-parkinsons.png';
import dtaStacksPharmacy from '../assets/img/company-logos/dta-2024/stacks-pharmacy.png';
import dtaUniversityLimerick from '../assets/img/company-logos/dta-2024/university-limerick.png';

import aiaUkChapman from '../assets/img/company-logos/aiauk-2024/champman.png';
import aiaUkLisi from '../assets/img/company-logos/aiauk-2024/lisi.png';
import aiaUkPath2pilot from '../assets/img/company-logos/aiauk-2024/path2pilot-1.png';
import aiaUkMicronav from '../assets/img/company-logos/aiauk-2024/micronav.png';
import aiaUkBritishAirways from '../assets/img/company-logos/aiauk-2024/britishairways.png';
import aiaUkEasyJet from '../assets/img/company-logos/aiauk-2024/easyjet.png';
import aiaUkSpinCraft from '../assets/img/company-logos/aiauk-2024/spincraft.png';
import aiaUkCaerdav from '../assets/img/company-logos/aiauk-2024/caerdav.png';
import aiaUkDnata from '../assets/img/company-logos/aiauk-2024/dnata.png';
import aiaUkLuxaviation from '../assets/img/company-logos/aiauk-2024/luxaviation.png';
import aiaUkTitan from '../assets/img/company-logos/aiauk-2024/titan.png';
import aiaUkJet2 from '../assets/img/company-logos/aiauk-2024/jet2.png';
import aiaUkImparta from '../assets/img/company-logos/aiauk-2024/imparta.png';
import aiaUkBusinessRiver from '../assets/img/company-logos/aiauk-2024/business-river-logo.png';
import aiaUkSwissport from '../assets/img/company-logos/aiauk-2024/Swissport-Aspire-Airport-Lounges.png';
import aiaUkCertoAerospace from '../assets/img/company-logos/aiauk-2024/Certo-Aerospace-Ltd.png';
import aiaUkBuckinghamshire from '../assets/img/company-logos/aiauk-2024/Buckinghamshire-New-University-Logo.png';
import aiaUkDeMontfort from '../assets/img/company-logos/aiauk-2024/De-Montfort-University-logo.png';
import aiaUkUniSouthWales from '../assets/img/company-logos/aiauk-2024/University-of-South-Wales.png';
import aiaUkEvolito from '../assets/img/company-logos/aiauk-2024/evolito-logo.png';
import aiaUkUniHigher from '../assets/img/company-logos/aiauk-2024/University-of-the-Highlands-and-Islands.png';
import aiaUkUniWestLondon from '../assets/img/company-logos/aiauk-2024/UNIVERSITY-OF-WEST-LONDON.png';
import aiaUkLeadingEdge from '../assets/img/company-logos/aiauk-2024/Leading-Edge-Aviation.png';
import aiaUkCityUniLondon from '../assets/img/company-logos/aiauk-2024/City-University-London.png';
import aiaUkMidlandAerospace from '../assets/img/company-logos/aiauk-2024/midland-aerospace.png';
import aiaUkLondonGatwick from '../assets/img/company-logos/aiauk-2024/london-gatwick.png';
import aiaUkAircamo from '../assets/img/company-logos/aiauk-2024/aircamo.png';
import aiaUkUniBedfordshire from '../assets/img/company-logos/aiauk-2024/unibedfordshire.png';
import aiaUkFirefly from '../assets/img/company-logos/aiauk-2024/firefly.png';
import aiaUkglobalAts from '../assets/img/company-logos/aiauk-2024/globalAts.png';
import aiaUkSkyPeople from '../assets/img/company-logos/aiauk-2024/skypeople.png';
import aiaUkNats from '../assets/img/company-logos/aiauk-2024/nats.png';
import aiaUkFarnborough from '../assets/img/company-logos/aiauk-2024/farnborough.png';
import aiaUkMaglondon from '../assets/img/company-logos/aiauk-2024/maglondon.png';
import aiaUkUniSunderland from '../assets/img/company-logos/aiauk-2024/University-of-Sunderland.png';
import aiaUkResourceGroup from '../assets/img/company-logos/aiauk-2024/Resource-Group.png';

import foaUkMorgan from '../assets/img/company-logos/foauk-2024/morgan.png';
import foaUkLike from '../assets/img/company-logos/foauk-2024/like.png';
import foaUkElliot from '../assets/img/company-logos/foauk-2024/elliot-group.png';
import foaUkEkho from '../assets/img/company-logos/foauk-2024/ekho-studio.jpg';
import foaUkIncognito from '../assets/img/company-logos/foauk-2024/incognito.png';
import foaUkBusinessRiver from '../assets/img/company-logos/foauk-2024/business-river-logo.png';
import foaUkConcorde from '../assets/img/company-logos/foauk-2024/concorde.png';
import foaUkZipWater from '../assets/img/company-logos/foauk-2024/zip-water.png';
import foaUkBuildSpace from '../assets/img/company-logos/foauk-2024/Build-Space.png';
import foaUkMamouMani from '../assets/img/company-logos/foauk-2024/mamou_mani_ltd.png';
import foaUkStudioSeilern from '../assets/img/company-logos/foauk-2024/Studio-Seilern-Architects.png';
import foaUkLumen from '../assets/img/company-logos/foauk-2024/Lumen.png';
import foaUkAqa from '../assets/img/company-logos/foauk-2024/AQA.png';
import foaUkInteraction from '../assets/img/company-logos/foauk-2024/interaction.png';
import foaUkClaremont from '../assets/img/company-logos/foauk-2024/claremont.png';
import foaUkDawnvale from '../assets/img/company-logos/foauk-2024/dawnvale.png';
import foaUkSpaceInvader from '../assets/img/company-logos/foauk-2024/spaceinvader.png';

import piaUkSeqirus from '../assets/img/company-logos/piauk-2024/seqirus.png';
import piaUkSkyCell from '../assets/img/company-logos/piauk-2024/skycell1.png';
import piaUkAstrazeca from '../assets/img/company-logos/piauk-2024/astrazeneca1.png';
import piaUkSanofi from '../assets/img/company-logos/piauk-2024/sanofi.png';
import piaUkInspiredPharma from '../assets/img/company-logos/piauk-2024/inspired-pharma-training.png';
import piaUkAstex from '../assets/img/company-logos/piauk-2024/astex.png';
import piaUkEllab from '../assets/img/company-logos/piauk-2024/ellab.png';
import piaUkCamida from '../assets/img/company-logos/piauk-2024/camida.png';
import piaUkKuehneNagel from '../assets/img/company-logos/piauk-2024/kuehnenagel.png';
import piaUkUnispace from '../assets/img/company-logos/piauk-2024/unispace.png';
import piaUkCpl from '../assets/img/company-logos/piauk-2024/cpl.png';
import piaUkCognizant from '../assets/img/company-logos/piauk-2024/cognizant.png';
import piaUkEcolab from '../assets/img/company-logos/piauk-2024/ecolab.png';
import piaUkBionow from '../assets/img/company-logos/piauk-2024/bionow.png';
import piaUkBouygues from '../assets/img/company-logos/piauk-2024/bouygues.png';
import piaUkOchrebio from '../assets/img/company-logos/piauk-2024/ochrebio.png';
import piaUkManchester from '../assets/img/company-logos/piauk-2024/manchester.png';
import piaUkTakeda from '../assets/img/company-logos/piauk-2024/takeda.png';
import piaUkNovai from '../assets/img/company-logos/piauk-2024/novai.png';
import piaUkBesins from '../assets/img/company-logos/piauk-2024/besins.png';
import piaUkUpperton from '../assets/img/company-logos/piauk-2024/upperton.png';
import piaUkCnx from '../assets/img/company-logos/piauk-2024/cnx.png';
import piaUkParadigmShift from '../assets/img/company-logos/piauk-2024/paradigm-shift.png';
import piaUkBusinessRiver from '../assets/img/company-logos/piauk-2024/business-river-logo.png';
import piaUkCbre from '../assets/img/company-logos/piauk-2024/cbre.png';
import piaUkAlifeinaday from '../assets/img/company-logos/piauk-2024/alifeinaday.png';
import piaUkTiro from '../assets/img/company-logos/piauk-2024/tiro.png';
import piaUkMagnitudeBiosciences from '../assets/img/company-logos/piauk-2024/magnitude-biosciences.png';
import piaUkO2h from '../assets/img/company-logos/piauk-2024/o2h.png';
import piaUkMesox from '../assets/img/company-logos/piauk-2024/mesox.png';
import piaUkBulbLab from '../assets/img/company-logos/piauk-2024/bulb-laboratories1.png';
import piaUkWg from '../assets/img/company-logos/piauk-2024/wg.png';
import piaUkAviadoBio from '../assets/img/company-logos/piauk-2024/aviadbio.png';
import piaUkBidwells from '../assets/img/company-logos/piauk-2024/bidwells1.png';
import piaUkGenefirst from '../assets/img/company-logos/piauk-2024/genefirst.png';
import piaUkCarterJonas from '../assets/img/company-logos/piauk-2024/carter-jonas.png';
import piaUkFingerPost from '../assets/img/company-logos/piauk-2024/fingerpost.png';
import piaUkCSLSeqirus from '../assets/img/company-logos/piauk-2024/csl-seqirus.png';
import piaUkQuickStat from '../assets/img/company-logos/piauk-2024/quickstat.png';
import piaUkMicroBio from '../assets/img/company-logos/piauk-2024/microbio.png';
import piaUkUniEastAnglia from '../assets/img/company-logos/piauk-2024/universityeastanglia.png';
import piaUkUniIspe from '../assets/img/company-logos/piauk-2024/ispe.png';
import piaUkUcl from '../assets/img/company-logos/piauk-2024/ucl.png';
import piaUkUniversityOfReading from '../assets/img/company-logos/piauk-2024/universityofreading.png';
import piaUkNPA from '../assets/img/company-logos/piauk-2024/npa.png';
import piaUkMattMoran from '../assets/img/company-logos/piauk-2024/matt-moran.png';
import piaUkEmirates from '../assets/img/company-logos/piauk-2024/emirates.png';
import piaUkDelta from '../assets/img/company-logos/piauk-2024/delta.png';
import piaUkReAgent from '../assets/img/company-logos/piauk-2024/reagent.png';
import piaUkAccordHealthcare from '../assets/img/company-logos/piauk-2024/accord-healthcare.png';
import piaUkWsp from '../assets/img/company-logos/piauk-2024/wsp.png';
import piaUkDawsonGroup from '../assets/img/company-logos/piauk-2024/dawson-group.png';
import piaUkFujifilm from '../assets/img/company-logos/piauk-2024/fujifilm.png';
import piaUkSgs from '../assets/img/company-logos/piauk-2024/sgs.png';
import piaUkCatalent from '../assets/img/company-logos/piauk-2024/catalent.png';
import piaUkBristol from '../assets/img/company-logos/piauk-2024/bristol.png';
import piaUkGsk from '../assets/img/company-logos/piauk-2024/gsk.png';
import piaUkWestMidlands from '../assets/img/company-logos/piauk-2024/west-midlands.png';
import piaUkTrack from '../assets/img/company-logos/piauk-2024/track.png';
import piaUkDepartment from '../assets/img/company-logos/piauk-2024/department.png';
import piaUkLonza from '../assets/img/company-logos/piauk-2024/lonza.png';
import piaUkPharmig from '../assets/img/company-logos/piauk-2024/pharmig.png';
import piaUkOcp from '../assets/img/company-logos/piauk-2024/ocp.png';
import piaUkCatapult from '../assets/img/company-logos/piauk-2024/catapult.png';
import piaUkPlanInternational from '../assets/img/company-logos/piauk-2024/planinternational.png';
import piaUkCeva from '../assets/img/company-logos/piauk-2024/cevalogistics.png';

import isaPwc from '../assets/img/company-logos/isa-2024/pwc-ireland.png';
import isaCpi from '../assets/img/company-logos/isa-2024/cpi.png';
import isa123ie from '../assets/img/company-logos/isa-2024/123.png';
import isaLondis from '../assets/img/company-logos/isa-2024/londis.png';
import isaJusteat from '../assets/img/company-logos/isa-2024/justeat.png';
import isaNaturevalley from '../assets/img/company-logos/isa-2024/naturevalley.png';
import isaMediahuis from '../assets/img/company-logos/isa-2024/mediahuis.png';
import isaOmnicam from '../assets/img/company-logos/isa-2024/omnicom.png';
import isaStPatrick from '../assets/img/company-logos/isa-2024/stpatrick.png';
import isaDrOetker from '../assets/img/company-logos/isa-2024/droetker.png';
import isaGalaTaker from '../assets/img/company-logos/isa-2024/galaretail.png';
import isaCyberSafeKids from '../assets/img/company-logos/isa-2024/cybersafekids.png';
import isaTheSundayTimes from '../assets/img/company-logos/isa-2024/thesundaytimes.png';
import isaThree from '../assets/img/company-logos/isa-2024/three.png';
import isaElectricIreland from '../assets/img/company-logos/isa-2024/electricireland.png';
import isaAerLingus from '../assets/img/company-logos/isa-2024/aerlingus.png';
import isaRte from '../assets/img/company-logos/isa-2024/rte.png';
import isaBusinessRiver from '../assets/img/company-logos/isa-2024/business-river-logo.png';
import isaMoriartyGroup from '../assets/img/company-logos/isa-2024/moriartygroup.png';
import isaHorseRacing from '../assets/img/company-logos/isa-2024/horseracing.png';
import isaGrooveyard from '../assets/img/company-logos/isa-2024/grooveyard.png';
import isaAccenture from '../assets/img/company-logos/isa-2024/accenture.png';
import isaKick from '../assets/img/company-logos/isa-2024/kick.png';
import isaConceptPartnership from '../assets/img/company-logos/isa-2024/conceptpartnership.png';
import isaIntoMedia from '../assets/img/company-logos/isa-2024/intomedia.png';
import isaDiff from '../assets/img/company-logos/isa-2024/diff.png';
import isaNewsIreland from '../assets/img/company-logos/isa-2024/newsireland.png';
import isaNewsUkIreland from '../assets/img/company-logos/isa-2024/newsukireland.png';
import isaMindshare from '../assets/img/company-logos/isa-2024/mindshare.jpg';
import isaCork from '../assets/img/company-logos/isa-2024/cork.png';
import isaIndeed from '../assets/img/company-logos/isa-2024/indeed.png';
import isaFuel from '../assets/img/company-logos/isa-2024/fuel.png';
import isaKia from '../assets/img/company-logos/isa-2024/kia.png';
import isaSportsDirect from '../assets/img/company-logos/isa-2024/sportsdirect.png';
import isaPr1 from '../assets/img/company-logos/isa-2024/Pr1.png';
import isaPieta from '../assets/img/company-logos/isa-2024/pieta.png';
import isaIrishlife from '../assets/img/company-logos/isa-2024/irishlife.png';
import isaKpmg from '../assets/img/company-logos/isa-2024/kpmg.png';
import isaAig from '../assets/img/company-logos/isa-2024/aig.png';
import isaSportForBusiness from '../assets/img/company-logos/isa-2024/sports-for-business.png';
import isaSpar from '../assets/img/company-logos/isa-2024/spar.png';
import isaEuropar from '../assets/img/company-logos/isa-2024/eurospar.png';
import isaMace from '../assets/img/company-logos/isa-2024/mace.png';

import beaaUisce from '../assets/img/company-logos/beaa-2024/uisce.png';
import beaaBordNaMona from '../assets/img/company-logos/beaa-2024/bordnamona.png';
import beaaPrl from '../assets/img/company-logos/beaa-2024/prl.png';
import beaaJohnson from '../assets/img/company-logos/beaa-2024/johnson.png';
import beaaAstellas from '../assets/img/company-logos/beaa-2024/astellas.png';
import beaaGilead from '../assets/img/company-logos/beaa-2024/gilead.png';
import beaaMahonPoint from '../assets/img/company-logos/beaa-2024/mahonpoint.png';
import beaaCastleKnock from '../assets/img/company-logos/beaa-2024/castleknock.png';
import beaaKyte from '../assets/img/company-logos/beaa-2024/kyte.png';
import beaaBreedon from '../assets/img/company-logos/beaa-2024/breedon.png';
import beaaAshleigh from '../assets/img/company-logos/beaa-2024/ashleigh.png';
import beaaRcsi from '../assets/img/company-logos/beaa-2024/rcsi.png';
import beaaConnacht from '../assets/img/company-logos/beaa-2024/connacht.png';
import beaaPfizer from '../assets/img/company-logos/beaa-2024/pfizer.png';
import beaaBord from '../assets/img/company-logos/beaa-2024/bord.png';
import beaaBusinessRiver from '../assets/img/company-logos/beaa-2024/business-river-logo.png';
import beaaLagan from '../assets/img/company-logos/beaa-2024/lagan.png';
import beaaDcu from '../assets/img/company-logos/beaa-2024/dcu.png';
import beaaFortEnergy from '../assets/img/company-logos/beaa-2024/fortenergy.png';
import beaaDigren from '../assets/img/company-logos/beaa-2024/digren.png';
import beaaTechUniDublin from '../assets/img/company-logos/beaa-2024/TUDublin.png';
import beaaPinergy from '../assets/img/company-logos/beaa-2024/pinergy.png';
import beaaFutureFit from '../assets/img/company-logos/beaa-2024/futurefit.png';
import beaaVantageTower from '../assets/img/company-logos/beaa-2024/vantagetower.png';
import beaaAnalogDevices from '../assets/img/company-logos/beaa-2024/analogdevices.png';
import beaaTakeda from '../assets/img/company-logos/beaa-2024/takedaireland.png';
import beaaClimeAction from '../assets/img/company-logos/beaa-2024/Climeaction.png';
import beaaAcornCloud from '../assets/img/company-logos/beaa-2024/acorncloud.png';
import beaaFreightspeed from '../assets/img/company-logos/beaa-2024/freightspeed.png';
import beaaDaa from '../assets/img/company-logos/beaa-2024/daa.png';
import beaaDairygold from '../assets/img/company-logos/beaa-2024/dairygold.png';

import phaMccarthy from '../assets/img/company-logos/pha-2024/mccarthy.png';
import phaBelfast from '../assets/img/company-logos/pha-2024/belfast.png';
import phaEmbrace from '../assets/img/company-logos/pha-2024/embrace.png';
import phaNewVision from '../assets/img/company-logos/pha-2024/newvision.png';
import phaStJohn from '../assets/img/company-logos/pha-2024/stjohn.png';
import phaBusinessRiver from '../assets/img/company-logos/pha-2024/business-river-logo.png';
import phaVhi from '../assets/img/company-logos/pha-2024/vhi.png';
import phaLikha from '../assets/img/company-logos/pha-2024/likha.png';
import phaAffidea from '../assets/img/company-logos/pha-2024/affidea.png';
import phaMkAesthetics from '../assets/img/company-logos/pha-2024/MkAesthetics.png';
import phaUpmc from '../assets/img/company-logos/pha-2024/upmc.png';
import phaAxonRehab from '../assets/img/company-logos/pha-2024/axonrehab.png';
import phaJohnsonJohnson from '../assets/img/company-logos/pha-2024/johnson.png';
import phaMedforce from '../assets/img/company-logos/pha-2024/medforce.png';
import phaMaterPrivate from '../assets/img/company-logos/pha-2024/materprivate.png';
import phaMusgrave from '../assets/img/company-logos/pha-2024/musgrave.png';
import phaAilesbury from '../assets/img/company-logos/pha-2024/ailesbury.png';
import phaPembrokeDental from '../assets/img/company-logos/pha-2024/pembroke.png';
import phaTruly from '../assets/img/company-logos/pha-2024/truly.png';
import phaTowerview from '../assets/img/company-logos/pha-2024/towerview.png';
import phaTallaght from '../assets/img/company-logos/pha-2024/tallaght.png';
import phaHse from '../assets/img/company-logos/pha-2024/hse.png';
import phaTheNewYou from '../assets/img/company-logos/pha-2024/thenewyou.png';
import phaRCSI from '../assets/img/company-logos/pha-2024/rcsi.png';
import phaOptilase from '../assets/img/company-logos/pha-2024/optilase.jpg';

import hrMde from '../assets/img/company-logos/hr-2024/mde.png';
import hrFragomen from '../assets/img/company-logos/hr-2024/fragomen.png';
import hrSpunout from '../assets/img/company-logos/hr-2024/spunout.png';
import hrBritvic from '../assets/img/company-logos/hr-2024/britvicireland.png';
import hrDecare from '../assets/img/company-logos/hr-2024/decare.png';
import hrBordnamona from '../assets/img/company-logos/hr-2024/bordnamona.png';
import hrDigitalMarketing from '../assets/img/company-logos/hr-2024/digitalmarketing.png';
import hrLidl from '../assets/img/company-logos/hr-2024/lidl.png';
import hrDepaul from '../assets/img/company-logos/hr-2024/depaul.png';
import hrTheDoyle from '../assets/img/company-logos/hr-2024/thedoyle.png';
import hrUisce from '../assets/img/company-logos/hr-2024/uisce.png';
import hrIrishlife from '../assets/img/company-logos/hr-2024/irishlife.png';
import hrBusinessRiver from '../assets/img/company-logos/hr-2024/business-river-logo.png';
import hrCopacetic from '../assets/img/company-logos/hr-2024/copacetic.png';
import hrCpl from '../assets/img/company-logos/hr-2024/cpl.png';
import hrBon from '../assets/img/company-logos/hr-2024/bonsecours.png';
import hrPrimeline from '../assets/img/company-logos/hr-2024/primeline.png';
import hrBaxter from '../assets/img/company-logos/hr-2024/baxterstorey.png';
import hrDoT from '../assets/img/company-logos/hr-2024/departmentoftransport.png';
import hrThree from '../assets/img/company-logos/hr-2024/three.png';
import hrBoots from '../assets/img/company-logos/hr-2024/boots.png';
import hrIrishDistillers from '../assets/img/company-logos/hr-2024/irishdistillers.png';
import hrSdWorx from '../assets/img/company-logos/hr-2024/sdworx.png';
import hrVoya from '../assets/img/company-logos/hr-2024/voya.png';
import hrAviva from '../assets/img/company-logos/hr-2024/aviva.png';
import hrPtc from '../assets/img/company-logos/hr-2024/ptc.png';
import hrTotalHealthcare from '../assets/img/company-logos/hr-2024/totalhealthcover.png';
import hrTelus from '../assets/img/company-logos/hr-2024/telus.png';
import hrSodexo from '../assets/img/company-logos/hr-2024/sodexo.png';
import hrClanwilliam from '../assets/img/company-logos/hr-2024/clanwilliam.png';
import hrDcu from '../assets/img/company-logos/hr-2024/dcu.png';
import hrChadwicks from '../assets/img/company-logos/hr-2024/chadwicks.png';
import hrExcel from '../assets/img/company-logos/hr-2024/excelrecruitment.png';
import hrHRLocker from '../assets/img/company-logos/hr-2024/hrlocker.png';
import hrOne4all from '../assets/img/company-logos/hr-2024/one4all.png';
import hrHse from '../assets/img/company-logos/hr-2024/hse.png';
import hrGlenveagh from '../assets/img/company-logos/hr-2024/glenveagh.png';
import hrFineos from '../assets/img/company-logos/hr-2024/fineos.png';
import hrAib from '../assets/img/company-logos/hr-2024/aib.png';
import hrTrigonHotels from '../assets/img/company-logos/hr-2024/trigon.png';
import hrAvolon from '../assets/img/company-logos/hr-2024/avolon.png';

import eeaEthos from '../assets/img/company-logos/eea-2024/ethos.png';
import eeaProdieco from '../assets/img/company-logos/eea-2024/prodieco.png';
import eeaWuxi from '../assets/img/company-logos/eea-2024/wuxi.png';
import eeaHanleyEnergy from '../assets/img/company-logos/eea-2024/hanleyenergy.png';
import eeaAecom from '../assets/img/company-logos/eea-2024/aecom.png';
import eeaCliniva from '../assets/img/company-logos/eea-2024/cliniva.png';
import eeaGalway from '../assets/img/company-logos/eea-2024/galway.png';
import eeaCardinal from '../assets/img/company-logos/eea-2024/cardinal.png';
import eeaIn2 from '../assets/img/company-logos/eea-2024/in2.png';
import eeaEps from '../assets/img/company-logos/eea-2024/eps.png';
import eeaAdvance from '../assets/img/company-logos/eea-2024/advance.png';
import eeaBusinessRiver from '../assets/img/company-logos/eea-2024/business-river-logo.png';
import eeaEnvironmental from '../assets/img/company-logos/eea-2024/environmental.png';
import eeaBFluid from '../assets/img/company-logos/eea-2024/bfluid.png';
import eeaDonnelly from '../assets/img/company-logos/eea-2024/donnellycivilengineering.png';
import eeaPunch from '../assets/img/company-logos/eea-2024/punchce.png';
import eeaAtu from '../assets/img/company-logos/eea-2024/atu.png';
import eeaJjRhatigan from '../assets/img/company-logos/eea-2024/jjrhatigan.png';
import eeaUniLimerick from '../assets/img/company-logos/eea-2024/limerickuni.png';
import eeaMtu from '../assets/img/company-logos/eea-2024/mtu.png';
import eeaTobin from '../assets/img/company-logos/eea-2024/tobin.png';
import eeaWritech from '../assets/img/company-logos/eea-2024/writech.png';
import eeaAxiseng from '../assets/img/company-logos/eea-2024/axiseng.png';
import eeaKirby from '../assets/img/company-logos/eea-2024/kirby.png';
import eeaJonesEngineering from '../assets/img/company-logos/eea-2024/jonesengineering.png';
import eeaJonesHmv from '../assets/img/company-logos/eea-2024/hmv.png';

import npaAlmac from '../assets/img/company-logos/npa-2024/almac.png';
import npaMckesson from '../assets/img/company-logos/npa-2024/mckesson.png';
import npaDaa from '../assets/img/company-logos/npa-2024/daa.png';
import npaViatirs from '../assets/img/company-logos/npa-2024/viatris.png';
import npaVerizon from '../assets/img/company-logos/npa-2024/verizon.png';
import npaBulmers from '../assets/img/company-logos/npa-2024/bulmers.png';
import npaDublinBus from '../assets/img/company-logos/npa-2024/dublinbus.png';
import npaIvalua from '../assets/img/company-logos/npa-2024/ivalua.png';
import npaKerry from '../assets/img/company-logos/npa-2024/kerry.png';
import npaElliot from '../assets/img/company-logos/npa-2024/elliotgroup.png';
import npaIrishDefence from '../assets/img/company-logos/npa-2024/irishdefence.png';
import npaBusinessRiver from '../assets/img/company-logos/npa-2024/business-river-logo.png';
import npaAib from '../assets/img/company-logos/npa-2024/aib.png';
import npaPtsb from '../assets/img/company-logos/npa-2024/ptsb.png';
import npaAnpost from '../assets/img/company-logos/npa-2024/anpost.png';
import npaEirgrid from '../assets/img/company-logos/npa-2024/eirgrid.png';
import npaBusEireann from '../assets/img/company-logos/npa-2024/buseireann.png';
import npaGreenIt from '../assets/img/company-logos/npa-2024/GreenIT.png';
import npaGreenville from '../assets/img/company-logos/npa-2024/greenville.png';
import npaKpmg from '../assets/img/company-logos/npa-2024/KPMG.png';
import npaUniversityLimerick from '../assets/img/company-logos/npa-2024/unilimerick.png';
import npaDcu from '../assets/img/company-logos/npa-2024/dcu.png';
import npaEy from '../assets/img/company-logos/npa-2024/ey.png';
import npaCompassIreland from '../assets/img/company-logos/npa-2024/compassireland.png';
import npaNatus from '../assets/img/company-logos/npa-2024/natus.png';
import npaAvantor from '../assets/img/company-logos/npa-2024/avantor.png';
import npaPwcIreland from '../assets/img/company-logos/npa-2024/pwcireland.jpg';
import npaEla from '../assets/img/company-logos/npa-2024/ela.png';
import npaFuturePlanet from '../assets/img/company-logos/npa-2024/futureplanet.png';
import npaPti from '../assets/img/company-logos/npa-2024/pti.png';
import npaCircularComputing from '../assets/img/company-logos/npa-2024/circularcomputing.png';
import npaIrishDistillers from '../assets/img/company-logos/npa-2024/irish-distillers.png';
import npaCoillte from '../assets/img/company-logos/npa-2024/coillte.png';
import npaKeelings from '../assets/img/company-logos/npa-2024/keelings.png';

import piaWuxi from '../assets/img/company-logos/pia-2024/wuxi.png';
import piaAsgard from '../assets/img/company-logos/pia-2024/asgard.png';
import piaLennox from '../assets/img/company-logos/pia-2024/lennox.png';
import piaPfizer from '../assets/img/company-logos/pia-2024/pfizer.png';
import piaSanofi from '../assets/img/company-logos/pia-2024/sanofi.png';
import piaMicrobio from '../assets/img/company-logos/pia-2024/microbio.png';
import piaCamida from '../assets/img/company-logos/pia-2024/camida.png';
import piaCognizant from '../assets/img/company-logos/pia-2024/cognizant.png';
import piaAcorn from '../assets/img/company-logos/pia-2024/acorn.png';
import piaAlexion from '../assets/img/company-logos/pia-2024/alexion.png';
import piaViatris from '../assets/img/company-logos/pia-2024/viatris.png';
import piaNuvalez from '../assets/img/company-logos/pia-2024/nuvalez.png';
import piaCytiva from '../assets/img/company-logos/pia-2024/cytiva.png';
import piaBiopharmaChemSkillnet from '../assets/img/company-logos/pia-2024/biopharmachemskillnet.png';
import piaBiostor from '../assets/img/company-logos/pia-2024/biostor.png';
import piaNaviGroup from '../assets/img/company-logos/pia-2024/navigroup.png';
import piaWolfMedical from '../assets/img/company-logos/pia-2024/wolfmedical.png';
import piaCliniva from '../assets/img/company-logos/pia-2024/cliniva.png';
import piaQuickStat from '../assets/img/company-logos/pia-2024/quickstat.png';
import piaKuehneNagel from '../assets/img/company-logos/pia-2024/kuehnenagel.png';
import piaMattMoran from '../assets/img/company-logos/pia-2024/matt-moran.png';
import piaTuDublin from '../assets/img/company-logos/pia-2024/tu-dublin.png';
import piaRcsiUhms from '../assets/img/company-logos/pia-2024/rcsi-umhs.png';
import piaUcc from '../assets/img/company-logos/pia-2024/ucc.png';
import piaEurofins from '../assets/img/company-logos/pia-2024/eurofins.png';
import piaCplResources from '../assets/img/company-logos/pia-2024/cpl.png';
import piaEllab from '../assets/img/company-logos/pia-2024/ellab.png';
import piaKpmg from '../assets/img/company-logos/pia-2024/KPMG.png';
import piaBoc from '../assets/img/company-logos/pia-2024/bocireland.jpg';
import piaExyte from '../assets/img/company-logos/pia-2024/exyte.png';
import piaRkd from '../assets/img/company-logos/pia-2024/rkd.png';
import piaCec from '../assets/img/company-logos/pia-2024/cec.png';
import piaAlmac from '../assets/img/company-logos/pia-2024/almac.png';
import piaSkycell from '../assets/img/company-logos/pia-2024/skycell.png';
import piaBiocair from '../assets/img/company-logos/pia-2024/biocair.png';
import piaEcolab from '../assets/img/company-logos/pia-2024/ecolab.png';
import piaObwTech from '../assets/img/company-logos/pia-2024/obwtech.png';
import piaUnispace from '../assets/img/company-logos/pia-2024/unispacelifesciences.png';
import piaMsdIreland from '../assets/img/company-logos/pia-2024/msdireland.png';
import piaAmgen from '../assets/img/company-logos/pia-2024/amgen.png';
import piaTakeda from '../assets/img/company-logos/pia-2024/takeda.png';
import piaMiaspharma from '../assets/img/company-logos/pia-2024/miaspharma.png';
import piaAbbvie from '../assets/img/company-logos/pia-2024/abbvie.png';
import piaFutureNeuro from '../assets/img/company-logos/pia-2024/futureresearch.png';
import piaLilly from '../assets/img/company-logos/pia-2024/lilly.png';
import piaLifeScience from '../assets/img/company-logos/pia-2024/lifescience.png';
import piaHanley from '../assets/img/company-logos/pia-2024/hanley.png';
import piaIpsen from '../assets/img/company-logos/pia-2024/ipsen.png';
import piaNibrtconcept from '../assets/img/company-logos/pia-2024/nibrtconcept.png';
import piaAstellas from '../assets/img/company-logos/pia-2024/astellas.png';
import piaBusinessRiver from '../assets/img/company-logos/pia-2024/business-river-logo.png';
import piaSynergySecurity from '../assets/img/company-logos/pia-2024/Synergy-Security-Solutions.png';
import piaEdgeConnex from '../assets/img/company-logos/pia-2024/Edge-ConneX.png';
import piaQrmi from '../assets/img/company-logos/pia-2024/qrmi.png';
import piaPmi from '../assets/img/company-logos/pia-2024/pmi.png';
import piaBiopharmaChemIreland from '../assets/img/company-logos/pia-2024/biopharmachemireland.png';
import piaCarrickTherapeutics from '../assets/img/company-logos/pia-2024/Carrick-Therapeutics.png';
import piaGaelicLabs from '../assets/img/company-logos/pia-2024/Gaelic-Labs.png';
import piaInchera from '../assets/img/company-logos/pia-2024/inchera.png';
import piaRhenus from '../assets/img/company-logos/pia-2024/rhenus.png';
import piaAizon from '../assets/img/company-logos/pia-2024/aizon.png';
import piaMainReception from '../assets/img/company-logos/pia-2024/aramex.png';
import piaPharmalex from '../assets/img/company-logos/pia-2024/pharmaLex.jpg';
import piaNextGeneration from '../assets/img/company-logos/pia-2024/nextgen.png';
import piaCroninGroup from '../assets/img/company-logos/pia-2024/cronin-group.png';
import piaDeciphex from '../assets/img/company-logos/pia-2024/deciphex.png';
import piaKcs from '../assets/img/company-logos/pia-2024/kcs.png';
import piaRubix from '../assets/img/company-logos/pia-2024/rubix.png';
import piaIbec from '../assets/img/company-logos/pia-2024/ibec.png';
import piaKArcher from '../assets/img/company-logos/pia-2024/karcher.png';
import piaAvantor from '../assets/img/company-logos/pia-2024/avantor.png';
import piaSgs from '../assets/img/company-logos/pia-2024/sgs.png';

import iciaSummerHill from '../assets/img/company-logos/icia-2024/summerhill.png';
import iciaMcAdam from '../assets/img/company-logos/icia-2024/mcadam.png';
import iciaCorgan from '../assets/img/company-logos/icia-2024/corgan.png';
import iciaArcadis from '../assets/img/company-logos/icia-2024/arcadis.png';
import iciaEastgate from '../assets/img/company-logos/icia-2024/eastgate.png';
import iciaMulcahy from '../assets/img/company-logos/icia-2024/mulcahy.png';
import iciaQuartz from '../assets/img/company-logos/icia-2024/quartz.png';
import iciaCunningham from '../assets/img/company-logos/icia-2024/cunningham.png';
import iciaHeatherbook from '../assets/img/company-logos/icia-2024/heatherbook.png';
import iciaEthos from '../assets/img/company-logos/icia-2024/ethos.png';
import iciaJandB from '../assets/img/company-logos/icia-2024/jandb.png';
import iciaPowerBetter from '../assets/img/company-logos/icia-2024/powerbetter.png';
import iciaConack from '../assets/img/company-logos/icia-2024/conack-new.png';
import iciaMitchellMcdermott from '../assets/img/company-logos/icia-2024/mitchellmcdermott.png';
import iciaCollen from '../assets/img/company-logos/icia-2024/collen.png';
import iciaBam from '../assets/img/company-logos/icia-2024/bam.png';
import iciaCfield from '../assets/img/company-logos/icia-2024/cfield.png';
import iciaMosart from '../assets/img/company-logos/icia-2024/mosart.png';
import iciaArdmac from '../assets/img/company-logos/icia-2024/ardmac.png';
import iciaBwdi from '../assets/img/company-logos/icia-2024/bwdi.png';
import icia1878 from '../assets/img/company-logos/icia-2024/eighteenseventyeight.png';
import iciawritech from '../assets/img/company-logos/icia-2024/writech.png';
import iciaFalconGreen from '../assets/img/company-logos/icia-2024/falcongreen.png';
import iciaLumenSearch from '../assets/img/company-logos/icia-2024/lumensearch.png';
import iciaElliotGroup from '../assets/img/company-logos/icia-2024/elliotgroup.png';
import iciaKingspan from '../assets/img/company-logos/icia-2024/kingspan.png';
import iciaGanson from '../assets/img/company-logos/icia-2024/ganson.png';
import iciaGraham from '../assets/img/company-logos/icia-2024/graham.png';
import iciaKmcHomes from '../assets/img/company-logos/icia-2024/kmchomes.png';
import iciaPMcvey from '../assets/img/company-logos/icia-2024/pmcvey.png';
import iciaPennypop from '../assets/img/company-logos/icia-2024/pennypop.png';
import iciaMastertech from '../assets/img/company-logos/icia-2024/mastertech.png';
import iciaJackie from '../assets/img/company-logos/icia-2024/jackiegreene.png';
import iciaTechRefrigiration from '../assets/img/company-logos/icia-2024/techref.png';
import iciaCroninGroup from '../assets/img/company-logos/icia-2024/croningroup.png';
import iciaTowerSecurity from '../assets/img/company-logos/icia-2024/towersecurity.png';
import iciaConnex from '../assets/img/company-logos/icia-2024/connex.png';
import iciaHaysIreland from '../assets/img/company-logos/icia-2024/hays.png';
import iciaKccGroup from '../assets/img/company-logos/icia-2024/kcc.png';
import iciaVisionContracting from '../assets/img/company-logos/icia-2024/visioncontracting.png';
import iciaMmcIreland from '../assets/img/company-logos/icia-2024/mmcireland.png';
import iciaConsiderateConstructors from '../assets/img/company-logos/icia-2024/ccs.png';
import iciaKsnHorizon from '../assets/img/company-logos/icia-2024/ksnhorizon.png';
import iciaPeptalk from '../assets/img/company-logos/icia-2024/peptalk.png';
import iciaHenryJLyons from '../assets/img/company-logos/icia-2024/henry.png';
import iciaVinDijk from '../assets/img/company-logos/icia-2024/vandijk.png';
import iciaPjHegarty from '../assets/img/company-logos/icia-2024/pjhegarty.png';
import iciaBoonEdam from '../assets/img/company-logos/icia-2024/boonedam.png';
import iciaDuggan from '../assets/img/company-logos/icia-2024/duggan.png';
import iciaBreedon from '../assets/img/company-logos/icia-2024/breedon.png';
import iciaMsd from '../assets/img/company-logos/icia-2024/msdireland.png';
import iciaTomOBrien from '../assets/img/company-logos/icia-2024/tomobrien.png';
import iciaTobin from '../assets/img/company-logos/icia-2024/tobin.png';
import iciaSuirEngineering from '../assets/img/company-logos/icia-2024/suirengineering.png';
import iciaFlynn from '../assets/img/company-logos/icia-2024/flynn.png';
import iciaCopperCoast from '../assets/img/company-logos/icia-2024/coppercoast.png';
import iciaRkd from '../assets/img/company-logos/icia-2024/rkd.png';
import iciaMcAleer from '../assets/img/company-logos/icia-2024/mcaleer.png';
import iciaLimerick from '../assets/img/company-logos/icia-2024/limericktwentythirty.png';
import iciaScottTallon from '../assets/img/company-logos/icia-2024/stw.png';
import iciaGlasgiven from '../assets/img/company-logos/icia-2024/glasgiven.jpg';
import iciaDesignerGroup from '../assets/img/company-logos/icia-2024/designergroup.png';
import iciaCairn from '../assets/img/company-logos/icia-2024/cairn.png';
import iciaDmc from '../assets/img/company-logos/icia-2024/dmc.png';
import iciaJonesEngineering from '../assets/img/company-logos/icia-2024/jonesengineering.png';
import iciaTitanRoofing from '../assets/img/company-logos/icia-2024/titanroofing.png';
import iciaTurnerTownsend from '../assets/img/company-logos/icia-2024/turnertownsend.png';
import iciaRainey from '../assets/img/company-logos/icia-2024/rainey.png';
import iciaKelbuild from '../assets/img/company-logos/icia-2024/kelbuild.png';
import iciaCrannog from '../assets/img/company-logos/icia-2024/crannog.png';
import iciaReddy from '../assets/img/company-logos/icia-2024/reddy.png';
import iciaIn2 from '../assets/img/company-logos/icia-2024/in2.png';
import iciaGeaney from '../assets/img/company-logos/icia-2024/geaney.png';
import iciaMcaArchitects from '../assets/img/company-logos/icia-2024/mcaarchitects.png';
import iciaOhac from '../assets/img/company-logos/icia-2024/ohac.png';
import iciaKeymore from '../assets/img/company-logos/icia-2024/keymore.png';
import iciaJtmEnergy from '../assets/img/company-logos/icia-2024/jtmenergy.jpg';
import iciaDornan from '../assets/img/company-logos/icia-2024/dornan.png';
import iciaGia from '../assets/img/company-logos/icia-2024/gia.png';
import iciaOcc from '../assets/img/company-logos/icia-2024/occ.png';
import iciaSeswater from '../assets/img/company-logos/icia-2024/seswater.png';
import iciaAquaTrace from '../assets/img/company-logos/icia-2024/aquatrace.png';
import iciaInstrotec from '../assets/img/company-logos/icia-2024/instrotec.png';
import iciaCluidHousing from '../assets/img/company-logos/icia-2024/cluid-housing.png';
import iciaSportIreland from '../assets/img/company-logos/icia-2024/sportireland.png';
import iciaMgs from '../assets/img/company-logos/icia-2024/mgs.png';
import iciaDelapWaller from '../assets/img/company-logos/icia-2024/delapwaller.png';
import iciaWalls from '../assets/img/company-logos/icia-2024/walls.png';
import iciaStructureTone from '../assets/img/company-logos/icia-2024/structuretone.png';
import iciaLighthouse from '../assets/img/company-logos/icia-2024/lighthouse-logo.png';
import iciaMhi from '../assets/img/company-logos/icia-2024/mhi.png';
import iciaKarcher from '../assets/img/company-logos/icia-2024/karcher.png';
import iciaIrishRail from '../assets/img/company-logos/icia-2024/karcher.png';
import iciaVirtus from '../assets/img/company-logos/icia-2024/virtus.png';
import iciaAltuArchitects from '../assets/img/company-logos/icia-2024/altuarchitects.png';
import iciaMCorm from '../assets/img/company-logos/icia-2024/mcorm.png';
import iciaRespond from '../assets/img/company-logos/icia-2024/respond.png';
import iciaRoger from '../assets/img/company-logos/icia-2024/roger.png';
import iciaSisk from '../assets/img/company-logos/icia-2024/john_sisk.png';
import iciaCogent from '../assets/img/company-logos/icia-2024/cogent1.png';
import iciaBroomfield from '../assets/img/company-logos/icia-2024/broomfield-cons.png';
import iciaNolans from '../assets/img/company-logos/icia-2024/nolans.png';
import iciaCWOBrien from '../assets/img/company-logos/icia-2024/cwobrien1.png';
import iciaFusion4Quality from '../assets/img/company-logos/icia-2024/fusion4quality.png';
import iciaBmce from '../assets/img/company-logos/icia-2024/bmce.png';
import iciaCunnane from '../assets/img/company-logos/icia-2024/cunnane.png';
import iciaDublinBus from '../assets/img/company-logos/icia-2024/dublinbus.png';
import iciaIpropertyRadio from '../assets/img/company-logos/icia-2024/iproperty-radio.png';
import iciaDct from '../assets/img/company-logos/icia-2024/dct.png';
import iciaTuDublin from '../assets/img/company-logos/icia-2024/TUDublin.png';
import iciaGlenveagh from '../assets/img/company-logos/icia-2024/glenveagh1.png';
import iciaArken from '../assets/img/company-logos/icia-2024/arken.png';
import iciaUniLimerick from '../assets/img/company-logos/icia-2024/unilimerick.png';
import iciaWard from '../assets/img/company-logos/icia-2024/wardpersonnel.png';

import foaKpmg from '../assets/img/company-logos/foa-2024/KPMG.png';
import foaTheInteriorsProject from '../assets/img/company-logos/foa-2024/theinteriorsproject.png';
import foaMolaArchitecture from '../assets/img/company-logos/foa-2024/mola.png';
import foaHenryJLyons from '../assets/img/company-logos/foa-2024/henry.png';
import foaAspectInteriors from '../assets/img/company-logos/foa-2024/aspectinteriors.png';
import foaConbo from '../assets/img/company-logos/foa-2024/conbo.png';
import foaEtag from '../assets/img/company-logos/foa-2024/etag.png';
import foaPmGroup from '../assets/img/company-logos/foa-2024/pmgroup.png';
import foaMcaArchitects from '../assets/img/company-logos/foa-2024/mca.png';
import foaAshWilson from '../assets/img/company-logos/foa-2024/ashwilson.png';
import foaCohn from '../assets/img/company-logos/foa-2024/cohn.png';
import foaDmod from '../assets/img/company-logos/foa-2024/dmod.png';
import foaSystemCore from '../assets/img/company-logos/foa-2024/systemcore.png';
import foaAllSystems from '../assets/img/company-logos/foa-2024/allsystems.png';
import foaAib from '../assets/img/company-logos/foa-2024/aib.png';
import foaReddy from '../assets/img/company-logos/foa-2024/reddy.png';
import foaTexfel from '../assets/img/company-logos/foa-2024/texfel.png';
import foaVescom from '../assets/img/company-logos/foa-2024/vescom.png';
import foaArdmac from '../assets/img/company-logos/foa-2024/ardmac.png';
import foaPerkins from '../assets/img/company-logos/foa-2024/perkins.png';
import foaFlynnManagement from '../assets/img/company-logos/foa-2024/flynn.png';
import foaElliotGroup from '../assets/img/company-logos/foa-2024/elliotgroup.png';
import foaTapCreative from '../assets/img/company-logos/foa-2024/tapcreative.png';
import foaEml from '../assets/img/company-logos/foa-2024/eml.png';
import foaMdo from '../assets/img/company-logos/foa-2024/mdo.png';
import foaBsgDesign from '../assets/img/company-logos/foa-2024/bsgdesign.png';
import foaIncio from '../assets/img/company-logos/foa-2024/incio.png';
import foaZipWater from '../assets/img/company-logos/foa-2024/zipwater.png';
import foaBuiltInteriors from '../assets/img/company-logos/foa-2024/built-interior-ltd.png';
import foaOptima from '../assets/img/company-logos/foa-2024/optima.jpg';
import foaW2W from '../assets/img/company-logos/foa-2024/w2w.png';
import foaAutex from '../assets/img/company-logos/foa-2024/autex.png';
import foaMjf from '../assets/img/company-logos/foa-2024/mjf.png';
import foaJonesEngineering from '../assets/img/company-logos/foa-2024/jones-engineering.png';
import foaCalibro from '../assets/img/company-logos/foa-2024/calibro.png';
import foaAxiseng from '../assets/img/company-logos/foa-2024/axiseng.png';
import foaDiarmuid from '../assets/img/company-logos/foa-2024/diarmuid.png';
import foaODonnellONeill from '../assets/img/company-logos/foa-2024/odonnelloneill.jpg';
import foaAppleGreen from '../assets/img/company-logos/foa-2024/applegreen.png';
import foaRoseConstruction from '../assets/img/company-logos/foa-2024/roseconstruction.png';
import foaRoisinLafferty from '../assets/img/company-logos/foa-2024/roisinlafferty.jpg';
import foaJohnston from '../assets/img/company-logos/foa-2024/johnston.png';
import foaBene from '../assets/img/company-logos/foa-2024/bene.png';
import foaVibe from '../assets/img/company-logos/foa-2024/vibebyvision.png';
import foaTandI from '../assets/img/company-logos/foa-2024/tandi.png';
import foaTotalFitout from '../assets/img/company-logos/foa-2024/totalfitout.png';
import foaMulcahy from '../assets/img/company-logos/foa-2024/mulcahy.png';
import foaVle from '../assets/img/company-logos/foa-2024/vle.png';
import foaCorgan from '../assets/img/company-logos/foa-2024/corgan.png';
import foaTarkett from '../assets/img/company-logos/foa-2024/tarkett.png';
import foaBWDI from '../assets/img/company-logos/foa-2024/bwdi.png';
import foaLumenSearch from '../assets/img/company-logos/foa-2024/lumensearch.png';
import foaSurfaceform from '../assets/img/company-logos/foa-2024/surfaceform.png';
import foaHavwoods from '../assets/img/company-logos/foa-2024/havwoods.png';
import foaInterfaceIreland from '../assets/img/company-logos/foa-2024/interface-ireland.png';
import foaStructureTone from '../assets/img/company-logos/foa-2024/structuretone.png';
import foaFinsa from '../assets/img/company-logos/foa-2024/finsa.png';
import foaEgger from '../assets/img/company-logos/foa-2024/egger.png';
import foaCrmFitOut from '../assets/img/company-logos/foa-2024/crmfitout.png';
import foaKDesign from '../assets/img/company-logos/foa-2024/kdesignstudio.png';
import foaBallymore from '../assets/img/company-logos/foa-2024/ballymore.png';
import foaGroveCo from '../assets/img/company-logos/foa-2024/groveco.png';
import foaTruwood from '../assets/img/company-logos/foa-2024/truwood.png';
import foaHoran from '../assets/img/company-logos/foa-2024/horan.png';
import foaOman from '../assets/img/company-logos/foa-2024/oman-beverly-smyth.png';
import foaJll from '../assets/img/company-logos/foa-2024/jll.png';
import foaSolutions from '../assets/img/company-logos/foa-2024/solutions.png';
import foaSas from '../assets/img/company-logos/foa-2024/sas.png';
import foaSigWorkplace from '../assets/img/company-logos/foa-2024/sigworkplace.png';
import foaTechref from '../assets/img/company-logos/foa-2024/techref.png';
import foaCogent from '../assets/img/company-logos/foa-2024/cogent.png';
import foaMillimetreDesign from '../assets/img/company-logos/foa-2024/millimetre-design.png';
import foaOConnorSuttonCronin from '../assets/img/company-logos/foa-2024/oconnorsuttoncronin.png';
import foaBha from '../assets/img/company-logos/foa-2024/bha.png';
import foaCommaArchitects from '../assets/img/company-logos/foa-2024/comma-architects.png';
import foaRKD from '../assets/img/company-logos/foa-2024/rkd.png';
import foaAspect from '../assets/img/company-logos/foa-2024/aspectinteriors.png';
import foaYgInteriors from '../assets/img/company-logos/foa-2024/yginteriors.png';
import foaSaulDesign from '../assets/img/company-logos/foa-2024/sauldesign.png';
import foaCreighan from '../assets/img/company-logos/foa-2024/creighan-contracts-ltd.png';
import foaLioncor from '../assets/img/company-logos/foa-2024/lioncore.png';
import foaPureFitout from '../assets/img/company-logos/foa-2024/purefitout.png';
import foaEthos from '../assets/img/company-logos/foa-2024/ethos.png';
import foaStyleSoSimple from '../assets/img/company-logos/foa-2024/stylesosimple.png';
import foaJennifer from '../assets/img/company-logos/foa-2024/jennifer.png';
import foaAshenCloud from '../assets/img/company-logos/foa-2024/ashencloud.png';
import foaSerpentineInteriors from '../assets/img/company-logos/foa-2024/serpentine.png';
import foaWhiteRiver from '../assets/img/company-logos/foa-2024/whiteriver.png';
import foaVardisGroup from '../assets/img/company-logos/foa-2024/vardisgroup.png';
import foaDcon from '../assets/img/company-logos/foa-2024/dcon.png';
import foaIwin from '../assets/img/company-logos/foa-2024/iwin.png';
import foaTonyODonovan from '../assets/img/company-logos/foa-2024/tony-o-donovan.png';
import foaTheAmtico from '../assets/img/company-logos/foa-2024/the-amtico.jpg';
import foaTanyaNeufeld from '../assets/img/company-logos/foa-2024/tanya-neufeld.png';
import foaSlmd from '../assets/img/company-logos/foa-2024/SLMD-ltd.png';
import foaTotp from '../assets/img/company-logos/foa-2024/totp.png';
import foaQid from '../assets/img/company-logos/foa-2024/qid.png';
import foaTexspace from '../assets/img/company-logos/foa-2024/texspace.png';
import foaPrimaBuild from '../assets/img/company-logos/foa-2024/primabuild.png';
import foaPlantech from '../assets/img/company-logos/foa-2024/plantechdesign.png';
import foaAlliance from '../assets/img/company-logos/foa-2024/alliance.png';
import foaGalteeGroup from '../assets/img/company-logos/foa-2024/thegalteegroup.png';
import foaAoshStudio from '../assets/img/company-logos/foa-2024/aoshstudio.png';
import foaGreenscene from '../assets/img/company-logos/foa-2024/greenscene.png';
import foaUmrGroup from '../assets/img/company-logos/foa-2024/umrgroup.png';
import foaRoyalton from '../assets/img/company-logos/foa-2024/royalton.png';
import foaAuburn from '../assets/img/company-logos/foa-2024/auburn.png';
import foaDcu from '../assets/img/company-logos/foa-2024/dcu.png';
import foaPurser from '../assets/img/company-logos/foa-2024/purser.png';
import foaTrafalgar from '../assets/img/company-logos/foa-2024/trafalgar.png';
import foaRespond from '../assets/img/company-logos/foa-2024/respond.png';
import foaShaw from '../assets/img/company-logos/foa-2024/shaw.png';
import foaDesignful from '../assets/img/company-logos/foa-2024/designful.png';
import foaFarrelly from '../assets/img/company-logos/foa-2024/farrelly.png';
import foaCarnehill from '../assets/img/company-logos/foa-2024/carnehill.png';
import foaWesternPostform from '../assets/img/company-logos/foa-2024/westernpostform.png';
import foaCbre from '../assets/img/company-logos/foa-2024/cbre.png';
import foaOpw from '../assets/img/company-logos/foa-2024/opw.png';
import foaGraceGahan from '../assets/img/company-logos/foa-2024/gracegahan.png';
import foaFurnishingDistributors from '../assets/img/company-logos/foa-2024/furnishingdistributors.png';
import foaHillmac from '../assets/img/company-logos/foa-2024/hillmacfitouts.png';
import foaHyde from '../assets/img/company-logos/foa-2024/HYDE.png';
import foaGriffith from '../assets/img/company-logos/foa-2024/griffith.png';
import foaIconic from '../assets/img/company-logos/foa-2024/iconic.png';
import foaOhmg from '../assets/img/company-logos/foa-2024/ohmg.png';
import foaTus from '../assets/img/company-logos/foa-2024/tus.png';
import foaCundall from '../assets/img/company-logos/foa-2024/cundall.png';
import foaKilrock from '../assets/img/company-logos/foa-2024/kilrock.png';
import foaBisley from '../assets/img/company-logos/foa-2024/bisley.png';
import foaRpp from '../assets/img/company-logos/foa-2024/rpp.png';
import foaElmGray from '../assets/img/company-logos/foa-2024/elmgray.png';
import foaSystemCore2 from '../assets/img/company-logos/foa-2024/SystemCore2.png';
import foaCarlton from '../assets/img/company-logos/foa-2024/carlton.png';
import foaLaminar from '../assets/img/company-logos/foa-2024/laminar.png';
import foaMitsubisihi from '../assets/img/company-logos/foa-2024/mitsubishi.png';
import foaClontarf from '../assets/img/company-logos/foa-2024/clontarf.png';
import foaMinima from '../assets/img/company-logos/foa-2024/minima.png';
import foaSteelcase from '../assets/img/company-logos/foa-2024/steelcase.png';
import foaUbi from '../assets/img/company-logos/foa-2024/ubi.png';
import foaGlenCullen from '../assets/img/company-logos/foa-2024/glencullen.png';
import foaOak from '../assets/img/company-logos/foa-2024/oak.jpg';
import foaTurnerTownsend from '../assets/img/company-logos/foa-2024/turnertownsend.png';
import foaBusinessRiver from '../assets/img/company-logos/foa-2024/business-river-logo.png';
import foaGenerateLeads from '../assets/img/company-logos/foa-2024/generateleads.png';
import foaKccGroup from '../assets/img/company-logos/foa-2024/kcc.png';
import foaHays from '../assets/img/company-logos/foa-2024/hays.png';
import foaLoughnane from '../assets/img/company-logos/foa-2024/loughnane.png';
import foaWeadick from '../assets/img/company-logos/foa-2024/weadick.png';
import foaAirconMech from '../assets/img/company-logos/foa-2024/airconmech.png';
import foaConroy from '../assets/img/company-logos/foa-2024/conroy.png';

import cxEsb from '../assets/img/company-logos/cx-2024/ESB.png';
import cxAib from '../assets/img/company-logos/cx-2024/aib.png';
import cxAerLingus from '../assets/img/company-logos/cx-2024/aerlingus.png';
import cxKylemore from '../assets/img/company-logos/cx-2024/kylemore.png';
import cxPassportService from '../assets/img/company-logos/cx-2024/passportservice.png';
import cxBordgais from '../assets/img/company-logos/cx-2024/bord-gais-energy.png';
import cxMasonHayes from '../assets/img/company-logos/cx-2024/masonhayes.png';
import cxResillience from '../assets/img/company-logos/cx-2024/resillience.png';
import cxTrinityCollegeDublin from '../assets/img/company-logos/cx-2024/trinity.png';
import cxCxpa from '../assets/img/company-logos/cx-2024/cxpa.png';
import cxPtsb from '../assets/img/company-logos/cx-2024/ptsb.png';
import cxPobal from '../assets/img/company-logos/cx-2024/pobal.png';
import cxBankofIreland from '../assets/img/company-logos/cx-2024/bankofireland.png';
import cxTaxback from '../assets/img/company-logos/cx-2024/taxback.png';
import cxRsa from '../assets/img/company-logos/cx-2024/rsa.png';
import cxAbtran from '../assets/img/company-logos/cx-2024/abtran.png';
import cxBusEireann from '../assets/img/company-logos/cx-2024/bus-eireann.png';
import cxKlearcom from '../assets/img/company-logos/cx-2024/klearcom.png';
import cxDMGardenCentre from '../assets/img/company-logos/cx-2024/dmgardencentre.png';
import cxWorkair from '../assets/img/company-logos/cx-2024/workair.png';
import cxCalor from '../assets/img/company-logos/cx-2024/calor.png';
import cxCornmarket from '../assets/img/company-logos/cx-2024/cornmarket.png';
import cxBusinessRiver from '../assets/img/company-logos/cx-2024/business-river-logo.png';
import cxInsight6 from '../assets/img/company-logos/cx-2024/insight6.png';
import cxIrishDistillers from '../assets/img/company-logos/cx-2024/irishdistillers.png';
import cxRentalMatics from '../assets/img/company-logos/cx-2024/rentalmatics.png';
import cxThriveMarketing from '../assets/img/company-logos/cx-2024/thrive.png';
import cxCapventis from '../assets/img/company-logos/cx-2024/capventis.png';
import cxLayaHealthcare from '../assets/img/company-logos/cx-2024/laya.png';
import cxPhonovation from '../assets/img/company-logos/cx-2024/phonovation.png';
import cxLeasepoint from '../assets/img/company-logos/cx-2024/leasepoint.png';
import cxTestReach from '../assets/img/company-logos/cx-2024/testreach.png';
import cxEpam from '../assets/img/company-logos/cx-2024/epam.png';
import cxIrishLifeGroup from '../assets/img/company-logos/cx-2024/irishlifegroup.png';
import cxW5 from '../assets/img/company-logos/cx-2024/w5.png';

import aiaLawSociety from '../assets/img/company-logos/aia-2024/lawsociety.png';
import aiaBelfast from '../assets/img/company-logos/aia-2024/belfast.png';
import aiaKillickAerospace from '../assets/img/company-logos/aia-2024/killickaerospace.jpg';
import aiaCrs from '../assets/img/company-logos/aia-2024/crs.png';
import aiaDcu from '../assets/img/company-logos/aia-2024/dcu.png';
import aiaUniLimerick from '../assets/img/company-logos/aia-2024/unilimerick.png';
import aiaIaa from '../assets/img/company-logos/aia-2024/iaa.png';
import aiaAeolus from '../assets/img/company-logos/aia-2024/aeolus.png';
import aiaAterim from '../assets/img/company-logos/aia-2024/aterim.png';
import aiaAtechSyn from '../assets/img/company-logos/aia-2024/atechsyn.png';
import aiaFutureMobility from '../assets/img/company-logos/aia-2024/futuremobility.png';
import aiaLeasepoint from '../assets/img/company-logos/aia-2024/leasepoint.png';
import aiaAblAviation from '../assets/img/company-logos/aia-2024/ablaviation.png';
import aiaInflightDublin from '../assets/img/company-logos/aia-2024/inflightdublin.png';
import aiaIac from '../assets/img/company-logos/aia-2024/iac.png';
import aiaAag from '../assets/img/company-logos/aia-2024/aag.png';
import aiaShannonAviation from '../assets/img/company-logos/aia-2024/shannonaviation.png';
import aiaDaaInternational from '../assets/img/company-logos/aia-2024/daainternational.png';
import aiaAvair from '../assets/img/company-logos/aia-2024/avair.png';
import aiaDaa from '../assets/img/company-logos/aia-2024/daa.png';
import aiaWcs from '../assets/img/company-logos/aia-2024/wcs.png';
import aiaShannonAirport from '../assets/img/company-logos/aia-2024/shannonairport.png';
import aiaGenesis from '../assets/img/company-logos/aia-2024/genesis.png';
import aiaAthboyAviation from '../assets/img/company-logos/aia-2024/athboyaviation.png';
import aiaHannonTravel from '../assets/img/company-logos/aia-2024/hannontravel.png';
import aiaCloudCards from '../assets/img/company-logos/aia-2024/cloudcards.png';
import aiaEirTrade from '../assets/img/company-logos/aia-2024/eirtrade.png';
import aiaMarqette from '../assets/img/company-logos/aia-2024/marqette.png';
import aiaMoog from '../assets/img/company-logos/aia-2024/moog.png';
import aiaBusinessRiver from '../assets/img/company-logos/aia-2024/business-river-logo.png';
import aiaAerLingus from '../assets/img/company-logos/aia-2024/aerlingus.png';
import aiaAviationSkillnet from '../assets/img/company-logos/aia-2024/aviationskillnet.png';
import aiaIntertec from '../assets/img/company-logos/aia-2024/intertec.png';
import aiaLocalEnterprise from '../assets/img/company-logos/aia-2024/localenterprise.png';
import aiaAirnavIreland from '../assets/img/company-logos/aia-2024/airnavireland.png';
import aiaNashville from '../assets/img/company-logos/aia-2024/nashville.png';
import aiaTus from '../assets/img/company-logos/aia-2024/tus.png';

import html2pdf from 'html2pdf.js';

let pdfDownloaded = 0;

const initialFormData = Object.freeze({
  name: '',
  description: '',
  awards: '',
  logo: '',
  schedule_1: '',
  schedule_2: '',
  schedule_3: '',
  schedule_4: '',
  schedule_5: '',
  website_url: '',
  finalists_url: '',
  judges_url: '',
  about_url: '',
  support_email: '',
  support_phone: '',
  host: '',
  host_description: '',
  start: '',
  location: '',
  dacast_url: '',
  vimeo_url: '',
  entry_guide_url: '',
  status: '',
  table_per_row: 8
});

const initialSeatViewData = Object.freeze({
  id: '',
});

const initialAttendeeData = Object.freeze({
  id: '',
  name: '',
  contact_number: '',
  company_name: '',
  company_position: ''
});

const initialVenueData = Object.freeze({
  venue_id: '',
  stage_length: '',
  stage_width: '',
  table_length: '',
  table_width: '',
  table_type: '',
  number_of_tables: '',
  total_seats_per_table: '',
  venue_name: '',
});

const initialResetFloorPlan = Object.freeze({
  floor_plan: '',
});

const initialTableData = Object.freeze({
  id: '',
  name: '',
});

const initialMoveAttendeeData = Object.freeze({
  attendee_ids_from: '',
  attendee_ids_to: '',
  table_number_from: '',
  table_number_to: '',
  user_id: '',
});

const AdminVenueSeatingPage = () => {
  let history = useHistory();
  const ref = useRef(null); // ref => { current: null }
  const stageRef = useRef(); // ref => { current: null }
  const [formData, setFormData] = useState(initialFormData);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const award_id = query.get('award_id');
  const rows = query.get('tables_per_row');
  const download = query.get('download');
  let fontSizeInit = query.get('font_size');

  if (fontSizeInit)
    fontSizeInit = Number(fontSizeInit) > 9 || Number(fontSizeInit) < 1 ? 5 : Number(fontSizeInit)

  const fontSize = fontSizeInit ? '0.' + fontSizeInit +'vw' : '0.5vw'
  const [award, setAward] = useState();

  const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'userRole', 'isLoggedIn', 'awardStatus', 'isWelcome', 'isFinalist', 'token', 'userDetails']);
  const currentUser = cookies.currentUser;
  const token = cookies.token;
  const isLoggedIn = cookies.isLoggedIn;
  let updatedAt = '';

  const isWarning = 'warning';
  const isSuccess = 'success';
  const isError = 'error';
  const toastCSS = {
    position: 'top-right',
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }
  const [attendeesListLoading, setAttendeesListLoading] = useState(false);
  const [attendees, setAttendees] = useState([]);
  const [seatingArrangementLoading, setSeatingArrangementLoading] = useState(false);
  const [reloadCount, setReloadCount] = useState(1);
  const [attendee, setAttendee] = useState(initialAttendeeData);
  const [venueData, setVenueData] = useState(initialVenueData);
  const [tables, setTables] = useState([]);
  const [tablesPlaceholder, setTablesPlacholder] = useState([]);
  const [numberOfSeats, setNumberOfSeats] = useState(0);
  const [tablesLoading, setTablesLoading] = useState(true);
  const [moveAttendeeData, setMoveAttendeeData] = useState(initialMoveAttendeeData);
  const [attendeeIdsFrom, setAttendeeIdsFrom] = useState([]);
  const [attendeeIdsTo, setAttendeeIdsTo] = useState([]);
  const [tableData, setTableData] = useState(initialTableData);
  const [table, setTable] = useState(initialTableData);
  const [tableModalShow, setTableModalShow] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [tableId, setTableId] = useState("");

  const [tableTo, setTableTo] = useState([]);
  const [tableFrom, setTableFrom] = useState([]);
  const [tableToSeats, setTableToSeats] = useState(0);
  const [tableFromSeats, setTableFromSeats] = useState(0);
  const [tableFromAttendees, setTableFromAttendees] = useState(0);
  const [selectAtleast, setSelectAtleast] = useState(true);
  const [moveAttendeeShow, setMoveAttendeeShow] = useState(false);
  const [tableIds, setTableIds] = useState([]);
  const [seatingArrangementModal, setSeatingArrangementModal] = useState(false);
  const [seatingImage, setSeatingImage] = useState();
  const [imageLoading, setImageLoading] = useState();
  const [columnMinWidth, setColumnMinWidth] = useState('12.5%');
  const [initialRun, setInitialRun] = useState(true);
  const staticAttendeeId = [
    24439, 24440, 24441, 24442, 24443,
    25085, 25086, 25087, 25088, 25089,
    25090, 24956, 25278, 25279, 25986,
    25987, 25988, 25989, 25990, 25390,
    25391, 25174, 25175, 25843, 25844,
    25845, 25846, 25847, 25848, 25849,
    25850, 25851, 25852, 25810, 25811,
    25812, 25813, 25814, 25815, 26133,
    26997, 26998, 26999, 26701, 26702,
    26703, 26704, 26902, 26903, 26904,
    26966, 26967, 26968, 27473, 27474,
    27475, 26834, 26835, 26836, 26432,
    26433, 26434, 26435, 26436, 26437,
    26438, 26439, 26440, 26441, 26475,
    26476, 26477, 26478, 26479, 25731,
    25734, 25735, 25736, 25737, 25738,
    25739, 25740, 25741, 25742, 26422,
    26423, 26424, 26425, 26426, 26427,
    26428, 26429, 26430, 26431, 25684,
    25684, 25820, 26414, 26415, 26416,
    26417, 26418, 26419, 26420, 26421,
    26973, 26974, 26975, 26976, 26977,
    26978, 26979, 26980, 26981, 26982,
    26734, 26735, 27442, 27443, 27444,
    26724, 26725, 26726, 26727, 26728,
    26729, 26730, 26731, 26732, 26733,
    27946, 27723, 27724, 26490, 26491,
    26492, 26493, 26494, 26495, 26496,
    26497, 26498, 26499, 26500, 26501,
    27509, 27510, 27511, 27512, 27513,
    27514, 27515, 27516, 27204, 27205,
    27206, 27207, 27208, 27209, 27210,
    27211, 27212, 27213, 26502, 26503,
    26504, 26505, 26506, 26507, 26508,
    26509, 26510, 26511, 26894, 26895,
    26896, 26897, 26898, 26905, 26906,
    26883, 26884, 26885, 26886, 26887,
    26888, 26891, 26892, 26893, 27437,
    26956, 26957, 26958, 26959, 26960,
    26961, 26962, 26963, 26871, 26872,
    26873, 26874, 26875, 26876, 26877,
    26878, 26879, 26880, 27727, 27728,
    27729, 27730, 27731, 27732, 27733,
    27734, 27735, 27736, 27737, 27738,
    27529, 27530, 27531, 28375, 28376,
    28033, 28034, 28035, 28036, 28037,
    28038, 27827, 27828, 27829, 27830,
    27811, 27812, 27813, 27814, 27560,
    27561, 27562, 27563, 27564, 27565,
    27476, 27477, 27478, 27479, 27480,
    27481, 27482, 27483, 27484, 27485,
    27855, 27856, 28030, 28031, 28032,
    28271, 28272, 28273, 28491, 28492,
    28178, 28485, 28486, 28525, 28526,
    28527, 28528, 28529, 28530, 28531,
    28532, 28533, 28534, 28642, 28643,
    28537, 28538, 28406, 28407, 28408,
    28806, 28807, 28808, 28809, 28810,
    28811, 28812, 28813, 28814, 28815,
    2870916, 2870917, 28620, 28621, 28653,
    28654, 28655, 28622, 28623, 28624,
    28625, 28626, 28627, 28628, 28629,
    28630, 28631, 28516, 28517, 28518,
    28519, 28865, 28866, 2873745, 2873536,
    2872202, 2872203, 2872204, 2872705, 2872704,
    2872703, 2872702, 2872734, 2872735, 2873291,
    2873292, 2872171, 2873742, 2872300, 2872301,
    2872306, 2872307, 2872504, 2872312, 2872313,
    2872314, 2872315, 2872316, 2872317, 2872318,
    2872319, 2872320, 2872321, 2873555, 2873556,
    2871786, 2871787, 2872505, 2872506, 2872627,
    2872576, 2872798, 2872690, 2872691, 2872692,
    2872693, 2872694, 2872695, 2872696, 2872697,
    2872698, 2872699, 2873602, 2873603, 2871769,
    2871770, 2872219, 2872220, 2873864, 2873306,
    2873616, 2873617, 2872632, 2872633, 2872634,
    2872564, 2872565, 2872566, 2872567, 2872568,
    2872569, 2872570, 2872571, 2872572, 2872573,
    2872574, 2872575, 2873923, 2873924, 2874550,
    2874551, 2874552, 2872875, 2872876, 2872877,
    2872878, 2872879, 2872880, 2872881, 2872882,
    2872883, 2872884, 2872885, 2872886, 2873232,
    2873233, 2873234, 2873235, 2873236, 2873237,
    2873238, 2873239, 2873240, 2873241, 2872787,
    2872788, 2872789, 2872790, 2872791, 2872792,
    2872793, 2872794, 2872795, 2872796, 2872861,
    2872862, 2872863, 2873787, 2872848, 2872849,
    2872850, 2874570, 2874789, 2874790, 2873504,
    2873505, 2873506, 2872602, 2872603, 2873482,
    2873483, 2873509, 2873510, 2873363, 2873364,
    2873365, 2873366, 2873367, 2873368, 2873369,
    2873370, 2873371, 2873372, 2873777, 2873778,
    2873779, 2873780, 2873781, 2873782, 2873783,
    2873784, 2873785, 2873786, 2874957, 2873512,
    2873513, 2873514, 2873515, 2873516, 2873517,
    2873518, 2873519, 2873520, 2873521, 2873522,
    2873523, 2873873, 2873874, 2873875, 2873876,
    2874571, 2874572, 2874573, 2874574, 2873953,
    2873954, 2872685, 2872686, 2873286, 2873287,
    2873288, 2873289, 2873290, 2873763, 2873764,
    2874152, 2874153, 2874154, 2874155, 2874156,
    2874157, 2874158, 2874159, 2874160, 2874161,
    2873436, 2873437, 2873438, 2873439, 2873440,
    2873441, 2873442, 2873443, 2873444, 2873445,
    2873323, 2873324, 2873325, 2873326, 2873327,
    2873328, 2873329, 2873330, 2873331, 2873332,
    2873465, 2873466, 2873467, 2873468, 2873469,
    2873470, 2873471, 2873472, 2873473, 2873474,
    2873491, 2873492, 2873493, 2873494, 2873495,
    2873496, 2873497, 2873498, 2873499, 2873500,
    2873638, 2873639, 2873640, 2873641, 2873642,
    2873643, 2873986, 2873987, 2875176, 2875177,
    2874162, 2874163, 2874794, 2874795, 2874796,
    2873744, 2873797, 2873833, 2873834, 2873407,
    2873408, 2873409, 2873410, 2873411, 2873412,
    2873413, 2873414, 2873415, 2873416, 2875262,
    2875263, 2875264, 2875265, 2875488, 2873909,
    2873910, 2873911, 2873912, 2873913, 2873914,
    2873915, 2873916, 2873917, 2873918, 2873919,
    2873920, 2874624, 2874625, 2874626, 2874627,
    2874628, 2874629, 2874630, 2874631, 2874632,
    2874633, 2874737, 2874738, 2874739, 2874740,
    2875261, 2873895, 2873896, 2873897, 2873898,
    2873899, 2873900, 2873901, 2873902, 2873903,
    2873904, 2875623, 2875624, 2875518, 2875519,
    2875520, 2875521, 2875522, 2875523, 2874080,
    2874081, 2874082, 2874741, 2874742, 2874743,
    2873392, 2873393, 2875654, 2873446, 2873447,
    2873448, 2873798, 2873799, 2873800, 2873801,
    2873802, 2873803, 2873804, 2873805, 2873806,
    2873807, 2874206, 2874207, 2874208, 2874209,
    2874210, 2874211, 2874212, 2874213, 2874214,
    2874215, 2874576, 2874577, 2874578, 2874579,
    2874580, 2874581, 2874582, 2874583, 2874584,
    2874585, 2876598, 2876610, 2876611, 2876612,
    2876613, 2877090, 2877091, 2877092, 2877093,
    27775, 27776, 27777, 27778, 2877963,
    2877978, 2875410, 2875411, 2875412, 2875413,
    2875414, 2875415, 2875416, 2875417, 2875418,
    2875419, 2877682, 2877683, 2877684, 2877685,
    2877686, 2876204, 2876205, 2877680, 2877681,
    2875465, 2875466, 2875467, 2875468, 2875469,
    2875525, 2875526, 2875527, 2875528, 2875529,
    2875530, 2875531, 2875532, 2875533, 2875534,
    2876374, 2876375,
  ];
  
  let tablePerRow = rows ? Number(rows) : 8 ;
  const [seatPlanHtml, setSeatPlanHtml] = useState(null);

  const toggleTableModalShow = () => {
    setTableModalShow(!tableModalShow);
  }

  const getAward = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id + '?user_id=' + currentUser + '&token=' + token);
  }

  const getCheckSeat = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id + '/seat/check?user_id=' + currentUser + '&token=' + token);
  }

  const getTablesNew = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id + '/venue/table?user_id=' + currentUser + '&token=' + token);
  }

  const getAttendees = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id + '/attendee?award_id=' + award_id + '&user_id=' + currentUser + '&token=' + token);
  }

  const toastAlert = ({ type, message }) => {
    if (type === 'error') {
      toast.error(message, toastCSS);
    } else if (type === 'warning') {
      toast.warning(message, toastCSS);
    } else {
      toast.success(message, toastCSS);
    }
  }

  const requestSuccess = () => {
    const message = 'Success!';
    toastAlert({ type: isSuccess, message: message });
  }

  const catchError = () => {
    const message = 'An error occured. Please try again or contact the administrator.';
    toastAlert({ type: isError, message: message });
  }

  const catchWarning = () => {
    const message = 'Something went wrong!';
    toastAlert({ type: isWarning, message: message });
  }

  const handleChangeAttendee = (e) => {
    const { name, value } = e.target;
    setAttendee({
      ...attendee,
      [name]: value,
    });
  }

  const submitSeatingImageCallback = useCallback(async () => {
    setImageLoading(() => true);
    await toJpeg(stageRef.current, { quality: 1.0 })
      .then(async (dataUrl) => {
        setSeatingImage(() => dataUrl);
        await submitSeatingImage(dataUrl);

      })
      .catch(() => {
        catchError();
      });

  }, [stageRef]);

  const toggleSeatingArrangement = e => {
    setSeatingArrangementModal(!seatingArrangementModal);
  }

  const retrieveHistory = async () => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'award/'+award_id+'/seat/history?user_id=' + currentUser +'&token='+token, {
      // const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'award/'+award_id+'/seating/history?award_id=' + award_id + '&user_id=' + currentUser +'&token='+token, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const retrieveAttendeesSearch = async (e) => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'award/'+award_id+'/attendee?award_id='+award_id+'&user_id='+currentUser+'&token='+token+'&table_numbers='+tableIds+'&query='+e , {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const retrieveTablesSearch = async (e) => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'award/'+award_id+'/seat/plan?award_id='+award_id+'&user_id='+currentUser+'&token='+token+'&table_numbers='+tableIds+'&query='+e , {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const retrieveTables = async () => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'award/'+award_id+'/venue/table?award_id='+award_id+'&user_id='+currentUser+'&token='+token , {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  async function submitSeatingImage(e) {
    const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'award/'+award_id+'?user_id=' + currentUser + '&token=' + token, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
      body: JSON.stringify({
        user_id: currentUser,
        award_id: award_id,
        judge_allocation: e,
      }), // body data type must match "Content-Type" header
    })
    const json = await response.json();
    if (json.status == "Success") {
      setReloadCount(reloadCount + 1);
      setAttendeeIdsFrom([]);
      setAttendeeIdsTo([]);
      setTableTo([]);
      setTableFrom([]);
    }
  }

  const addTableNumber = (id) => {
    if (!tableIds.includes(id)) {
      setTableIds(tableIds => [...tableIds, id]);
    }
  }

  const removeTableNumber = (id) => {
    let filteredArray = tableIds.filter(item => item !== id);
    setTableIds(filteredArray);
  }

  const addAttendeeTo = (id) => {
    if (attendeeIdsTo.includes(id)) {
    } else {
      setAttendeeIdsTo(attendeeIdsTo => [...attendeeIdsTo, id]);
      setMoveAttendeeData({
        ...moveAttendeeData,
        user_id: currentUser,
        attendee_ids_to: attendeeIdsTo,
      });
      var attendee_ids_to = [...attendeeIdsTo, id];
      var seatsNeeded = ((tableToSeats - tableTo.length) - attendeeIdsFrom.length) + attendee_ids_to.length;
      if (seatsNeeded >= 0) {
        setSelectAtleast(false);
      } else {
        setSelectAtleast(true);
      }
    }
  }

  const removeAttendeeTo = (id) => {
    let filteredArray = attendeeIdsTo.filter(item => item !== id);
    setAttendeeIdsTo(filteredArray);
    setMoveAttendeeData({
      ...moveAttendeeData,
      user_id: currentUser,
      attendee_ids_to: filteredArray,
    });
    var seatsNeeded = ((tableToSeats - tableTo.length) - attendeeIdsFrom.length) + filteredArray.length;
    if (seatsNeeded >= 0) {
      setSelectAtleast(false);
    } else {
      setSelectAtleast(true);
    }
  }

  const retrieveTableTo = async (e) => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'venue/table/'+e+'?award_id=' + award_id + '&user_id=' + currentUser +'&token='+token, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const retrieveTable = async (e) => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'venue/table/'+e+'?award_id=' + award_id + '&user_id=' + currentUser +'&token='+token, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const retrieveTableFrom = async (e) => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'venue/table/'+e+'?award_id=' + award_id + '&user_id=' + currentUser +'&token='+token, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const handleTableFrom = (e) => {
    var table_id_from = e;
    setAttendeeIdsFrom([]);
    setMoveAttendeeData({
      ...moveAttendeeData,
      table_number_from: table_id_from,
    });

    const getTableFrom = async (table_id_from) => {
      const selectedTableFrom = await retrieveTableFrom(table_id_from);
      if(selectedTableFrom) {
        setTableFrom(selectedTableFrom.attendees);
        setTableFromSeats(selectedTableFrom.no_of_seats);
        setTableFromAttendees(selectedTableFrom.attendees.length);
      }
    };
    getTableFrom(table_id_from);

  }

  const handleChangeTable = (e) => {
    setTableData({
      ...tableData,
      [e.target.name]: e.target.value,
    });
  }

  const handleTableTo = (e) => {
    setAttendeeIdsTo([]);
    var table_id_to = e.target.value;

    if (table_id_to && table_id_to != "") {
      setMoveAttendeeData({
        ...moveAttendeeData,
        table_number_to: e.target.value,
      });

      const getTableTo = async (table_id_to) => {
        const selectedTableTo = await retrieveTableTo(table_id_to);
        if(selectedTableTo) {
          setTableTo(selectedTableTo.attendees);
          setMoveAttendeeShow(true);
          setTableToSeats(selectedTableTo.no_of_seats);
        }
      };

      getTableTo(table_id_to);
    }
  }

  const handleTable = (e) => {
    var table_id = e;
    setTableData({
      ...tableData,
      id: table_id,
    });
    setTableId(table_id);
    toggleTableModalShow();
    const getTable = async (table_id) => {
      const selectedTable = await retrieveTable(table_id);
      if(selectedTable) {
        setTable(selectedTable);
        setTableData({
          ...tableData,
          name: selectedTable.name,
        });
        setTableLoading(false);
      }
    };
    getTable(table_id);
  }

  const getTables = async (row) => {
    const selectedTables = await retrieveTables();
    if (selectedTables) {
      const removedTableIds = [ 67, 68 ];
      const tables = selectedTables.filter(table => table.category !== "other" && !removedTableIds.includes(table.id) && table.attendees.length > 0).map(x => x);
      const placeholder = tables.length % tablePerRow;
      let hiddenColumns = [];

      let length = tables.length / (tablePerRow * 4);

      if (length % 1 > 0) {
        length++;
      }

      const sortedTable = tables.sort((a, b) => parseInt(a.name) - parseInt(b.name)).filter(table => table.category !== "other").map(x => x);

      let result = [];
      for (let i = length - length % 1; i > 0; i--) {
        result.push(sortedTable.splice(0, (tablePerRow * 4)));
      }
      setTables(() => [ ...result ]);
      for (let i = 0; i < (tablePerRow - placeholder); i++)
        hiddenColumns = [...hiddenColumns, ''];

      setTablesPlacholder(() => hiddenColumns);
      setSeatingArrangementLoading(false);
      setTablesLoading(false);
      setInitialRun(false);
    }
  }

  const getCompanyLogos = (companyName, id) => {
    const companyLogoOpex = [
      {
        company_name: 'Abbott Nutrition Cootehill',
        logo: abbott
      },
      {
        company_name: 'AbbVie',
        logo: abbvie
      },
      {
        company_name: 'An Post',
        logo: anpost
      },
      {
        company_name: 'BAM Facilities Management',
        logo: bam
      },
      {
        company_name: 'Breedon Group',
        logo: breedon
      },
      {
        company_name: 'Bridgewater Construction',
        logo: bridgewater
      },
      {
        company_name: 'Centric Health',
        logo: centric
      },
      {
        company_name: 'Comfort Keepers Homecare',
        logo: comfort
      },
      {
        company_name: 'Crannog Roofing',
        logo: crannog
      },
      {
        company_name: 'AllView Healthcare',
        logo: allview
      },
      {
        company_name: 'DPD Ireland',
        logo: dpd
      },
      {
        company_name: 'Elis Pest Control',
        logo: elis
      },
      {
        company_name: 'ESB',
        logo: esb
      },
      {
        company_name: 'Eurofins BioPharmaceutical Product Testing',
        logo: eurofins
      },
      {
        company_name: 'Faithful+Gould',
        logo: faithfulGould
      },
      {
        company_name: 'Fastway',
        logo: fastway
      },
      {
        company_name: 'Gas Networks Ireland',
        logo: gasNetworks
      },
      {
        company_name: 'George Best Belfast City Airport',
        logo: george
      },
      {
        company_name: 'Griffith College Faculty of Law',
        logo: griffithFaculty
      },
      {
        company_name: 'Griffith Halls of Residence',
        logo: griffithResidence
      },
      {
        company_name: 'Guildhall - Derry City and Strabane District Council',
        logo: guildhall
      },
      {
        company_name: 'Iarnród Éireann Irish Rail',
        logo: larnrod
      },
      {
        company_name: 'Leckey',
        logo: leckey
      },
      {
        company_name: 'Keltech',
        logo: keltech
      },
      {
        company_name: 'Kerrygold Park, Ornua',
        logo: kerrygold
      },
      {
        company_name: 'Kirby Group Engineering',
        logo: kirby
      },
      {
        company_name: 'LMC FM',
        logo: lmc
      },
      {
        company_name: 'LotusWorks',
        logo: lotusWorks
      },
      {
        company_name: 'Mannok',
        logo: mannok
      },
      {
        company_name: 'MIAS Pharma',
        logo: mias
      },
      {
        company_name: 'Mitie',
        logo: mitie
      },
      {
        company_name: 'O\'Donoghue Ring Collection',
        logo: donoghue
      },
      {
        company_name: 'Primark',
        logo: primark
      },
      {
        company_name: 'Rural WiFi',
        logo: ruralWifi
      },
      {
        company_name: 'South Coast Logistics',
        logo: southCoast
      },
      {
        company_name: 'The Skibbereen Food Company',
        logo: skibbereen
      },
      {
        company_name: 'Tirlán',
        logo: tirlan
      },
      {
        company_name: 'Uisce Éireann',
        logo: uisce
      },
      {
        company_name: 'Ventac',
        logo: ventac
      },
      {
        company_name: 'Viking UK/IE',
        logo: viking
      },
      {
        company_name: 'West Pharmaceutical Services',
        logo: west
      },
      {
        company_name: 'Wines Direct',
        logo: winesDirect
      },
      {
        company_name: 'Workhuman',
        logo: workhuman
      },
      {
        company_name: 'Salesforce',
        logo: salesforce
      },
      {
        company_name: 'Atlantic Technological University (ATU) Sligo',
        logo: atu
      },
      {
        company_name: 'Cheshire Ireland',
        logo: cheshire
      },
      {
        company_name: 'ISS Facility Services Ireland',
        logo: iss
      },
      {
        company_name: 'Sensori Facilities Management',
        logo: sensori
      },
      {
        company_name: 'Cushman & Wakefield',
        logo: sensori
      }
    ];
    const companyLogoLsa = [
      {
        company_name: 'Teagasc',
        logo: teagasc
      },
      {
        company_name: 'CXV Global',
        logo: cxv
      },
      {
        company_name: 'Kora Healthcare',
        logo: kora
      },
      {
        company_name: 'Metabolomic Diagnostics',
        logo: metabolomic
      },
      {
        company_name: 'Dentaqua Ltd.',
        logo: dentaqua
      },
      {
        company_name: 'Boston Scientific',
        logo: boston
      },
      {
        company_name: 'Almac',
        logo: almac
      },
      {
        company_name: 'Medtronic (Parkmore Galway)',
        logo: medtronic
      },
      {
        company_name: 'McArdle Skeath',
        logo: mcardle
      },
      {
        company_name: 'Cork University Hospital',
        logo: cuh
      },
      {
        company_name: 'University College Cork',
        logo: cuh
      },
      {
        company_name: 'Icon Plc.',
        logo: iconPlc
      },
      {
        company_name: 'ICON plc',
        logo: iconPlc
      },
      {
        company_name: 'ICON Clinical Research',
        logo: iconPlc
      },
      {
        company_name: 'MIAS Pharma',
        logo: mias
      },
      {
        company_name: 'Janssen Sciences Ireland UC',
        logo: janssen
      },
      {
        company_name: 'Syncrophi Systems',
        logo: syncrophi
      },
      {
        company_name: 'CBRE',
        logo: cbre
      },
      {
        company_name: 'Pfizer',
        logo: pfizer
      },
      {
        company_name: 'Deciphex',
        logo: deciphex
      },
      {
        company_name: 'PTC Therapeutics',
        logo: ptc
      },
      {
        company_name: 'Abbott Diagnostics',
        logo: abbotLsa
      },
      {
        company_name: 'DMF Systems',
        logo: dmf
      },
      {
        company_name: 'Wuxi Biologics',
        logo: wuxi
      },
      {
        company_name: 'Controlled Environments Company',
        logo: cec
      },
      {
        company_name: 'Arrotek Medical',
        logo: arrotek
      },
      {
        company_name: 'Forensic Science Ireland',
        logo: fsi
      },
      {
        company_name: 'MCR',
        logo: mcr
      },
      {
        company_name: 'HGF',
        logo: hgf
      },
      {
        company_name: 'Kuehne+Nagel',
        logo: kuehne
      },
      {
        company_name: 'Synergy Security Solutions',
        logo: synergy
      },
      {
        company_name: 'DHL',
        logo: dhl
      },
      {
        company_name: 'Atlantic Technological University',
        logo: atuLsa
      },
      {
        company_name: 'Eli Lilly and Company',
        logo: lilly
      },
      {
        company_name: 'TU Dublin',
        logo: tuDublin
      },
      {
        company_name: 'SymPhysis Medical',
        logo: symphysis
      },
      {
        company_name: 'Asgard Cleanroom Solutions',
        logo: asgard
      },
      {
        company_name: 'ByrneWallace LLP',
        logo: byrne
      },
      {
        company_name: 'Unispace Life Sciences',
        logo: unispaceLifeSciences
      },
      {
        company_name: 'Abbvie',
        logo: abbvie
      },
      {
        company_name: 'Viatris',
        logo: viatris
      },
      {
        company_name: 'BusinessRiver',
        logo: businessriver
      },
      {
        company_name: 'Actylis',
        logo: actylis
      },
    ];
    const companyLogoBeaa = [
      {
        company_name: 'Aldi Ireland',
        logo: aldi
      },
      {
        company_name: 'Alexion Athlone',
        logo: alexion
      },
      {
        company_name: 'An Post',
        logo: anpost
      },
      {
        company_name: 'Arthur Cox',
        logo: arthurCox
      },
      {
        company_name: 'Business River',
        logo: businessriver
      },
      {
        company_name: 'Clayton Hotel Charlemont',
        logo: clayton
      },
      {
        company_name: 'Dairygold Co-Operative Society Limited',
        logo: dairygold
      },
      {
        company_name: 'Dublin Bus',
        logo: dublinBus
      },
      {
        company_name: 'ebay',
        logo: ebay
      },
      {
        company_name: 'Garrett Motion',
        logo: garrett
      },
      {
        company_name: 'harmac medical products',
        logo: harmac
      },
      {
        company_name: 'John Graham Construction Ltd',
        logo: graham
      },
      {
        company_name: 'Lidl',
        logo: lidl
      },
      {
        company_name: 'Mahon Point Shopping Centre',
        logo: mahon
      },
      {
        company_name: 'Matheson',
        logo: matheson
      },
      {
        company_name: 'Matheson LLP',
        logo: matheson
      },
      {
        company_name: 'Merit Medical Ireland Ltd',
        logo: meritmedical
      },
      {
        company_name: 'Queen\'s University Belfast',
        logo: queenUniversity
      },
      {
        company_name: 'Refillz Zero Waste',
        logo: refillz
      },
      {
        company_name: 'SIG Ireland',
        logo: sig
      },
      {
        company_name: 'Swords Pavilions',
        logo: swords
      },
      {
        company_name: 'The Samuel Hotel',
        logo: samuel
      },
      {
        company_name: 'Thermo Fisher Cork',
        logo: thermo
      },
      {
        company_name: 'Wren Urban Nest',
        logo: wren
      },
      {
        company_name: 'Optien',
        logo: thermo
      },
      {
        company_name: 'Jacobs Engineer',
        logo: jacobs
      },
      {
        company_name: 'Irish Solar Energy Association',
        logo: isea
      },
      {
        company_name: 'Climate Action',
        logo: climate
      },
      {
        company_name: 'Aviva Stadium',
        logo: aviva
      },
      {
        company_name: 'Anglo Beef Processors',
        logo: abp
      },
      {
        company_name: 'Gilead Sciences Ireland',
        logo: gilead
      },
      {
        company_name: 'Analog Devices',
        logo: analog
      },
      {
        company_name: 'RCSI',
        logo: rcsi
      }
    ];
    const companyLogoIsa = [
      {
        company_name: '123.ie',
        logo: isa123
      },
      {
        company_name: 'Allianz',
        logo: allianz
      },
      {
        company_name: 'Aviva',
        logo: avivaIsa
      },
      {
        company_name: 'Electric Ireland',
        logo: electricIreland
      },
      {
        company_name: 'Football Association of Ireland',
        logo: footballAssociationOfIreland
      },
      {
        company_name: 'Just Eat',
        logo: justEat
      },
      {
        company_name: 'KPMG',
        logo: kpmg
      },
      {
        company_name: 'Legacy Communications',
        logo: legacyCommunications
      },
      {
        company_name: 'Live Nation',
        logo: liveNation
      },
      {
        company_name: 'Punchestown Racecourse & Event Venue',
        logo: punchestownRacecourseEventVenue
      },
      {
        company_name: 'PwC',
        logo: pwc
      },
      {
        company_name: 'RTÉ',
        logo: rte
      },
      {
        company_name: 'Sage',
        logo: sage
      },
      {
        company_name: 'Supermac\'s',
        logo: supermacs
      },
      {
        company_name: 'The B!G Idea',
        logo: theBigIdea
      },
      {
        company_name: 'Urban Media',
        logo: urbanMedia
      },
      {
        company_name: 'URBANMEDIA',
        logo: urbanmedia
      },
      {
        company_name: 'Badminton Ireland',
        logo: badmintonIreland
      },
      {
        company_name: 'Bord Gáis Energy',
        logo: bordGaisEnergy
      },
      {
        company_name: 'Irish Life',
        logo: irishLand
      },
      {
        company_name: 'Permanent TSB',
        logo: permanentTsb
      },
      {
        company_name: 'Vhi',
        logo: vhi
      },
      {
        company_name: 'BWG Foods (SPAR Ireland)',
        logo: bwgFoodsSparIteland
      },
      {
        company_name: 'Dublin International Film Festival',
        logo: dublinInternationalFilmFestival
      },
      {
        company_name: 'ESB Group',
        logo: esbIsa
      },
      {
        company_name: 'ForeFront Sports',
        logo: forefrontSports
      },
      {
        company_name: 'Greyhound Racing Ireland',
        logo: greyhoundRacingIreland
      },
      {
        company_name: 'Horse Racing Ireland',
        logo: horseRacingIreland
      },
      {
        company_name: 'KICK',
        logo: kick
      },
      {
        company_name: 'Pentland Brands / Canterbury',
        logo: pentlandBrandsCanterbury
      },
      {
        company_name: 'Special Olympics Ireland',
        logo: specialOlympicsIreland
      },
      {
        company_name: 'Special Olympics Ireland/eir',
        logo: specialOlympicsIrelandEir
      },
      {
        company_name: 'Teneo',
        logo: teneo
      },
      {
        company_name: 'The Camogie Assocation',
        logo: theCamogieAssocation
      },
      {
        company_name: 'TITAN Experience',
        logo: titanExperience
      },
      {
        company_name: 'Pinergy',
        logo: pinergy
      },
      {
        company_name: 'Aer Lingus',
        logo: aerLingus
      },
      {
        company_name: 'Future Proof of Media',
        logo: futureProofOfMedia
      },
      {
        company_name: 'IMAGE Media',
        logo: imageMedia
      },
      {
        company_name: 'Leader Kicking',
        logo: leaderKicking
      },
      {
        company_name: 'News Ireland',
        logo: newsIreland
      },
      {
        company_name: 'Zenith',
        logo: zenith
      },
      {
        company_name: 'Mindshare Media Ireland',
        logo: mindshareMediaIreland
      },
      {
        company_name: 'Sweartaker',
        logo: sweartaker
      },
      {
        company_name: 'BusinessRiver',
        logo: businessRiverIsa
      },
      {
        company_name: 'Mediahuis Ireland',
        logo: mediahuisIreland
      },
      {
        company_name: 'National Concert Hall',
        logo: nationalConcertHall
      },
      {
        company_name: 'The Sunday Times',
        logo: theSundayTimes
      },
      {
        company_name: 'AIG',
        logo: aig
      },
      {
        company_name: 'Glenveagh Homes',
        logo: glenveagh
      },
      {
        company_name: 'Clean Coast',
        logo: cleanCoast
      },
      {
        company_name: 'Silver Cloud Health Limited',
        logo: silverCloud
      },
      {
        company_name: 'Hot Press',
        logo: hotPress
      },
    ];
    const companyLogoPiaUk = [
      {
        company_name: 'Accord Healthcare',
        logo: accordHealthcare
      },
      {
        company_name: 'Almac Group',
        logo: almacGroup
      },
      {
        company_name: 'BioPharma Dynamics',
        logo: biopharmaDynamics
      },
      {
        company_name: 'Bristol Laboratories Ltd',
        logo: bristolLaboratoriesLtd
      },
      {
        company_name: 'Business River',
        logo: businessRiver
      },
      {
        company_name: 'CELLOMATICS BIOSCIENCES LIMITED',
        logo: cellomaticsBiosciencesLimited
      },
      {
        company_name: 'Chiesi',
        logo: chiesi
      },
      {
        company_name: 'Diaceutics PLC',
        logo: diaceuticsPlc
      },
      {
        company_name: 'Hertility Health',
        logo: hertilityHealth
      },
      {
        company_name: 'Insife',
        logo: insife
      },
      {
        company_name: 'Inspired Pharma Training Ltd',
        logo: inspiredPharmaTrainingLtd
      },
      {
        company_name: 'IPSEN BIOPHARM LTD',
        logo: ipsenBioharmLtd
      },
      {
        company_name: 'Kiwi Biosciences',
        logo: kiwiBiosciences
      },
      {
        company_name: 'Kuehne+Nagel Ltd',
        logo: kuehneNagelLtd
      },
      {
        company_name: 'Medisonal',
        logo: medisonal
      },
      {
        company_name: 'Micregen',
        logo: micregen
      },
      {
        company_name: 'Panthera Biopartners',
        logo: pantheraBiopartners
      },
      {
        company_name: 'Paradigm Shift Consulting Limited',
        logo: paradigmShiftConsultingLimited
      },
      {
        company_name: 'Pharsafer',
        logo: pharsafer
      },
      {
        company_name: 'Rosemont Pharmaceuticals',
        logo: rosemontPharmaceuticals
      },
      {
        company_name: 'Seda Pharmaceutical Development Services',
        logo: sedaPharmaceuticalDevelopmentServices
      },
      {
        company_name: 'Semarion',
        logo: semarion
      },
      {
        company_name: 'TauRx Pharmaceuticals Ltd',
        logo: taurxPharmaceuticalsLtd
      },
      {
        company_name: 'Tiro Training Ltd',
        logo: tiroTrainingLtd
      },
      {
        company_name: 'Uptake',
        logo: uptake
      },
      {
        company_name: 'Dawsongroup tcs',
        logo: dawsongroup
      },
      {
        company_name: 'SkyCell',
        logo: skycell
      },
      {
        company_name: 'Agility',
        logo: agility
      },
      {
        company_name: 'AquaClinical',
        logo: aquaclinical
      },
      {
        company_name: 'Arecor',
        logo: arecor
      },
      {
        company_name: 'AstraZeneca',
        logo: astrazeneca
      },
      {
        company_name: 'Besins Healthcare UK Limited',
        logo: besinsHealthcareUkLimited
      },
      {
        company_name: 'Besins Healthcare UK Ltd',
        logo: besinsHealthcareUkLimited
      },
      {
        company_name: 'Biocair',
        logo: biocare
      },
      {
        company_name: 'Bionow',
        logo: bionow
      },
      {
        company_name: 'Boyds Consultants',
        logo: boydsConsultans
      },
      {
        company_name: 'DeepX Health',
        logo: deepxHealth
      },
      {
        company_name: 'Dexcel Pharma',
        logo: dexcelPharma
      },
      {
        company_name: 'Emirates',
        logo: skycell
      },
      {
        company_name: 'Euroapi',
        logo: dawsongroup
      },
      {
        company_name: 'Fisher Scientific',
        logo: bionow
      },
      {
        company_name: 'Geodis',
        logo: skycell
      },
      {
        company_name: 'International Health Partners',
        logo: internationalHealthPartners
      },
      {
        company_name: 'Kingston University London',
        logo: kingstonUniversityLondon
      },
      {
        company_name: 'Kyowa Kirin',
        logo: kyowaKirin
      },
      {
        company_name: 'Micro-Bio Ireland Ltd',
        logo: microBioIrelandLtd
      },
      {
        company_name: 'PBD Biotech Ltd',
        logo: bionow
      },
      {
        company_name: 'Pharmanovia',
        logo: pharmanovia
      },
      {
        company_name: 'University College London  (UCL)',
        logo: universityCollegeLondonUcl
      },
      {
        company_name: 'University of East Anglia',
        logo: universityOfEastAnglia
      },
      {
        company_name: 'UPS',
        logo: skycell
      },
      {
        company_name: 'Cognizant',
        logo: cognizant
      },
      {
        company_name: 'Charles River Labs',
        logo: cbre
      },
      {
        company_name: 'CBRE',
        logo: cbre
      },
      {
        company_name: 'Expeditors',
        logo: skycell
      },
      {
        company_name: 'Johnson & Johnson',
        logo: skycell
      },
      {
        company_name: 'Marken',
        logo: skycell
      },
      {
        company_name: 'NVS',
        logo: dawsongroup
      },
      {
        company_name: 'Sterling Pharma Solutions',
        logo: bionow
      },
      {
        company_name: 'Vertex',
        logo: skycell
      },
      {
        company_name: 'Mitigo Group',
        logo: bionow
      },
      {
        company_name: 'Unispace Life Sciences',
        logo: unispace
      },
      {
        company_name: 'Bulb Laboratories',
        logo: unispace
      },
      {
        company_name: 'Carter Jones',
        logo: unispace
      },
      {
        company_name: 'H2 Compliance',
        logo: h2Compliance
      },
      {
        company_name: 'Inspiros',
        logo: inspiros
      },
    ];
    const companyLogoHr = [
      {
        company_name: 'Approach People Recruitment',
        logo: approachPeopleRecruitment
      },
      {
        company_name: 'Bus Éireann',
        logo: busEirann
      },
      {
        company_name: 'BusinessRiver',
        logo: businessriverHr
      },
      {
        company_name: 'Carton House Resort',
        logo: cartonHouseResort
      },
      {
        company_name: 'Chadwicks Group',
        logo: chadwicksGroup
      },
      {
        company_name: 'Circet',
        logo: circet
      },
      {
        company_name: 'CluneTech',
        logo: clunetech
      },
      {
        company_name: 'Compliance and Risks',
        logo: complianceAndRisks
      },
      {
        company_name: 'Copacetic Business Solution Ltd',
        logo: copaceticBusinessSolutionLtd
      },
      {
        company_name: 'Copacetic Business Solutions Ltd',
        logo: copaceticBusinessSolutionLtd
      },
      {
        company_name: 'CPL',
        logo: cpl
      },
      {
        company_name: 'CPM Ireland',
        logo: cpmIreland
      },
      {
        company_name: 'Cyara Ireland Limited',
        logo: cyaraIrelandLimited
      },
      {
        company_name: 'DeCare',
        logo: decare
      },
      {
        company_name: 'Department of Health',
        logo: deparmentOfHealth
      },
      {
        company_name: 'Department of Transport',
        logo: deparmentOfTransport
      },
      {
        company_name: 'Digital Marketing Institute',
        logo: digitalMarketingInstitute
      },
      {
        company_name: 'DPD Ireland',
        logo: dpdIreland
      },
      {
        company_name: 'First Derivative',
        logo: firstDerivative
      },
      {
        company_name: 'Fragomen Ireland Ltd',
        logo: fragomenIrelandLtd
      },
      {
        company_name: 'Gleneagle Hotel Group',
        logo: gleneagleHotelGroup
      },
      {
        company_name: 'ICON PLC',
        logo: iconPlcHr
      },
      {
        company_name: 'Irish Rail',
        logo: irishRail
      },
      {
        company_name: 'Kerry Diocesan Youth Service',
        logo: kerryDiocesanYouthService
      },
      {
        company_name: 'Mater Misericordiae University Hospital',
        logo: materMisericordiaeUniversityHospital
      },
      {
        company_name: 'Mater Private Network',
        logo: materPrivateNetwork
      },
      {
        company_name: 'McKesson',
        logo: mckesson
      },
      {
        company_name: 'NIBRT',
        logo: nibrt
      },
      {
        company_name: 'Oriflame R&D Ltd',
        logo: oriflameRndLtd
      },
      {
        company_name: 'Permanent TSB',
        logo: permanentTsbHr
      },
      {
        company_name: 'Pobal',
        logo: pobal
      },
      {
        company_name: 'PTC Therapeutics',
        logo: ptcTherapeutics
      },
      {
        company_name: 'RCSI',
        logo: rcsiHr
      },
      {
        company_name: 'SD Worrx',
        logo: sdworx
      },
      {
        company_name: 'SD Worx',
        logo: sdworx
      },
      {
        company_name: 'TELUS International',
        logo: telusInternational
      },
      {
        company_name: 'Trigon Hotels',
        logo: trigonHotels
      },
      {
        company_name: 'Tusla',
        logo: tusla
      },
      {
        company_name: 'Tusla Child & Family Agency',
        logo: tusla
      },
      {
        company_name: 'Tusla Irelands Child and Family Agency',
        logo: tusla
      },
      {
        company_name: 'Valeo Foods Ireland',
        logo: valeoFoodsIreland
      },
      {
        company_name: 'Version 1',
        logo: version1
      },
      {
        company_name: 'VMware',
        logo: vmware
      },
      {
        company_name: 'Voltedge Management',
        logo: voltedgeManagement
      },
      {
        company_name: 'Woodie\'s',
        logo: woodies
      },
      {
        company_name: 'CluneTech',
        logo: clunetech
      },
      {
        company_name: 'Avolon',
        logo: avolon
      },
      {
        company_name: 'HSE',
        logo: hse
      },
      {
        company_name: 'Baxter Storey',
        logo: excelRecruitment
      },
      {
        company_name: 'Excel Recruitment',
        logo:  excelRecruitment
      },
      {
        company_name: 'Health and Safety Authority',
        logo:  healthAndSafetyAuthority
      },
      {
        company_name: 'Health Service Executive',
        logo:  healthServiceExecutive
      },
      {
        company_name: 'IDA Ireland',
        logo: idaireland
      },
      {
        company_name: 'Mason Hayes & Curran',
        logo: masonHayesCurran
      },
      {
        company_name: 'NCBI',
        logo: ncbi
      },
      {
        company_name: 'Orpea Residences Ireland',
        logo: excelRecruitment
      },
      {
        company_name: 'Primeline',
        logo: excelRecruitment
      },
      {
        company_name: 'Primeline Express',
        logo: primelineExpress
      },
      {
        company_name: 'Steering Point',
        logo: steeringPoint
      },
      {
        company_name: 'Dublin City University',
        logo: dublinCityUniversity
      },
      {
        company_name: 'Ryan HRS Ltd',
        logo: ryanHrsLtd
      },
      {
        company_name: 'NCI',
        logo: ncbi
      },
    ];
    const companyLogoPiaIE = [
      {
        company_name: 'Alexion Athlone',
        logo: alexionAthlone
      },
      {
        company_name: 'Alexion Pharma',
        logo: alexionPharma
      },
      {
        company_name: 'Amgen',
        logo: amgen
      },
      {
        company_name: 'APC & VLE Therapeutic',
        logo: apcVleTherapeutics
      },
      {
        company_name: 'Asgard Cleanroom Solutions',
        logo: asgardCleanroomSolutions
      },
      {
        company_name: 'Bausch Health',
        logo: bauschHealth
      },
      {
        company_name: 'Biocair',
        logo: biocair
      },
      {
        company_name: 'BioPharmaChem Ireland',
        logo: biopharmachemIreland
      },
      {
        company_name: 'Biostór Ireland',
        logo: biostorIreland
      },
      {
        company_name: 'Blynksolve',
        logo: blynksolve
      },
      {
        company_name: 'Boston Scientific',
        logo: bakerFinn
      },
      {
        company_name: 'Bristol Myers Squibb',
        logo: bristolMyersSquibb
      },
      {
        company_name: 'CBRE',
        logo: piaIrelandCbre
      },
      {
        company_name: 'Cognizant',
        logo: piaIrelandCognizant
      },
      {
        company_name: 'CPL',
        logo: piaIrelandcpl
      },
      {
        company_name: 'Eli Lilly',
        logo: eliLilly
      },
      {
        company_name: 'Eurofins BPT',
        logo: eurofinsBpt
      },
      {
        company_name: 'GetReSkilled',
        logo: getreskilled
      },
      {
        company_name: 'H2 Compliance',
        logo: piaIrelandh2Compliance
      },
      {
        company_name: 'Hanley Calibration',
        logo: hanleyCalibration
      },
      {
        company_name: 'Horizon Therapeutics now part of Amgen',
        logo: horizonTherapeiticsNowAPartAmgen
      },
      {
        company_name: 'Janssen Sciences Ireland',
        logo: janssenSciencesIreland
      },
      {
        company_name: 'Janssen - Oglivy',
        logo: janssenSciencesIreland
      },
      {
        company_name: 'Kiernan Steel',
        logo: kiernanSteel
      },
      {
        company_name: 'Koerber Pharma Software',
        logo: koerberPharmaSoftware
      },
      {
        company_name: 'LotusWorks',
        logo: piaIrelandLotusworks
      },
      {
        company_name: 'McKesson',
        logo: piaIrelandMckesson
      },
      {
        company_name: 'Merck KGaA',
        logo: merckKgaa
      },
      {
        company_name: 'Metabolomic Diagnostics',
        logo: metabolomicDiagnostics
      },
      {
        company_name: 'Micro-Bio Ireland',
        logo: microBioIreland
      },
      {
        company_name: 'MSD',
        logo: piaIrelandSkycell
      },
      {
        company_name: 'MSD Ireland',
        logo: msdIreland
      },
      {
        company_name: 'NIBRT',
        logo: piaIrelandNibrt
      },
      {
        company_name: 'Nutriband',
        logo: nutriband
      },
      {
        company_name: 'OBW Technologies',
        logo: obwTechnologies
      },
      {
        company_name: 'Pfizer Ireland Pharmaceuticals Ringaskiddy',
        logo: pfizerIrelandPharmaceuticalsRingaskiddy
      },
      {
        company_name: 'Pharma Integrity',
        logo: pharmaIntegrity
      },
      {
        company_name: 'Q1 Scientific',
        logo: q1Scientific
      },
      {
        company_name: 'SkyCell',
        logo: piaIrelandSkycell
      },
      {
        company_name: 'SSPC',
        logo: sspc
      },
      {
        company_name: 'Synergy',
        logo: piaIrelandSynergy
      },
      {
        company_name: 'Takeda Ireland',
        logo: takedaIreland
      },
      {
        company_name: 'TU Dublin',
        logo: piaIrelandTuDublin
      },
      {
        company_name: 'Unispace Life Sciences',
        logo: piaIrelandUnispaceLifeSciences
      },
      {
        company_name: 'Viatris Little Island',
        logo: viatrisLittleIsland
      },
      {
        company_name: 'WD Excellence',
        logo: wdExcellence
      },
      {
        company_name: 'West Pharmaceutical Services',
        logo: westPharceuticalServices
      },
      {
        company_name: 'Wolf Medical',
        logo: wolfMedical
      },
      {
        company_name: 'WuXi Biologics Ireland',
        logo:  piaIrelandLotusworks
      },
      {
        company_name: 'Zoetis',
        logo:  zoetis
      },
      {
        company_name: 'Baker Finn',
        logo:  bakerFinn
      },
      {
        company_name: 'BOC Gases',
        logo:  bocGasses
      },
      {
        company_name: 'Camida',
        logo:  camida
      },
      {
        company_name: 'Ecolab',
        logo:  ecolab
      },
      {
        company_name: 'Ellab Ireland',
        logo:  ellabIreland
      },
      {
        company_name: 'Indaver',
        logo:  indaver
      },
      {
        company_name: 'Next Generation',
        logo:  nextGeneration
      },
      {
        company_name: 'Cognizant Life Science Manufacturing',
        logo:  cognizant
      },
      {
        company_name: 'KPC International',
        logo:  kpc
      },
      {
        company_name: 'Arcadis DPS Group',
        logo:  asgardCleanroomSolutions
      },
      {
        company_name: 'Baku GLS',
        logo:  piaIrelandSkycell
      },
      {
        company_name: 'Bonafi',
        logo:  piaIrelandcpl
      },
      {
        company_name: 'Ceva Logistics',
        logo:  piaIrelandcpl
      },
      {
        company_name: 'Consultancy',
        logo:  piaIrelandcpl
      },
      {
        company_name: 'Independent Consultant',
        logo:  camida
      },
      {
        company_name: 'John Paul Construction',
        logo:  asgardCleanroomSolutions
      },
      {
        company_name: 'Pinewood Healthcare',
        logo:  camida
      },
      {
        company_name: 'PlantQuest',
        logo:  blynksolve
      },
      {
        company_name: 'SCM Flex limited',
        logo:  piaIrelandSkycell
      },
      {
        company_name: 'Sterling',
        logo:  piaIrelandSkycell
      },
      {
        company_name: 'TBC',
        logo:  piaIrelandSkycell
      },
      {
        company_name: 'TOTEM',
        logo:  camida
      },
      {
        company_name: 'NOD Apiary',
        logo: piaIrelandcpl
      },
      {
        company_name: 'QuickSTAT A Kuehne+Nagel Company',
        logo: quickstat
      },
      {
        company_name: 'Kuehne+Nagel',
        logo: kuehne
      },
      {
        company_name: 'Nuvalez',
        logo: quickstat
      },
      {
        company_name: 'Source Bioscience',
        logo: quickstat
      },
      {
        company_name: 'Catalyx OpenBIO',
        logo: catalyx
      },
      {
        company_name: 'APC & VLE Therapeutics',
        logo: apcVleTherapeutics
      },
      {
        company_name: 'Controlled Environments Company (CEC)',
        logo: cec
      },
      {
        company_name: 'Actylis',
        logo: actylis
      },
      {
        company_name: 'AbbVie',
        logo: abbvie
      },
      {
        company_name: 'BioPharmaChem Skillnet',
        logo: biopharmachemIreland
      },
      {
        company_name: 'Titan Experience',
        logo: titanExperiencePia
      },
      {
        company_name: 'PMBRC, SETU',
        logo: pmbrc
      },
      {
        company_name: 'Munster Technological University',
        logo: mtu
      },
      {
        company_name: 'CareerVision',
        logo: careerVision
      },
      {
        company_name: 'University College Cork',
        logo: ucc
      },
      {
        company_name: 'Aramex',
        logo: biocair
      }
    ];
    const companyLogoIcia = [
      {
        company_name: 'Actavo',
        logo: actavo
      },
      {
        company_name: 'Alternative Heat',
        logo: alternativeHeat
      },
      {
        company_name: 'Alucraft',
        logo: alucraft
      },
      {
        company_name: 'Arcadis Ireland Limited',
        logo: arcadisIrelandLimited
      },
      {
        company_name: 'Ardmac',
        logo: ardmac
      },
      {
        company_name: 'ArdmacArdmac',
        logo: ardmac
      },
      {
        company_name: 'ASEE',
        logo: asee
      },
      {
        company_name: 'Asee ltd',
        logo: asee
      },
      {
        company_name: 'ASEE Ltd.',
        logo: asee
      },
      {
        company_name: 'Ashview Consultants Ltd',
        logo: ashviewConsultantsLtd
      },
      {
        company_name: 'Axiseng M&E Ltd.',
        logo: axisengMELtd
      },
      {
        company_name: 'Ballymore',
        logo: ballymore
      },
      {
        company_name: 'BAM Ireland',
        logo: bamIreland
      },
      {
        company_name: 'Bennett (Construction) Ltd',
        logo: bennetConstructionLtd
      },
      {
        company_name: 'Boyle Construction',
        logo: boyleConstruction
      },
      {
        company_name: 'Breedon',
        logo: breedonIcia
      },
      {
        company_name: 'Cairn Homes',
        logo: cairnHomes
      },
      {
        company_name: 'CField Construction',
        logo: cfieldConstruction
      },
      {
        company_name: 'Clancy Engineering',
        logo: clancyEngineering
      },
      {
        company_name: 'Collen Construction',
        logo: collenConstruction
      },
      {
        company_name: 'Cunningham Civil & marine Ltd',
        logo: cunningmanCivilMarineLtd
      },
      {
        company_name: 'Cunningham Civil & Marine Ltd',
        logo: cunningmanCivilMarineLtd
      },
      {
        company_name: 'Duggan Brothers',
        logo: dugganBrothers
      },
      {
        company_name: 'Duke McCaffrey',
        logo: dukeMccaffrey
      },
      {
        company_name: 'Duke McCaffrey Consulting.',
        logo: visionContracting
      },
      {
        company_name: 'EDC - Engineering Design Consultants Limited',
        logo: edcEngineeringDesignConsultantsLimited
      },
      {
        company_name: 'Ethos Engineering',
        logo: ethosEngineering
      },
      {
        company_name: 'Extreme Balconies',
        logo: extremeBalconies
      },
      {
        company_name: 'Flynn',
        logo: flynn
      },
      {
        company_name: 'HOLLIS',
        logo: hollis
      },
      {
        company_name: 'Instrotec Ltd',
        logo: instrotecLtd
      },
      {
        company_name: 'JCPS Mechanical Ltd',
        logo: jcpsMechanicalLtd
      },
      {
        company_name: 'John Paul Construction',
        logo: johnPaulConstruction
      },
      {
        company_name: 'John Sisk & Son',
        logo: johnSiskSon
      },
      {
        company_name: 'Jones Engineering',
        logo: jonesEngineering
      },
      {
        company_name: 'JPM Contracts Ltd',
        logo: jpmContractsLtd
      },
      {
        company_name: 'Kelbuild Ltd',
        logo: kelbuildLtd
      },
      {
        company_name: 'Kiernan Steel Ltd',
        logo: kiernanSteelLtd
      },
      {
        company_name: 'King & Moffatt Building Services',
        logo: kingMoffattBuildingServices
      },
      {
        company_name: 'Kirby Group',
        logo: kirbyGroup
      },
      {
        company_name: 'Lmd build',
        logo: lmdBuild
      },
      {
        company_name: 'lmd Build',
        logo: lmdBuild
      },
      {
        company_name: 'McCabe Group',
        logo: mccabeGroup
      },
      {
        company_name: 'McCauley Daye O\'Connell Architects',
        logo: mccauleyDateOConnellArchitects
      },
      {
        company_name: 'Meitheal Architects',
        logo: miethealArchitects
      },
      {
        company_name: 'Mitchell McDermott',
        logo: mitchellMcdermott
      },
      {
        company_name: 'MOLA Architecture',
        logo: molaArchitecture
      },
      {
        company_name: 'Murphy Ireland',
        logo: murphyIreland
      },
      {
        company_name: 'National Broadband Ireland',
        logo: nationalBroadbandIreland
      },
      {
        company_name: 'NBI National Broadband Ireland',
        logo: nationalBroadbandIreland
      },
      {
        company_name: 'ORS',
        logo: ors
      },
      {
        company_name: 'P McVey Building Systems',
        logo: pMcveyBuildingSystems
      },
      {
        company_name: 'QMAC Construction Ltd',
        logo: qmacConstructionLtd
      },
      {
        company_name: 'Reddy Architecture & Urbanism',
        logo: reddyArchitectureUrbanism
      },
      {
        company_name: 'Roccul ltd',
        logo: rocculLtd
      },
      {
        company_name: 'Scott Tallon Walker Architects',
        logo: scottTallonWalkerArchitects
      },
      {
        company_name: 'SES Water Management',
        logo: sesWaterManagement
      },
      {
        company_name: 'Shareridge',
        logo: shareridge
      },
      {
        company_name: 'SHAY MURTAGH PRECAST',
        logo: shayMurtaghPrecast
      },
      {
        company_name: 'Stonecon Limited',
        logo: stoneconLimited
      },
      {
        company_name: 'Suir Engineering Ltd',
        logo: suirEngineeringLtd
      },
      {
        company_name: 'Timeless Sash Windows',
        logo: timelessSashWindows
      },
      {
        company_name: 'Titan Roofing Ltd',
        logo: titanRoofingLtd
      },
      {
        company_name: 'Truwood',
        logo: truwood
      },
      {
        company_name: 'Truwood Ltd',
        logo: truwood
      },
      {
        company_name: 'Vision Contracting',
        logo: visionContracting
      },
      {
        company_name: 'Walls Construction Ltd',
        logo: wallsConstruction
      },
      {
        company_name: 'Writech',
        logo: writech
      },
      {
        company_name: 'Boon Edam',
        logo: boomEdam
      },
      {
        company_name: 'Globoserv',
        logo: globoserv
      },
      {
        company_name: 'KCC Group',
        logo: kccGroup
      },
      {
        company_name: 'Williaam Cox',
        logo: williamCox
      },
      {
        company_name: 'Meinhardt Ireland',
        logo: meinhardtIreland
      },
      {
        company_name: 'Tech Refrigeration and Air Conditioning',
        logo: techRefrigeration
      },
      {
        company_name: 'Lumen Search',
        logo: lumenSearch
      },
      {
        company_name: 'MMC Ireland',
        logo: mmcIreland
      },
      {
        company_name: 'Considerate Constructors Scheme',
        logo: considerateConstructorsScheme
      },
      {
        company_name: 'Dynamic Safety Solutions',
        logo: nationalBroadbandIreland
      },
      {
        company_name: 'Korec Group',
        logo: nationalBroadbandIreland
      },
      {
        company_name: 'Belton Consulting Engineers Ltd.',
        logo: visionContracting
      },
      {
        company_name: 'Leading Edge Project',
        logo: visionContracting
      },
      {
        company_name: 'Red Rock Developments',
        logo: visionContracting
      },
      {
        company_name: 'DTA Architects',
        logo: visionContracting
      },
      {
        company_name: 'Actavo Guest DLRCC',
        logo: actavo
      },
      {
        company_name: 'Prestige windows and doors',
        logo: lmdBuild
      },
      {
        company_name: 'Po Mc namee design',
        logo: lmdBuild
      },
      {
        company_name: 'Patrick m Kerr architects',
        logo: lmdBuild
      },
      {
        company_name: 'Kenny cost estimating',
        logo: lmdBuild
      },
      {
        company_name: 'ARÓ architects',
        logo: lmdBuild
      },
      {
        company_name: 'Brian Conneely & Co Ltd.',
        logo: pMcveyBuildingSystems
      },
      {
        company_name: 'RDCC and Raddison Blue',
        logo: reddyArchitectureUrbanism
      },
      {
        company_name: 'Luxor Leisure',
        logo: reddyArchitectureUrbanism
      },
      {
        company_name: 'MMC Eco Homes',
        logo: arcadisIrelandLimited
      },
      {
        company_name: 'FTSQUARED - Development and Management Consultancy',
        logo: arcadisIrelandLimited
      },
      {
        company_name: 'Revantage',
        logo: arcadisIrelandLimited
      },
      {
        company_name: 'Arthur Cox LLP',
        logo: arcadisIrelandLimited
      },
      {
        company_name: 'SCSI',
        logo: arcadisIrelandLimited
      },
      {
        company_name: 'M7',
        logo: arcadisIrelandLimited
      },
      {
        company_name: 'Pennypop',
        logo: shayMurtaghPrecast
      },
      {
        company_name: 'ACEI',
        logo: shayMurtaghPrecast
      },
      {
        company_name: 'AON',
        logo: shayMurtaghPrecast
      },
      {
        company_name: 'McNally Kitchens',
        logo: shayMurtaghPrecast
      },
      {
        company_name: 'All Human',
        logo: shayMurtaghPrecast
      },
      {
        company_name: 'NRH',
        logo: shayMurtaghPrecast
      },
      {
        company_name: 'Construction Industry Federation',
        logo: shayMurtaghPrecast
      },
      {
        company_name: 'Spark Digital',
        logo: shayMurtaghPrecast
      },
      {
        company_name: 'KOREC | Building Point UK & Ireland',
        logo: korec
      },
      {
        company_name: 'Industry Training Services',
        logo: its
      },
      {
        company_name: 'Ardale Property',
        logo: meinhardtIreland
      },
      {
        company_name: 'Brock McClure',
        logo: meinhardtIreland
      },
      {
        company_name: 'Clonmel Enterprises Ltd',
        logo: meinhardtIreland
      },
      {
        company_name: 'Marlet',
        logo: meinhardtIreland
      },
      {
        company_name: 'Vantage DC’s',
        logo: meinhardtIreland
      },
      {
        company_name: 'Robin Mandal Architect',
        logo: robinmandal
      },
      {
        company_name: 'Property District',
        logo: propertyDistrict
      },
      {
        company_name: 'Technological University Dublin',
        logo: technologicalUniversity
      },
      {
        company_name: 'South East Technological University (SETU)',
        logo: southEastTechno
      },
      {
        company_name: 'Layher Ireland',
        logo: layherIreland
      },
      {
        company_name: 'Allied Irish Banks plc.',
        logo: alliedIrish
      },
      {
        company_name: 'Tom Phillips + Associates',
        logo: meinhardtIreland
      },
      {
        company_name: 'Maxol',
        logo: meinhardtIreland
      },
      {
        company_name: 'Ashview Consultants',
        logo: meinhardtIreland
      },
      {
        company_name: 'LMD Build',
        logo: lmdBuild
      },
      {
        company_name: 'Cunningham Contracts',
        logo: cunninghamContracts
      },
    ];
    const companyLogoNpa = [
      {
        company_name: 'An Post',
        logo: anPost
      },
      {
        company_name: 'Bord na Mona',
        logo: bordNaMona
      },
      {
        company_name: 'Bus Eireann',
        logo: busEireann
      },
      {
        company_name: 'Compass Group Ireland',
        logo: compassGroupIreland
      },
      {
        company_name: 'Dansko Foods',
        logo: danskoFoods
      },
      {
        company_name: 'Dublin Bus',
        logo: dublinBusNpa
      },
      {
        company_name: 'ESB',
        logo: esbNpa
      },
      {
        company_name: 'Greenville Procurement Partners',
        logo: greenvilleProcurementPartners
      },
      {
        company_name: 'Griffith College',
        logo: griffithCollege
      },
      {
        company_name: 'Health Service Executive',
        logo: healthServiceExecutiveNpa
      },
      {
        company_name: 'Heineken Ireland',
        logo: heinekenIreland
      },
      {
        company_name: 'HSE',
        logo: hseNpa
      },
      {
        company_name: 'HSE - Services for Older People',
        logo: hseServicesForOldPeople
      },
      {
        company_name: 'HSE Services for Older People',
        logo: hseServicesForOldPeopleCopy
      },
      {
        company_name: 'Iarnród Éireann - Irish Rail',
        logo: iarnodEirann
      },
      {
        company_name: 'Meade Farm Group',
        logo: meadeFarmGroup
      },
      {
        company_name: 'MSD',
        logo: msdNpa
      },
      {
        company_name: 'Musgrave',
        logo: musgrave
      },
      {
        company_name: 'OBW Technologies',
        logo: obwTechnologiesNpa
      },
      {
        company_name: 'Office of Government Procurement',
        logo: officeOfGovermentProcurement
      },
      {
        company_name: 'Penneys/Primark',
        logo: penneysPrimark
      },
      {
        company_name: 'Permanent TSB and Efficio',
        logo: permanentTsbAndEfficio
      },
      {
        company_name: 'Pfizer Ireland Pharmaceuticals',
        logo: pfizerIrelandPharmaceuticals
      },
      {
        company_name: 'Uisce Éireann',
        logo: uisceEireann
      },
      {
        company_name: 'University College Cork',
        logo: universityCollegeCork
      },
      {
        company_name: 'Verizon',
        logo: verizon
      },
      {
        company_name: 'West Pharmaceutical',
        logo: westPharmaceutical
      },
      {
        company_name: 'Velopi',
        logo: universityCollegeCork
      },
      {
        company_name: 'Transport Infrastructure Ireland',
        logo: transportInfrastructureIreland
      },
      {
        company_name: 'Bristol Myers Squibb',
        logo: bristolMyersSquibbNpa
      },
      {
        company_name: 'KPMG',
        logo: kpmgNpa
      },
      {
        company_name: 'SIGMA',
        logo: sigma
      },
    ];
    const companyLogoIcxia = [
      {
        company_name: 'Accountant Online',
        logo: accountOnline
      },
      {
        company_name: 'Aer Lingus',
        logo: aerLingusIcx
      },
      {
        company_name: 'AIB',
        logo: aibIcx
      },
      {
        company_name: 'Allview Healthcare',
        logo: allviewHealthcare
      },
      {
        company_name: 'Bank of Ireland',
        logo: bankOfIreland
      },
      {
        company_name: 'BUS EIREANN - IRISH BUS',
        logo: busEirannIrishBus
      },
      {
        company_name: 'BusinessRiver',
        logo: businessRiverIcx
      },
      {
        company_name: 'Calor',
        logo: calor
      },
      {
        company_name: 'Circit',
        logo: circit
      },
      {
        company_name: 'Coll-8 Logistics',
        logo: coll8Logisctics
      },
      {
        company_name: 'Diageo Ireland',
        logo: diageoIreland
      },
      {
        company_name: 'Dubarry of Ireland',
        logo: dubarryOfIreland
      },
      {
        company_name: 'Eason',
        logo: eason
      },
      {
        company_name: 'Electric Ireland',
        logo: electricIrelandIcx
      },
      {
        company_name: 'ESB Networks',
        logo: esbNetworks
      },
      {
        company_name: 'Fastway',
        logo: fastwayIcx
      },
      {
        company_name: 'Fragomen Ireland',
        logo: fragomenIrealandIcx
      },
      {
        company_name: 'Gas Networks Ireland',
        logo: gasNetworksIreland
      },
      {
        company_name: 'Irish Life',
        logo: irishLifeIcx
      },
      {
        company_name: 'Kara Connect',
        logo: karaConnect
      },
      {
        company_name: 'Kylemore Cars',
        logo: kylemoreCarsIcx
      },
      {
        company_name: 'KylemoreCars',
        logo: kylemoreCarsIcx
      },
      {
        company_name: 'Liberty Insurance',
        logo: libertyInsurance
      },
      {
        company_name: 'LOCO Scooters Ltd',
        logo: locoScooterLtd
      },
      {
        company_name: 'Magnet Plus',
        logo: magnetPlus
      },
      {
        company_name: 'Magnet+',
        logo: magnetPlus
      },
      {
        company_name: 'Musgrave',
        logo: musgraveIcx
      },
      {
        company_name: 'National Rehabilitation Hospital',
        logo: nationalRehabilitationHospital
      },
      {
        company_name: 'Oakview Early Years Services',
        logo: oakviewEarlyYearsServices
      },
      {
        company_name: 'Passport Service, Department of Foreign Affairs',
        logo: passportServiceDepartmentOfForeignAffairs
      },
      {
        company_name: 'Department of Social Protection',
        logo: passportServiceDepartmentOfForeignAffairs
      },
      {
        company_name: 'Pennypop',
        logo: pennypopIcx
      },
      {
        company_name: 'KC Services',
        logo: pennypopIcx
      },
      {
        company_name: 'Shay Murtagh',
        logo: pennypopIcx
      },
      {
        company_name: 'Queen\'s University',
        logo: queensUniversity
      },
      {
        company_name: 'Shaws Department Stores',
        logo: shawsDepartmentStores
      },
      {
        company_name: 'SSE Airtricity',
        logo: sseAirtricity
      },
      {
        company_name: 'The Convention Centre Dublin',
        logo: theConventionCentreDublin
      },
      {
        company_name: 'Trinity Brand Commercial Services ltd',
        logo: trinityBrandCommercialServicesLtd
      },
      {
        company_name: 'University of Limerick',
        logo: universityOfLimerick
      },
      {
        company_name: 'RentalMatics',
        logo: rentalMatic
      },
      {
        company_name: 'W5',
        logo: w5
      },
      {
        company_name: 'EPAM Systems',
        logo: epam
      },
      {
        company_name: 'GS1 Ireland',
        logo: gs
      },
      {
        company_name: 'UCD',
        logo: ucd
      },
      {
        company_name: 'Irish Management Institute',
        logo: imi
      },
      {
        company_name: 'RSA Group',
        logo: cxia123
      },
      {
        company_name: 'CXPA, Actavo',
        logo: cxpa
      },
      {
        company_name: 'CXPA, Irish Life Group',
        logo: cxpa
      },
      {
        company_name: 'CXPA Ireland',
        logo: cxpa
      },
      {
        company_name: 'The CX Company',
        logo: cxCompany
      },
      {
        company_name: 'The Experience Edge',
        logo: theExperienceEdge
      },
      {
        company_name: 'Shaw & Sons Ltd.',
        logo: shawsDepartmentStores
      },
    ];
    const companyLogoFoa = [
      {
        company_name: 'Alpha Mechanical Services',
        logo: alphaMechanicalServices
      },
      {
        company_name: 'Ardmac',
        logo: ardmacFoa
      },
      {
        company_name: 'ARUP',
        logo: arup
      },
      {
        company_name: 'OPW',
        logo: arup
      },
      {
        company_name: 'ASH WILSON DESIGN',
        logo: ashWilsonDesign
      },
      {
        company_name: 'Ashen & Cloud',
        logo: ashenCLoud
      },
      {
        company_name: 'Atlantic Technological University',
        logo: altanticTechnologicalUniversity
      },
      {
        company_name: 'AXISENG M AND E  LTD',
        logo: axisengME
      },
      {
        company_name: 'Axiseng M&E Ltd.',
        logo: axisengME
      },
      {
        company_name: 'Azure Contracting Ltd',
        logo: azureContractingLtd
      },
      {
        company_name: 'BSG Design',
        logo: bsgDesign
      },
      {
        company_name: 'Built Interior Ltd',
        logo: builtInteriorLtd
      },
      {
        company_name: 'Bus Eireann',
        logo: busEirannFoa
      },
      {
        company_name: 'Byrne Custom Joinery',
        logo: byrneCustomJoinery
      },
      {
        company_name: 'Calibro Workspace',
        logo: calibroWorkspace
      },
      {
        company_name: 'Calibro Workspace Ltd',
        logo: calibroWorkspace
      },
      {
        company_name: 'Castlebrook',
        logo: castleBrook
      },
      {
        company_name: 'CBRE',
        logo: cbreFoa
      },
      {
        company_name: 'Cogent Associates',
        logo: cogentAssociates
      },
      {
        company_name: 'COMMA Architects',
        logo: commaArchitects
      },
      {
        company_name: 'Corgan',
        logo: corgan
      },
      {
        company_name: 'Corgan IE Limited',
        logo: corgan
      },
      {
        company_name: 'CRM Fit-Out',
        logo: crmFitOut
      },
      {
        company_name: 'David James Retail Solutions',
        logo: davidJamesRetailSolutions
      },
      {
        company_name: 'Dore Optical',
        logo: davidJamesRetailSolutions
      },
      {
        company_name: 'Diarmuid Reil Architects',
        logo: diarmuidReilArchitects
      },
      {
        company_name: 'DMOD Architects',
        logo: ddmodArchitects
      },
      {
        company_name: 'DMVF Architects',
        logo: dmvfArchitects
      },
      {
        company_name: 'Duke McCaffrey Consulting',
        logo: dukeMccaffreyConsulting
      },
      {
        company_name: 'EDC',
        logo: edc
      },
      {
        company_name: 'Edit Design Studio',
        logo: editDesignStudio
      },
      {
        company_name: 'Elliott Group',
        logo: elliottGroup
      },
      {
        company_name: 'elm gray',
        logo: elmGray
      },
      {
        company_name: 'Ethos Engineering',
        logo: ethosEngineeringFoa
      },
      {
        company_name: 'Ethos Engineering Limited',
        logo: ethosEngineeringFoa
      },
      {
        company_name: 'GDC Interiors',
        logo: gdcInteriors
      },
      {
        company_name: 'Globoserv Ltd',
        logo: globoservLtd
      },
      {
        company_name: 'Griffith College',
        logo: griffithCollegeFoa
      },
      {
        company_name: 'Hamilton Robson',
        logo: hamiltonRobson
      },
      {
        company_name: 'Helene Broderick Interiors',
        logo: heleneBroderickInteriors
      },
      {
        company_name: 'Henry J Lyons',
        logo: henryJLyons
      },
      {
        company_name: 'Iconic Offices',
        logo: iconicOffices
      },
      {
        company_name: 'Interiors by Sisk',
        logo: interiorsBySisk
      },
      {
        company_name: 'JCA Architects',
        logo: jcaArchitects
      },
      {
        company_name: 'DBFL Consulting Engineers',
        logo: jcaArchitects
      },
      {
        company_name: 'Linesight',
        logo: jcaArchitects
      },
      {
        company_name: 'JENNINGS \\ DESIGN STUDIO Architects',
        logo: jenningsDesignStudioArchitects
      },
      {
        company_name: 'Kingston Lafferty Design',
        logo: kingstonLaffertyDesign
      },
      {
        company_name: 'Knight Frank',
        logo: knightFrank
      },
      {
        company_name: 'Pinebridge Construction',
        logo: knightFrank
      },
      {
        company_name: 'PTSB',
        logo: knightFrank
      },
      {
        company_name: 'Pinebridge Guest',
        logo: knightFrank
      },
      {
        company_name: 'KOTA',
        logo: kota
      },
      {
        company_name: 'KPMG Construction Advisory',
        logo: kpmgFoa
      },
      {
        company_name: 'LL Solutions',
        logo: llSolutions
      },
      {
        company_name: 'LUCA ARCHITECTURE',
        logo: lucaArchitecture
      },
      {
        company_name: 'LUCA Architecture',
        logo: lucaArchitecture
      },
      {
        company_name: 'MCA Architects',
        logo: mcaArchitects
      },
      {
        company_name: 'Lafferty',
        logo: mcaArchitects
      },
      {
        company_name: 'An Post',
        logo: mcaArchitects
      },
      {
        company_name: 'Image Now',
        logo: mcaArchitects
      },
      ///
      {
        company_name: 'McMahon Associates',
        logo: mcmahonAssociates
      },
      {
        company_name: 'Millimetre Design',
        logo: millimetreDesign
      },
            {
        company_name: 'MILLIMETRE DESIGN',
        logo: millimetreDesign
      },
      {
        company_name: 'MOLA Architecture',
        logo: molaArchitectureFoa
      },
      {
        company_name: 'MOLA Architecture Limited',
        logo: molaArchitectureFoa
      },
      {
        company_name: 'O\'Donnell O\'Neill Design',
        logo: oDonnelONiellDesign
      },
      {
        company_name: 'Oak PM',
        logo: oakPm
      },
      {
        company_name: 'Oppermann Associates',
        logo: oppermannAssociates
      },
      {
        company_name: 'Oppermann Associates Limited',
        logo: oppermannAssociates
      },
      {
        company_name: 'Louth & Meath ETB',
        logo: oppermannAssociates
      },
      {
        company_name: 'Semple McKillop',
        logo: oppermannAssociates
      },
      {
        company_name: 'Fire Protection Ireland',
        logo: oppermannAssociates
      },
      {
        company_name: 'Perkins & Will',
        logo: perkinsWill
      },
      {
        company_name: 'Perkins&Will',
        logo: foaFlynn
      },
      {
        company_name: 'Pure Fitout Associated Ltd',
        logo: pureFitoutAssociatedLtd
      },
      {
        company_name: 'D\'Olier Street Restaurant',
        logo: pureFitoutAssociatedLtd
      },
      {
        company_name: 'Reddy Architecture and Urbanism',
        logo: reddyArchitectureUrbanismFoa
      },
      {
        company_name: 'Roccul',
        logo: roccul
      },
      {
        company_name: 'Saul Design',
        logo: vardisGroup
      },
      {
        company_name: 'Sinéad Cassidy Design',
        logo: sineadCassidyDesign
      },
      {
        company_name: 'Sinead Cassidy Design',
        logo: sineadCassidyDesign
      },
      {
        company_name: 'Stephen Carr Architects',
        logo: stephenCarrArchitects
      },
      {
        company_name: 'Structure Tone',
        logo: structureTone
      },
      {
        company_name: 'T&I Fitouts',
        logo: tIFitouts
      },
      {
        company_name: 'TapCreative',
        logo: tapCreative
      },
      {
        company_name: 'Homecare',
        logo: tapCreative
      },
      {
        company_name: 'The Interiors Group',
        logo: theInteriorGroup
      },
      {
        company_name: 'TODD Architects',
        logo: toddArchitects
      },
      {
        company_name: 'Glandore',
        logo: toddArchitects
      },
      {
        company_name: 'Graham Interior Fit out',
        logo: toddArchitects
      },
      {
        company_name: 'Mace',
        logo: toddArchitects
      },
      {
        company_name: 'Total Fitout',
        logo: totalFitout
      },
      {
        company_name: 'TUDublin',
        logo: tuDublinFoa
      },
      {
        company_name: 'Turner & Townsend',
        logo: turnerTownsend
      },
      {
        company_name: 'Vardis Group',
        logo: vardisGroup
      },
      {
        company_name: 'Wilson Architecture',
        logo: wilsonArchitecture
      },
      {
        company_name: 'Yg Interiors',
        logo: ygInteriors
      },
      {
        company_name: 'The Warehouse Gym',
        logo: ygInteriors
      },
      {
        company_name: 'Jones Engineering',
        logo: jonesEngineering
      },
      {
        company_name: 'DCON',
        logo: dcon
      },
      {
        company_name: 'Procore',
        logo: procore
      },
      {
        company_name: 'W2W',
        logo: w2w
      },
      {
        company_name: 'BENE',
        logo: bene
      },
      {
        company_name: 'OPTIMA',
        logo: optima
      },
      {
        company_name: 'Johnston Fitout',
        logo: johnstonFitout
      },
      {
        company_name: 'MJF Interiors',
        logo: mjfInteriors
      },
      {
        company_name: 'Etag',
        logo: etag
      },
      {
        company_name: 'Radii',
        logo: radii
      },
      {
        company_name: 'Solutions',
        logo: solutions
      },
      {
        company_name: 'Jan Janssens & Company',
        logo: janJanssens
      },
      {
        company_name: 'Jan Janssens',
        logo: janJanssens
      },
      {
        company_name: 'SIG Workplace',
        logo: sigWorkplace
      },
      {
        company_name: 'Avitor',
        logo: sigWorkplace
      },
      {
        company_name: 'Steelcase',
        logo: sigWorkplace
      },
      {
        company_name: 'Autex Acoustics',
        logo: autexAcoustics
      },
      {
        company_name: 'Office Interiors',
        logo: autexAcoustics
      },
      {
        company_name: 'Vision Creative Studios',
        logo: visionCreativeStudios
      },
      {
        company_name: 'Jabes Interiors',
        logo: jabesInteriors
      },
      {
        company_name: 'Winroy',
        logo: winroy
      },
      {
        company_name: 'Winroy Interiors',
        logo: winroy
      },
      {
        company_name: 'DMG Engineering Limited',
        logo: dmgEngineeringLimited
      },
      {
        company_name: 'Conbu Interior Design',
        logo: conbuInteriorDesign
      },
      {
        company_name: 'Hillmac Fitouts',
        logo: hillmacFitouts
      },
      {
        company_name: 'Harcourt Architects',
        logo: harcourt
      },
      {
        company_name: 'SLMD LTD',
        logo: slmdLtd
      },
      {
        company_name: 'GC Fitout Limited',
        logo: gcFitout
      },
      {
        company_name: 'GC Fitout',
        logo: gcFitout
      },
      {
        company_name: 'Blackrock Further Education Institute',
        logo: blackrockFurther
      },
      {
        company_name: 'Lioncor',
        logo: lioncor
      },
      {
        company_name: 'Creighan Contracts Ltd',
        logo: creighanContracts
      },
      {
        company_name: 'Gannon Office Solutions',
        logo: gannonOfficeSolutions
      },
      {
        company_name: 'Havwoods Ireland',
        logo: havwoodsIreland
      },
      {
        company_name: 'Willie Duggan Lighting',
        logo: willieDuggan
      },
      {
        company_name: 'Tarkett',
        logo: tarkett
      },
      {
        company_name: 'Creative Technology (Audio Visual) Ireland Ltd',
        logo: creativeTechnology
      },
      {
        company_name: 'Radius Office',
        logo: radiusOffice
      },
      {
        company_name: 'GEM Joinery',
        logo: gemJoinery
      },
      {
        company_name: 'OMAN Beverly Smyth',
        logo: omanBeverlySmyth
      },
      {
        company_name: 'Alliance Explore Flooring',
        logo: allianceExploreFlooring
      },
      {
        company_name: 'Noyeks Newmans',
        logo: noyeks
      },
      {
        company_name: 'Reputation Interiors(The Reputations Agency Ltd)',
        logo: reputation
      },
      {
        company_name: 'INTERFACE IRELAND LIMITED',
        logo: interfaceIreland
      },
      {
        company_name: 'C+W O\'Brien Architects',
        logo: cwBrien
      },
    ];
    const companyLogoAia = [
      {
        company_name: 'ABL Aviation',
        logo: ablaviation
      },
      {
        company_name: 'Aer Arann Islands',
        logo: aerarann
      },
      {
        company_name: 'Aer Lingus',
        logo: aerlingus
      },
      {
        company_name: 'AerAdvise',
        logo: aeradvise
      },
      {
        company_name: 'Aero Inspection',
        logo: aeroinspection
      },
      {
        company_name: 'Aeropods Ltd',
        logo: aeropods
      },
      {
        company_name: 'Airline Flight Academy',
        logo: airlineflight
      },
      {
        company_name: 'Airtel ATN',
        logo: airtel
      },
      {
        company_name: 'Aterim',
        logo: aterim
      },
      {
        company_name: 'Avolon',
        logo: avolonLogo
      },
      {
        company_name: 'BusinessRiver',
        logo: businessriveraia
      },
      {
        company_name: 'CAE Parc Technical Support Services',
        logo: cae
      },
      {
        company_name: 'cloudcards aviation software',
        logo: cloudcards
      },
      {
        company_name: 'DHL Express',
        logo: dhlExpress
      },
      {
        company_name: 'Dublin Airport',
        logo: dublinAirport
      },
      {
        company_name: 'Dublin City University',
        logo: dublincityuniverity
      },
      {
        company_name: 'Eirtech Aviation Services',
        logo: eirtech
      },
      {
        company_name: 'Emerald Airlines',
        logo: emeraldairlines
      },
      {
        company_name: 'Fingal Aviation Services',
        logo: fingalaviation
      },
      {
        company_name: 'FunFly Aerosports Aero Club',
        logo: funflyaero
      },
      {
        company_name: 'Galway Flying Club',
        logo: galway
      },
      {
        company_name: 'Inflight Dublin',
        logo: inflightdublin
      },
      {
        company_name: 'International Aerospace Coatings',
        logo: iac
      },
      {
        company_name: 'Leinster Gliding Centre',
        logo: leinster
      },
      {
        company_name: 'Manna',
        logo: manna
      },
      {
        company_name: 'Pilot Path',
        logo: pilotpath
      },
      {
        company_name: 'Retail inMotion',
        logo: retailinmotion
      },
      {
        company_name: 'SellUe',
        logo: sellue
      },
      {
        company_name: 'T-C-ALLIANCE',
        logo: tcalliance
      },
      {
        company_name: 'University of Limerick',
        logo: universitylimerick
      },
      {
        company_name: 'ReGEN Aviation ltd',
        logo: regen
      },
      {
        company_name: 'SOCOMORE IRELAND',
        logo: socomore
      },
      {
        company_name: 'Altair Advisory',
        logo: altairadvisory
      },
      {
        company_name: 'Falko',
        logo: falko
      },
    ];
    const companyLogoGa = [
      {
        company_name: 'AbbVie',
        logo: gaAbbvie
      },
      {
        company_name: 'An Post',
        logo: gaAnpost
      },
      {
        company_name: 'Analog Devices',
        logo: gaAnalog
      },
      {
        company_name: 'Citi',
        logo: gaCiti
      },
      {
        company_name: 'Coca-Cola HBC Ireland and Northern Ireland',
        logo: gaCocacola
      },
      {
        company_name: 'DAA PUBLIC LIMITED COMPANY',
        logo: gaDaa
      },
      {
        company_name: 'Deloitte',
        logo: gaDeloitte
      },
      {
        company_name: 'Ethos Engineering Limited',
        logo: gaEthos
      },
      {
        company_name: 'Eversheds Sutherland LLP',
        logo: gaEvershed
      },
      {
        company_name: 'Gas Networks Ireland',
        logo: gaGasNetworks
      },
      {
        company_name: 'Grant Thornton',
        logo: gaGrantThorthon
      },
      {
        company_name: 'Kildare and  Wicklow Education and Training Board',
        logo: gaKildare
      },
      {
        company_name: 'Kilkenny County Council',
        logo: gaKilkenky
      },
      {
        company_name: 'MSD Ireland',
        logo: gaMsd
      },
      {
        company_name: 'Offaly County Council',
        logo: gaComhaircle
      },
      {
        company_name: 'Pfizer Grange Castle',
        logo: gaPfizer
      },
      {
        company_name: 'Pfizer Irl Pharmaceuticals (Newbridge)',
        logo: gaPfizer
      },
      {
        company_name: 'Shareclub',
        logo: gaShareclub
      },
      {
        company_name: 'SSE Airtricity',
        logo: gaSse
      },
      {
        company_name: 'Takeda Ireland',
        logo: gaTakeda
      },
      {
        company_name: 'Tirlan',
        logo: gaTirlan
      },
      {
        company_name: 'Waterways Ireland',
        logo: gaWaterways
      },
      {
        company_name: 'Britvic Ireland',
        logo: gaBritvic
      },
      {
        company_name: 'Momenta Hub',
        logo: gaMomenta
      },
      {
        company_name: 'Arup',
        logo: gaArup
      },
      {
        company_name: 'Jacobs Engineer',
        logo: gaJacobs
      },
      {
        company_name: 'EcoMerit Limited',
        logo: gaEcomerit
      },
      {
        company_name: 'Change by Degrees',
        logo: gaChangeDegrees
      },
      {
        company_name: 'Change by degrees',
        logo: gaChangeDegrees
      },
      {
        company_name: 'Climate Change Advisory Council',
        logo: gaClimateChange
      },
      {
        company_name: 'Environmental Research Institute (ERI) - University College Cork',
        logo: gaERI
      },
      {
        company_name: 'CoolPlanet',
        logo: gaCoolplanet
      },
      {
        company_name: 'Emma Plant Veterniary',
        logo: gaEmma
      },
      {
        company_name: 'Emma Plant Veterinary',
        logo: gaEmma
      },
      {
        company_name: 'Ambie',
        logo: gaAmbie
      },
      {
        company_name: 'The panel',
        logo: gaPanel
      },
      {
        company_name: 'South West College',
        logo: gaSouthWest
      },
      {
        company_name: 'Broadlake',
        logo: gaBroadlake
      },
      {
        company_name: 'D/RES Properties',
        logo: gaDRes
      },
      {
        company_name: 'Longford County Council',
        logo: gaLongford
      },
      {
        company_name: 'Education for Sustainability',
        logo: gaEducation
      },
      {
        company_name: 'Zero Waste Scotland',
        logo: gaZeroWaste
      }
    ]
    const companyLogoIlta = [
      {
        company_name: 'Musgrave',
        logo: iltaMusgrave
      },
      {
        company_name: 'Ace Express Freight',
        logo: iltaAce
      },
      {
        company_name: 'BWG Foods',
        logo: iltaBwgFoods
      },
      {
        company_name: 'RSA',
        logo: iltaRsa
      },
      {
        company_name: 'Bunzl Ireland Limited',
        logo: iltaBunzl
      },
      {
        company_name: 'ISM Recruitment',
        logo: iltaIsm
      },
      {
        company_name: 'Crane Worldwide Logistics',
        logo: iltaCrane
      },
      {
        company_name: 'Technological University Dublin',
        logo: iltaTub
      },
      {
        company_name: 'Queen`s University Belfast',
        logo: iltaQub
      },
      {
        company_name: 'Facilitrain',
        logo: iltaFacilitrain
      },
      {
        company_name: 'Munster Technological University',
        logo: iltaMtu
      },
      {
        company_name: 'IPICS The Supply Chain Management Institute',
        logo: iltaIpics
      },
      {
        company_name: 'University College Dublin',
        logo: iltaUcd
      },
      {
        company_name: 'Irish Defence Forces',
        logo: iltaIdf
      },
      {
        company_name: 'Irish International Freight Association',
        logo: iltaIifa
      },
      {
        company_name: 'AIB',
        logo: iltaAib
      },
      {
        company_name: 'Green Tiger Ltd',
        logo: iltaGreenTiger
      },
      {
        company_name: 'Green Tiger',
        logo: iltaGreenTiger
      },
      {
        company_name: 'Midland Tyre Services Ltd',
        logo: iltaMilandTyre
      },
      {
        company_name: 'Dixon Transport Ltd',
        logo: iltaDixon
      },
      {
        company_name: 'Dempsey & Associates',
        logo: iltaDempsey
      },
      {
        company_name: 'Driving Force',
        logo: iltaDrivingForce
      },
      {
        company_name: 'Clare Distribution Services',
        logo: iltaClare
      },
      {
        company_name: 'Iarnród Éireann',
        logo: iltaIanrod
      },
      {
        company_name: 'Dublin Bus',
        logo: iltaDublinBus
      },
      {
        company_name: 'Excel Recruitment',
        logo: iltaExcel
      },
      {
        company_name: 'Road Truck Services',
        logo: iltaRoadTruckServices
      },
      {
        company_name: 'Transpoco',
        logo: iltaTranspoco
      },
      {
        company_name: 'Cubo',
        logo: iltaCubo
      },
      {
        company_name: 'cargo-partner',
        logo: iltaCargoPartner
      },
      {
        company_name: 'Sysco',
        logo: iltaSysco
      },
      {
        company_name: 'Department of Transport',
        logo: iltaDot
      },
      {
        company_name: 'Fleet DATA',
        logo: iltaFleetData
      },
      {
        company_name: 'Corduff Travel',
        logo: iltaCorduff
      },
      {
        company_name: 'Mcculla Ireland Ltd',
        logo: iltaMcculla
      },
      {
        company_name: 'WaterWipes UC',
        logo: iltaWaterwipes
      },
      {
        company_name: 'Derry Bros Shipping & Customs Clearance',
        logo: iltaDerryBros
      },
      {
        company_name: 'Spectrum',
        logo: iltaSpectrum
      },
      {
        company_name: 'Freightspeed Transport Ltd',
        logo: iltaFreightspeed
      },
      {
        company_name: 'West Pharmaceuticals',
        logo: iltaWestPharma
      },
      {
        company_name: 'Bus Éireann',
        logo: iltaBusEirann
      },
      {
        company_name: 'JACOBUS COACHES LTD.',
        logo: iltaJacobus
      },
    ];
    const companyLogoFM = [
      {
        company_name: 'KPMG',
        logo: fmKpmg
      },
      {
        company_name: 'Circle K',
        logo: fmCircle
      },
      {
        company_name: 'Ryans Cleaning',
        logo: fmRyans
      },
      {
        company_name: 'Glenveagh Properties plc',
        logo: fmOffice
      },
      {
        company_name: 'Salesforce Ireland',
        logo: fmSalesForce
      },
      {
        company_name: 'Office of Government Procurement',
        logo: fmOfficeGov
      },
      {
        company_name: 'BioMarin International Ltd',
        logo: fmBiomarin
      },
      {
        company_name: 'Mastertech',
        logo: fmMasterTech
      },
      {
        company_name: 'Uisce Éireann',
        logo: fmUisce
      },
      {
        company_name: 'MAGNA INTERNATIONAL AUTOLAUNCH IRELAND',
        logo: fmMagna
      },
      {
        company_name: 'Analog Devices',
        logo: fmAnalog
      },
      {
        company_name: 'King & Moffatt Connected',
        logo: fmKing
      },
      {
        company_name: 'Danone Nutricia',
        logo: fmDanone
      },
      {
        company_name: 'The Powerscourt Hotel Resort & Spa',
        logo: fmPowerscourt
      },
      {
        company_name: 'Elis',
        logo: fmElis
      },
      {
        company_name: 'An Post',
        logo: fmAnPost
      },
      {
        company_name: 'ATU Sligo',
        logo: fmAtu
      },
      {
        company_name: 'Bunzl Ireland',
        logo: fmBunzl
      },
      {
        company_name: 'eir',
        logo: fmEir
      },
      {
        company_name: 'G4S FM Ireland',
        logo: fmG4s
      },
      {
        company_name: 'SecuriGroup Services',
        logo: fmSecuriGroup
      },
      {
        company_name: 'Cronin Group',
        logo: fmCroninGroup
      },
      {
        company_name: 'Gilead',
        logo: fmGilead
      },
      {
        company_name: 'Hibernia Real Estate Group',
        logo: fmHibernia
      },
      {
        company_name: 'Macro Group',
        logo: fmMacro
      },
      {
        company_name: 'OASIS Group',
        logo: fmOasis
      },
      {
        company_name: 'BusinessRiver',
        logo: businessriver
      },
      {
        company_name: 'Apleona',
        logo: fmApleona
      },
      {
        company_name: 'Wyse Property Management',
        logo: fmWyse
      },
      {
        company_name: 'Riverside Mechanical',
        logo: fmRiverside
      },
      {
        company_name: 'Applegreen',
        logo: fmAppleGreen
      },
      {
        company_name: 'Horizon Property Support Ltd',
        logo: fmHps
      }
    ];
    const companyLogoMea = [
      {
        company_name: 'Ballymaguire Foods',
        logo: meaBallyMaguireFoods
      },
      {
        company_name: 'Biopax Ltd.',
        logo: meaBiopaxLtd
      },
      {
        company_name: 'Conroy Pallets',
        logo: meaOliverConroyPallets
      },
      {
        company_name: 'Danone',
        logo: meaDanoneNutricia
      },
      {
        company_name: 'Dell Technologies',
        logo: meaDellTechnologies
      },
      {
        company_name: 'Hanley Energy',
        logo: meaHanleyEnergy
      },
      {
        company_name: 'Harmac Medical',
        logo: meaHarmacMedical
      },
      {
        company_name: 'Janssen Sciences',
        logo: meaJanssenSciences
      },
      {
        company_name: 'Jones Engineering',
        logo: meaJonesEngineering
      },
      {
        company_name: 'Merck Group',
        logo: meaMerckGroup
      },
      {
        company_name: 'Merenda',
        logo: meaMeranda
      },
      {
        company_name: 'Suretank',
        logo: meaSuretank
      },
      {
        company_name: 'Takeda',
        logo: meaTakeda
      },
      {
        company_name: 'Viatris Little Island',
        logo: meaViatris
      },
      {
        company_name: 'VLE Therapeutics',
        logo: meaVle
      },
      {
        company_name: 'University College Dublin',
        logo: meaUniversityCollegeDublin
      },
      {
        company_name: 'UCC',
        logo: meaUcc
      },
      {
        company_name: 'URCM Ltd.',
        logo: meaUcc
      },
      {
        company_name: 'Mannok',
        logo: meaMannok
      },
      {
        company_name: 'Business River',
        logo: meaBusinessRiver
      },
      {
        company_name: 'Analog Devices',
        logo: meaAnalog
      },
    ];
    const companyLogoBaya = [
      {
        company_name: 'CWPA',
        logo: bayaCwpa
      },
      {
        company_name: 'Denis Byrne Architects',
        logo: bayaDenis
      },
      {
        company_name: 'dhb Architects',
        logo: bayaDhb
      },
      {
        company_name: 'Farren Architects',
        logo: bayaFarren
      },
      {
        company_name: 'Henry J Lyons',
        logo: bayaHenry
      },
      {
        company_name: 'MCA',
        logo: bayaMcadam
      },
      {
        company_name: 'McAdam Design',
        logo: bayaMcadam
      },
      {
        company_name: 'MosArt Architects',
        logo: bayaMosart
      },
      {
        company_name: 'Noyeks Newmans',
        logo: bayaNoyeks
      },
      {
        company_name: "O'Mahony Pike Architects",
        logo: bayaOmahony
      },
      {
        company_name: 'Pascall + Watson',
        logo: bayaPascall
      },
      {
        company_name: 'Perkins & Will',
        logo: bayaPerkins
      },
      {
        company_name: 'Perkins & Will',
        logo: bayaPerkins
      },
      {
        company_name: 'Wain Morehead Architects',
        logo: bayaWain
      },
      {
        company_name: 'Reddy Architecture and Urbanism Group',
        logo: bayaReddy
      },
      {
        company_name: 'Craftstudio Architecture',
        logo: bayaCraftstudio
      },
      {
        company_name: 'McCabe Architects',
        logo: bayaMccabe
      },
      {
        company_name: 'DMVF Architects',
        logo: bayaDmvf
      },
      {
        company_name: 'Crawford Architecture',
        logo: bayaCrawford
      },
      {
        company_name: 'Clancy Moore Architects',
        logo: bayaClancy
      },
      {
        company_name: 'Scott Tallon Walker Architects',
        logo: bayaStw
      },
      {
        company_name: 'Boon Edam',
        logo: bayaBoonedam
      },
      {
        company_name: 'McCauley Daye O\'Connell Architects',
        logo: bayaMccauley
      },
      {
        company_name: 'Gró.Works',
        logo: bayaGroWorks
      },
      {
        company_name: 'Business River',
        logo: businessriver
      },
      {
        company_name: 'TODD Architects',
        logo: bayaTodd
      },
      {
        company_name: 'SBI Contracts',
        logo: bayaSbi
      },
      {
        company_name: 'RKD',
        logo: bayaRkd
      },
      {
        company_name: 'Texspace',
        logo: bayaTexSpace
      },
      {
        company_name: 'BWDI',
        logo: bayaBwdi
      },
    ];
    const companyLogoHs = [
      {
        company_name: 'ISS Ireland',
        logo: hsIss
      },
      {
        company_name: 'Bretland Construction',
        logo: hsBretland
      },
      {
        company_name: 'O\'Reilly Group',
        logo: hsOreilly
      },
      {
        company_name: 'Skillko',
        logo: hsSkillko
      },
      {
        company_name: 'Beam Suntory Cooley Distillery',
        logo: hsCooley
      },
      {
        company_name: 'BREEDON',
        logo: hsBreedon
      },
      {
        company_name: 'Siemens Energy',
        logo: hsSiemens
      },
      {
        company_name: 'TU Dublin',
        logo: hsTUDublin
      },
      {
        company_name: 'Tritech Group',
        logo: hsTritech
      },
      {
        company_name: 'DHL Express Ireland',
        logo: hsDHL
      },
      {
        company_name: 'Sodexo Ireland Ltd',
        logo: hsSodexo
      },
      {
        company_name: 'United Hardware',
        logo: hsUH
      },
      {
        company_name: 'Surecom Network Solutions',
        logo: hsSurecom
      }
    ];
    const companyLogoEdu = [
      {
        company_name: 'CMIT',
        logo: eduCmit
      },
      {
        company_name: 'Finnesse Careers and Empowerment',
        logo: eduFinnesse
      },
      {
        company_name: 'IBAT College Dublin',
        logo: eduIbat
      },
      {
        company_name: 'Mary Immaculate College (LINC Programme)',
        logo: eduLincMic
      },
      {
        company_name: 'SSPC at Trinity College Dublin',
        logo: eduSspc
      },
      {
        company_name: 'Technological University of the Shannon (Midwest)',
        logo: eduTusst
      },
      {
        company_name: 'I-SWAP Research Team/ ATU&UU',
        logo: eduIswapatu
      },
      {
        company_name: 'University of Limerick (Global)',
        logo: eduUniversityul
      },
      {
        company_name: 'SETU',
        logo: eduSetu
      },
      {
        company_name: 'University of Limerick',
        logo: eduUniversityul
      },
      {
        company_name: 'University of Limerick - Academic Registry',
        logo: eduLimerickacademic
      },
      {
        company_name: 'Medtronic',
        logo: eduMedtronic
      },
      {
        company_name: 'University College Dublin',
        logo: eduUcd
      },
      {
        company_name: 'Middletwon Centre for Autism, Co. Armagh',
        logo: eduMiddletown
      },
      {
        company_name: 'Virtual Labs Team',
        logo: eduVirtuallabs
      },
      {
        company_name: 'IADT',
        logo: eduIadt
      },
      {
        company_name: 'Atlantic Technological University',
        logo: eduAtu
      },
      {
        company_name: 'MIC',
        logo: eduMic
      },
      {
        company_name: 'National College of Ireland',
        logo: eduNationalcollegeireland
      },
      {
        company_name: 'University of Limerick - HREDI',
        logo: eduUnilimerick
      },
      {
        company_name: 'Atlantic Technological University (Forward Emphasis International)',
        logo: eduForwardemphasis
      },
      {
        company_name: 'Atlantic Technological University Galway & Mayo',
        logo: eduAtu
      },
      {
        company_name: 'GoMappED',
        logo: eduGomapped
      },
      {
        company_name: 'Brickfield Education Labs',
        logo: eduBrickfield
      },
      {
        company_name: 'ETBI',
        logo: eduEtbi
      },
      {
        company_name: 'Dundalk Institute of Technology (DkIT)',
        logo: eduDundalk
      },
      {
        company_name: 'College of Further Education & Training, Mulgrave St. Campus, Limerick',
        logo: eduMulgravelimerick
      },
      {
        company_name: 'ATU Donegal',
        logo: eduAtu
      },
      {
        company_name: 'The Technological Higher Education Association',
        logo: eduThea
      },
      {
        company_name: 'Munster Technological University',
        logo: eduMunster
      },
    ];
    const companyLogoIaa = [
      {
        company_name: 'Axon Outsourcing Limited',
        logo: iaaAxon
      },
      {
        company_name: 'Big Red Cloud',
        logo: iaaBigred
      },
      {
        company_name: 'Capisso Ltd.',
        logo: iaaCapisso
      },
      {
        company_name: 'CPA Ireland',
        logo: iaaCpa
      },
      {
        company_name: 'CPA Ireland (Hotel)',
        logo: iaaCpa
      },
      {
        company_name: 'ETL Global',
        logo: iaaEtl
      },
      {
        company_name: 'Financefair',
        logo: iaaFinance
      },
      {
        company_name: 'Intax',
        logo: iaaIntax
      },
      {
        company_name: 'INTAX',
        logo: iaaIntax
      },
      {
        company_name: 'iplicit',
        logo: iaaIplicit
      },
      {
        company_name: 'Pinnacle Global Group',
        logo: iaaPinnacle
      },
      {
        company_name: 'Pinnacle Global Group`',
        logo: iaaPinnacle
      },
      {
        company_name: 'ptsb',
        logo: iaaPtsb
      },
      {
        company_name: 'Sage',
        logo: iaaSage
      },
      {
        company_name: 'Fenero',
        logo: iaaFenero
      },
      {
        company_name: 'Fexco',
        logo: iaaFexco
      },
      {
        company_name: 'Fitzgerald Power',
        logo: iaaFitz
      },
      {
        company_name: 'FPM',
        logo: iaaFpm
      },
      {
        company_name: 'Chartered Accountants Ireland',
        logo: iaaChartered
      },
      {
        company_name: 'Griffith Professional Accountancy',
        logo: iaaGriffith
      },
      {
        company_name: 'JPA Brenson Lawlor',
        logo: iaaJpa
      },
      {
        company_name: 'Lidl Ireland & Lidl Northern Ireland',
        logo: iaaLidl
      },
      {
        company_name: 'Maynooth University',
        logo: iaaMaynooth
      },
      {
        company_name: 'Satori Accounting',
        logo: iaaSatori
      },
      {
        company_name: 'Uisce Éireann',
        logo: iaaUisce
      },
      {
        company_name: 'University of Galway',
        logo: iaaUniversitygalway
      },
      {
        company_name: 'West Pharma - Global Financial Services',
        logo: iaaWest
      },
      {
        company_name: 'Xeinadin',
        logo: iaaXeinadin
      },
      {
        company_name: 'Chartered Accountants',
        logo: iaaChartered
      },
      {
        company_name: 'Atlantic Technological University',
        logo: iaaAtu
      },
      {
        company_name: 'Moore - Limerick',
        logo: iaaMoore
      },
      {
        company_name: 'Deloitte Ireland',
        logo: iaaDeloitte
      },
      {
        company_name: 'TU Dublin',
        logo: iaaTuDublin
      },
      {
        company_name: 'General Electric (GE)',
        logo: iaaGeneralElectric
      },
      {
        company_name: 'BDO',
        logo: iaaBdo
      },
      {
        company_name: 'Ctrl-s',
        logo: iaaCtrlS
      },
      {
        company_name: 'Dublin City University and Boston College',
        logo: iaaDublinCityUniversity
      },
      {
        company_name: 'AG Associates Accountants',
        logo: iaaAgAssociates
      },
      {
        company_name: 'TD Securities',
        logo: iaaTdSecurities
      },
      {
        company_name: 'Taxlink Accountants Ltd',
        logo: iaaTaxlink
      },
      {
        company_name: 'FM Accountants & Business Advisors',
        logo: iaaFmAccountants
      },
      {
        company_name: 'Floirin Corporate Finance Limited',
        logo: iaaFloirin
      },
      {
        company_name: 'Garvey Moran',
        logo: iaaGarveyMoran
      },
      {
        company_name: 'Glanbia PLC',
        logo: iaaGlanbia
      },
      {
        company_name: 'KPMG Ireland',
        logo: iaaKpmg
      },
      {
        company_name: 'MoneyCorp',
        logo: iaaMoneyCorp
      },
      {
        company_name: 'Accounting Technicians Ireland',
        logo: iaaAccountingTechniciansIreland
      },
      {
        company_name: 'Accounting Technicians Ireland (ATI)',
        logo: iaaAccountingTechniciansIreland
      },
      {
        company_name: 'ESB',
        logo: iaaEsb
      },
      {
        company_name: 'Shamrock',
        logo: iaaShamrock
      },
      {
        company_name: 'Mannings',
        logo: iaaMannings
      },
      {
        company_name: 'Medical Accountants',
        logo: iaaMedicalAccountants
      },
      {
        company_name: 'Davys',
        logo: iaaDavy
      },
      {
        company_name: 'JudgeChartered Accountant, Non Executive Director, Governance Consultant and Executive Coach',
        logo: iaaNonExecutive
      },
      {
        company_name: 'Sustainability Business Partner Freelance',
        logo: iaaOlivia
      },
      {
        company_name: 'Guest of Gail',
        logo: iaaCpa
      },
      {
        company_name: 'Bord na Móna',
        logo: iaaBordNaMona
      },
      {
        company_name: 'Mara Young Accountants',
        logo: iaaMaraYoung
      },
      {
        company_name: 'BusinessRiver',
        logo: iaaBusinessRiver
      },
    ];
    const companyLogoTaia = [
      {
        company_name: 'Chartered Accountants Ireland',
        logo: taiaChartered
      },
      {
        company_name: 'International Institute of Nutrition & Health (IINH)',
        logo: taiaHealth
      },
      {
        company_name: 'HealthTech Ireland',
        logo: taiaHealthtech
      },
      {
        company_name: 'Insurance Institute of Ireland',
        logo: taiaInsurance
      },
      {
        company_name: 'Learnovate',
        logo: taiaLearnovate
      },
      {
        company_name: 'Women In Finance Charter (IBEC)',
        logo: taiaWomen
      },
      {
        company_name: 'Association Innovation',
        logo: taiaInnovation
      },
      {
        company_name: 'CIPFA Northern Ireland',
        logo: taiaCipfa
      },
      {
        company_name: 'The Technical Higher Education Association (THEA)',
        logo: taiaThea
      },
      {
        company_name: 'Wovan Housing Association',
        logo: taiaWoven
      },
      {
        company_name: 'BusinessRiver',
        logo: taiaBusinessriver
      },
      {
        company_name: 'The Institute Of Advertising Practitioners In Ireland',
        logo: taiaIapi
      },
    ];
    const companyLogoOpex24 = [
      {
        company_name: 'Abbott Nutrition - Cootehill',
        logo: Opex24Abbott
      },
      {
        company_name: 'ALDI Ireland',
        logo: Opex24Aldi
      },
      {
        company_name: 'An Post Commerce',
        logo: Opex24AnpostCommerce
      },
      {
        company_name: 'APC & VLE Therapeutics',
        logo: Opex24ApcVle
      },
      {
        company_name: 'Bridgewater Construction',
        logo: Opex24Bridgewater
      },
      {
        company_name: 'BusinessRiver',
        logo: Opex24BusinessRiver
      },
      {
        company_name: 'BWG Foods',
        logo: Opex24BwgFoods
      },
      {
        company_name: 'ESB',
        logo: Opex24Esb
      },
      {
        company_name: 'Gleneagle INEC Arena',
        logo: Opex24Gleneagle
      },
      {
        company_name: 'Griffith College',
        logo: Opex24GriffithCollege
      },
      {
        company_name: 'Health Products Regulatory Authority',
        logo: Opex24Hpra
      },
      {
        company_name: 'ISS Ireland',
        logo: Opex24Iss
      },
      {
        company_name: 'LotusWorks',
        logo: Opex24LotusWorks
      },
      {
        company_name: 'Sensori FM',
        logo: Opex24Sensori
      },
      {
        company_name: 'Sodexo Ireland',
        logo: Opex24Sodexo
      },
      {
        company_name: 'UCC',
        logo: Opex24Ucc
      },
      {
        company_name: 'Comfort Keepers Homecare',
        logo: Opex24ComfortKeepers
      },
      {
        company_name: 'cargo- partner',
        logo: Opex24CargoPartner
      },
      {
        company_name: 'Enterprise Ireland',
        logo: Opex24EnterpriseIreland
      },
      {
        company_name: 'Atlantic Technological University (ATU) Sligo',
        logo: Opex24Atu
      },
      {
        company_name: 'Salesforce',
        logo: Opex24SalesForce
      },
      {
        company_name: 'Aurivo',
        logo: Opex24Aurivo
      },
      {
        company_name: 'DCU',
        logo: Opex24Dcu
      },
      {
        company_name: 'Dublin City University',
        logo: Opex24Dcu
      },
      {
        company_name: 'IMIL - Ipsen Manufacturing Ireland Limited',
        logo: Opex24Imil
      },
    ];
    const companyLogoWica = [
      {
        company_name: 'Ardmac',
        logo: WicaArdmac
      },
      {
        company_name: 'Bretland',
        logo: WicaBretland
      },
      {
        company_name: 'Dubh House Studio',
        logo: WicaDubh
      },
      {
        company_name: 'Elliott Group',
        logo: WicaElliott
      },
      {
        company_name: 'Ethos Engineering Ltd',
        logo: WicaEthos
      },
      {
        company_name: 'FKM Fit Out',
        logo: WicaFkm
      },
      {
        company_name: 'Helena McElmeel Architects Ltd',
        logo: WicaHelena
      },
      {
        company_name: 'Kirby Group Engineering',
        logo: WicaKirby
      },
      {
        company_name: 'Pennypop',
        logo: WicaPennypop
      },
      {
        company_name: 'RKD',
        logo: WicaRkd
      },
      {
        company_name: 'SIG WORKPLACE',
        logo: WicaSigWorkplace
      },
      {
        company_name: 'TU Dublin',
        logo: WicaTuDublin
      },
      {
        company_name: 'Walls Construction',
        logo: WicaWalls
      },
      {
        company_name: 'Lighthouse Construction Industry Charity',
        logo: WicaLighthouse
      },
      {
        company_name: 'Falcon Green',
        logo: WicaFalconGreen
      },
      {
        company_name: 'B.W.D.I',
        logo: WicaBwdi
      },
      {
        company_name: 'Business River',
        logo: WicaBusinessRiver
      },
      {
        company_name: 'EirEng Consulting Engineers Limited',
        logo: WicaEir
      },
      {
        company_name: 'Corgan',
        logo: WicaCargon
      },
      {
        company_name: 'Rockfon part of the Rockwool Group',
        logo: WicaRockfon
      },
      {
        company_name: 'Shay Murtagh',
        logo: WicaShay
      },
      {
        company_name: 'Property District',
        logo: WicaProperty
      },
      {
        company_name: 'University of Bath',
        logo: WicaUB
      },
      {
        company_name: 'KC Services',
        logo: WicaKC
      },
      {
        company_name: 'Workday',
        logo: WicaWorkday
      },
      {
        company_name: 'Humanscale',
        logo: WicaHumanScale
      },
      {
        company_name: 'Murphy',
        logo: WicaMurphy
      },
      {
        company_name: 'Wyse Property  Management Ltd',
        logo: WicaWyse
      },
      {
        company_name: 'Claraghlea Professional Services Ltd.',
        logo: WicaClaraglhea
      },
      {
        company_name: 'Considerate Constructors Scheme',
        logo: WicaCcs
      },
      {
        company_name: 'Joyce and Sons Headford Ltd',
        logo: WicaJoyces
      },
      {
        company_name: 'C + W O’Brien Architects',
        logo: WicaCw
      },
      {
        company_name: 'MCA Architects',
        logo: WicaMCAArchitects
      },
    ];
    const companyLogoLsia = [
      {
        company_name: 'APC & VLE Therapeutics Ltd',
        logo: LsiaApcVle
      },
      {
        company_name: 'Assay Genie Ltd',
        logo: LsiaAssayGenie
      },
      {
        company_name: 'Astellas Ireland Co. Ltd. (Kerry Plant)',
        logo: LsiaAstellas
      },
      {
        company_name: 'Biostor Ireland Ltd',
        logo: LsiaBiostor
      },
      {
        company_name: 'BusinessRiver',
        logo: LsiaBusinessRiver
      },
      {
        company_name: 'Deciphex',
        logo: LsiaDeciphex
      },
      {
        company_name: 'G&L Healthcare Advisors',
        logo: LsiaGlHealthAdvisors
      },
      {
        company_name: 'McArdle Skeath',
        logo: LsiaMcArdle
      },
      {
        company_name: 'Pfizer Healthcare Ireland',
        logo: LsiaPfizer
      },
      {
        company_name: 'PTC Therapeutics International Limited',
        logo: LsiaPtcTherapeutics
      },
      {
        company_name: 'Roche Diagnostics Limited',
        logo: LsiaRoche
      },
      {
        company_name: 'Safe Care Technologies',
        logo: LsiaSelfCare
      },
      {
        company_name: 'Vertigenius',
        logo: LsiaVertigenius
      },
      {
        company_name: 'Westbourne IT Global Services',
        logo: LsiaWestbourne
      },
      {
        company_name: 'IPU',
        logo: lsiaIPU
      },
      {
        company_name: 'Healthy Ireland Local Government',
        logo: lsiaHealthyIre
      },
      {
        company_name: 'Cundall',
        logo: lsiaCundall
      },
      {
        company_name: 'VHA Architects',
        logo: lsiaVha
      },
      {
        company_name: 'CPC Project Services',
        logo: lsiaCpc
      },
      {
        company_name: 'SK biotek Ireland',
        logo: lsiaSkBiotek
      },
      {
        company_name: 'MKO',
        logo: lsiaMko
      },
      {
        company_name: 'RKD',
        logo: lsiaRkd
      },
      {
        company_name: 'Controlled Environments Company',
        logo: lsiaCec
      },
      {
        company_name: 'Ellab',
        logo: lsiaEllab
      },
      {
        company_name: 'Enterprise Ireland',
        logo: lsiaEnterprise
      },
      {
        company_name: 'X-Bolt Orthopedics',
        logo: lsiaXBolt
      },
      {
        company_name: 'Royal College of Surgeons in Ireland',
        logo: lsiaRcsi
      },
      {
        company_name: 'Unispace Life Sciences',
        logo: lsiaUnispace
      },
      {
        company_name: 'ISPE Ireland Affiliate',
        logo: lsiaIspe
      },
      {
        company_name: 'MSD',
        logo: lsiaMsd
      },
      {
        company_name: 'TU Dublin',
        logo: lsiaTuDublin
      },
      {
        company_name: 'Sterling Engineering',
        logo: lsiaEngineersIre
      },
      {
        company_name: 'Enhanced Information Solutions',
        logo: lsiaEnhanced
      },
      {
        company_name: 'RCSI University of Medicine and Health Sciences',
        logo: lsiaRcsiUmhs
      },
    ];
    const companyLogoDta = [
      {
        company_name: 'Bridgewater Construction',
        logo: dtaBridgewater
      },
      {
        company_name: 'Danske Bank',
        logo: dtaDanskeBank
      },
      {
        company_name: 'ESB Networks',
        logo: dtaEsbNetworks
      },
      {
        company_name: 'BusinessRiver',
        logo: dtaBusinessRiver
      },
      {
        company_name: 'Complete Laboratory Solutions',
        logo: dtaCls
      },
      {
        company_name: 'Goodbodys',
        logo: dtaGoodbodys
      },
      {
        company_name: 'GS1 Ireland',
        logo: dtaGs1
      },
      {
        company_name: 'Dublin City University Business School',
        logo: dtaDcuBusinessSchool
      },
      {
        company_name: 'Aleph',
        logo: dtaAleph
      },
      {
        company_name: 'Eviden',
        logo: dtaEviden
      },
      {
        company_name: 'EPAM Systems',
        logo: dtaEpam
      },
      {
        company_name: 'Clanwilliam',
        logo: dtaClanwilliam
      },
      {
        company_name: 'Maynooth University',
        logo: dtaMaynoothUniversity
      },
      {
        company_name: 'Syncrophi Systems',
        logo: dtaSyncrophi
      },
      {
        company_name: 'MSD',
        logo: dtaMsd
      },
      {
        company_name: 'Accuscience',
        logo: dtaAccuscience
      },
      {
        company_name: 'HSE CARE Virtual Ward',
        logo: dtaHse
      },
      {
        company_name: 'Merck',
        logo: dtaMerck
      },
      {
        company_name: 'Core Optimisation',
        logo: dtaCore
      },
      {
        company_name: 'Redzinc',
        logo: dtaRedZinc
      },
      {
        company_name: 'VP Parkinson’s Europe',
        logo: dtaVpParkinsons
      },
      {
        company_name: 'Stacks Pharmacies/Digicare',
        logo: dtaStacksPharmacy
      },
      {
        company_name: 'University of Limerick',
        logo: dtaUniversityLimerick
      },
    ];

    const companyLogoAiaUk = [
      {
        company_name: 'Chapman Freeborn Airchartering Ltd',
        logo: aiaUkChapman
      },
      {
        company_name: 'Lisi Aerospace',
        logo: aiaUkLisi
      },
      {
        company_name: 'Path2Pilot',
        logo: aiaUkPath2pilot
      },
      {
        company_name: 'Global ATS',
        logo: aiaUkglobalAts
      },
      {
        company_name: 'Micro Nav Ltd',
        logo: aiaUkMicronav
      },
      {
        company_name: 'British Airways',
        logo: aiaUkBritishAirways
      },
      {
        company_name: 'easyJet',
        logo: aiaUkEasyJet
      },
      {
        company_name: 'Spincraft ETG',
        logo: aiaUkSpinCraft
      },
      {
        company_name: 'Caerdav',
        logo: aiaUkCaerdav
      },
      {
        company_name: 'dnata',
        logo: aiaUkDnata
      },
      {
        company_name: 'Luxaviation Group',
        logo: aiaUkLuxaviation
      },
      {
        company_name: 'Titan Airways',
        logo: aiaUkTitan
      },
      {
        company_name: 'Jet2',
        logo: aiaUkJet2
      },
      {
        company_name: 'Imparta',
        logo: aiaUkImparta
      },
      {
        company_name: 'Business River',
        logo: aiaUkBusinessRiver
      },
      {
        company_name: 'Swissport (Aspire Airport Lounges)',
        logo: aiaUkSwissport
      },
      {
        company_name: 'Certo Aerospace Ltd',
        logo: aiaUkCertoAerospace
      },
      {
        company_name: 'Buckinghamshire New University',
        logo: aiaUkBuckinghamshire
      },
      {
        company_name: 'De Montfort University',
        logo: aiaUkDeMontfort
      },
      {
        company_name: 'University of South Wales',
        logo: aiaUkUniSouthWales
      },
      {
        company_name: 'Evolito',
        logo: aiaUkEvolito
      },
      {
        company_name: 'University of the Highlands and Islands',
        logo: aiaUkUniHigher
      },
      {
        company_name: 'University of West London',
        logo: aiaUkUniWestLondon
      },
      {
        company_name: 'Leading Edge Aviation',
        logo: aiaUkLeadingEdge
      },
      {
        company_name: 'City University London',
        logo: aiaUkCityUniLondon
      },
      {
        company_name: 'Midland Aerospace',
        logo: aiaUkMidlandAerospace
      },
      {
        company_name: 'University of Sunderland',
        logo: aiaUkUniSunderland
      },
      {
        company_name: 'easyJet (Imparta)',
        logo: aiaUkImparta
      },
      {
        company_name: 'Resource Group',
        logo: aiaUkResourceGroup
      },
    ];

    const companyFoaUk = [
      {
        company_name: 'Morgan Lovell',
        logo: foaUkMorgan
      },
      {
        company_name: 'Like Architects',
        logo: foaUkLike
      },
      {
        company_name: 'Elliott Group',
        logo: foaUkElliot
      },
      {
        company_name: 'BusinessRiver',
        logo: foaUkBusinessRiver
      },
      {
        company_name: 'ZIP Water',
        logo: foaUkZipWater
      },
      {
        company_name: 'PMP Consultants',
        logo: foaUkElliot
      },
      {
        company_name: 'Amicus',
        logo: foaUkElliot
      },
      {
        company_name: 'The Belfry',
        logo: foaUkElliot
      },
      {
        company_name: 'Tri Prop',
        logo: foaUkElliot
      },
      {
        company_name: 'Rund',
        logo: foaUkElliot
      },
      {
        company_name: 'Ridge',
        logo: foaUkElliot
      },
      {
        company_name: 'Total Fitout',
        logo: foaUkElliot
      },
      {
        company_name: 'Buildspace Group Limited',
        logo: foaUkBuildSpace
      },
      {
        company_name: 'Mamou-Mani ltd',
        logo: foaUkMamouMani
      },
      {
        company_name: 'Studio Seilern Architects',
        logo: foaUkStudioSeilern
      },
      {
        company_name: 'LUMEN Fit-out Ltd.',
        logo: foaUkLumen
      },
      {
        company_name: 'Dawnvale Group',
        logo: foaUkDawnvale
      },
      {
        company_name: 'SpaceInvader Design',
        logo: foaUkSpaceInvader
      },
    ];

    const companyPiaUk = [
      {
        company_name: 'SkyCell',
        logo: piaUkSkyCell
      },
      {
        company_name: 'AstraZeneca',
        logo: piaUkAstrazeca
      },
      {
        company_name: 'Sanofi',
        logo: piaUkSanofi
      },
      {
        company_name: 'Inspired Pharma Training Ltd',
        logo: piaUkInspiredPharma
      },
      {
        company_name: 'Astex Pharmaceuticals',
        logo: piaUkAstex
      },
      {
        company_name: 'Ellab',
        logo: piaUkEllab
      },
      {
        company_name: 'Camida',
        logo: piaUkCamida
      },
      {
        company_name: 'Kuehne+Nagel',
        logo: piaUkKuehneNagel
      },
      {
        company_name: 'Unispace Life Sciences',
        logo: piaUkUnispace
      },
      {
        company_name: 'Cpl',
        logo: piaUkCpl
      },
      {
        company_name: 'Cognizant',
        logo: piaUkCognizant
      },
      {
        company_name: 'Ecolab',
        logo: piaUkEcolab
      },
      {
        company_name: 'Bionow',
        logo: piaUkBionow
      },
      {
        company_name: 'Bouygues Energies & Services',
        logo: piaUkBouygues
      },
      {
        company_name: 'CSL Seqirus',
        logo: piaUkSeqirus
      },
      {
        company_name: 'Ochre Bio',
        logo: piaUkOchrebio
      },
      {
        company_name: 'Manchester Metropolitan University',
        logo: piaUkManchester
      },
      {
        company_name: 'Takeda',
        logo: piaUkTakeda
      },
      {
        company_name: 'Novai',
        logo: piaUkNovai
      },
      {
        company_name: 'Besins Healthcare',
        logo: piaUkBesins
      },
      {
        company_name: 'Upperton Pharma Solutions',
        logo: piaUkUpperton
      },
      {
        company_name: 'AstraZeneca Young Health Programme',
        logo: piaUkAstrazeca
      },
      {
        company_name: 'CNX Therapeutics',
        logo: piaUkCnx
      },
      {
        company_name: 'Paradigm Shift',
        logo: piaUkParadigmShift
      },
      {
        company_name: 'BusinessRiver',
        logo: piaUkBusinessRiver
      },
      {
        company_name: 'CBRE',
        logo: piaUkCbre
      },
      {
        company_name: 'Bulb Laboratories',
        logo: piaUkBulbLab
      },
      {
        company_name: 'Woodbourne Group',
        logo: piaUkWg
      },
      {
        company_name: 'AviadoBio',
        logo: piaUkAviadoBio
      },
      {
        company_name: 'Bidwells, Cambridge',
        logo: piaUkBidwells
      },
      {
        company_name: 'GeneFirst',
        logo: piaUkGenefirst
      },
      {
        company_name: 'Carter Jonas',
        logo: piaUkCarterJonas
      },
      {
        company_name: 'Fingerpost Consulting Ltd',
        logo: piaUkFingerPost
      },
      {
        company_name: 'Seqirus',
        logo: piaUkCSLSeqirus
      },
      {
        company_name: 'QuickSTAT',
        logo: piaUkQuickStat
      },
      {
        company_name: 'Micro-Bio Ireland Ltd',
        logo: piaUkMicroBio
      },
      {
        company_name: 'University of East Anglia',
        logo: piaUkUniEastAnglia
      },
      {
        company_name: 'ISPE UK , Mace',
        logo: piaUkUniIspe
      },
      {
        company_name: 'University of Reading',
        logo: piaUkUniversityOfReading
      },
      {
        company_name: 'National Pharmacy Association',
        logo: piaUkNPA
      },
      {
        company_name: 'Matt Moran Consulting',
        logo: piaUkMattMoran
      },
      {
        company_name: 'Emirates',
        logo: piaUkEmirates
      },
      {
        company_name: 'Delta',
        logo: piaUkDelta
      },
      {
        company_name: 'ReAgent',
        logo: piaUkReAgent
      },
      {
        company_name: 'Accord Healthcare',
        logo: piaUkAccordHealthcare
      },
      {
        company_name: 'WSP',
        logo: piaUkWsp
      },
      {
        company_name: 'Dawson Group',
        logo: piaUkDawsonGroup
      },
      {
        company_name: 'Fujifilm Diosynth Biotechnologies',
        logo: piaUkFujifilm
      },
      {
        company_name: 'SGS Vitrology',
        logo: piaUkSgs
      },
      {
        company_name: 'Catalent',
        logo: piaUkCatalent
      },
      {
        company_name: 'Bristol Myers Squibb',
        logo: piaUkBristol
      },
      {
        company_name: 'GSK',
        logo: piaUkGsk
      },
      {
        company_name: 'West Midlands Combined Authority',
        logo: piaUkWestMidlands
      },
      {
        company_name: 'Track Real Estate',
        logo: piaUkTrack
      },
      {
        company_name: 'Department for Business & Trade',
        logo: piaUkDepartment
      },
      {
        company_name: 'Lonza',
        logo: piaUkLonza
      },
      {
        company_name: 'Pharmig',
        logo: piaUkPharmig
      },
      {
        company_name: 'OCP Consultants',
        logo: piaUkOcp
      },
      {
        company_name: 'Catapult',
        logo: piaUkCatapult
      },
      {
        company_name: 'Plan International',
        logo: piaUkPlanInternational
      },
      {
        company_name: 'CEVA Logistics',
        logo: piaUkCeva
      },
      {
        company_name: 'AMC Consulting',
        logo: piaUkEcolab
      },
    ];

    const companyIsa = [
      {
        company_name: 'PwC Ireland',
        logo: isaPwc
      },
      {
        company_name: 'Conference Partners International',
        logo: isaCpi
      },
      {
        company_name: '123.ie',
        logo: isa123ie
      },
      {
        company_name: 'Londis',
        logo: isaLondis
      },
      {
        company_name: 'Mediahuis',
        logo: isaMediahuis
      },
      {
        company_name: 'Gala Retail',
        logo: isaGalaTaker
      },
      {
        company_name: 'CyberSafeKids',
        logo: isaCyberSafeKids
      },
      {
        company_name: 'The Sunday Times',
        logo: isaTheSundayTimes
      },
      {
        company_name: 'Aer Lingus',
        logo: isaAerLingus
      },
      {
        company_name: 'RTÉ',
        logo: isaRte
      },
      {
        company_name: 'BusinessRiver',
        logo: isaBusinessRiver
      },
      {
        company_name: "Horse Racing Ireland & O'Driscolls Irish Whiskey",
        logo: isaHorseRacing
      },
      {
        company_name: "Grooveyard",
        logo: isaGrooveyard
      },
      {
        company_name: "Accenture",
        logo: isaAccenture
      },
      {
        company_name: "KICK",
        logo: isaKick
      },
      {
        company_name: "The Concept Partnership",
        logo: isaConceptPartnership
      },
      {
        company_name: "Electric Ireland",
        logo: isaElectricIreland
      },
      {
        company_name: "IntoMedia Group",
        logo: isaIntoMedia
      },
      {
        company_name: "DIFF",
        logo: isaDiff
      },
      {
        company_name: "News Ireland",
        logo: isaNewsIreland
      },
      {
        company_name: "News UK & Ireland",
        logo: isaNewsUkIreland
      },
      {
        company_name: "Mindshare",
        logo: isaMindshare
      },
      {
        company_name: "Cork International Film Festival",
        logo: isaCork
      },
      {
        company_name: "Indeed.com",
        logo: isaIndeed
      },
      {
        company_name: "FUEL",
        logo: isaFuel
      },
      {
        company_name: "Kia Ireland",
        logo: isaKia
      },
      {
        company_name: "Sports Direct",
        logo: isaSportsDirect
      },
      {
        company_name: "PR1",
        logo: isaPr1
      },
      {
        company_name: "Pieta",
        logo: isaPieta
      },
      {
        company_name: "Irish Life",
        logo: isaIrishlife
      },
      {
        company_name: "KPMG Ireland",
        logo: isaKpmg
      },
      {
        company_name: "AIG",
        logo: isaAig
      },
      {
        company_name: "Sport for Business",
        logo: isaSportForBusiness
      },
    ];

    const companyBeaa = [
      {
        company_name: 'Uisce Éireann',
        logo: beaaUisce
      },
      {
        company_name: 'Bord Na Móna',
        logo: beaaBordNaMona
      },
      {
        company_name: 'PRL Holdings ULC',
        logo: beaaPrl
      },
      {
        company_name: 'Johnson & Johnson',
        logo: beaaJohnson
      },
      {
        company_name: 'Gilead Sciences',
        logo: beaaGilead
      },
      {
        company_name: 'Gilead Science Ireland UC',
        logo: beaaGilead
      },
      {
        company_name: 'Castleknock Hotel',
        logo: beaaCastleKnock
      },
      {
        company_name: 'Ashleigh Farms',
        logo: beaaAshleigh
      },
      {
        company_name: 'Royal College of Surgeons Ireland (RCSI)',
        logo: beaaRcsi
      },
      {
        company_name: 'Connacht GAA',
        logo: beaaConnacht
      },
      {
        company_name: 'Pfizer Newbridge',
        logo: beaaPfizer
      },
      {
        company_name: 'Bord Iascaigh Mhara',
        logo: beaaBord
      },
      {
        company_name: 'BusinessRiver',
        logo: beaaBusinessRiver
      },
      {
        company_name: 'Lagan Energy Engineering',
        logo: beaaLagan
      },
      {
        company_name: 'Dublin City University',
        logo: beaaDcu
      },
      {
        company_name: 'Fort Energy',
        logo: beaaFortEnergy
      },
      {
        company_name: 'Digren Energy Management',
        logo: beaaDigren
      },
      {
        company_name: 'Technological University Dublin',
        logo: beaaTechUniDublin
      },
      {
        company_name: 'Pinergy',
        logo: beaaPinergy
      },
      {
        company_name: 'Futurefit',
        logo: beaaFutureFit
      },
      {
        company_name: 'Vantage Towers (Ireland)',
        logo: beaaVantageTower
      },
      {
        company_name: 'Analog Devices',
        logo: beaaAnalogDevices
      },
      {
        company_name: 'Takeda Ireland Ltd',
        logo: beaaTakeda
      },
      {
        company_name: 'Climeaction',
        logo: beaaClimeAction
      },
      {
        company_name: 'Freightspeed Transport ltd',
        logo: beaaFreightspeed
      },
      {
        company_name: 'daa',
        logo: beaaDaa
      },
      {
        company_name: 'Dairygold Food Ingredients',
        logo: beaaDairygold
      },
    ];

    const companyPha = [
      {
        company_name: 'McCarthy Insurance Group',
        logo: phaMccarthy
      },
      {
        company_name: 'Belfast Skin Clinic',
        logo: phaBelfast
      },
      {
        company_name: 'Embrace Community Services Ltd',
        logo: phaEmbrace
      },
      {
        company_name: 'New Vision Healthcare Ltd.',
        logo: phaNewVision
      },
      {
        company_name: 'St John of God Hospital',
        logo: phaStJohn
      },
      {
        company_name: 'BusinessRiver',
        logo: phaBusinessRiver
      },
      {
        company_name: 'Vhi Hospital@Home',
        logo: phaVhi
      },
      {
        company_name: 'Medforce',
        logo: phaMedforce
      },
      {
        company_name: 'Pembroke Dental',
        logo: phaPembrokeDental
      },
      {
        company_name: 'Truly Dental',
        logo: phaTruly
      },
      {
        company_name: 'TowerView',
        logo: phaTowerview
      },
      {
        company_name: 'Tallaght University Hospital',
        logo: phaTallaght
      },
      {
        company_name: 'formerly HSE',
        logo: phaHse
      },
      {
        company_name: 'The New You Clinic',
        logo: phaTheNewYou
      },
      {
        company_name: 'School of Nursing & Midwifery',
        logo: phaRCSI
      },
      {
        company_name: 'HealthCheck@Work Enhanced / Vhi Health and Wellness Coaching and LiveWell Team',
        logo: phaVhi
      },
      {
        company_name: 'Optilase Eye Clinic',
        logo: phaOptilase
      },
    ];


    const companyHr = [
      {
        company_name: 'MDE Installations',
        logo: hrMde
      },
      {
        company_name: 'Fragomen',
        logo: hrFragomen
      },
      {
        company_name: 'spunout',
        logo: hrSpunout
      },
      {
        company_name: 'Britvic Ireland',
        logo: hrBritvic
      },
      {
        company_name: 'DeCare',
        logo: hrDecare
      },
      {
        company_name: 'Bord na Móna',
        logo: hrBordnamona
      },
      {
        company_name: 'Digital Marketing Institute',
        logo: hrDigitalMarketing
      },
      {
        company_name: 'Lidl',
        logo: hrLidl
      },
      {
        company_name: 'Depaul',
        logo: hrDepaul
      },
      {
        company_name: 'The Doyle Collection',
        logo: hrTheDoyle
      },
      {
        company_name: 'Uisce Éireann',
        logo: hrUisce
      },
      {
        company_name: 'Irish Life',
        logo: hrIrishlife
      },
      {
        company_name: 'BusinessRiver',
        logo: hrBusinessRiver
      },
      {
        company_name: 'Total Health Cover',
        logo: hrTotalHealthcare
      },
      {
        company_name: 'TELUS International Ireland',
        logo: hrTelus
      },
      {
        company_name: 'Clanwilliam',
        logo: hrClanwilliam
      },
      {
        company_name: 'Dublin City University',
        logo: hrDcu
      },
      {
        company_name: 'Sodexo Corporate Services',
        logo: hrSodexo
      },
      {
        company_name: 'Chadwicks Group',
        logo: hrChadwicks
      },
      {
        company_name: 'Excel Recruitment',
        logo: hrExcel
      },
      {
        company_name: 'HRLocker',
        logo: hrHRLocker
      },
      {
        company_name: 'One4all',
        logo: hrOne4all
      },
      {
        company_name: 'HSE',
        logo: hrHse
      },
      {
        company_name: 'AIB',
        logo: hrAib
      },
      {
        company_name: 'Trigon Hotels',
        logo: hrTrigonHotels
      },
    ];


    const companyEea = [
      {
        company_name: 'Ethos Engineering Ltd',
        logo: eeaEthos
      },
      {
        company_name: 'Prodieco - Advanced Engineering Solutions',
        logo: eeaProdieco
      },
      {
        company_name: 'Wuxi Biologics',
        logo: eeaWuxi
      },
      {
        company_name: 'Hanley Energy',
        logo: eeaHanleyEnergy
      },
      {
        company_name: 'AECOM',
        logo: eeaAecom
      },
      {
        company_name: 'Cliniva',
        logo: eeaCliniva
      },
      {
        company_name: 'Galway City Council',
        logo: eeaGalway
      },
      {
        company_name: 'Cardinal Health Ireland Manufacturing Ltd',
        logo: eeaCardinal
      },
      {
        company_name: 'IN2 Engineering',
        logo: eeaIn2
      },
      {
        company_name: 'EPS Group',
        logo: eeaEps
      },
      {
        company_name: 'Ad-vance Engineering Lisburn Ltd',
        logo: eeaAdvance
      },
      {
        company_name: 'BusinessRiver',
        logo: eeaBusinessRiver
      },
      {
        company_name: 'Environmental Products and Services Ltd.',
        logo: eeaEnvironmental
      },
      {
        company_name: 'B-Fluid Limited',
        logo: eeaBFluid
      },
      {
        company_name: 'Donnelly Civil Engineering',
        logo: eeaDonnelly
      },
      {
        company_name: 'Punch Consulting Engineers',
        logo: eeaPunch
      },
      {
        company_name: 'Atlantic Technological University',
        logo: eeaAtu
      },
      {
        company_name: 'JJ Rhatigan',
        logo: eeaJjRhatigan
      },
      {
        company_name: 'University of Limerick',
        logo: eeaUniLimerick
      },
      {
        company_name: 'MTU',
        logo: eeaMtu
      },
    ];

    const companyNpa = [
      {
        company_name: 'Almac Clinical Services',
        logo: npaAlmac
      },
      {
        company_name: 'McKesson Ireland',
        logo: npaMckesson
      },
      {
        company_name: 'daa PLC',
        logo: npaDaa
      },
      {
        company_name: 'Viatris',
        logo: npaViatirs
      },
      {
        company_name: 'Verizon',
        logo: npaVerizon
      },
      {
        company_name: 'Bulmers Ltd',
        logo: npaBulmers
      },
      {
        company_name: 'Dublin Bus',
        logo: npaDublinBus
      },
      {
        company_name: 'Ivalua',
        logo: npaIvalua
      },
      {
        company_name: 'Kerry',
        logo: npaKerry
      },
      {
        company_name: 'Elliott Group',
        logo: npaElliot
      },
      {
        company_name: 'Irish Defence Forces',
        logo: npaIrishDefence
      },
      {
        company_name: 'BusinessRiver',
        logo: npaBusinessRiver
      },
      {
        company_name: 'GreenIT',
        logo: npaGreenIt
      },
      {
        company_name: 'Greenville Procurement',
        logo: npaGreenville
      },
      {
        company_name: 'KPMG',
        logo: npaKpmg
      },
      {
        company_name: 'University of Limerick',
        logo: npaUniversityLimerick
      },
      {
        company_name: 'DCU',
        logo: npaDcu
      },
      {
        company_name: 'EY',
        logo: npaEy
      },
      {
        company_name: 'Compass Group Ireland',
        logo: npaCompassIreland
      },
      {
        company_name: 'Natus Medical Inc.',
        logo: npaNatus
      },
      {
        company_name: 'Avantor',
        logo: npaAvantor
      },
      {
        company_name: 'Dublin City University',
        logo: npaDcu
      },
      {
        company_name: 'PWC Ireland',
        logo: npaPwcIreland
      },
      {
        company_name: 'ELA Consulting Ltd',
        logo: npaEla
      },
      {
        company_name: 'Future Planet',
        logo: npaFuturePlanet
      },
      {
        company_name: 'Procurement Transformation Institute, powered by Future Planet',
        logo: npaPti
      },
      {
        company_name: 'Circular Computing',
        logo: npaCircularComputing
      },
      {
        company_name: 'Irish Distillers',
        logo: npaIrishDistillers
      },
      {
        company_name: 'Coillte',
        logo: npaCoillte
      },
      {
        company_name: 'Keelings Retail UC',
        logo: npaKeelings
      },
    ];


    const companyPia = [
      {
        company_name: 'Wuxi Biologics Ireland',
        logo: piaWuxi
      },
      {
        company_name: 'Asgard Cleanroom Solutions',
        logo: piaAsgard
      },
      {
        company_name: 'Asgard',
        logo: piaAsgard
      },
      {
        company_name: 'Lennox',
        logo: piaLennox
      },
      {
        company_name: 'Pfizer Ringaskiddy',
        logo: piaPfizer
      },
      {
        company_name: 'Pfizer - Clinical Manufacturing Facility',
        logo: piaPfizer
      },
      {
        company_name: 'Sanofi Waterford',
        logo: piaSanofi
      },
      {
        company_name: 'Micro-Bio Ireland Ltd',
        logo: piaMicrobio
      },
      {
        company_name: 'Micro-Bio',
        logo: piaMicrobio
      },
      {
        company_name: 'Camida',
        logo: piaCamida
      },
      {
        company_name: 'Cognizant',
        logo: piaCognizant
      },
      {
        company_name: 'Acorn Regulatory',
        logo: piaAcorn
      },
      {
        company_name: 'Alexion',
        logo: piaAlexion
      },
      {
        company_name: 'Viatris Damastown',
        logo: piaViatris
      },
      {
        company_name: 'Viatris',
        logo: piaViatris
      },
      {
        company_name: 'Nuvalez Consulting Limited',
        logo: piaNuvalez
      },
      {
        company_name: 'Cytiva™',
        logo: piaCytiva
      },
      {
        company_name: 'BioPharmaChem Skillnet',
        logo: piaBiopharmaChemSkillnet
      },
      {
        company_name: 'Biostór Ireland',
        logo: piaBiostor
      },
      {
        company_name: 'Navi Group',
        logo: piaNaviGroup
      },
      {
        company_name: 'Wolf Medical',
        logo: piaWolfMedical
      },
      {
        company_name: 'Cliniva',
        logo: piaCliniva
      },
      {
        company_name: 'QuickSTAT',
        logo: piaQuickStat
      },
      {
        company_name: 'Kuehne + Nagel',
        logo: piaKuehneNagel
      },
      {
        company_name: 'Matt Moran Consulting',
        logo: piaMattMoran
      },
      {
        company_name: 'TU Dublin',
        logo: piaTuDublin
      },
      {
        company_name: 'RCSI University of Medicine and Health Sciences',
        logo: piaRcsiUhms
      },
      {
        company_name: 'University College Cork',
        logo: piaUcc
      },
      {
        company_name: 'Eurofins',
        logo: piaEurofins
      },
      {
        company_name: 'Cpl Resources',
        logo: piaCplResources
      },
      {
        company_name: 'Ellab',
        logo: piaEllab
      },
      {
        company_name: 'KPMG',
        logo: piaKpmg
      },
      {
        company_name: 'BOC Gases Ireland',
        logo: piaBoc
      },
      {
        company_name: 'Exyte',
        logo: piaExyte
      },
      {
        company_name: 'RKD',
        logo: piaRkd
      },
      {
        company_name: 'Controlled Environments Company',
        logo: piaCec
      },
      {
        company_name: 'Almac Group',
        logo: piaAlmac
      },
      {
        company_name: 'SkyCell',
        logo: piaSkycell
      },
      {
        company_name: 'Biocair',
        logo: piaBiocair
      },
      {
        company_name: 'Ecolab',
        logo: piaEcolab
      },
      {
        company_name: 'OBW Technologies',
        logo: piaObwTech
      },
      {
        company_name: 'Unispace Life Sciences',
        logo: piaUnispace
      },
      {
        company_name: 'MSD Ireland',
        logo: piaMsdIreland
      },
      {
        company_name: 'Amgen',
        logo: piaAmgen
      },
      {
        company_name: 'Takeda Ireland',
        logo: piaTakeda
      },
      {
        company_name: 'MIAS Pharma',
        logo: piaMiaspharma
      },
      {
        company_name: 'AbbVie North Dublin',
        logo: piaAbbvie
      },
      {
        company_name: 'FutureNeuro Research Centre',
        logo: piaFutureNeuro
      },
      {
        company_name: 'Eli Lilly Cork',
        logo: piaLilly
      },
      {
        company_name: 'Life Science Fusion Ireland & Blynksolve',
        logo: piaLifeScience
      },
      {
        company_name: 'Hanley Calibration',
        logo: piaHanley
      },
      {
        company_name: 'IPSEN',
        logo: piaIpsen
      },
      {
        company_name: 'NIBRT - CONCEPT',
        logo: piaNibrtconcept
      },
      {
        company_name: 'Astellas Ireland - Kerry Plant',
        logo: piaAstellas
      },
      {
        company_name: 'BusinessRiver',
        logo: piaBusinessRiver
      },
      {
        company_name: 'Synergy Security Solutions',
        logo: piaSynergySecurity
      },
      {
        company_name: 'Edge ConneX',
        logo: piaEdgeConnex
      },
      {
        company_name: 'The Quality Risk Management Institute (QRMi)',
        logo: piaQrmi
      },
      {
        company_name: 'The Pharmaceutical Managers Institute (the PMI)',
        logo: piaPmi
      },
      {
        company_name: 'BiopharmaChem Ireland',
        logo: piaBiopharmaChemIreland
      },
      {
        company_name: 'Carrick Therapeutics',
        logo: piaCarrickTherapeutics
      },
      {
        company_name: 'Gaelic Labs',
        logo: piaGaelicLabs
      },
      {
        company_name: 'Inchera Executive Search',
        logo: piaInchera
      },
      {
        company_name: 'Rhenus',
        logo: piaRhenus
      },
      {
        company_name: 'Aizon',
        logo: piaAizon
      },
      {
        company_name: 'Main Reception - Aramex',
        logo: piaMainReception
      },
      {
        company_name: 'PharmaLex',
        logo: piaPharmalex
      },
      {
        company_name: 'Next Generation Recruitment',
        logo: piaNextGeneration
      },
      {
        company_name: 'Cronin Group',
        logo: piaCroninGroup
      },
      {
        company_name: 'Deciphex',
        logo: piaDeciphex
      },
      {
        company_name: 'KCStrengthsHUB(Ireland) Limited',
        logo: piaKcs
      },
      {
        company_name: 'Rubix Consultancy Limited',
        logo: piaRubix
      },
      {
        company_name: 'Delif Consulting',
        logo: piaCamida
      },
      {
        company_name: 'IBEC',
        logo: piaIbec
      },
      {
        company_name: 'Karcher',
        logo: piaKArcher
      },
      {
        company_name: 'Avantor Sciences',
        logo: piaAvantor
      },
      {
        company_name: 'SGS',
        logo: piaSgs
      },
    ];


    const companyIcia = [
      {
        company_name: 'Summerhill Construction',
        logo: iciaSummerHill
      },
      {
        company_name: 'McAdam Design',
        logo: iciaMcAdam
      },
      {
        company_name: 'McAdam Ireland',
        logo: iciaMcAdam
      },
      {
        company_name: 'Corgan',
        logo: iciaCorgan
      },
      {
        company_name: 'Arcadis Ireland Limited',
        logo: iciaArcadis
      },
      {
        company_name: 'Eastgate Building Services',
        logo: iciaEastgate
      },
      {
        company_name: 'Mulcahy McDonagh & Partners Ltd',
        logo: iciaMulcahy
      },
      {
        company_name: 'Quartz Project Services',
        logo: iciaQuartz
      },
      {
        company_name: 'Cunningham',
        logo: iciaCunningham
      },
      {
        company_name: 'Heatherbrook Homes',
        logo: iciaHeatherbook
      },
      {
        company_name: 'Ethos Engineering Ltd',
        logo: iciaEthos
      },
      {
        company_name: 'J and B O Sullivan Builders Ltd.',
        logo: iciaJandB
      },
      {
        company_name: 'Powerbetter Developments Limited',
        logo: iciaPowerBetter
      },
      {
        company_name: 'Conack Construction ULC',
        logo: iciaConack
      },
      {
        company_name: 'Mitchell McDermott',
        logo: iciaMitchellMcdermott
      },
      {
        company_name: 'Collen Construction Limited',
        logo: iciaCollen
      },
      {
        company_name: 'BAM Ireland',
        logo: iciaBam
      },
      {
        company_name: 'CField Construction',
        logo: iciaCfield
      },
      {
        company_name: 'MosArt Ltd',
        logo: iciaMosart
      },
      {
        company_name: 'Ardmac',
        logo: iciaArdmac
      },
      {
        company_name: 'BWDI Ltd',
        logo: iciaBwdi
      },
      {
        company_name: 'BWDI',
        logo: iciaBwdi
      },
      {
        company_name: 'The 1878 at 3Arena',
        logo: icia1878
      },
      {
        company_name: 'Writech Ltd',
        logo: iciawritech
      },
      {
        company_name: 'Falcon Green',
        logo: iciaFalconGreen
      },
      {
        company_name: 'Lumen Search',
        logo: iciaLumenSearch
      },
      {
        company_name: 'Elliott Group',
        logo: iciaElliotGroup
      },
      {
        company_name: 'Kingspan Light, Air and Water',
        logo: iciaKingspan
      },
      {
        company_name: 'Ganson Building & Civil Engineering Ltd.',
        logo: iciaGanson
      },
      {
        company_name: 'GRAHAM',
        logo: iciaGraham
      },
      {
        company_name: 'KMC Homes Ltd',
        logo: iciaKmcHomes
      },
      {
        company_name: 'P McVey Building Systems',
        logo: iciaPMcvey
      },
      {
        company_name: 'Pennypop',
        logo: iciaPennypop
      },
      {
        company_name: 'Mastertech',
        logo: iciaMastertech
      },
      {
        company_name: 'Jackie Greene Construction Ltd.',
        logo: iciaJackie
      },
      {
        company_name: 'MandE Consulting Engineers Ltd',
        logo: iciaJackie
      },
      {
        company_name: 'Tech Refrigeration and Air Conditioning',
        logo: iciaTechRefrigiration
      },
      {
        company_name: 'Cronin Group',
        logo: iciaCroninGroup
      },
      {
        company_name: 'Tower Security',
        logo: iciaTowerSecurity
      },
      {
        company_name: 'Connex Offsite',
        logo: iciaConnex
      },
      {
        company_name: 'Hays Ireland',
        logo: iciaHaysIreland
      },
      {
        company_name: 'KCC Group',
        logo: iciaKccGroup
      },
      {
        company_name: 'Vision Contracting',
        logo: iciaVisionContracting
      },
      {
        company_name: 'MMC Ireland',
        logo: iciaMmcIreland
      },
      {
        company_name: 'Considerate Constructors Scheme Head of Operations',
        logo: iciaConsiderateConstructors
      },
      {
        company_name: 'Considerate Constructors Scheme',
        logo: iciaConsiderateConstructors
      },
      {
        company_name: 'Glenveagh Properties Plc',
        logo: iciaGlenveagh
      },
      {
        company_name: 'Ward Personnel',
        logo: iciaWard
      },
      {
        company_name: 'University of Limerick',
        logo: iciaUniLimerick
      },
      {
        company_name: 'KSN Horizon',
        logo: iciaKsnHorizon
      },
      {
        company_name: 'Arken Construction',
        logo: iciaArken
      },
      {
        company_name: 'PepTalk',
        logo: iciaPeptalk
      },
      {
        company_name: 'PepTalk/MSD Ireland',
        logo: iciaPeptalk
      },
      {
        company_name: 'Henry J Lyons',
        logo: iciaHenryJLyons
      },
      {
        company_name: 'Van Dijk Architects',
        logo: iciaVinDijk
      },
      {
        company_name: 'PJ Hegarty',
        logo: iciaPjHegarty
      },
      {
        company_name: 'Boon Edam Ireland Ltd',
        logo: iciaBoonEdam
      },
      {
        company_name: 'Duggan Brothers',
        logo: iciaDuggan
      },
      {
        company_name: 'Duggan Brothers',
        logo: iciaDuggan
      },
      {
        company_name: 'Breedon Group',
        logo: iciaBreedon
      },
      {
        company_name: 'MSD Ireland',
        logo: iciaMsd
      },
      {
        company_name: "Tom O'Brien Construction",
        logo: iciaTomOBrien
      },
      {
        company_name: "TOBIN",
        logo: iciaTobin
      },
      {
        company_name: "Suir Engineering",
        logo: iciaSuirEngineering
      },
      {
        company_name: "Flynn Management & Contractors Ltd.",
        logo: iciaFlynn
      },
      {
        company_name: "Copper Coast Renewables",
        logo: iciaCopperCoast
      },
      {
        company_name: "RKD",
        logo: iciaRkd
      },
      {
        company_name: "McAleer & Rushe",
        logo: iciaMcAleer
      },
      {
        company_name: "Limerick Twenty Thirty",
        logo: iciaLimerick
      },
      {
        company_name: "Scott Tallon Walker Architects",
        logo: iciaScottTallon
      },
      {
        company_name: "Glasgiven Contracts Limited",
        logo: iciaGlasgiven
      },
      {
        company_name: "Designer Group",
        logo: iciaDesignerGroup
      },
      {
        company_name: "Cairn",
        logo: iciaCairn
      },
      {
        company_name: "DMC Global Partners",
        logo: iciaDmc
      },
      {
        company_name: "Jones Engineering Manufacturing",
        logo: iciaJonesEngineering
      },
      {
        company_name: "Titan Roofing Ltd",
        logo: iciaTitanRoofing
      },
      {
        company_name: "Turner & Townsend",
        logo: iciaTurnerTownsend
      },
      {
        company_name: "Rainey & Co",
        logo: iciaRainey
      },
      {
        company_name: "Kelbuild Ltd",
        logo: iciaKelbuild
      },
      {
        company_name: "Crannog Roofing",
        logo: iciaCrannog
      },
      {
        company_name: "Reddy Architecture and Urbanism",
        logo: iciaReddy
      },
      {
        company_name: "IN2 Engineering",
        logo: iciaIn2
      },
      {
        company_name: "Geaney Engineering Consultants",
        logo: iciaGeaney
      },
      {
        company_name: "MCA Architects",
        logo: iciaMcaArchitects
      },
      {
        company_name: "O’Herlihy Access Consultancy",
        logo: iciaOhac
      },
      {
        company_name: "Keymore Construction",
        logo: iciaKeymore
      },
      {
        company_name: "JTM Energy",
        logo: iciaJtmEnergy
      },
      {
        company_name: "Dornan Engineering Ltd.",
        logo: iciaDornan
      },
      {
        company_name: "GIA",
        logo: iciaGia
      },
      {
        company_name: "OCC Construction",
        logo: iciaOcc
      },
      {
        company_name: "SES Water Management",
        logo: iciaSeswater
      },
      {
        company_name: "AquaTrace",
        logo: iciaAquaTrace
      },
      {
        company_name: "Instrotec",
        logo: iciaInstrotec
      },
      {
        company_name: "Cluid",
        logo: iciaMcAdam
      },
      {
        company_name: "Sport Ireland",
        logo: iciaMcAdam
      },
      {
        company_name: "MGS",
        logo: iciaMcAdam
      },
      {
        company_name: "Delap & Waller",
        logo: iciaMcAdam
      },
      {
        company_name: "Walls Construction",
        logo: iciaWalls
      },
      {
        company_name: "Structuretone",
        logo: iciaStructureTone
      },
      {
        company_name: "Lighthouse Construction Industry Charity",
        logo: iciaLighthouse
      },
      {
        company_name: "MHI",
        logo: iciaBwdi
      },
      {
        company_name: "Kärcher Hire",
        logo: iciaKarcher
      },
      {
        company_name: "Irish Rail",
        logo: iciaConack
      },
      {
        company_name: "Virtus",
        logo: iciaConack
      },
      {
        company_name: "Altu Architects",
        logo: iciaElliotGroup
      },
      {
        company_name: "MCorm Architecture & Urban Design",
        logo: iciaJackie
      },
      {
        company_name: "Respond",
        logo: iciaJackie
      },
      {
        company_name: "Roger Mullarkey & Associates",
        logo: iciaJackie
      },
      {
        company_name: "John Sisk & Son (Holdings) Ltd",
        logo: iciaSisk
      },
      {
        company_name: "Cogent Associates",
        logo: iciaCogent
      },
      {
        company_name: "Broomfield Construction",
        logo: iciaBroomfield
      },
      {
        company_name: "Nolans Group",
        logo: iciaKelbuild
      },
      {
        company_name: "C+W O'Brien Architects",
        logo: iciaCWOBrien
      },
      {
        company_name: "Fusion 4 Quality",
        logo: iciaEthos
      },
      {
        company_name: "Bmce",
        logo: iciaConack
      },
      {
        company_name: "Mitsubishi",
        logo: iciaTechRefrigiration
      },
      {
        company_name: "NGS",
        logo: iciaHaysIreland
      },
      {
        company_name: "Cunnane Stratton Reynolds",
        logo: iciaJackie
      },
      {
        company_name: "Dublin Bus",
        logo: iciaDublinBus
      },
      {
        company_name: "Property District",
        logo: iciaIpropertyRadio
      },
      {
        company_name: "DCT",
        logo: iciaDct
      },
      {
        company_name: "Technological University Dublin",
        logo: iciaTuDublin
      },
      {
        company_name: "Minima",
        logo: foaMinima
      },
      {
        company_name: "Steelcase",
        logo: foaSteelcase
      },
      {
        company_name: "Universal Business Installations Ltd (UBI)",
        logo: foaUbi
      },
      {
        company_name: "Glen Cullen Construction Ltd",
        logo: foaGlenCullen
      },
      {
        company_name: "OAK Project Management",
        logo: foaOak
      },
    ];

    const companyFoa = [
      {
        company_name: 'KPMG IRELAND',
        logo: foaKpmg
      },
      {
        company_name: 'The Interiors Project',
        logo: foaTheInteriorsProject
      },
      {
        company_name: 'MOLA Architecture',
        logo: foaMolaArchitecture
      },
      {
        company_name: 'MOLA',
        logo: foaMolaArchitecture
      },
      {
        company_name: 'Henry J Lyons',
        logo: foaHenryJLyons
      },
      {
        company_name: 'Aspect Interior Design Ltd',
        logo: foaAspectInteriors
      },
      {
        company_name: 'Conbu Interior Design',
        logo: foaConbo
      },
      {
        company_name: 'etag',
        logo: foaEtag
      },
      {
        company_name: 'PM Group',
        logo: foaPmGroup
      },
      {
        company_name: 'MCA Architects',
        logo: foaMcaArchitects
      },
      {
        company_name: 'Ash Wilson Design',
        logo: foaAshWilson
      },
      {
        company_name: 'COHN',
        logo: foaCohn
      },
      {
        company_name: 'DMOD Architects',
        logo: foaDmod
      },
      {
        company_name: 'SystemCore Building Consultants',
        logo: foaSystemCore
      },
      {
        company_name: 'All Systems Commercial Fitouts Ltd',
        logo: foaAllSystems
      },
      {
        company_name: 'AIB',
        logo: foaAib
      },
      {
        company_name: 'Reddy A+U',
        logo: foaReddy
      },
      {
        company_name: 'Texfel',
        logo: foaTexfel
      },
      {
        company_name: 'Vescom',
        logo: foaVescom
      },
      {
        company_name: 'Ardmac',
        logo: foaArdmac
      },
      {
        company_name: 'Perkins&Will',
        logo: foaPerkins
      },
      {
        company_name: 'Flynn Management & Contractors',
        logo: foaFlynnManagement
      },
      {
        company_name: 'Elliott Group',
        logo: foaElliotGroup
      },
      {
        company_name: 'TapCreative',
        logo: foaTapCreative
      },
      {
        company_name: 'eml architects',
        logo: foaEml
      },
      {
        company_name: 'MDO Architects',
        logo: foaMdo
      },
      {
        company_name: 'Bsg Design Limited',
        logo: foaBsgDesign
      },
      {
        company_name: 'Inicio Interiors',
        logo: foaIncio
      },
      {
        company_name: 'ZIP Water',
        logo: foaZipWater
      },
      {
        company_name: 'Built Interiors',
        logo: foaBuiltInteriors
      },
      {
        company_name: 'Optima',
        logo: foaOptima
      },
      {
        company_name: 'W2W',
        logo: foaW2W
      },
      {
        company_name: 'Autex Acoustics',
        logo: foaAutex
      },
      {
        company_name: 'MJF Interiors',
        logo: foaMjf
      },
      {
        company_name: 'Jones Engineering House',
        logo: foaJonesEngineering
      },
      {
        company_name: 'Calibro Workspace Ltd',
        logo: foaCalibro
      },
      {
        company_name: 'Axiseng Consulting Engineers',
        logo: foaAxiseng
      },
      {
        company_name: 'Diarmuid Reil Architects',
        logo: foaDiarmuid
      },
      {
        company_name: "O'Donnell O'Neill Design",
        logo: foaODonnellONeill
      },
      {
        company_name: "Applegreen",
        logo: foaAppleGreen
      },
      {
        company_name: "Rose Construction",
        logo: foaRoseConstruction
      },
      {
        company_name: "Roisin Lafferty",
        logo: foaRoisinLafferty
      },
      {
        company_name: "Johnston Fit Out",
        logo: foaJohnston
      },
      {
        company_name: "Bene",
        logo: foaBene
      },
      {
        company_name: "VIBE by VISION",
        logo: foaVibe
      },
      {
        company_name: "T&I Fit Outs",
        logo: foaTandI
      },
      {
        company_name: "Total Fitout",
        logo: foaTotalFitout
      },
      {
        company_name: "Mulcahy McDonagh & Partners",
        logo: foaMulcahy
      },
      {
        company_name: "VLE Therapeutics",
        logo: foaVle
      },
      {
        company_name: "Corgan",
        logo: foaCorgan
      },
      {
        company_name: "Tarkett Ltd",
        logo: foaTarkett
      },
      {
        company_name: "B.W.D.I",
        logo: foaBWDI
      },
      {
        company_name: "Lumen Search",
        logo: foaLumenSearch
      },
      {
        company_name: "Surfaceform",
        logo: foaSurfaceform
      },
      {
        company_name: "Havwoods",
        logo: foaHavwoods
      },
      {
        company_name: "Interface Ireland Limited",
        logo: foaInterfaceIreland
      },
      {
        company_name: "Structure Tone",
        logo: foaStructureTone
      },
      {
        company_name: "Finsa Ireland",
        logo: foaFinsa
      },
      {
        company_name: "EGGER",
        logo: foaEgger
      },
      {
        company_name: "CRM Fit Out",
        logo: foaCrmFitOut
      },
      {
        company_name: "K-Design Studio",
        logo: foaKDesign
      },
      {
        company_name: "Ballymore",
        logo: foaBallymore
      },
      {
        company_name: "Grove & Co",
        logo: foaGroveCo
      },
      {
        company_name: "Truwood Ltd.",
        logo: foaTruwood
      },
      {
        company_name: "Horan Rainsford Architects",
        logo: foaHoran
      },
      {
        company_name: "Oman Beverly Smyth",
        logo: foaOman
      },
      {
        company_name: "JLL",
        logo: foaJll
      },
      {
        company_name: "Solutions",
        logo: foaSolutions
      },
      {
        company_name: "SAS International",
        logo: foaSas
      },
      {
        company_name: "SIG Workplace",
        logo: foaSigWorkplace
      },
      {
        company_name: "Tech Refrigeration & Air Conditioning",
        logo: foaTechref
      },
      {
        company_name: "KPMG Construction Advisory",
        logo: foaKpmg
      },
      {
        company_name: "Cogent Associates",
        logo: foaCogent
      },
      {
        company_name: "MILLIMETRE DESIGN",
        logo: foaMillimetreDesign
      },
      {
        company_name: "Millimetre Design",
        logo: foaMillimetreDesign
      },
      {
        company_name: "Milimetre",
        logo: foaMillimetreDesign
      },
      {
        company_name: "O Connor Sutton Cronin",
        logo: foaOConnorSuttonCronin
      },
      {
        company_name: "BHA Construction",
        logo: foaBha
      },
      {
        company_name: "COMMA Architects",
        logo: foaCommaArchitects
      },
      {
        company_name: "RKD",
        logo: foaRKD
      },
      {
        company_name: "Aspect Interior Design",
        logo: foaAspect
      },
      {
        company_name: "YG Interiors Ltd",
        logo: foaYgInteriors
      },
      {
        company_name: "Saul Design",
        logo: foaSaulDesign
      },
      {
        company_name: "Creighan Contracts Ltd",
        logo: foaCreighan
      },
      {
        company_name: "Lioncor",
        logo: foaLioncor
      },
      {
        company_name: "Pure Fit Out",
        logo: foaPureFitout
      },
      {
        company_name: "Ethos Engineering Limited",
        logo: foaEthos
      },
      {
        company_name: "Style So Simple",
        logo: foaStyleSoSimple
      },
      {
        company_name: "Jennifer Anne Interiors",
        logo: foaJennifer
      },
      {
        company_name: "Ashen & Cloud",
        logo: foaAshenCloud
      },
      {
        company_name: "Serpentine Interiors",
        logo: foaSerpentineInteriors
      },
      {
        company_name: "WRG",
        logo: foaWhiteRiver
      },
      {
        company_name: "Vardis Group",
        logo: foaVardisGroup
      },
      {
        company_name: "DCON Safety Consultants",
        logo: foaDcon
      },
      {
        company_name: "IWIN",
        logo: foaIwin
      },
      {
        company_name: "Tony O Donovan Office Interiors Ltd",
        logo: foaTonyODonovan
      },
      {
        company_name: "The Amtico Company Ltd",
        logo: foaTheAmtico
      },
      {
        company_name: "Tanya Neufeld Flanagan",
        logo: foaTanyaNeufeld
      },
      {
        company_name: "SLMD Ltd",
        logo: foaSlmd
      },
      {
        company_name: "Primabuild Ltd",
        logo: foaPrimaBuild
      },
      {
        company_name: "Plantech Design",
        logo: foaPlantech
      },
      {
        company_name: "Alliance Contract Carpets & Flooring Ltd",
        logo: foaAlliance
      },
      {
        company_name: "Galtee Group",
        logo: foaGalteeGroup
      },
      {
        company_name: "AoSH.studio",
        logo: foaAoshStudio
      },
      {
        company_name: "Greenscene",
        logo: foaGreenscene
      },
      {
        company_name: "UMR Group",
        logo: foaUmrGroup
      },
      {
        company_name: "Royalton",
        logo: foaRoyalton
      },
      {
        company_name: "Auburn Real Estate",
        logo: foaAuburn
      },
      {
        company_name: "DCU",
        logo: foaDcu
      },
      {
        company_name: "Purser.ie",
        logo: foaPurser
      },
      {
        company_name: "Trafalgar",
        logo: foaTrafalgar
      },
      {
        company_name: "Respond",
        logo: foaRespond
      },
      {
        company_name: "Shaw Contract",
        logo: foaShaw
      },
      {
        company_name: "Designful by Deirdre Walshe Design",
        logo: foaDesignful
      },
      {
        company_name: "Farrelly & Co",
        logo: foaFarrelly
      },
      {
        company_name: "Carnehill Joinery",
        logo: foaCarnehill
      },
      {
        company_name: "Western Postform",
        logo: foaWesternPostform
      },
      {
        company_name: "CBRE",
        logo: foaCbre
      },
      {
        company_name: "OPW",
        logo: foaOpw
      },
      {
        company_name: "Grace Gahan Interiors & National Design Academy",
        logo: foaGraceGahan
      },
      {
        company_name: "Furnishing Distributors",
        logo: foaFurnishingDistributors
      },
      {
        company_name: "Hillmac Fitouts",
        logo: foaHillmac
      },
      {
        company_name: "HYDE",
        logo: foaHyde
      },
      {
        company_name: "Griffith College",
        logo: foaGriffith
      },
      {
        company_name: "Iconic Offices",
        logo: foaIconic
      },
      {
        company_name: "OHMG Construction",
        logo: foaOhmg
      },
      {
        company_name: "Technological University of the Shannon: Midlands Midwest",
        logo: foaTus
      },
      {
        company_name: "Cundall",
        logo: foaCundall
      },
      {
        company_name: "Kilrock Limited",
        logo: foaKilrock
      },
      {
        company_name: "Bisley",
        logo: foaBisley
      },
      {
        company_name: "RPP Architects",
        logo: foaRpp
      },
      {
        company_name: "Elm Gray Ltd.",
        logo: foaElmGray
      },
      {
        company_name: "Systemcore",
        logo: foaSystemCore2
      },
      {
        company_name: "Carlton Hotel",
        logo: foaCarlton
      },
      {
        company_name: "Laminar",
        logo: foaLaminar
      },
      {
        company_name: "Mitsubishi Electric",
        logo: foaMitsubisihi
      },
      {
        company_name: "Mitsubishi Electric",
        logo: foaMitsubisihi
      },
      {
        company_name: "Clontarf Interiors",
        logo: foaClontarf
      },
      {
        company_name: "Turner & Townsend",
        logo: foaTurnerTownsend
      },
      {
        company_name: "BusinessRiver",
        logo: foaBusinessRiver
      },
      {
        company_name: "Generate Leads",
        logo: foaGenerateLeads
      },
      {
        company_name: "KCC Group",
        logo: foaKccGroup
      },
      {
        company_name: "Hays Recruitment",
        logo: foaHays
      },
      {
        company_name: "Loughnane Joinery",
        logo: foaLoughnane
      },
      {
        company_name: "Andrew Weadick Carpets Ltd",
        logo: foaWeadick
      },
    ];

    const companyCx = [
      {
        company_name: 'ESB',
        logo: cxEsb
      },
      {
        company_name: 'AIB',
        logo: cxAib
      },
      {
        company_name: 'Aer Lingus',
        logo: cxAerLingus
      },
      {
        company_name: 'Kylemore cars',
        logo: cxKylemore
      },
      {
        company_name: 'The Passport Service',
        logo: cxPassportService
      },
      {
        company_name: 'Bord Gáis Energy',
        logo: cxBordgais
      },
      {
        company_name: 'Mason Hayes & Curran',
        logo: cxMasonHayes
      },
      {
        company_name: 'Resilience Healthcare',
        logo: cxResillience
      },
      {
        company_name: 'Trinity College Dublin',
        logo: cxTrinityCollegeDublin
      },
      {
        company_name: 'CXPA',
        logo: cxCxpa
      },
      {
        company_name: 'The Customer Experience Professionals Association (CXPA) Ireland(W5 Marketing Intelligence Limited)',
        logo: cxCxpa
      },
      {
        company_name: 'The Customer Experience Professionals Association (CXPA) Ireland (EPAM Systems)',
        logo: cxCxpa
      },
      {
        company_name: 'The Customer Experience Professionals Association (CXPA) Ireland (Irish Life Group)',
        logo: cxCxpa
      },
      {
        company_name: 'The Customer Experience Profe ssionals Association (CXPA) Ireland(CXPA)',
        logo: cxCxpa
      },
      {
        company_name: 'The Customer Experience Professionals Association (CXPA) Ireland (The Experience Edge)',
        logo: cxCxpa
      },
      {
        company_name: 'The Customer Experience Professionals Association (CXPA) Ireland',
        logo: cxCxpa
      },
      {
        company_name: 'The Customer Experience Professionals Association (CXPA) Ireland (GS1 Ireland)',
        logo: cxCxpa
      },
      {
        company_name: 'Ptsb',
        logo: cxPtsb
      },
      {
        company_name: 'Pobal',
        logo: cxPobal
      },
      {
        company_name: 'Bank of Ireland',
        logo: cxBankofIreland
      },
      {
        company_name: 'Taxback International',
        logo: cxTaxback
      },
      {
        company_name: 'RSA',
        logo: cxRsa
      },
      {
        company_name: 'Abtran',
        logo: cxAbtran
      },
      {
        company_name: 'Bus Éireann',
        logo: cxBusEireann
      },
      {
        company_name: 'Klearcom',
        logo: cxKlearcom
      },
      {
        company_name: 'D&M Garden Centre',
        logo: cxDMGardenCentre
      },
      {
        company_name: 'Workair',
        logo: cxWorkair
      },
      {
        company_name: 'Calor',
        logo: cxCalor
      },
      {
        company_name: 'Cornmarket Group Financial Services',
        logo: cxCornmarket
      },
      {
        company_name: 'BusinessRiver',
        logo: cxBusinessRiver
      },
      {
        company_name: 'Insight6',
        logo: cxInsight6
      },
      {
        company_name: 'Irish Distillers Brand Homes',
        logo: cxIrishDistillers
      },
      {
        company_name: 'RentalMatics',
        logo: cxRentalMatics
      },
      {
        company_name: 'RentalMatics',
        logo: cxRentalMatics
      },
      {
        company_name: 'Thrive Marketing',
        logo: cxThriveMarketing
      },
      {
        company_name: 'Capventis',
        logo: cxCapventis
      },
      {
        company_name: 'Laya Healthcare',
        logo: cxLayaHealthcare
      },
      {
        company_name: 'Phonovation',
        logo: cxPhonovation
      },
      {
        company_name: 'leasepoint',
        logo: cxLeasepoint
      },
      {
        company_name: 'The Experience Edge (EPAM Systems)',
        logo: cxEpam
      },
      {
        company_name: 'The Experience Edge (Irish Life Group)',
        logo: cxIrishLifeGroup
      },
      {
        company_name: 'The Experience Edge (W5 Marketing Intelligence Limited)',
        logo: cxW5
      },
    ];

    const companyAia = [
      {
        company_name: 'Law Society of Ireland',
        logo: aiaLawSociety
      },
      {
        company_name: 'Belfast City Airport',
        logo: aiaBelfast
      },
      {
        company_name: 'Killick Aerospace',
        logo: aiaKillickAerospace
      },
      {
        company_name: 'CRS Technics',
        logo: aiaCrs
      },
      {
        company_name: 'Dublin City University',
        logo: aiaDcu
      },
      {
        company_name: 'University of Limerick',
        logo: aiaUniLimerick
      },
      {
        company_name: 'Irish Aviation Authority',
        logo: aiaIaa
      },
      {
        company_name: 'Aeolus Engine Services',
        logo: aiaAeolus
      },
      {
        company_name: 'Aterim',
        logo: aiaAterim
      },
      {
        company_name: 'A-techsyn LTD',
        logo: aiaAtechSyn
      },
      {
        company_name: 'Future Mobility Campus Ireland',
        logo: aiaFutureMobility
      },
      {
        company_name: 'leasepoint',
        logo: aiaLeasepoint
      },
      {
        company_name: 'ABL Aviation',
        logo: aiaAblAviation
      },
      {
        company_name: 'Inflight Dublin',
        logo: aiaInflightDublin
      },
      {
        company_name: 'International Aerospace Coatings',
        logo: aiaIac
      },
      {
        company_name: 'Atlantic Aviation Group',
        logo: aiaAag
      },
      {
        company_name: 'Shannon Aviation Museum',
        logo: aiaShannonAviation
      },
      {
        company_name: 'DAA International',
        logo: aiaDaaInternational
      },
      {
        company_name: 'AvAir',
        logo: aiaAvair
      },
      {
        company_name: 'DAA PUBLIC LIMITED COMPANY',
        logo: aiaDaa
      },
      {
        company_name: 'Worldwide Composites Services',
        logo: aiaWcs
      },
      {
        company_name: 'Shannon Airport Group',
        logo: aiaShannonAirport
      },
      {
        company_name: 'Genesis',
        logo: aiaGenesis
      },
      {
        company_name: 'Athboy Aviation',
        logo: aiaAthboyAviation
      },
      {
        company_name: 'Hannon Travel',
        logo: aiaHannonTravel
      },
      {
        company_name: 'Cloudcards',
        logo: aiaCloudCards
      },
      {
        company_name: 'EirTrade Aviation',
        logo: aiaEirTrade
      },
      {
        company_name: 'Marqette Dublin Airport',
        logo: aiaMarqette
      },
      {
        company_name: 'The Shannon Airport Group',
        logo: aiaShannonAirport
      },
      {
        company_name: 'Moog MRO Services',
        logo: aiaMoog
      },
      {
        company_name: 'BusinessRiver',
        logo: aiaBusinessRiver
      },
      {
        company_name: 'Aviation Skillnet',
        logo: aiaAviationSkillnet
      },
      {
        company_name: 'Inter-tec',
        logo: aiaIntertec
      },
      {
        company_name: 'Local Enterprise Office at Clare County Council',
        logo: aiaLocalEnterprise
      },
      {
        company_name: 'AirNav Ireland',
        logo: aiaAirnavIreland
      },
      {
        company_name: 'Nashville International Airport',
        logo: aiaNashville
      },
      {
        company_name: 'AAV / Shannon Aviation Museum',
        logo: aiaShannonAviation
      },
      {
        company_name: 'Technological University of the Shannon Region',
        logo: aiaTus
      },
    ];

    const companyLogos = {
      '34': companyLogoOpex,
      '36': companyLogoLsa,
      '37': companyLogoPiaUk,
      '39': companyLogoIcia,
      '40': companyLogoHr,
      '41': companyLogoIcxia,
      '42': companyLogoBeaa,
      '43': companyLogoPiaIE,
      '44': companyLogoIsa,
      '45': companyLogoAia,
      '46': companyLogoNpa,
      '47': companyLogoFoa,
      '49': companyLogoGa,
      '50': companyLogoIlta,
      '51': companyLogoFM,
      '53': companyLogoMea,
      '54': companyLogoBaya,
      '55': companyLogoHs,
      '56': companyLogoEdu,
      '57': companyLogoIaa,
      '71': companyLogoTaia,
      '58': companyLogoOpex24,
      '72': companyLogoWica,
      '59': companyLogoLsia,
      '75': companyLogoDta,
      '78': companyLogoAiaUk,
      '70': companyFoaUk,
      '62': companyPiaUk,
      '61': companyIsa,
      '60': companyBeaa,
      '76': companyPha,
      '64': companyHr,
      '77': companyEea,
      '65': companyNpa,
      '63': companyPia,
      '66': companyIcia,
      '67': companyFoa,
      '68': companyCx,
      '69': companyAia
    }


    if (id == 22375) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={piaIrelandcpl}/>);
    } else if (id == 22506) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={camida}/>);
    } else if (id == 22075 || id == 22076 || id == 22077 || id == 22078 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={biopharmachemIreland}/>);
    } else if (id == 17230 || id == 17231 || id == 17234 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={piaIrelandSynergy}/>);
    } else if (id == 16967 || id == 16968 || id == 16969 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={actylis}/>);
    } else if (id == 20684 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={blynksolve}/>);
    } else if (id == 17259 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={quickstat}/>);
    } else if (id == 19014 || id == 19015 || id == 19016 || id == 19017 || id == 19018 || id == 19019 || id == 19020 || id == 19021 || id == 19022 || id == 19023) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={uValue}/>);
    } else if (id == 18075 || id == 18076 || id == 18077 || id == 18078 || id == 18079 || id == 18080 || id == 18081 || id == 18082 || id == 18083 || id == 18084 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={ksn}/>);
    } else if (id == 17449 || id == 17450 || id == 17451 || id == 17452 || id == 17453 || id == 17454 || id == 17455 || id == 17456 || id == 17457 || id == 17458 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={sas}/>);
    } else if (id == 17470 || id == 17469 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={lumenFoa}/>);
    } else if (id == 17481 || id == 17482 || id == 17483 || id == 17484 || id == 17507|| id == 17508 || id == 17509 || id == 17510 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={vescom}/>);
    } else if (id == 17521 || id == 17522 || id == 17523 || id == 17524 || id == 17525|| id == 17526 || id == 17527 || id == 17528 || id == 17529 || id == 17530 || id == 17531 || id == 17532|| id == 17533 || id == 17534 || id == 17535|| id == 17536 || id == 17537|| id == 17538 || id == 17539 || id == 17540|| id == 23184 || id == 23185 || id == 23186|| id == 23187) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={structureTone}/>);
    } else if (id == 17551 || id == 17552 || id == 17553 || id == 17554 || id == 17555 || id == 17556 || id == 17557 || id == 17558 || id == 17559 || id == 17560 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={dcon}/>);
    } else if (id == 17571 || id == 17572 || id == 17573 || id == 17574 || id == 17575 || id == 17576 || id == 17577 || id == 17578 || id == 17579 || id == 17580 || id == 23412 || id == 23413 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={greenLam}/>);
    } else if (id == 17591 || id == 17592 || id == 17593 || id == 17594 || id == 17595 || id == 17596 || id == 17597 || id == 17598 || id == 17599 || id == 17600 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={finsaIreland}/>);
    } else if (id == 17611 || id == 17612 || id == 17613 || id == 17614 || id == 17615 || id == 17616 || id == 17617 || id == 17618 || id == 17619 || id == 17620 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={w2w}/>);
    } else if (id == 23152 || id == 23153 || id == 23154 || id == 23155 || id == 23156 || id == 23157 || id == 23158 || id == 23159 || id == 23160 || id == 23161 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={w2w}/>);
    } else if (id == 23298 || id == 23299 || id == 23300 || id == 23301 || id == 23302 || id == 23303 || id == 23304 || id == 23305 || id == 23306 || id == 23307 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={w2w}/>);
    } else if (id == 17631 || id == 17632 || id == 17633 || id == 17634 || id == 17635 || id == 17636 || id == 17637 || id == 17638 || id == 17639 || id == 17640 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={mckeonGroup}/>);
    } else if (id == 17671 || id == 17672 || id == 17673 || id == 17674 || id == 17675 || id == 17676 || id == 17677 || id == 17678 || id == 17679 || id == 17680 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={zipWater}/>);
    } else if (id == 17711 || id == 17712 || id == 17713 || id == 17714 || id == 17715 || id == 17716 || id == 17717 || id == 17718 || id == 17719 || id == 17720 || id == 17825 || id == 17826 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hamiltonRobsonFoa}/>);
    } else if (id == 17731 || id == 17732 || id == 17733 || id == 17734 || id == 17735 || id == 17736 || id == 17737 || id == 17738 || id == 17739 || id == 17740 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={crmFitOut}/>);
    } else if (id == 18095 || id == 18096 || id == 18097 || id == 18098 || id == 18099 || id == 18100 || id == 18101 || id == 18102 || id == 18103 || id == 18104 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={janJanssens}/>);
    } else if (id == 18115 || id == 18116 || id == 18117 || id == 18118 || id == 18119 || id == 18120 || id == 18121 || id == 18122 || id == 18123 || id == 18124 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={solutions}/>);
    } else if (id == 18135 || id == 18136 || id == 18137 || id == 18138 || id == 18139 || id == 18140 || id == 18141 || id == 18142 || id == 18143 || id == 18144 || id == 19937 || id == 19938 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={techRefrigeration}/>); 
    } else if (id == 18165 || id == 18166 || id == 18167 || id == 18168 || id == 18169 || id == 18170 || id == 18171 || id == 18172 || id == 18173 || id == 18174 || id == 18175 || id == 18176 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={mjfInteriors}/>);  
    } else if (id == 19262 || id == 19263 || id == 19264 || id == 19265 || id == 19266 || id == 19267 || id == 19268 || id == 19269 || id == 19270 || id == 19271 || id == 19272 || id == 19273 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={mjfInteriors}/>);
    } else if (id == 19054 || id == 19055 || id == 19056 || id == 19057 || id == 19058 || id == 19059 || id == 19060 || id == 19061 || id == 19062 || id == 19063 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={azureContractingLtd}/>);
    } else if (id == 19074 || id == 19075 || id == 19076 || id == 19083 || id == 19077 || id == 19078 || id == 19079 || id == 19080 || id == 19081 || id == 19082 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={johnstonFitout}/>);
    } else if (id == 19094 || id == 19095 || id == 19096 || id == 19097 || id == 19098 || id == 19099 || id == 19100 || id == 19101 || id == 19102 || id == 19103 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={uValueFoa}/>);
    } else if (id == 19114 || id == 19115 || id == 19116 || id == 19117 || id == 19118 || id == 19119 || id == 19120 || id == 19121 || id == 19122 || id == 19123 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={tIFitouts}/>);  
    } else if (id == 20961 || id == 20962 || id == 20963 || id == 20964 || id == 20965 || id == 20966 || id == 20967 || id == 20968 || id == 20969 || id == 20970 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={tIFitouts}/>); 
    } else if (id == 23168 || id == 23169 || id == 23170 || id == 23171 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={textSpace}/>);
    } else if (id == 22700 || id == 22701 || id == 22702 || id == 22703 || id == 22704 || id == 22705 || id == 22706 || id == 22707 || id == 22708 || id == 22709 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={procore}/>);
    } else if (id == 17691 || id == 17692 || id == 17693 || id == 17694 || id == 17695 || id == 17696 || id == 17697 || id == 17698 || id == 17699 || id == 17700 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={theInteriorGroup}/>);
    } else if (id == 22363 || id == 22364 || id == 22365 || id == 22366 || id == 22367 || id == 22368 || id == 22369 || id == 22370 || id == 22371 || id == 22372 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gannonOfficeSolutions}/>);
    } else if (id == 21840 || id == 21841 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={toddArchitects}/>);
    } else if (id == 22614 || id == 22615 || id == 22616 || id == 22617 || id == 22618 || id == 22619 || id == 22620 || id == 22621 || id == 22622 || id == 22623 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={elliottGroup}/>);
    }  else if (id == 23429 || id == 23430 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={kccGroup}/>);
    } else if (id == 23431 || id == 23432 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={meinhardtFoa}/>);
    } else if (id == 23433 || id == 23434 || id == 23435 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={handleCraft}/>);
    } else if (id == 23436) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={businessriver}/>);
    } else if (id == 21905 || id == 21906 || id == 21907 || id == 21908 || id == 21909 || id == 21910 || id == 21911 || id == 21912 || id == 21913 || id == 21914 || id == 21915 || id == 21916) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iac}/>);
    } else if (id == 21968 || id == 21969 || id == 21970 || id == 21971 || id == 21972 || id == 21973 || id == 21974 || id == 21975 || id == 21976 || id == 21977 || id == 21978 || id == 21979) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={manna}/>);
    } else if (id == 21997 || id == 21998) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={aterim}/>);
    } else if (id == 22569 || id == 22570 || id == 22571) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={cae}/>);
    } else if (id == 22632 || id == 22633 || id == 22634 || id == 22635 || id == 22636 || id == 22637 || id == 22638 || id == 22639 || id == 22640 || id == 22641) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={aeradvise}/>);
    } else if (id == 22731 || id == 22732) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={tcalliance}/>);
    } else if (id == 22711 || id == 22712 || id == 22713 || id == 22714) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={airtel}/>);
    } else if (id == 22711 || id == 22712 || id == 22713 || id == 22714) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={airtel}/>);
    } else if (id == 22735 || id == 22736 || id == 22737 || id == 22738 || id == 22739 || id == 22740 || id == 22741 || id == 22742 || id == 22743 || id == 22744 || id == 22745 || id == 22746) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={aeroinspection}/>);
    } else if (id == 22936 || id == 22937) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={cloudcards}/>);
    } else if (id == 23002 || id == 23003 || id == 23004 || id == 23005 || id == 23006) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={aeropods}/>);
    } else if (id == 23162 || id == 23163) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={galway}/>);
    } else if (id == 23498 || id == 23499) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={airlineflight}/>);
    } else if (id == 23235 || id == 23236 || id == 23237 || id == 23238 || id == 23239 || id == 23240 || id == 23241 || id == 23242 || id == 23243 || id == 23244 || id == 23245 || id == 23246) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={regen}/>);
    } else if (id == 23007 || id == 23008 || id == 23009 || id == 23010 || id == 23011 || id == 23012 || id == 23013 || id == 23014 || id == 23015 || id == 23016) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={falko}/>);
    } else if (id == 23696 || id == 23697 || id == 23698 || id == 23699 || id == 23700 || id == 23701 || id == 23702 || id == 23703 || id == 23704 || id == 23705) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaBam}/>);
    } else if (id == 23706 || id == 23707 || id == 23708 || id == 23709 || id == 23710 || id == 23711 || id == 23712 || id == 23713 || id == 23714 || id == 23715 || id == 23716 || id == 23717) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaAlkermes}/>);
    } else if (id == 23728 || id == 23729 || id == 23730) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaXlvets}/>);
    } else if (id == 23765 || id == 23766 || id == 23708 || id == 23767 || id == 23768 || id == 23769 || id == 23770 || id == 23771 || id == 23772 || id == 23773 || id == 23774) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaCrowleys}/>);
    } else if (id == 23775) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaBurren}/>);
    } else if (id == 23776 || id == 23777 || id == 23778 || id == 23779 || id == 23780 || id == 23781 || id == 23782 || id == 23783 || id == 23784 || id == 23785) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaKingMoffat}/>);
    } else if (id == 23786 || id == 23787 || id == 23788 || id == 23789 || id == 23790 || id == 23791 || id == 23792 || id == 23793 || id == 23794 || id == 23795 || id == 23796 || id == 23797) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaBreedon}/>);
    } else if (id == 23798 || id == 23799 || id == 23800) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaBrownThomas}/>);
    } else if (id == 23801 || id == 23802) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaWisetek}/>);
    } else if (id == 23804 || id == 23805 || id == 23806 || id == 23807 || id == 23808 || id == 23809 || id == 23810 || id == 23811 || id == 23812 || id == 23813) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaThorntons}/>);
    } else if (id == 23840 || id == 23841 || id == 23842 || id == 23843 || id == 23844 || id == 23845 || id == 23846 || id == 23847 || id == 23848 || id == 23849) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaBon}/>);
    } else if (id == 23850 || id == 23851 || id == 23852 || id == 23853 || id == 23854 || id == 23855 || id == 23856 || id == 23857 || id == 23858 || id == 23859 || id == 23860 || id == 23861) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaPv}/>);
    } else if (id == 23890 || id == 23891 || id == 23892 || id == 23893 || id == 23894 || id == 23895 || id == 23896 || id == 23897 || id == 23898 || id == 23899) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaDhl}/>);
    } else if (id == 23872) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaIes}/>);
    } else if (id == 23873) {
      // eco merit
      // return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={}/>);
    } else if (id == 23874 || id == 23875 || id == 23876 || id == 23877 || id == 23878 || id == 23879 || id == 23880 || id == 23881 || id == 23882 || id == 23883) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaBreedon}/>);
    } else if (id == 23885 || id == 23886 || id == 23887 || id == 23888 || id == 23889) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaVersion}/>);
    } else if (id == 23900 || id == 23901 || id == 23902 || id == 23903 || id == 23904 || id == 23905 || id == 23906 || id == 23907 || id == 23908 || id == 23909) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaCairn}/>);
    } else if (id == 23910 || id == 23911 || id == 23912 || id == 23913 || id == 23914 || id == 23915 || id == 23916 || id == 23917 || id == 23918 || id == 23919) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaJcdecaux}/>);
    } else if (id == 23920 || id == 23921) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaWren}/>);
    } else if (id == 23922 || id == 23923 || id == 23924) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaCastle}/>);
    } else if (id == 23925 || id == 23926) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaLawler}/>);
    } else if (id == 23927 || id == 23928 || id == 23929 || id == 23930) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaAer}/>);
    } else if (id == 23931 || id == 23932 || id == 23933) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaDublin}/>);
    } else if (id == 23934 || id == 23935 || id == 23936 || id == 23937 || id == 23938 || id == 23939 || id == 23940 || id == 23941 || id == 23942 || id == 23943 || id == 23944 || id == 23945) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaApc}/>);
    } else if (id == 23946 || id == 23947 || id == 23948 || id == 23949) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaFineFoods}/>);
    } else if (id == 23950 || id == 23951) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaSmarttech}/>);
    } else if (id == 23952 || id == 23953 || id == 23954) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaWalkers}/>);
    } else if (id == 23955 || id == 23956) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaDromoland}/>);
    } else if (id == 23957 || id == 23958 || id == 23959 || id == 23960) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaHanley}/>);
    } else if (id == 23961 || id == 23962 || id == 23963) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaPrimark}/>);
    } else if (id == 23984 || id == 23985 || id == 23986 || id == 23987) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaWyeth}/>);
    } else if (id == 23988 || id == 23989 || id == 23990 || id == 23991 || id == 23992 || id == 23993 || id == 23994 || id == 23995 || id == 23996 || id == 23997 || id == 23998 || id == 23999) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaJohnson}/>);
    } else if (id == 24026 || id == 24027) {
      // change by degrees
    } else if (id == 24028 || id == 24029 || id == 24030) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaDeloitte}/>);
    } else if (id == 24031 || id == 24032 || id == 24033 || id == 24034 || id == 24035 || id == 24036 || id == 24037 || id == 24038 || id == 24039 || id == 24040 || id == 24041 || id == 24042) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaHealthcare}/>);
    } else if (id == 24043 || id == 24044 || id == 24045) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaKillarney}/>);
    } else if (id == 24046 || id == 24047 || id == 24048 || id == 24049) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaPowerCapital}/>);
    } else if (id == 24050 || id == 24051 || id == 24052) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaWipeout}/>);
    } else if (id == 24053 || id == 24054) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaSymphony}/>);
    } else if (id == 24055 || id == 24056 || id == 24057 || id == 24058) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaHarmac}/>);
    } else if (id == 24059 || id == 24060) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaHorizon}/>);
    } else if (id == 24061 || id == 24062 || id == 24063 || id == 24064 || id == 24065 || id == 24066) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaArdmore}/>);
    } else if (id == 24067 || id == 24068) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaAbp}/>);
    } else if (id == 24069 || id == 24070 || id == 24071 || id == 24072 || id == 24073 || id == 24074 || id == 24075 || id == 24076 || id == 24077 || id == 24078) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaIres}/>);
    } else if (id == 24079 || id == 24080 || id == 24081 || id == 24082 || id == 24083) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaMusgrave}/>);
    } else if (id == 24084 || id == 24085 || id == 24086 || id == 24087) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaMercury}/>);
    } else if (id == 24088 || id == 24089) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaSwordsPavilions}/>);
    } else if (id == 24094 || id == 24095) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaHotelDoolin}/>);
    } else if (id == 24096 || id == 24097) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaMeade}/>);
    } else if (id == 24098 || id == 24099 || id == 24100 || id == 24101) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaAurivo}/>);
    } else if (id == 24102 || id == 24103 || id == 24104 || id == 24105 || id == 24106 || id == 24107 || id == 24108 || id == 24109 || id == 24110 || id == 24111) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaMurphy}/>);
    } else if (id == 24227 || id == 24228 || id == 24229 || id == 24230 || id == 24231 || id == 24232 || id == 24233) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaFexco}/>);
    } else if (id == 25392 || id == 25393) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaPfizer}/>);
    } else if (id == 25242 || id == 25242 || id == 25243 || id ==  25244 || id ==  25245) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaCoolplanet}/>);
    } else if (id == 25055) {
      // The panel
    } else if (id == 25051) {
      // Phonovation Ltd
    } else if (id == 24974) {
      // Zero Waste Scotland
    } else if (id == 24960 || id == 24961) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaMcculla}/>);
    } else if (id == 24958 || id == 24959) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaGrafton}/>);
    } else if (id == 24954) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaVodafone}/>);
    } else if (id == 24942 || id == 24943 || id == 24927 || id == 24928) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaGmc}/>);
    } else if (id == 24877 || id == 24878 || id == 24879 || id == 24880 || id == 24881) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaDgd}/>);
    } else if (id == 24867 || id == 24868 || id == 24869 || id == 24870 || id == 24871 || id == 24872 || id == 24873 || id == 24874 || id == 24875 || id == 24876) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaFreightSpeed}/>);
    } else if (id == 24840 || id == 24841) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaIrishManu}/>);
    } else if (id == 24776 || id == 24777) {
      // Education for Sustainability
    } else if (id == 24772 || id == 24773 || id == 24774 || id == 24775) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaClayton}/>);
    } else if (id == 24757 || id == 24758) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaKpmg}/>);
    } else if (id == 24750 || id == 24751 || id == 24752) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaDpd}/>);
    } else if (id == 24753 || id == 24754 || id == 24755 || id == 24756) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaVesi}/>);
    } else if (id == 24697) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaCully}/>);
    } else if (id == 24708 || id == 24709 || id == 24710 || id == 24711 || id == 24712 || id == 24713 || id == 24714 || id == 24715 || id == 24716 || id == 24717 || id == 24718 || id == 24719) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaAib}/>);
    } else if (id == 24533 || id == 24534) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaFyffes}/>);
    } else if (id == 24527 || id == 24528 || id == 24529 || id == 24530 || id == 24531) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaAramark}/>);
    } else if (id == 24517 || id == 24518 || id == 24519 || id == 24520 || id == 24521 || id == 24522 || id == 24523 || id == 24524 || id == 24525 || id == 24526) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaCie}/>);
    } else if (id == 24507 || id == 24508 || id == 24509 || id == 24510 || id == 24511 || id == 24512 || id == 24513 || id == 24514 || id == 24515 || id == 24516) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaKildare}/>);
    } else if (id == 24505 || id == 24506) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaSupernode}/>);
    } else if (id == 24502 || id == 24503) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaAllfresh}/>);
    } else if (id == 24492 || id == 24493 || id == 24494 || id == 24495 || id == 24496 || id == 24497 || id == 24498 || id == 24499 || id == 24500 || id == 24501) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaBostonScientific}/>);
    } else if (id == 24490 || id == 24491) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaAldi}/>);
    } else if (id == 24475 || id == 24476 || id == 24477 || id == 24478) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaGmc}/>);
    } else if (id == 24445 || id == 24446 || id == 24447 || id == 24448) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaThorn}/>);
    } else if (id == 24431 || id == 24432 || id == 24433 || id == 24434 || id == 24435) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaVodafone}/>);
    } else if (id == 24418 || id == 24419 || id == 24420) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaKilsaran}/>);
    } else if (id == 24449 || id == 24450 || id == 24451 || id == 24452 || id == 24453 || id == 24454 || id == 24455 || id == 24456 || id == 24457 || id == 24458 || id == 24459 || id == 24460) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaEliLilly}/>);
    } else if (id == 24368 || id == 24369 || id == 24370 || id == 24371 || id == 24372 || id == 24373) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaKingspan}/>);
    } else if (id == 24353 || id == 24354 || id == 24355) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaWalls}/>);
    } else if (id == 24331 || id == 24332) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaWinthrop}/>);
    } else if (id == 24319 || id == 24320 || id == 24321 || id == 24322 || id == 24323 || id == 24324 || id == 24325 || id == 24326 || id == 24327 || id == 24328) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaMason}/>);
    } else if (id == 24314 || id == 24315 || id == 24316 || id == 24317 || id == 24318) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaCompass}/>);
    } else if (id == 24309 || id == 24310 || id == 24311 || id == 24312 || id == 24313) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaClarins}/>);
    } else if (id == 24287 || id == 24288) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaLidi}/>);
    } else if (id == 24270 || id == 24271 || id == 24272 || id == 24273 || id == 24274) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaSpeedFibre}/>);
    } else if (id == 21022 || id == 21023 || id == 21024 || id == 21025 || id == 21026 || id == 21027 || id == 21028 || id == 21029 || id == 21030 || id == 21031) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaUisce}/>);
    } else if (id == 24396 || id == 24397 || id == 24398 || id == 24399 || id == 24400 || id == 24401 || id == 24402 || id == 24403 || id == 24404 || id == 24405) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaSchneider}/>);
    } else if (id == 23974 || id == 23975 || id == 23976 || id == 23977 || id == 23978 || id == 23979 || id == 23980 || id == 23981 || id == 23982 || id == 23983) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaCpl}/>);
    } else if (id == 22056 || id == 22057 || id == 22058 || id == 22059 || id == 22060 || id == 22061 || id == 22062 || id == 22063 || id == 22064 || id == 22065) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaPanda}/>);
    } else if (id == 21659 || id == 21660 || id == 21661 || id == 21662 || id == 21663 || id == 21664 || id == 21665 || id == 21666 || id == 25500) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={ga20fifty}/>);
    } else if (id == 24809 || id == 24810 || id == 24811 || id == 24812 || id == 24813 || id == 24814 || id == 24815 || id == 24816 || id == 24817 || id == 24818) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaEnergia}/>);
    } else if (id == 23828 || id == 23829 || id == 23830 || id == 23831 || id == 23832 || id == 23833 || id == 23834 || id == 23835 || id == 23836 || id == 23837) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaEnergia}/>);
    } else if (id == 24213 || id == 24214 || id == 24215 || id == 24216 || id == 24217 || id == 24218 || id == 24219 || id == 24220 || id == 24221 || id == 24222 || id == 24223 || id == 24224) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaDiageo}/>);
    } else if (id == 21651 || id == 21652 || id == 21653 || id == 21654 || id == 21655 || id == 21656 || id == 21657 || id == 21658) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={ga20fifty}/>);
    } else if (id == 23824 || id == 23825 || id == 23826 || id == 23827) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaVeolia}/>);
    } else if (id == 23838 || id == 23839) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaLumen}/>);
    } else if (id == 24234 || id == 24235 || id == 24236 || id == 24237 || id == 24238 || id == 24239) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaVerde}/>);
    } else if (id == 23862 || id == 23863 || id == 23864 || id == 23865 || id == 23866 || id == 23867 || id == 23868 || id == 23869 || id == 23870 || id == 23871) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaDavy}/>);
    } else if (id == 24346 || id == 24347) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaGreenOcean}/>);
    } else if (id == 24394 || id == 24395) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaGreenOcean}/>);
    } else if (id == 21999 || id == 22000 || id == 22001 || id == 22002 || id == 22003 || id == 22004 || id == 22005 || id == 22006 || id == 22007 || id == 22008 || id == 22009 || id == 22010 || id == 25069 || id == 25070 || id == 25071 || id == 25072) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaBord}/>);
    } else if (id == 21002 || id == 21003 || id == 21004 || id == 21005 || id == 21006 || id == 21007 || id == 21008 || id == 21009 || id == 21010 || id == 21011) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaEsb}/>);
    } else if (id == 24720 || id == 24721 || id == 24722 || id == 24723 || id == 24724 || id == 24725 || id == 24726 || id == 24727 || id == 24728 || id == 24729) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaCodex}/>);
    } else if (id == 20982 || id == 20983 || id == 20984 || id == 20985 || id == 20986 || id == 20987 || id == 20988 || id == 20989 || id == 20990 || id == 20991) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaCalor}/>);
    } else if (id == 24915 || id == 24916 || id == 24917 || id == 24918 || id == 24919 || id == 24920 || id == 24921 || id == 24922 || id == 24923 || id == 24924) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={gaEnterprise}/>);
    } else if (id == 25030) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iltaCubo}/>);
    } else if (id == 25821) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iltaFreightspeed}/>);
    } else if (id == 25724 || id == 25725 || id == 25726 || id == 25727 || id == 25753 || id == 25754 || id == 25755 || id == 25756) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iltaRsa}/>);
    } else if (id == 24010 || id == 24011 || id == 24012 || id == 24013 || id == 24014 || id == 24015 || id == 24016 || id == 24017 || id == 24018 || id == 24019 || id == 24020 || id == 24021) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iltaAib}/>);
    } else if (id == 23164 || id == 23165 || id == 23166 || id == 23167 || id == 25833) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iltaDrivingForce}/>);
    } else if (id == 21032 || id == 21033 || id == 21034 || id == 21035 || id == 21036 || id == 21037 || id == 21038 || id == 21039 || id == 21040 || id == 21041 || id == 21042 || id == 21043 || id == 21044 || id == 21045 || id == 21046 || id == 21047 || id == 21048 || id == 21049 || id == 21050 || id == 21051) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmSodexo}/>);
    } else if (id == 21052 || id == 21053 || id == 21054 || id == 21055 || id == 21056 || id == 21057 || id == 21058 || id == 21059 || id == 21060 || id == 21061 || id == 21062 || id == 21063 || id == 21064 || id == 21065 || id == 25246 || id == 25247) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmWestern}/>);
    } else if (id == 21066 || id == 21067 || id == 21068 || id == 21069 || id == 21070 || id == 21071 || id == 21072 || id == 21073 || id == 21074 || id == 21075 || id == 21076 || id == 21077 || id == 21078 || id == 21079) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmWilliamcox}/>);
    } else if (id == 21080 || id == 21081 || id == 21082 || id == 21083 || id == 21084 || id == 21085 || id == 21086 || id == 21087 || id == 21088 || id == 21089 || id == 21090 || id == 21091 || id == 21092 || id == 21093 || id == 21094 || id == 21095 || id == 21096 || id == 21097 || id == 21098 || id == 21099) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmSynergy}/>);
    } else if (id == 21533 || id == 21534 || id == 21535 || id == 21536 || id == 21537 || id == 21538 || id == 21539 || id == 21540 || id == 21541 || id == 21542 || id == 21543 || id == 21544 || id == 21545 || id == 21546 || id == 21547 || id == 21548 || id == 21549 || id == 21550 || id == 21551 || id == 21552) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmBerka}/>);
    } else if (id == 21553 || id == 21554 || id == 21555 || id == 21556 || id == 21557 || id == 21558 || id == 21559 || id == 21560 || id == 21561 || id == 21562 || id == 21563 || id == 21564 || id == 21565 || id == 21566 || id == 21567 || id == 21568 || id == 21569 || id == 21570 || id == 21571 || id == 21572 || id == 21573 || id == 21574) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmMitie}/>);
    } else if (id == 21575 || id == 21576 || id == 21577 || id == 21578 || id == 21579 || id == 21580 || id == 21581 || id == 21582 || id == 21583 || id == 21584 || id == 21585 || id == 21586 || id == 21587 || id == 21588 || id == 21589 || id == 21590 || id == 21591 || id == 21592 || id == 21593 || id == 21594) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmMartin}/>);
    } else if (id == 21595 || id == 21596 || id == 21597 || id == 21598 || id == 21599 || id == 21600 || id == 21601 || id == 21602 || id == 21603 || id == 21604 || id == 21605 || id == 21606 || id == 21607 || id == 21608) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmKarcher}/>);
    } else if (id == 21609 || id == 21610 || id == 21611 || id == 21612 || id == 21613 || id == 21614 || id == 21615 || id == 21616 || id == 21617 || id == 21618 || id == 21619 || id == 21620 || id == 21621 || id == 21622 || id == 21623 || id == 21624 || id == 21625 || id == 21626 || id == 21627 || id == 21628 || id == 21629 || id == 21630) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmTork}/>);
    } else if (id == 21668 || id == 21669 || id == 21670 || id == 21671 || id == 21672 || id == 21673 || id == 21674 || id == 21675 || id == 21676 || id == 21677) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmSensori}/>);
    } else if (id == 22519 || id == 21669 || id == 22520 || id == 22521 || id == 22522 || id == 22523 || id == 22524 || id == 22525 || id == 22526 || id == 22527 || id == 22528) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmSos}/>);
    } else if (id == 23471 || id == 23472 || id == 23473 || id == 23474 || id == 23475 || id == 23476 || id == 23477 || id == 23478 || id == 23479 || id == 23480) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmIss}/>);
    } else if (id == 23489 || id == 23490 || id == 23491 || id == 23492 || id == 23493 || id == 23494 || id == 23515 || id == 23516 || id == 23517 || id == 23518 || id == 23519 || id == 23520) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmThorntons}/>);
    } else if (id == 23601 || id == 23602 || id == 23603 || id == 23604) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmContico}/>);
    } else if (id == 23605 || id == 23606 || id == 23607 || id == 23608 ||id == 23609 || id == 23610 || id == 23611 || id == 23612 || id == 23613 || id == 23614) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmBam}/>);
    } else if (id == 23615 || id == 23616 || id == 23617 || id == 23618 ||id == 23619 || id == 23620 || id == 23621 || id == 23622 || id == 23623 || id == 23624) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmGloboserv}/>);
    } else if (id == 23718 || id == 23719 || id == 23720 || id == 23721 ||id == 23722 || id == 23723 || id == 23724 || id == 23725 || id == 23726 || id == 23727) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmBailey}/>);
    } else if (id == 23731 || id == 23732 || id == 23733 || id == 23734 ||id == 23735 || id == 23736 || id == 23737 || id == 23738 || id == 23739 || id == 23740) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmCbre}/>);
    } else if (id == 24250 || id == 24251 || id == 24252 || id == 24253 ||id == 24254 || id == 24255 || id == 24256 || id == 24257 || id == 24258 || id == 24259) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmLmc}/>);
    } else if (id == 24340 || id == 24341 || id == 24342 || id == 24343 ||id == 24344 || id == 24345) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmTech}/>);
    } else if (id == 25019 || id == 25020 || id == 25021 || id == 25022 ||id == 25023 || id == 25024 || id == 25025 || id == 25026 || id == 25027 || id == 25028) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmEwl}/>);
    } else if (id == 25732 || id == 25733) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmMkf}/>);
    } else if (id == 24987 || id == 24988 || id == 24989 || id == 24990 || id == 24991 || id == 24992 || id == 24993 || id == 24994 || id == 24995 || id == 24996) {
      //Horizon Property Support Ltd
      // return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmMkf}/>);
    } else if (id == 25053 || id == 25054) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmMkf}/>);
    } else if (id == 26324 || id == 26325) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={fmBrien}/>);
    } else if (id == 26698 || id == 26699 || id == 26700) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={meaDenroy}/>);
    } else if (id == 26035 || id == 26036) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={meaMcavoy}/>);
    } else if (id == 24956) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={meaVentac}/>);
    } else if (id == 25278 || id == 25279) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={meaDosco}/>);
    } else if (id == 25986 || id == 25987 || id == 25988 || id == 25989 || id == 25990) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={meaViatris}/>);
    } else if (id == 25390 || id == 25391) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={meaArrotek}/>);
    } else if (id == 25841 || id == 25842) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={bayaDavid}/>);
    } else if (id == 26097 || id == 26098 || id == 26099 || id == 26100 || id == 26101 || id == 26102 || id == 26103) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={bayaRhatigan}/>);
    } else if (id == 26359 || id == 26360 || id == 26361 || id == 26362 || id == 26363 || id == 26364 || id == 26365 || id == 26366 || id == 26367 || id == 26368) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={bayaPascall}/>);
    } else if (id == 23685 || id == 23686 || id == 23687 || id == 23688 || id == 23689 || id == 23690 || id == 23691 || id == 23692 || id == 23693 || id == 23694) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={bayaWalls}/>);
    } else if (id == 24202 || id == 24203 || id == 24204 || id == 24205 || id == 24206 || id == 24207 || id == 24208 || id == 24209 || id == 24210 || id == 24211) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={bayaNoyeks}/>);
    } else if (id == 27058 || id == 27062) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={bayaCwobrienarchi}/>);
    } else if (id == 27059) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={bayaDarmody}/>);
    } else if (id == 27060) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={bayaRyanlamb}/>);
    } else if (id == 27061) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={bayaTudublin}/>);
    } else if (id == 27063) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={bayaCundall}/>);
    } else if (id == 27064) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={bayaMcorm}/>);
    } else if (id == 27317 || id == 27318 || id == 27319) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsApc}/>);
    } else if (id == 27303 || id == 27304) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsArdmac}/>);
    } else if (id == 27258) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsAsgard}/>);
    } else if (id == 27326 || id == 27327) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsBiomarin}/>);
    } else if (id == 27294 || id == 27295 || id == 27296 || id == 27297 || id == 27298 || id == 27299 || id == 27300 || id == 27301 || id == 27302) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsClancy}/>);
    } else if (id == 27310 || id == 27311 || id == 27312) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsDanone}/>);
    } else if (id == 27313 || id == 27314 || id == 27315 || id == 27316) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsKellihers}/>);
    } else if (id == 27283 || id == 27284 || id == 27285 || id == 27286 || id == 27287 || id == 27288 || id == 27289 || id == 27290 || id == 27291 || id == 27292) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsLmc}/>);
    } else if (id == 27320 || id == 27321 || id == 27325 || id == 27322) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsMater}/>);
    } else if (id == 27269 || id == 27270 || id == 27271  || id == 27272 || id == 27273 || id == 27274 || id == 27275 || id == 27276 || id == 27277 || id == 27278) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsPobal}/>);
    } else if (id == 27307 || id == 27308 || id == 27309) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsShareridge}/>);
    } else if (id == 27323 || id == 27324) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsStewarts}/>);
    } else if (id == 27279 || id == 27280 || id == 27281 || id == 27282) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsTirlan}/>);
    } else if (id == 27305 || id == 27306) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsWoodies}/>);
    } else if (id == 27405 || id == 27406 || id == 27407 || id == 27408 || id == 27448) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsUisce}/>);
    } else if (id == 27329) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsAryzta}/>);
    } else if (id == 27339) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsBam}/>);
    } else if (id == 27331) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsBoston}/>);
    } else if (id == 27338) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsCentric}/>);
    } else if (id == 27332) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsCef}/>);
    } else if (id == 27334) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsDublinBus}/>);
    } else if (id == 27259 || id == 27260 || id == 27261  || id == 27262  || id == 27263  || id == 27264  || id == 27265  || id == 27266  || id == 27267  || id == 27268) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsIarnrod}/>);
    } else if (id == 27336) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsIbec}/>);
    } else if (id == 27337) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsPfizer}/>);
    } else if (id == 27330) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hsUcd}/>);
    } else if (id == 27630 || id == 27631 || id == 27632) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduAbbvie}/>);
    } else if (id == 27640 || id == 27641) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduApplegreen}/>);
    } else if (id == 27689 || id == 27690) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduCmit}/>);
    } else if (id == 27685 || id == 27686 || id == 27687 || id == 27688) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduDrogheda}/>);
    } else if (id == 27608 || id == 27609 || id == 27610 ||  id == 27611 ||  id == 27612 || id == 27613) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduDundalk}/>);
    } else if (id == 27589) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduFluirse}/>);
    } else if (id == 27636 || id == 27637) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduInsurance}/>);
    } else if (id == 27614 || id == 27615 || id == 27616 || id == 27617) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduLove}/>);
    } else if (id == 27634 || id == 27694 || id == 27695) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduMic125}/>);
    } else if (id == 27601 || id == 27602 || id == 27603 || id == 27604) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduMayo}/>);
    } else if (id == 27638 || id == 27639) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduNibrt}/>);
    } else if (id == 27554 || id == 27555 || id == 27556 || id == 27557 || id == 27558 || id == 27559 || id == 27566 || id == 27567 || id == 27568 || id == 27569) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduOfiaich}/>);
    } else if (id == 27698 || id == 27699) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduRcsi}/>);
    } else if (id == 27597) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduTechnological}/>);
    } else if (id == 27570) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduTusst}/>);
    } else if (id == 27633) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduUcc}/>);
    } else if (id == 27700 || id == 27701 || id == 27702 || id == 27739) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduSystems}/>);
    } else if (id == 27517 || id == 27518 || id == 27519 || id == 27520 || id == 27521 || id == 27522 || id == 27523 || id == 27524 || id == 2752 || id == 27525 || id == 27526 || id == 27547 || id == 27548 || id == 27549 || id == 27550 || id == 27551) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduCalpd}/>);
    } else if (id == 27647) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduUcccork}/>);
    } else if (id == 27719 || id == 27596 || id == 27703 || id == 27653 || id == 27704 || id == 27654 || id == 27705 || id == 27655 || id == 27706 || id == 27656 || id == 27707 || id == 27657 || id == 27708 || id == 27658 || id == 27709 || id == 27659 || id == 27710 || id == 27660 || id == 27711 || id == 27661 || id == 27712 || id == 27662 || id == 27715 || id == 27716 || id == 27717 || id == 27718) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduUniversityul}/>);
    } else if (id == 27697) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduMiddletown}/>);
    } else if (id == 27473 || id == 27474 || id == 27475) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduDbs}/>);
    } else if (id == 26834 || id == 26835 || id == 26836) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduAtu}/>);
    } else if (id == 26432 || id == 26433 || id == 26434 || id == 26435 || id == 26436 || id == 26437 || id == 26438 || id == 26439 || id == 26440 || id == 26441) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduGriffithcol}/>);
    } else if (id == 26475 || id == 26476 || id == 26477 || id == 26478 || id == 26479) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduEdhoc}/>);
    } else if (id == 25731 || id == 25734 || id == 25735 || id == 25736 || id == 25737 || id == 25738 || id == 25739 || id == 25740 || id == 25741 || id == 25742 || id == 27618 || id == 27619 || id == 27620 || id == 27586 || id == 27621 || id == 27622 || id == 27623 || id == 27648 || id == 27649 || id == 27650 || id == 27651 || id == 27652) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduMunster}/>);
    } else if (id == 26422 || id == 26423 || id == 26424 || id == 26425 || id == 26426 || id == 26427 || id == 26428 || id == 26429 || id == 26430 || id == 26431) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduLia}/>);
    } else if (id == 25684 || id == 25685) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduIce}/>);
    } else if (id == 25820) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduGenireland}/>);
    } else if (id == 26414 || id == 26415 || id == 26416 || id == 26417 || id == 26418 || id == 26419 || id == 26420 || id == 26421) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduGaelchultur}/>);
    } else if (id == 26973 || id == 26974 || id == 26975 || id == 26976 || id == 26977 || id == 26978 || id == 26979 || id == 26980 || id == 26981 || id == 26982) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eduSetu}/>);
    } else if (id == 28039 || id == 28040) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaAtu}/>);
    } else if (id == 25040) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaScaleIreland}/>);
    } else if (id == 26734 || id == 26735) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaUlster}/>);
    } else if (id == 27442 || id == 27443 || id == 27444) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaRoryWilliams}/>);
    } else if (id == 26724 || id == 26725 || id == 26726 || id == 26727 || id == 26728 || id == 26729 || id == 26730 || id == 26731 || id == 26732 || id == 26733 || id == 27946) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaHostelWorld}/>);
    } else if (id == 27723 || id == 27724) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaStorm}/>);
    } else if (id == 26490 || id == 26491 || id == 26492 || id == 26493 || id == 26494 || id == 26495 || id == 26496 || id == 26497 || id == 26498 || id == 26499 || id == 26500 || id == 26501) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaStrata}/>);
    } else if (id == 27509 || id == 27510 || id == 27511 || id == 27512 || id == 27513 || id == 27514 || id == 27515 || id == 27516) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaFahChart}/>);
    } else if (id == 27204 || id == 27205 || id == 27206 || id == 27207 || id == 27208 || id == 27209 || id == 27210 || id == 27211 || id == 27212 || id == 27213) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaWoodsPartners}/>);
    } else if (id == 26502 || id == 26503 || id == 26504 || id == 26505 || id == 26506 || id == 26507 || id == 26508 || id == 26509 || id == 26510 || id == 26511) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaBt}/>);
    } else if (id == 26894 || id == 26895 || id == 26896 || id == 26897 || id == 26898) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaRbk}/>);
    } else if (id == 26905 || id == 26906) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaWentWorth}/>);
    } else if (id == 26883 || id == 26884 || id == 26885 || id == 26886) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaIncorpro}/>);
    } else if (id == 26887 || id == 26888) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaFintechPro}/>);
    } else if (id == 26891 || id == 26892 || id == 26893 || id == 27437) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaFdcGroup}/>);
    } else if (id == 26956 || id == 26957 || id == 26958 || id == 26959 || id == 26960 || id == 26961 || id == 26962 || id == 26963) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaMcManus}/>);
    } else if (id == 26871 || id == 26872 || id == 26873 || id == 26874 || id == 26875 || id == 26876 || id == 26877 || id == 26878 || id == 26879 || id == 26880) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaHlbIreland}/>);
    } else if (id == 28051) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaAgAssociates}/>);
    } else if (id == 28045) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iaaAccountingTechniciansIreland}/>);
    } else if (id == 27727 || id == 27728 || id == 27729 || id == 27730 || id == 27731 || id == 27732 || id == 27733 || id == 27734 || id == 27735 || id == 27736 || id == 27737 || id == 27738) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={taiaCpa}/>);
    } else if (id == 27529 || id == 27530 || id == 27531) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={Opex24IrishPensionsFinance}/>);
    } else if (id == 28375 || id == 28376) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={Opex24DerryBros}/>);
    } else if (id == 28033 || id == 28034 || id == 28035 || id == 28036 || id == 28037 || id == 28038) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={Opex24CentricHealth}/>);
    } else if (id == 27827 || id == 27828 || id == 27829 || id == 27830) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={Opex24Writech}/>);
    } else if (id == 27811 || id == 27812 || id == 27813 || id == 27814) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={Opex24PassportService}/>);
    } else if (id == 27560 || id == 27561 || id == 27562 || id == 27563 || id == 27564 || id == 27565) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={Opex24DublinBus}/>);
    } else if (id == 27476 || id == 27477 || id == 27478 || id == 27479 || id == 27480 || id == 27481 || id == 27482 || id == 27483 || id == 27484 || id == 27485) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={Opex24Lmc}/>);
    } else if (id == 27855 || id == 27856) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={Opex24ConnectedHealth}/>);
    } else if (id == 28030 || id == 28031 || id == 28032) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={Opex24CjSheeran}/>);
    } else if (id == 28271 || id == 28272 || id == 28273) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={Opex24SouthCoast}/>);
    } else if (id == 28491 || id == 28492) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={Opex24Viatris}/>);
    } else if (id == 28525 || id == 28526 || id == 28527 || id == 28528 || id == 28529 || id == 28530 || id == 28531 || id == 28532 || id == 28533 || id == 28534) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={LsiaJanssenScience}/>);
    } else if (id == 28642 || id == 28643) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={LsiaNeurovalens}/>);
    } else if (id == 28537 || id == 28538) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={LsiaWuxi}/>);
    } else if (id == 28406 || id == 28407 || id == 28408) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={LsiaNelipak}/>);
    } else if (id == 26911 || id == 26912) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={lsiaUnispace}/>);
    } else if (id == 23745 || id == 23746) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={lsiaEllab}/>);
    } else if (id == 24673 || id == 24674 || id == 24675 || id == 24676 || id == 24677) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={lsiaCundall}/>);
    } else if (id == 2870981 || id == 2870982) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={lsiaEviView}/>);
    } else if (id == 2870984 || id == 2870985) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={lsiaExyte}/>);
    } else if (id == 2870990 || id == 2870991 || id == 2870992) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={lsiaHse}/>);
    } else if (id == 28806 || id == 28807 || id == 28808 || id == 28809 || id == 28810 || id == 28811 ||id == 28812 || id == 28813 || id == 28814 || id == 28815 || id == 2870916 || id == 2870917) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={dtaPat}/>);
    } else if (id == 28620 || id == 28621) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={dtaKaraConnect}/>);
    } else if (id == 28653 || id == 28654 || id == 28655 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={dtaJonesEngineering}/>);
    } else if (id == 28622 || id == 28623 || id == 28624 || id == 28625 || id == 28626 || id == 28627 ||id == 28628 || id == 28629 || id == 28630 || id == 28631) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={dtaBt}/>);
    } else if (id == 28516 || id == 28517 || id == 28518 || id == 28519) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={dtaKeltech}/>);
    } else if (id == 28865 || id == 28866) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={dtaMaynoothHse}/>);
    } else if (id == 2871052) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={dtaHse}/>);
    } else if (id == 2873745) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={aiaUkBusinessRiver}/>);
    } else if (id == 2873536) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={aiaUkLuxaviation}/>);
    } else if (id == 2872202 || id == 2872203 || id == 2872204) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={aiaUkLondonGatwick}/>);
    } else if (id == 2872702 || id == 2872703 || id == 2872704 || id == 2872705) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={aiaUkAircamo}/>);
    } else if (id == 2872734 || id == 2872735) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={aiaUkUniBedfordshire}/>);
    } else if (id == 2873291 || id == 2873292) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={aiaUkFirefly}/>);
    } else if (id == 2872171 || id == 2873742) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={aiaUkPath2pilot}/>);
    } else if (id == 2872300 || id == 2872301) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={aiaUkSkyPeople}/>);
    } else if (id == 2872300 || id == 2872301) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={aiaUkSkyPeople}/>);
    } else if (id == 2872306 || id == 2872307) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={aiaUkNats}/>);
    } else if (id == 2872504) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={aiaUkFarnborough}/>);
    } else if (id == 2872312 || id == 2872313 || id == 2872314 || id == 2872315 || id == 2872316 || id == 2872317 || id == 2872318 || id == 2872319 || id == 2872320 || id == 2872321) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={aiaUkMaglondon}/>);
    } else if (id == 2873555 || id == 2873556) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaUkConcorde}/>);
    } else if (id == 2871786 || id == 2871787) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={piaUkAlifeinaday}/>);
    } else if (id == 2872505 || id == 2872506) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={piaUkTiro}/>);
    } else if (id == 2872627) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={piaUkMagnitudeBiosciences}/>);
    } else if (id == 2872576) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={piaUkO2h}/>);
    } else if (id == 2872798) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={piaUkMesox}/>);
    } else if (id == 2872690 || id == 2872691 || id == 2872692 || id == 2872693 || id == 2872694 || id == 2872695 || id == 2872696 || id == 2872697 || id == 2872698 || id == 2872699) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={piaUkFingerPost}/>);
    } else if (id == 2873602 || id == 2873603) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={piaUkUcl}/>);
    } else if (id == 2871769 || id == 2871770) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaUkInteraction}/>);
    } else if (id == 2872219 || id == 2872220) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaUkClaremont}/>);
    } else if (id == 2873864) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaUkAqa}/>);
    } else if (id == 2873306) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaUkElliot}/>);
    } else if (id == 2874587 || id == 2874588) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaUkEkho}/>);
    } else if (id == 2873616 || id == 2873617) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={isaJusteat}/>);
    } else if (id == 2872632 || id == 2872633 || id == 2872634) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={isaNaturevalley}/>);
    } else if (id == 2872564 || id == 2872565 || id == 2872566 || id == 2872567 || id == 2872568 || id == 2872569 || id == 2872570 || id == 2872571 || id == 2872572 || id == 2872573 || id == 2872574 || id == 2872575) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={isaOmnicam}/>);
    } else if (id == 2873923 || id == 2873924) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={isaStPatrick}/>);
    } else if (id == 2874550 || id == 2874551 || id == 2874552) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={isaDrOetker}/>);
    } else if (id == 2872875 || id == 2872876 || id == 2872877 || id == 2872878 || id == 2872879 || id == 2872880 || id == 2872881 || id == 2872882 || id == 2872883 || id == 2872884 || id == 2872885 || id == 2872886) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={isaThree}/>);
    } else if (id == 2873232 || id == 2873233 || id == 2873234 || id == 2873235 || id == 2873236 || id == 2873237 || id == 2873238 || id == 2873239 || id == 2873240 || id == 2873241) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={isaElectricIreland}/>);
    } else if (id == 2872787 || id == 2872788 || id == 2872789 || id == 2872790 || id == 2872791 || id == 2872792 || id == 2872793 || id == 2872794 || id == 2872795 || id == 2872796) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={isaMoriartyGroup}/>);
    } else if (id == 2872861 || id == 2872862 || id == 2872863) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={beaaAstellas}/>);
    } else if (id == 2873787) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={beaaMahonPoint}/>);
    } else if (id == 2872848 || id == 2872849 || id == 2872850) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={beaaKyte}/>);
    } else if (id == 2874570) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={beaaBreedon}/>);
    } else if (id == 2874589 || id == 2874590) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaUkIncognito}/>);
    } else if (id == 2871528) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaUkElliot}/>);
    } else if (id == 2874789 || id == 2874790) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={beaaAcornCloud}/>);
    } else if (id == 2873504) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={isaEuropar}/>);
    } else if (id == 2873505) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={isaMace}/>);
    } else if (id == 2873506) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={isaSpar}/>);
    } else if (id == 2872602 || id == 2872603) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={phaLikha}/>);
    } else if (id == 2873482 || id == 2873483) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={phaAffidea}/>);
    } else if (id == 2873509 || id == 2873510) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={phaMkAesthetics}/>);
    } else if (id == 2873363 || id == 2873364 || id == 2873365 || id == 2873366 || id == 2873367 || id == 2873368 || id == 2873369 || id == 2873370 || id == 2873371 || id == 2873372) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={phaUpmc}/>);
    } else if (id == 2873777 || id == 2873778 || id == 2873779 || id == 2873780 || id == 2873781 || id == 2873782 || id == 2873783 || id == 2873784 || id == 2873785 || id == 2873786) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={phaAxonRehab}/>);
    } else if (id == 2874957) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={phaJohnsonJohnson}/>);
    } else if (id == 2873512 || id == 2873513 || id == 2873514 || id == 2873515 || id == 2873516 || id == 2873517 || id == 2873518 || id == 2873519 || id == 2873520 || id == 2873521 || id == 2873522 || id == 2873523) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={phaMaterPrivate}/>);
    } else if (id == 2873873 || id == 2873874 || id == 2873875 || id == 2873876) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={phaMusgrave}/>);
    } else if (id == 2874571 || id == 2874572 || id == 2874573 || id == 2874574) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={phaAilesbury}/>);
    } else if (id == 2873953 || id == 2873954) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={phaNewVision}/>);
    } else if (id == 2872685 || id == 2872686) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hrCopacetic}/>);
    } else if (id == 2873286 || id == 2873287 || id == 2873288 || id == 2873289 || id == 2873290) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hrCpl}/>);
    } else if (id == 2873763 || id == 2873764) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hrBon}/>);
    } else if (id == 2874152 || id == 2874153 || id == 2874154 || id == 2874155 || id == 2874156 || id == 2874157 || id == 2874158 || id == 2874159 || id == 2874160 || id == 2874161) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hrPrimeline}/>);
    } else if (id == 2873436 || id == 2873437 || id == 2873438 || id == 2873439 || id == 2873440 || id == 2873441 || id == 2873442 || id == 2873443 || id == 2873444 || id == 2873445) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hrBaxter}/>);
    } else if (id == 2873323 || id == 2873324 || id == 2873325 || id == 2873326 || id == 2873327 || id == 2873328 || id == 2873329 || id == 2873330 || id == 2873331 || id == 2873332) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hrBordnamona}/>);
    } else if (id == 2873465 || id == 2873466 || id == 2873467 || id == 2873468 || id == 2873469 || id == 2873470 || id == 2873471 || id == 2873472 || id == 2873473 || id == 2873474) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hrDoT}/>);
    } else if (id == 2873491 || id == 2873492 || id == 2873493 || id == 2873494 || id == 2873495 || id == 2873496 || id == 2873497 || id == 2873498 || id == 2873499 || id == 2873500) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hrThree}/>);
    } else if (id == 2873638 || id == 2873639 || id == 2873640 || id == 2873641 || id == 2873642 || id == 2873643) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hrBoots}/>);
    } else if (id == 2873986 || id == 2873987) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hrIrishDistillers}/>);
    } else if (id == 2875176 || id == 2875177) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hrSdWorx}/>);
    } else if (id == 2874162 || id == 2874163) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hrVoya}/>);
    } else if (id == 2874794 || id == 2874795 || id == 2874796) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hrAviva}/>);
    } else if (id == 2873744 || id == 2873797 || id == 2873833 || id == 2873834) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hrPtc}/>);
    } else if (id == 2873407 || id == 2873408 || id == 2873409 || id == 2873410 || id == 2873411 || id == 2873412 || id == 2873413 || id == 2873414 || id == 2873415 || id == 2873416) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hrGlenveagh}/>);
    } else if (id == 2875262 || id == 2875263 || id == 2875264 || id == 2875265) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hrFineos}/>);
    } else if (id == 2875488) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={hrAvolon}/>);
    } else if (id == 2873909 || id == 2873910 || id == 2873911 || id == 2873912 || id == 2873913 || id == 2873914 || id == 2873915 || id == 2873916 || id == 2873917 || id == 2873918 || id == 2873919 || id == 2873920) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={npaAib}/>);
    } else if (id == 2874624 || id == 2874625 || id == 2874626 || id == 2874627 || id == 2874628 || id == 2874629 || id == 2874630 || id == 2874631 || id == 2874632 || id == 2874633) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={npaPtsb}/>);
    } else if (id == 2874737 || id == 2874738 || id == 2874739 || id == 2874740 || id == 2875261) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={npaAnpost}/>);
    } else if (id == 2873895 || id == 2873896 || id == 2873897 || id == 2873898 || id == 2873899 || id == 2873900 || id == 2873901 || id == 2873902 || id == 2873903 || id == 2873904 || id == 2875623 || id == 2875624) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={npaEirgrid}/>);
    } else if (id == 2875518 || id == 2875519 || id == 2875520 || id == 2875521 || id == 2875522 || id == 2875523) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={npaBusEireann}/>);
    } else if (id == 2874080 || id == 2874081 || id == 2874082) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eeaTobin}/>);
    } else if (id == 2874741 || id == 2874742 || id == 2874743) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eeaWritech}/>);
    } else if (id == 2873392 || id == 2873393 || id == 2875654) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eeaBFluid}/>);
    } else if (id == 2873446 || id == 2873447 || id == 2873448) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eeaAxiseng}/>);
    } else if (id == 2873798 || id == 2873799 || id == 2873800 || id == 2873801 || id == 2873802 || id == 2873803 || id == 2873804 || id == 2873805 || id == 2873806 || id == 2873807) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eeaKirby}/>);
    } else if (id == 2874206 || id == 2874207 || id == 2874208 || id == 2874209 || id == 2874210 || id == 2874211 || id == 2874212 || id == 2874213 || id == 2874214 || id == 2874215) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eeaJonesEngineering}/>);
    } else if (id == 2874576 || id == 2874577 || id == 2874578 || id == 2874579 || id == 2874580 || id == 2874581 || id == 2874582 || id == 2874583 || id == 2874584 || id == 2874585) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={eeaJonesHmv}/>);
    } else if (id == 2876598) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iciaKelbuild}/>);
    } else if (id == 2876610 || id == 2876611 || id == 2876612 || id == 2876613) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iciaReddy}/>);
    } else if (id == 2875380 || id == 2875381 || id == 2875382 || id == 2875384 || id == 2875385 || id == 2875386 || id == 2876743) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iciaHaysIreland}/>);
    } else if (id == 2876618 || id == 2876619 || id == 2876706 || id == 2876707 || id == 2876708 || id == 2876709 || id == 2876710 || id == 2876711 || id == 2876763) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iciaWard}/>);
    } else if (id == 2874635) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={iciaConack}/>);
    } else if (id == 27775 || id == 27776 || id == 27777 || id == 27778) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaSteelcase}/>);
    } else if (id == 2877090 || id == 2877091 || id == 2877092 || id == 2877093) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaUbi}/>);
    } else if (id == 2877950 || id == 2877951) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaMinima}/>);
    } else if (id == 2877963 || id == 2877978) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaOak}/>);
    } else if (id == 2875410 || id == 2875410 || id == 2875410 || id == 2875410 || id == 2875410 || id == 2875410 || id == 2875410 || id == 2875410 || id == 2875410 || id == 2875410) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaReddy}/>);
    } else if (id == 2877682 || id == 2877683 || id == 2877684 || id == 2877685 || id == 2877686) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaHoran}/>);
    } else if (id == 2876204 || id == 2876205) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaGlenCullen}/>);
    } else if (id == 2877680 || id == 2877681) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaAirconMech}/>);
    } else if (id == 2871543 || id == 2871544) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaConroy}/>);
    } else if (id == 2875884 || id == 2875914 || id == 2877642 || id == 2877838) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaSlmd}/>);
    } else if (id == 2877839) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaTotp}/>);
    } else if (id == 2875882 || id == 2875883) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaQid}/>);
    } else if (id == 2871541 || id == 2871542) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={foaTexspace}/>);
    } else if (id == 2875465 || id == 2875466 || id == 2875467 || id == 2875468 || id == 2875469) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={cxTestReach}/>);
    } else if (id == 2875525 || id == 2875526 || id == 2875527 || id == 2875528 || id == 2875529 || id == 2875530 || id == 2875531 || id == 2875532 || id == 2875533 || id == 2875534) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={aiaAerLingus}/>);
    } else if (id == 2876374 || id == 2876375) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={aiaIntertec}/>);
    }
  
    if (companyName && companyLogos[award_id]) {
      const image = companyLogos[award_id].filter(x => {
        if (x.company_name == companyName) {
          return x;
        }
      }).map(x => x);

      if(image.length < 1) {
        return '';
      } else {
        return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={image[0].logo}/>);
      }
    } else {
      return '';
    }
  }

  useEffect(() => {
  }, []);

  useEffect(() => {

    if (!(isLoggedIn !== 'true' && window.location.pathname.includes('admin'))) {
      getAward().then(async response => {
        const selectedAward = response.data.data;
        if (selectedAward) {
          setAward(selectedAward);

          if (tablePerRow) {
            const columnWidth = 100 / tablePerRow;
            const columnWidthString = columnWidth.toString() + '%';
            setColumnMinWidth(columnWidthString);
          }

          await getTables();

          setFormData(() => ({
            ...formData,
            name: selectedAward.name,
            logo: selectedAward.logo,
            website_url: selectedAward.website_url,
            description: selectedAward.description,
            support_email: selectedAward.support_email,
            support_phone: selectedAward.support_phone,
            event: selectedAward.event,
            entry_guide_url: selectedAward.event.entry_guide_url,
            status: selectedAward.status,
            table_per_row: tablePerRow,
          }));
          setVenueData({
            ...venueData,
            venue_name: selectedAward.venue.name,
          });
          setTablesLoading(false);
        } else {
          catchWarning();
        }
      }).catch(() => {
        catchError();
      });
    }

  }, [reloadCount])

  useEffect(() => {
    if (isLoggedIn !== 'true' && window.location.pathname.includes('admin')) {
      window.location.href = "http://app.businessriver.com/";
    } else {
      const interval = setInterval( () => {
        getCheckSeat().then(response => {
          const result = response.data.seat_last_updated;
          if (result != updatedAt) {
            const row = response.data.table_per_row;
            updatedAt = result;
            if (updatedAt) {
              getTables();
            }
          }
        }).catch(() => catchError());
      }, 8000);

      return () => clearInterval(interval);
    }
  }, []);

  const handleSearch = (e) => {
    const getTables = async (e) => {
      const selectedTables = await retrieveTablesSearch(e);
      if (selectedTables) {
        setTables(selectedTables.tables);
        setSeatingArrangementLoading(false);
        if (selectedTables.total_seats_per_table) {
          setNumberOfSeats(selectedTables.total_seats_per_table);
        }
        setTablesLoading(false);
      }
    };
    getTables(e);

    const getAttendees = async (e) => {
      const selectedAttendees = await retrieveAttendeesSearch(e);
      if (selectedAttendees) {
        setAttendeesListLoading(false);
        setAttendees(selectedAttendees);
      };
    };

    getAttendees(e);
  }

  const renderToHTMLString = (jsx) => {
    // Get the current base URL (for relative paths)
    const baseUrl = window.location.origin;
  
    // Convert JSX to an HTML string
    const htmlString = ReactDOMServer.renderToStaticMarkup(jsx);
  
    // Modify any relative image URLs by prepending the base URL
    return htmlString.replace(/src="(\/[^\"]+)"/g, (match, p1) => {
      return `src="${baseUrl}${p1}"`; // Prepend base URL to relative paths
    });
  };

  // Function to safely escape special characters in user data
  const safeString = (str) => str ? str.replace(/</g, "&lt;").replace(/>/g, "&gt;") : "";

  // Download the Seat Plan HTML
  const handleDownloadSeatPlanHtml = () => {  
    // Create the HTML content dynamically with maps and conditions
    const htmlContent = `
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
        .entry-logo img {
          height: auto !important;
          width: 100% !important;
          min-height: 17px !important;
          max-height: 17px !important;
          max-width: 100% !important;
        }
        .cr-app.bg-light {
          background-color: transparent !important;
        }
      </style>
      <div style="font-family: Poppins;">
        <div style="text-align: center; min-height: 850px; display: flex; justify-content: center; align-items: center;">
          <div>
            <img src=${formData.logo} style="max-width: 600px; width: auto; height: auto; max-height: 100%; display: block; margin-left: auto; margin-right: auto;">
            <p style="margin-top: 20px; margin-bottom: 0px; font-size: 35px; font-family: Poppins; font-weight: 600; text-align: center;">
              Seating Plan
            </p>
            ${venueData.venue_name && venueData.venue_name !== "" ? `
              <p style="font-size: 18px; margin-top: 0; font-family: Poppins; text-align: center; font-weight: 600;">
                ${safeString(venueData.venue_name)}
              </p>
            ` : ""}
            <img src="https://bis-administration.web.app/static/media/seat-plan-qr.efbe250e.png" style="max-width: 200px; width: auto; height: auto; max-height: 100%; display: block; margin-left: auto; margin-right: auto;">
          </div>
        </div>
        ${tables.map((x, tableIndex) => `
          <div style="padding-left: 50px; padding-right: 50px;">
            ${x.map(({ id, image, name, no_of_seats, company_name, attendees }, index) => `
              <div style="width: 44%; display: inline-block; padding-left: 2.5%; padding-right: 2.5%; vertical-align: top; margin-top: 40px;">
                <div style="min-height: 400px;">
                  <div>
                    <p
                      style="font-size: 30px; font-family: Poppins; margin-bottom: 10px; margin-top: 0; font-weight: 600;">
                      Table ${safeString(name)}
                    </p>
                  </div>
                  <div>
                    ${attendees.length > 0
                      ? attendees.map((user) => `
                        <div style="display: flex; justify-content: space-between; padding-bottom: 6px; padding-top: 6px; border-top: 1px solid #e2e2e2;">
                          <div>
                            <span style="height: 0.5vw; width: 0.5vw;"></span>
                            <span style="font-family: Poppins; font-size: 12px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; width: 5.5vw;">
                              ${user.first_name && user.last_name 
                                ? `${safeString(user.first_name)} ${safeString(user.last_name)}` 
                                : user.first_name 
                                  ? safeString(user.first_name) 
                                  : user.last_name 
                                    ? safeString(user.last_name) 
                                    : "Reserved"}
                            </span>
                          </div>
                          <div class="entry-logo">
                            ${user.entry_logo && !staticAttendeeId.includes(user.id)
                              ? `<img src="${user.entry_logo}" />`
                              : renderToHTMLString(getCompanyLogos(user.company_name, user.id))
                            }
                          </div>
                        </div>`
                      ).join("") : ""}
                  </div>
                </div>
              </div>
            `).join("")}
          </div>
        `).join("")}
      </div>
    `;
  
    // Create a Blob from the HTML string
    const blob = new Blob([htmlContent], { type: 'text/html' });
  
    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);
  
    // Create an anchor element and trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = 'seat-plan.html'; // Name of the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // const element = document.createElement('div');
    // element.innerHTML = htmlContent; // Set the generated HTML content

    // const options = {
    //   margin: 1,
    //   filename: 'seat-plan.pdf',
    //   image: { type: 'jpeg', quality: 0.98 },
    //   html2canvas: { scale: 2 },
    //   jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
    // };

    // html2pdf().from(element).set(options).save();

    setSeatPlanHtml(htmlContent);
  };

  useEffect(() => {
    if (download == 1 && tables && tables.length > 1 && pdfDownloaded == 0 && formData && formData.logo && formData.logo != "") {
      handleDownloadSeatPlanHtml();
      pdfDownloaded = 1;
    }
  }, [download, tables]);

  return (
    <>
      <Container className={window.location.pathname.includes('venue/plan') ? 'h-100 mt-2' : 'mt-2'} fluid>
        {tablesLoading ?
          <>
            <PageSpinner />
          </>
          :
          <Row>
            <Col lg="12">
              <Card className={window.location.pathname.includes('venue/plan') ? 'border-0' : null}>
                {!window.location.pathname.includes('venue/plan') ?
                  <div className='card-body py-2 px-4' style={{borderBottom: '1px solid #f2e7e7'}}>
                    <div className='floor-plan-font mb-0 d-flex justify-content-between align-items-center'>
                      {award_id == 62 || award_id == 63 ?
                        <img src={formData.logo} style={{height: 'auto', width: '15%'}}/>
                        : award_id == 76 ?
                        <img src={formData.logo} style={{height: 'auto', width: '14%'}}/>
                        : award_id == 77 ?
                        <img src={formData.logo} style={{height: 'auto', width: '12%'}}/>
                        :
                        <img src={formData.logo} style={{height: 'auto', width: '20%'}}/>
                      }
                      <div style={{flex: 2, paddingLeft: 20, paddingRight: 20, overflow: 'hidden' }}>
                        <SponsorsMarquee award_id={award_id} />
                      </div>
                      <div className="d-flex align-items-center justify-content-end" style={{ width: "30%"}}>
                        <div className="mr-2" style={{ width: "77%" }}>
                          <span style={{fontSize: '3vw', fontWeight: '700'}}>Seating Plan</span>
                          <div className="floor-plan-font-divider"></div>
                          {venueData.venue_name && venueData.venue_name != "" ?
                            <p className="floor-plan-subfont mb-0" style={{fontSize: '1vw', marginTop: '6px'}}>{award.venue.name}</p>
                            :
                            null
                          }
                        </div>
                        <div style={{ width: "23%" }}>
                          <img style={{height: 'auto', width: '100%'}} src={SeatPlanQR}/>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  null
                }
                <CardBody className='py-1'>
                  {tables.length > 0 ?
                    <>
                      {/* Descending */}
                      <Carousel cols={1} rows={1} containerStyle={{maxWidth: '100%', marginBottom: '0.5rem'}} showDots={false} hideArrow={true} loop dotColorActive='#1ec2f3' autoplay={30000}>
                        {tables.map((x, tableIndex) => (
                          <Carousel.Item>
                            <Row>
                              {x.map(({ id, image, name, no_of_seats, company_name, attendees }, index) => (
                                <>
                                  <Col className="mb-1" style={{minWidth: columnMinWidth}} key={"attendee-table-"+index}>
                                    <Card className="h-100 border-0">
                                      <CardHeader className='p-1 d-flex align-items-center justify-content-between border-bottom-0'>
                                        {/*<Row>*/}
                                        {/*  <Col lg="12">*/}
                                        <h5 className="modal-title text-uppercase text-left mb-0" style={{fontSize: fontSize}}>Table {name}</h5>
                                        <span className='d-flex align-items-center'>
                                              {/*<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={softworks}/>*/}
                                          {/*<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={icon}/>*/}
                                          {/*<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={irishTimes}/>*/}
                                        </span>
                                        {/*  </Col>*/}
                                        {/*</Row>*/}
                                      </CardHeader>
                                      <CardBody className='py-0 px-1 border-top-0'>
                                        {attendees.length > 0 ?
                                          <>
                                            {attendees.map((user, user_index) => {
                                              return (
                                                <>
                                                  <div className="mb-0 d-flex justify-content-between">
                                                    <div
                                                      className='d-flex align-items-center mr-1'
                                                    >
                                                      <span className='attendee-box taken mr-1' style={{height: fontSize, width: fontSize}}></span>
                                                      <span
                                                        style={{
                                                          fontSize: fontSize,
                                                          textOverflow: 'ellipsis',
                                                          whiteSpace: 'nowrap',
                                                          overflow: 'hidden',
                                                          width: '5.5vw'
                                                        }}>
                                                            {user.first_name && user.last_name ?
                                                              user.first_name + ' ' + user.last_name
                                                              : user.first_name ?
                                                                user.first_name
                                                                : user.last_name ?
                                                                  user.last_name
                                                                  :
                                                                  'Reserved'
                                                            }
                                                          </span>
                                                    </div>
                                                    {/*<div className='text-center mr-1'*/}
                                                    {/*     style={{*/}
                                                    {/*       fontSize: fontSize,*/}
                                                    {/*       textOverflow: 'ellipsis',*/}
                                                    {/*       whiteSpace: 'nowrap',*/}
                                                    {/*       overflow: 'hidden',*/}
                                                    {/*       width: '7vw'*/}
                                                    {/*     }}>*/}
                                                    {/*  {user.company_name}*/}
                                                    {/*</div>*/}
                                                    <div className='d-flex align-items-center justify-content-end' style={{width: '2vw'}}>
                                                      {user.custom_logo && !staticAttendeeId.includes(user.id) ?
                                                        <img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={process.env.REACT_APP_URL + user.custom_logo}/>
                                                      : user.entry_logo && !staticAttendeeId.includes(user.id) ?
                                                        <img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: fontSize}} src={user.entry_logo}/>
                                                      :
                                                        <>
                                                          {getCompanyLogos(user.company_name, user.id)}
                                                        </>
                                                      }
                                                    </div>
                                                  </div>

                                                </>
                                              )
                                            })}
                                            {(function (rows, i, len) {
                                              while (++i <= len) {
                                                rows.push(
                                                  <div className="mb-0 d-flex align-items-center" style={{fontSize: fontSize, opacity: 0}}>
                                                    <div
                                                      className='d-flex align-items-center'
                                                    >
                                                      <span className='attendee-box available mr-1' style={{height: fontSize, width: fontSize}}></span>
                                                      <span style={{
                                                        fontSize: fontSize,
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        width: '5.5vw'
                                                      }}>Available</span>
                                                    </div>
                                                    <div className='text-right'
                                                        style={{
                                                          fontSize: fontSize,
                                                          textOverflow: 'ellipsis',
                                                          whiteSpace: 'nowrap',
                                                          overflow: 'hidden',
                                                          width: '2vw'
                                                        }}>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                              return rows;
                                            })([], attendees.length, no_of_seats)}
                                          </>
                                          :
                                          <>
                                            {(function (rows, i, len) {
                                              while (++i <= len) {
                                                rows.push(
                                                  <div className="mb-0 d-flex align-items-center" style={{fontSize: fontSize, opacity: 0}}>
                                                    <div
                                                      className='d-flex align-items-center'
                                                    >
                                                      <span className='attendee-box available mr-1' style={{height: fontSize, width: fontSize}}></span>
                                                      <span style={{
                                                        fontSize: fontSize,
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        width: '5.5vw'
                                                      }}>Available</span>
                                                    </div>
                                                    <div className='text-right'
                                                        style={{
                                                          fontSize: fontSize,
                                                          textOverflow: 'ellipsis',
                                                          whiteSpace: 'nowrap',
                                                          overflow: 'hidden',
                                                          width: '2vw'
                                                        }}>
                                                    </div>
                                                  </div>
                                                )
                                              }
                                              return rows;
                                            })([], 0, no_of_seats)}
                                          </>
                                        }
                                      </CardBody>
                                    </Card>
                                  </Col>
                                  {tables.length - 1 === tableIndex && x.length - 1 === index ?
                                    <>
                                      {tablesPlaceholder.map(() => (
                                        <Col>
                                          <Card className='border-0'>
                                            <CardHeader className='border-0 py-0 px-1'>
                                              <h5 className="modal-title d-inline text-uppercase text-left" style={{fontSize: fontSize}}> </h5>
                                            </CardHeader>
                                            <CardBody className='border-0 py-0 px-1'>
                                              <div className="mb-0 d-flex align-items-center" style={{fontSize: fontSize}}>
                                                <div
                                                  className='d-flex align-items-center'
                                                >
                                                  {/*<span className='attendee-box available mr-1' style={{height: fontSize, width: fontSize}}></span>*/}
                                                  <span style={{
                                                    fontSize: fontSize,
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    width: '5vw'
                                                  }}> </span>
                                                </div>
                                                <div className='text-right'
                                                    style={{
                                                      fontSize: fontSize,
                                                      textOverflow: 'ellipsis',
                                                      whiteSpace: 'nowrap',
                                                      overflow: 'hidden',
                                                      width: '2  vw'
                                                    }}>
                                                </div>
                                              </div>
                                            </CardBody>
                                          </Card>
                                        </Col>
                                      ))}
                                    </>
                                    :
                                    null
                                  }
                                </>
                              ))}
                            </Row>
                          </Carousel.Item>
                        ))}
                      </Carousel>
                      {/*{tables.sort((a, b) => parseInt(a.name) - parseInt(b.name)).filter(table => table.category !== "other").map(({ id, image, name, no_of_seats, company_name, attendees }, index) => (*/}
                      {/*  <Col lg="4" className="mb-3" key={"attendee-table-"+index}>*/}
                      {/*    <Card className="h-100">*/}
                      {/*      <CardHeader>*/}
                      {/*        <Row>*/}
                      {/*          <Col lg="12">*/}
                      {/*            <h5 className="modal-title d-inline text-uppercase text-left">Table {name}</h5>*/}
                      {/*          </Col>*/}
                      {/*        </Row>*/}
                      {/*      </CardHeader>*/}
                      {/*      <CardBody>*/}
                      {/*        {attendees.length > 0 ?*/}
                      {/*          <>*/}
                      {/*            {attendees.map((user, user_index) => {*/}
                      {/*              return (*/}
                      {/*                <>*/}
                      {/*                  <div className="mb-2 d-flex justify-content-between">*/}
                      {/*                    <div className='d-flex align-items-center'>*/}
                      {/*                      <span className='attendee-box taken mr-1'></span>*/}
                      {/*                      {user.first_name && user.last_name ?*/}
                      {/*                        <span> {user.first_name} {user.last_name}</span>*/}
                      {/*                        : user.first_name ?*/}
                      {/*                          <span> {user.first_name}</span>*/}
                      {/*                          : user.last_name ?*/}
                      {/*                            <span> {user.last_name}</span>*/}
                      {/*                            :*/}
                      {/*                            <span> Reserved</span>*/}
                      {/*                      }*/}
                      {/*                    </div>*/}
                      {/*                    <div className='text-right'>*/}
                      {/*                      {user.company_name}*/}
                      {/*                    </div>*/}
                      {/*                  </div>*/}

                      {/*                </>*/}
                      {/*              )*/}
                      {/*            })}*/}
                      {/*            {(function (rows, i, len) {*/}
                      {/*              while (++i <= len) {*/}
                      {/*                rows.push(*/}
                      {/*                  <div className="mb-2">*/}
                      {/*                    <span className='attendee-box available mr-1'></span>*/}
                      {/*                    <span>Available</span>*/}
                      {/*                  </div>*/}
                      {/*                )*/}
                      {/*              }*/}
                      {/*              return rows;*/}
                      {/*            })([], attendees.length, no_of_seats)}*/}
                      {/*          </>*/}
                      {/*          :*/}
                      {/*          <>*/}
                      {/*            {(function (rows, i, len) {*/}
                      {/*              while (++i <= len) {*/}
                      {/*                rows.push(*/}
                      {/*                  <div className="attendee-container available mb-2">*/}
                      {/*                    <span>Available</span>*/}
                      {/*                  </div>*/}
                      {/*                )*/}
                      {/*              }*/}
                      {/*              return rows;*/}
                      {/*            })([], 0, no_of_seats)}*/}
                      {/*          </>*/}
                      {/*        }*/}
                      {/*      </CardBody>*/}
                      {/*    </Card>*/}
                      {/*  </Col>*/}
                      {/*))}*/}
                    </>
                    :
                    <div lg={12}>
                      <p className="mb-0 text-center">No records found</p>
                    </div>
                  }
                  {/*  </CardBody>*/}
                  {/*</Card>*/}
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
      </Container>
    </>
  )
}

export default AdminVenueSeatingPage;